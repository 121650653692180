(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('antivirus', {
            parent: 'admin',
            url: '/antivirus',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'antivirus.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/antivirus/antivirus.html',
                    controller: 'AntivirusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('antivirus');
                        return $translate.refresh();
                    }],
                status: ['$http', function ($http) {
                        return $http.get('api/antivirus/status').then(function (response) {
                            return response.data;
                        });
                    }],
                cron: ['$http', function ($http) {
                        return $http.get('api/antivirus/cron').then(function (response) {
                            return response.data;
                        });
                    }],
                stats: ['$http', function ($http) {
                        return $http.get('api/antivirus/stats').then(function (response) {
                            return response.data;
                        });
                    }],
                url: ['$http', function ($http) {
                        return $http.get('api/antivirus/url').then(function (response) {
                            return response.data;
                        });
                    }]
            }
        });
    }
})();