(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('EventRegistrationMailSearch', EventRegistrationMailSearch);

    EventRegistrationMailSearch.$inject = ['$resource'];

    function EventRegistrationMailSearch($resource) {
        var resourceUrl =  'api/_search/event-registration-mails/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
