(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('call-for-project-form-field', {
            parent: 'entity',
            url: '/call-for-project-form-field',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'canceropoleGsoApp.callForProjectFormField.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-form-field/call-for-project-form-fields.html',
                    controller: 'CallForProjectFormFieldController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectFormField');
                    $translatePartialLoader.addPart('formFieldType');
                    $translatePartialLoader.addPart('criterionType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('call-for-project-form-field-detail', {
            parent: 'entity',
            url: '/call-for-project-form-field/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'canceropoleGsoApp.callForProjectFormField.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-form-field/call-for-project-form-field-detail.html',
                    controller: 'CallForProjectFormFieldDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectFormField');
                    $translatePartialLoader.addPart('formFieldType');
                    $translatePartialLoader.addPart('criterionType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CallForProjectFormField', function($stateParams, CallForProjectFormField) {
                    return CallForProjectFormField.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('call-for-project-form-field.new', {
            parent: 'call-for-project-form-field',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form-field/call-for-project-form-field-dialog.html',
                    controller: 'CallForProjectFormFieldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                type: null,
                                label: null,
                                position: null,
                                required: false,
                                legend: null,
                                maxLength: null,
                                criterion: false,
                                criterionType: null,
                                defaultValue: null,
                                config: null,
                                criterionConfig: null,
                                criterionHelp: null,
                                criterionLabel: null,
                                criterionDefaultValue: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form-field', null, { reload: true });
                }, function() {
                    $state.go('call-for-project-form-field');
                });
            }]
        })
        .state('call-for-project-form-field.edit', {
            parent: 'call-for-project-form-field',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form-field/call-for-project-form-field-dialog.html',
                    controller: 'CallForProjectFormFieldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CallForProjectFormField', function(CallForProjectFormField) {
                            return CallForProjectFormField.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form-field', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('call-for-project-form-field.delete', {
            parent: 'call-for-project-form-field',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form-field/call-for-project-form-field-delete-dialog.html',
                    controller: 'CallForProjectFormFieldDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CallForProjectFormField', function(CallForProjectFormField) {
                            return CallForProjectFormField.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form-field', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
