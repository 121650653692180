(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('person-axe', {
                    parent: 'entity',
                    url: '/person-axe',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.personAxe.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/person-axe/person-axes.html',
                            controller: 'PersonAxeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('personAxe');
                                $translatePartialLoader.addPart('personAxeType');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('person-axe-detail', {
                    parent: 'entity',
                    url: '/person-axe/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.personAxe.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/person-axe/person-axe-detail.html',
                            controller: 'PersonAxeDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('personAxe');
                                $translatePartialLoader.addPart('personAxeType');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'PersonAxe', function ($stateParams, PersonAxe) {
                                return PersonAxe.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('person-axe.new', {
                    parent: 'person-axe',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/person-axe/person-axe-dialog.html',
                                controller: 'PersonAxeDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            type: null,
                                            startDate: null,
                                            endDate: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('person-axe', null, {reload: true});
                            }, function () {
                                $state.go('person-axe');
                            });
                        }]
                })
                .state('person-axe.edit', {
                    parent: 'person-axe',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/person-axe/person-axe-dialog.html',
                                controller: 'PersonAxeDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['PersonAxe', function (PersonAxe) {
                                            return PersonAxe.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('person-axe', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('person-axe.delete', {
                    parent: 'person-axe',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/person-axe/person-axe-delete-dialog.html',
                                controller: 'PersonAxeDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['PersonAxe', function (PersonAxe) {
                                            return PersonAxe.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('person-axe', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
