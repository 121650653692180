(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('FileAttachementDetailController', FileAttachementDetailController);

    FileAttachementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'FileAttachement', 'Submission', 'EventRegistration'];

    function FileAttachementDetailController($scope, $rootScope, $stateParams, DataUtils, entity, FileAttachement, Submission, EventRegistration) {
        var vm = this;

        vm.fileAttachement = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:fileAttachementUpdate', function(event, result) {
            vm.fileAttachement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
