(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('TeamTypeSearch', TeamTypeSearch);

    TeamTypeSearch.$inject = ['$resource'];

    function TeamTypeSearch($resource) {
        var resourceUrl =  'api/_search/team-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
