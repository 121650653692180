(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('publications', {
                controller: 'PublicationsController',
                bindings: {
                    personId: '@?',
                    projectId: '@?',
                    societyId: '@?',
                    showTitle: '@?'
                },
                templateUrl: 'app/components/publications/publications.html'
            });
})();
