(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('arrayAsString', arrayAsString);

    function arrayAsString() {
        return arrayAsStringFilter;

        function arrayAsStringFilter(array) {
            var result = "";
            var separator = ",";
            angular.forEach(array, function (item, index) {
                if (index > 0) {
                    result += separator;
                }
                result += item;
            });

            return result;
        }
    }
})();
