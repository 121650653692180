(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .factory('AbstractService', AbstractService);

    AbstractService.$inject = ['CustomUtils', 'DateUtils', 'Principal', '$translate'];

    function AbstractService(CustomUtils, DateUtils, Principal, $translate) {
        var service = {
            getAvailableCommunicationType: getAvailableCommunicationType,
            getAvailableFinalType: getAvailableFinalType,
            oralCommunicationDepositIsOpen: oralCommunicationDepositIsOpen,
            posterDepositIsOpen: posterDepositIsOpen,
            abstractDespositIsOpen: abstractDespositIsOpen,
            renderFinalType: renderFinalType,
            renderAccepted: renderAccepted,
            getLabelType: getLabelType
        };

        return service;

        function getAvailableCommunicationType(mainEvent, invite) {
            var types = [];
            if (mainEvent.hasOral) {
                types.push('O');
            }
            if (mainEvent.hasPoster) {
                types.push('P');
            }
            if (mainEvent.hasOral && mainEvent.hasPoster) {
                types.push('OP');
            }
            if (invite) {
                types.push('I');
            }
            return types;
        }

        function getAvailableFinalType(mainEvent, invite) {
            var types = [];
            types.push('');
            if (mainEvent.hasOral) {
                types.push('O');
            }
            if (mainEvent.hasPoster) {
                types.push('P');
            }
            types.push('A');
            if (invite) {
                types.push('I');
            }
            return types;
        }

        function oralCommunicationDepositIsOpen(mainEvent, withoutAdmin) {
            if (mainEvent.hasOral) {
                if (Principal.hasAnyAuthority(['ROLE_ADMIN']) && CustomUtils.isUndefinedOrNull(withoutAdmin)) {
                    return true;
                }
                var now = new Date();
                return DateUtils.compare(mainEvent.oralCommunicationLimitDate, now) > 0;
            }
            return false;
        }

        function posterDepositIsOpen(mainEvent, withoutAdmin) {
            if (mainEvent.hasPoster) {
                if (Principal.hasAnyAuthority(['ROLE_ADMIN']) && CustomUtils.isUndefinedOrNull(withoutAdmin)) {
                    return true;
                }
                var now = new Date();
                return DateUtils.compare(mainEvent.posterLimitDate, now) > 0;
            }
            return false;
        }

        function abstractDespositIsOpen(mainEvent) {
            return oralCommunicationDepositIsOpen(mainEvent) || posterDepositIsOpen(mainEvent);
        }

        function getLabelType(type) {
            if (!_.isNil(type)) {
                switch (type.toUpperCase()) {
                    case 'O':
                        return $translate.instant("sheet.mainevent.abstracts.oral");
                    case 'P':
                        return $translate.instant("sheet.mainevent.abstracts.poster");
                    case 'OP':
                        return $translate.instant("sheet.mainevent.abstracts.oral") + ' & ' + $translate.instant("sheet.mainevent.abstracts.poster");
                    case 'I':
                        return $translate.instant("sheet.mainevent.abstracts.invite");
                    case 'A':
                        return $translate.instant("sheet.mainevent.abstracts.abandon");
                }
            }
            return type;
        }

        function renderFinalType(type, addDiv) {
            var result = "";
            if (addDiv) {
                result += '<div class="jqx-grid-cell-middle-align" style="margin-top:6px;">';
            }

            var image = null;
            var title = null;
            if (!_.isNil(type)) {
                switch (type.toUpperCase()) {
                    case 'O' :
                        image = "comment";
                        title = $translate.instant("sheet.mainevent.abstracts.oral");
                        break;
                    case 'OP':
                        result += '<span class="column-images">';
                        result += '<span class="glyphicon glyphicon-comment" title="' + $translate.instant("sheet.mainevent.abstracts.oral") + '"></span>';
                        result += '<span class="glyphicon glyphicon-picture" title="' + $translate.instant("sheet.mainevent.abstracts.poster") + '"></span>';
                        result += "</span>";
                        break;
                    case 'P' :
                        image = "picture";
                        title = $translate.instant("sheet.mainevent.abstracts.poster");
                        break;
                    case 'A' :
                        image = "remove";
                        title = $translate.instant("sheet.mainevent.abstracts.abandon");
                        break;
                    case 'I':
                        image = "user";
                        title = $translate.instant("sheet.mainevent.abstracts.invite");
                        break;
                }
            }

            if (image !== null) {
                result += '<span class="column-images">';
                result += '<span class="glyphicon glyphicon-' + image + '" title="' + title + '"></span>';
                result += "</span>";
            }
            if (CustomUtils.isEmpty(type)) {
                result += '&nbsp;';
            }
            if (addDiv) {
                result += "</div>";
            }
            return result;
        }

        function renderAccepted(accepted, addDiv) {
            var result = '';
            if (!CustomUtils.isEmpty(accepted)) {
                if (addDiv) {
                    result += '<div class="jqx-grid-cell-middle-align" style="margin-top:6px;">';
                }
                if (accepted === "OK" || accepted === true) {
                    result += '<span class="column-images" style="color:green">';
                    result += '<i class="fa fa-check" aria-hidden="true" title="Accepté"></i>';
                } else if (accepted === "KO" || accepted === false) {
                    result += '<span class="column-images" style="color:red">';
                    result += '<i class="fa fa-times" aria-hidden="true" title="Refusé"></i>';
                }
                result += '</span>';
                if (addDiv) {
                    result += "</div>";
                }
            }
            return result;
        }
    }

})();
