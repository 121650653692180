/* global _ */

(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$http', '$document', 'AlertService', '$log', 'FileSaver', 'Blob', 'bowser'];

    function DataUtils($window, $http, $document, AlertService, $log, FileSaver, Blob, bowser) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            openFileFromUrl: openFileFromUrl,
            openFileFromPostUrl: openFileFromPostUrl,
            downloadFile: downloadFile,
            downloadFileFromUrl: downloadFileFromUrl,
            downloadFileFromPostUrl: downloadFileFromPostUrl,
            downloadAttachment: downloadAttachment,
            toBase64: toBase64,
            b64toBlob: b64toBlob,
            size: size
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function size(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }
            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }
            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }
            return size(base64String);
        }

        function openFile(type, data, height, width, fileName) {

            if (bowser.msie && fileName) {
                var blob = this.b64toBlob(data);
                FileSaver.saveAs(blob, fileName);
                return;
            }

            if (height == null) {
                height = 300;
            }
            if (width == null) {
                width = 400;
            }

            var blob = this.b64toBlob(data, type);

            var fileURL = URL.createObjectURL(blob);
            $window.open(fileURL);
        }

        function openFileFromUrl(url, fileName) {
            var that = this;
            $http.get(url)
                    .then(function (result) {
                        if (result.data.errors) {
                            AlertService.error(result.data.errors);
                        }
                        if (result.data.content != null) {
                            that.openFile(result.data.contentType, result.data.content, $window.innerHeight, $window.innerHeight, fileName);
                        }
                    });
        }

        function openFileFromPostUrl(url, data, fileName) {
            var that = this;
            $http.post(url, data)
                    .then(function (result) {
                        if (result.data.errors) {
                            AlertService.error(result.data.errors);
                        }
                        if (!_.isNil(result.data.content)) {
                            if (!_.isNil(result.data.fileName)) {
                                fileName = result.data.fileName;
                            }
                            that.openFile(result.data.contentType, result.data.content, $window.innerHeight, $window.innerHeight, fileName);
                        }
                    });
        }

        function downloadFile(data, fileName, contentType) {
            var blob = this.b64toBlob(data, contentType);
            FileSaver.saveAs(blob, fileName);
        }

        function downloadFileFromUrl(url, fileName) {
            var that = this;
            $http.get(url)
                    .then(function (result) {
                        if (result.data.errors) {
                            AlertService.error(result.data.errors);
                        }
                        if (!_.isNil(result.data.content)) {
                            if (!_.isNil(result.data.fileName)) {
                                fileName = result.data.fileName;
                            }
                            that.downloadFile(result.data.content, fileName, result.data.contentType);
                        }
                    });
        }

        function downloadFileFromPostUrl(url, data, fileName) {
            var that = this;
            $http.post(url, data)
                    .then(function (result) {
                        if (result.data.errors) {
                            AlertService.warning(result.data.errors);
                        }
                        if (!_.isNil(result.data.fileName)) {
                            fileName = result.data.fileName;
                        }

                        that.downloadFile(result.data.content, fileName, result.data.contentType);
                    });
        }

        function downloadAttachment(id, name) {
            this.downloadFileFromUrl("api/public/file-attachement/" + id, name);
        }

        function b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }
    }
})();
