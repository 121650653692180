/* global _ */

(function () {
    'use strict';

    angular.module('canceropoleGsoApp').controller('CallForProjectStatsController', CallForProjectStatsController);

    CallForProjectStatsController.$inject = ['$http', '$scope', 'JQWidgetsService', '$translate', 'DataUtils'];

    function CallForProjectStatsController($http, $scope, JQWidgetsService, $translate, DataUtils) {
        var vm = this;
        vm.from = new Date();
        vm.from.setMonth(vm.from.getMonth() - 12);
        vm.from = vm.from.getFullYear();
        vm.to = new Date().getFullYear();
        vm.validDate = true;
        vm.stats = [];

        var yearsSource = [];
        for (var i = 2010; i <= 2040; i++) {
            yearsSource.push(i.toString());
        }

        vm.fromSettings = {
            source: yearsSource,
            //width: '100%',
            height: 32,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use())
        };

        vm.toSettings = {
            source: yearsSource,
            //width: '100%',
            height: 32,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use())
        };



        vm.checkDates = function () {
            vm.validDate = vm.from <= vm.to;
        };

        vm.retrieveStats = function () {
            $http({
                url: 'api/callforprojects/stats',
                method: 'GET',
                params: {
                    from: vm.from,
                    to: vm.to
                }
            }).success(function (response) {
                vm.stats = response;
            });

        };

        vm.exportStats = function () {
            var url = "api/callforprojects/stats/export";
            url += "?from=" + vm.from;
            url += "&to=" + vm.to;
            DataUtils.downloadFileFromUrl(url, 'Export_' + vm.from + "_" + vm.to + ".xls");
        };

        vm.refresh = function () {
            if (!_.isNil(vm.statDetailGrid)) {
                vm.statDetailGrid.clear();
            }
            if (!_.isNil(vm.statsGrid)) {
                vm.statsGrid.clearselection();
            }
            vm.statsGridSettings.source = new $.jqx.dataAdapter({
                localdata: vm.stats,
                datatype: 'json',
                datafields: [{
                        name: 'year',
                        type: 'int'
                    }, {
                        name: 'geosite',
                        type: 'string'
                    }, {
                        name: 'axe',
                        type: 'string'
                    }, {
                        name: 'type',
                        type: 'string'
                    }, {
                        name: 'budget',
                        type: 'int'
                    }, {
                        name: 'nbAccepted',
                        type: 'int'
                    }, {
                        name: 'societies',
                        type: 'string'
                    }]
            });
        };

        var sumCellRenderer = function (row, column, value, html, cell, resource) {
            if (value.toString().indexOf("Sum") >= 0) {
                return html.replace("Sum", "<b>Total</b>");
            }
        };

        var aggregateRenderer = function (aggregates, column, element) {
            var renderstring = '';
            $.each(aggregates, function (key, value) {
                var label = key;
                if (label === 'sum') {
                    label = '<b>Total</b>';
                }
                return renderstring += '<div style="position: relative; margin: 4px; overflow: hidden;">' + label + ': ' + value + '</div>';
            });
            return renderstring;
        };

        vm.statsGridSettings = {
            width: '100%',
            height: 600,
            groupable: true,
            showgroupaggregates: true,
            showstatusbar: true,
            showaggregates: true,
            statusbarheight: 25,
            columnsresize: true,
            columnsreorder: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            created: function (args) {
                vm.statsGrid = args.instance;
            },
            rowselect: function (event) {
                var args = event.args;
                if (args && args.row) {
                    var row = args.row;
                    console.log(row);

                    vm.statDetailGrid.clearselection();

                    var url = 'api/callforprojects/stat-details' +
                            '?year=' + row.year +
                            '&type=' + row.type +
                            '&geosite=' + row.geosite +
                            '&axe=' + row.axe;

                    vm.statDetailGridSettings.source = new $.jqx.dataAdapter({
                        datatype: 'json',
                        url: url,
                        loadComplete: function (records) {
                            console.log(records);
                        },
                        datafields: [{
                                name: 'callForProjectId',
                                type: 'number'
                            }, {
                                name: 'callForProjectName',
                                type: 'string'
                            }, {
                                name: 'submissionId',
                                type: 'number'
                            }, {
                                name: 'submission',
                                type: 'string'
                            }, {
                                name: 'acceptedBudget',
                                type: 'number'
                            }, {
                                name: 'firstName',
                                type: 'string'
                            }, {
                                name: 'lastName',
                                type: 'string'
                            }, {
                                name: 'personId',
                                type: 'number'
                            }, {
                                name: 'key',
                                type: 'number'
                            }, {
                                name: 'date',
                                type: 'date'
                            }]
                    });
                }
            },
            columns: [{
                    text: $translate.instant('canceropoleGsoApp.callForProject.year'),
                    datafield: 'year',
                    width: 80,
                    filtertype: 'checkedlist',
                    align: 'center',
                    cellsalign: 'center',
                    pinned: true
                }, {
                    text: $translate.instant('canceropoleGsoApp.callForProject.callForProjectType'),
                    datafield: 'type',
                    width: 180,
                    filtertype: 'checkedlist',
                    align: 'center',
                    cellsalign: 'center',
                    pinned: true
                }, {
                    text: $translate.instant('global.form.geosite'),
                    datafield: 'geosite',
                    width: 200,
                    filtertype: 'checkedlist',
                    align: 'center',
                    cellsalign: 'center'
                }, {
                    text: $translate.instant('global.form.organismes'),
                    datafield: 'societies',
                    minwidth: 200,
                    filtertype: 'checkedlist'
                }, {
                    text: $translate.instant('canceropoleGsoApp.callForProject.axe'),
                    datafield: 'axe',
                    minwidth: 200,
                    filtertype: 'checkedlist'
                }, {
                    text: $translate.instant('canceropoleGsoApp.callForProject.budget'),
                    datafield: 'budget',
                    width: 130,
                    filtertype: 'number',
                    align: 'center',
                    cellsalign: 'center',
                    cellsrenderer: sumCellRenderer,
                    cellsformat: 'c',
                    aggregatesrenderer: aggregateRenderer,
                    aggregates: ['sum']
                }, {
                    text: 'Projets financés',
                    datafield: 'nbAccepted',
                    width: 120,
                    filtertype: 'number',
                    align: 'center',
                    cellsalign: 'center',
                    cellsrenderer: sumCellRenderer,
                    aggregates: ['sum'],
                    aggregatesrenderer: aggregateRenderer
                }]
        };

        var linkCellRenderer = function (row, column, value, html, cell, resource) {
            if (value.length > 0) {
                var url = "";
                if (column === 'lastName' || column === 'firstName') {
                    if (!_.isNil(resource.personId)) {
                        url = "#/sheet/person/" + resource.personId + "?tab=project";
                    } else {
                        return html;
                    }
                } else if (column === 'callForProjectName') {
                    url = "#/sheet/callforproject/" + resource.callForProjectId + "?tab=projects";
                } else if (column === 'submission') {
                    url = "#/callforproject/" + resource.callForProjectId + '/space?key=' + resource.key;
                }

                var link = "<a href=\"" + url + "\" title=\"" + value + "\">" + value + "</a>";
                return html.replace(value, link);
            }
            return '';
        };

        vm.statDetailGridSettings = {
            width: '100%',
            height: 400,
            showgroupaggregates: true,
            showstatusbar: true,
            showaggregates: true,
            statusbarheight: 25,
            columnsresize: true,
            columnsreorder: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            created: function (args) {
                vm.statDetailGrid = args.instance;
            },
            columns: [{
                    text: 'Appel à projets',
                    datafield: 'callForProjectName',
                    width: 250,
                    filtertype: 'checkedlist',
                    cellsrenderer: linkCellRenderer,
                    align: 'center',
                    cellsalign: 'center'
                }, {
                    text: 'Soumission',
                    datafield: 'submission',
                    minwidth: 200,
                    filtertype: 'checkedlist',
                    cellsrenderer: linkCellRenderer
                }, {
                    text: $translate.instant('canceropoleGsoApp.callForProject.budget'),
                    datafield: 'acceptedBudget',
                    width: 130,
                    filtertype: 'number',
                    align: 'center',
                    cellsalign: 'center',
                    cellsrenderer: sumCellRenderer,
                    cellsformat: 'c',
                    aggregatesrenderer: aggregateRenderer,
                    aggregates: ['sum']
                }, {
                    text: 'Prénom',
                    datafield: 'firstName',
                    width: 130,
                    cellsrenderer: linkCellRenderer
                }, {
                    text: 'Nom',
                    datafield: 'lastName',
                    width: 130,
                    cellsrenderer: linkCellRenderer
                }]
        };

        $scope.$watch('vm.from', vm.checkDates);
        $scope.$watch('vm.to', vm.checkDates);
        $scope.$watch('vm.stats', vm.refresh);

        vm.retrieveStats();
    }
})();
