(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('TeamOrganizationSearch', TeamOrganizationSearch);

    TeamOrganizationSearch.$inject = ['$resource'];

    function TeamOrganizationSearch($resource) {
        var resourceUrl =  'api/_search/team-organizations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
