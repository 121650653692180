(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectManagementDialogController', CallForProjectManagementDialogController);

    CallForProjectManagementDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CallForProjectManagement', 'Person', 'CallForProject'];

    function CallForProjectManagementDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CallForProjectManagement, Person, CallForProject) {
        var vm = this;

        vm.callForProjectManagement = entity;
        vm.clear = clear;
        vm.save = save;
        vm.people = Person.query();
        vm.callforprojects = CallForProject.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.callForProjectManagement.id !== null) {
                CallForProjectManagement.update(vm.callForProjectManagement, onSaveSuccess, onSaveError);
            } else {
                CallForProjectManagement.save(vm.callForProjectManagement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:callForProjectManagementUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
