(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PublicationDetailController', PublicationDetailController);

    PublicationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Publication', 'PublicationType', 'Person', 'Project', 'Society'];

    function PublicationDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Publication, PublicationType, Person, Project, Society) {
        var vm = this;

        vm.publication = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:publicationUpdate', function(event, result) {
            vm.publication = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
