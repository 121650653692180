(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ConfirmDialogController', ConfirmDeleteDialogController);

    ConfirmDeleteDialogController.$inject = ['$translate', '$uibModalInstance', 'context'];

    function ConfirmDeleteDialogController($translate, $uibModalInstance, context) {
        var ctrl = this;

        ctrl.ok = function () {
            $uibModalInstance.close(true);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };


        ctrl.type = context.type;
        ctrl.title = context.title;
        ctrl.text = context.text;
        ctrl.buttonColor = 'btn-success';
        ctrl.buttonIcon = 'glyphicon-ok';
        ctrl.buttonText = $translate.instant('dialog.confirm.button.text');
        ctrl.cancelButtonText = $translate.instant('entity.action.cancel');
        ctrl.cancelButton = ctrl.cancel;
        if (_.startsWith(ctrl.type, 'remove')) {
            ctrl.title = 'entity.delete.title';
            ctrl.buttonColor = 'btn-danger';
            ctrl.buttonIcon = 'glyphicon-trash';
            ctrl.buttonText = $translate.instant('entity.action.delete');
        } else if (ctrl.type === 'yesNo') {
            ctrl.buttonText = $translate.instant('dialog.yesNo.button.yes');
            ctrl.buttonColor = 'btn-success';
            ctrl.cancelButtonText = $translate.instant('dialog.yesNo.button.no');
            ctrl.cancelButton = function () {
                $uibModalInstance.close(false);
            };
        }



    }
})();