(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectMailDialogController', CallForProjectMailDialogController);

    CallForProjectMailDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'CallForProjectMail', 'CallForProject'];

    function CallForProjectMailDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, CallForProjectMail, CallForProject) {
        var vm = this;

        vm.callForProjectMail = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.callforprojects = CallForProject.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.callForProjectMail.id !== null) {
                CallForProjectMail.update(vm.callForProjectMail, onSaveSuccess, onSaveError);
            } else {
                CallForProjectMail.save(vm.callForProjectMail, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:callForProjectMailUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
