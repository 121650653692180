(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('UploadFileController', UploadFileController);

    UploadFileController.$inject = ['$translate', '$translatePartialLoader', 'Upload', 'AlertService', 'CustomUtils', '$log', 'DataUtils', 'ConfirmDialog'];

    function UploadFileController($translate, $translatePartialLoader, Upload, AlertService, CustomUtils, $log, DataUtils, ConfirmDialog) {
        $translatePartialLoader.addPart('upload');
        $translatePartialLoader.addPart('fileAttachement');

        var toSendTitle = "";
        var sendingTitle = "";

        $translate.refresh().then(function () {
            toSendTitle = $translate.instant("canceropoleGsoApp.upload.send");
            sendingTitle = $translate.instant("canceropoleGsoApp.upload.sending");
            ctrl.init();
        });

        var ctrl = this;

        ctrl.init = function () {
            ctrl.progress = 0;
            ctrl.uploading = false;
            ctrl.title = toSendTitle;
            ctrl.upload = null;
        };

        ctrl.selectFile = function (files) {
            if (files && files.length === 1) {
                ctrl.file = files[0];
                ctrl.setContent(ctrl.file);
                $log.info(ctrl.file);
            }
        };

        ctrl.uploadFile = function () {
            ctrl.init();
            if (CustomUtils.isDefined(ctrl.file)) {
                ctrl.uploading = true;
                ctrl.title = sendingTitle;
                ctrl.upload = Upload.http({
                    url: ctrl.url,
                    data: ctrl.fileAttachement
                });
                ctrl.upload.then(function (response) {
                    ctrl.init();
                    ctrl.onSuccess(response);
                }, function (response) {
                    ctrl.init();

                    if (response.status > 0) {
                        ctrl.errorMsg = response.status + ': ' + response.data;
                        AlertService.error(ctrl.errorMsg);
                    }
                    if (CustomUtils.isDefined(ctrl.onError)) {
                        ctrl.onError(response);
                    }
                }, function (evt) {
                    ctrl.progress =
                            Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                });
            }
        };

        ctrl.abort = function () {
            ctrl.upload.abort();
            ctrl.file = null;
            ctrl.init();
        };


        ctrl.setContent = function ($file) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    ctrl.fileAttachement.content = base64Data;
                    ctrl.fileAttachement.contentContentType = $file.type;
                    ctrl.fileAttachement.fileSize = DataUtils.size(ctrl.fileAttachement.content);
                    ctrl.fileAttachement.filePath = $file.name;
                });
            }
        };

        ctrl.openAttachement = function () {
            if (CustomUtils.isUndefinedOrNull(ctrl.fileAttachement.id)) {
                DataUtils.openFile(ctrl.fileAttachement.contentContentType, ctrl.fileAttachement.content);
            } else {
                DataUtils.openFileFromUrl("api/file-attachement/" + ctrl.fileAttachement.id);
            }
        };

        ctrl.getFileName = function () {
            if (ctrl.file) {
                return ctrl.file.name;
            } else if (ctrl.fileAttachement.id) {
                return ctrl.fileAttachement.name;
            }
        };

        ctrl.remove = function () {
            ConfirmDialog.openConfirmRemove(ctrl.fileAttachement.name, function (response) {
                if (response === true) {
                    ctrl.onRemove(ctrl.fileAttachement);
                }
            });
        };

    }
})();