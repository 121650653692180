(function() {
    "use strict";
    angular
        .module("canceropoleGsoApp")
        .controller(
            "ScientificAttachmentComponentController",
            ScientificAttachmentController
        );

    ScientificAttachmentController.$inject = [
        "JQWidgetsService",
        "ScientificAttachment",
        "$translatePartialLoader",
        "$translate"
    ];

    function ScientificAttachmentController(
        JQWidgetsService,
        ScientificAttachment,
        $translatePartialLoader,
        $translate
    ) {
        $translatePartialLoader.addPart("scientificAttachment");
        $translate.refresh();

        var ctrl = this;

        ctrl.scientificAttachments = ScientificAttachment.query({
            size: 100
        });

        ctrl.underEdition = false;

        ctrl.edit = function() {
            ctrl.underEdition = true;
        };

        ctrl.cancel = function() {
            ctrl.underEdition = false;
        };

        ctrl.saveScientificAttachment = function() {
            ctrl.save("scientificAttachment");
        };

        ctrl.scientificListBoxSettings = {
            width: "100%",
            height: 200,
            displayMember: "name",
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: ctrl.scientificAttachments,
            created: function(args) {
                ctrl.scientificList = args.instance;
                angular.forEach(ctrl.entity.scientificAttachments, function(
                    scientificAttachment
                ) {
                    ctrl.scientificList.checkItem(scientificAttachment.id);
                });
            },
            checkChange: function(event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;
                    for (
                        var i = 0;
                        i < ctrl.entity.scientificAttachments.length;
                        i++
                    ) {
                        var attachement = ctrl.entity.scientificAttachments[i];
                        if (attachement.id === args.value) {
                            index = i;
                            break;
                        }
                    }

                    if (args.checked === true && index === null) {
                        ctrl.entity.scientificAttachments.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        ctrl.entity.scientificAttachments.splice(index, 1);
                    }
                }
            }
        };
    }
})();
