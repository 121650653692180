(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.society', {
            parent: 'sheet',
            url: 'society/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.society.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/society/sheet.society.html',
                    controller: 'SheetSocietyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('job');
                        $translatePartialLoader.addPart('society');
                        $translatePartialLoader.addPart('societyOrganization');
                        $translatePartialLoader.addPart('publication');
                        $translatePartialLoader.addPart('societyLevel');
                        return $translate.refresh();
                    }],
                societyEntity: ['$stateParams', 'Society', function ($stateParams, Society) {
                        return Society.get({id: $stateParams.id}).$promise;
                    }],
                geosites: ['Geosite', function (Geosite) {
                        return Geosite.query({
                            size: 100
                        }).$promise;
                    }],
                societyTypes: ['SocietyType', function (SocietyType) {
                        return SocietyType.query({
                            size: 100
                        }).$promise;
                    }]
            }
        }).state('sheet.society.main', {
            parent: 'sheet.society',
            views: {
                'sheet.society.main': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.main.html',
                    controller: 'SheetSocietyMainTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                parents: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get('api/society/parents/' + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }]
            }
        }).state('sheet.society.presentation', {
            parent: 'sheet.society',
            views: {
                'sheet.society.presentation': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.presentation.html',
                    controller: 'SheetSocietyPresentationTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        }).state('sheet.society.team', {
            parent: 'sheet.society',
            views: {
                'sheet.society.team': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.team.html',
                    controller: 'SheetSocietyTeamTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        }).state('sheet.society.employee', {
            parent: 'sheet.society',
            views: {
                'sheet.society.employee': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.employee.html',
                    controller: 'SheetSocietyEmployeeTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        }).state('sheet.society.followed', {
            parent: 'sheet.society',
            views: {
                'sheet.society.followed': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.followed.html',
                    controller: 'SheetSocietyFollowedTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        }).state('sheet.society.structure', {
            parent: 'sheet.society',
            views: {
                'sheet.society.structure': {
                    templateUrl: 'app/sheet/society/tab/sheet.society.structure.html',
                    controller: 'SheetSocietyStructureTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        });
    }
})();