(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('OrganizationsController', OrganizationsController);

  OrganizationsController.$inject = [
    'JQWidgetsService',
    '$uibModal',
    'AlertService',
    '$translate',
    'CustomUtils',
    '$filter',
    'ConfirmDialog',
    'SocietyOrganization',
    'TeamOrganization',
    '$log'
  ];

  function OrganizationsController(
    JQWidgetsService,
    $uibModal,
    AlertService,
    $translate,
    CustomUtils,
    $filter,
    ConfirmDialog,
    SocietyOrganization,
    TeamOrganization,
    $log
  ) {
    var ctrl = this;

    if (_.isNil(ctrl.canEdit)) {
      ctrl.canEdit = 'false';
    }

    var url = null;

    if (ctrl.teamId) {
      url = 'api/team-organizations/team/' + ctrl.teamId;
    } else if (ctrl.societyId) {
      url = 'api/society-organizations/society/' + ctrl.societyId;
    }

    ctrl.organizationDropDownListSettings = {
      displayMember: 'name',
      valueMember: 'id',
      placeHolder: JQWidgetsService.getLocalization($translate.use()).filterchoosestring,
      source: {
        url: 'api/organization',
        datatype: 'json',
        datafields: [
          {
            name: 'id',
            type: 'number'
          },
          {
            name: 'name',
            type: 'string'
          }
        ]
      },
      created: function (event) {
        ctrl.organizationDropDown = event.instance;
      }
    };

    var organizationDataAdapter = new $.jqx.dataAdapter({
      url: url,
      datatype: 'json',
      datafields: [
        {
          name: 'societyId',
          type: 'number'
        },
        {
          name: 'organizationId',
          type: 'number'
        },
        {
          name: 'organizationName',
          type: 'string'
        },
        {
          name: 'societyName',
          type: 'string'
        },
        {
          name: 'code',
          type: 'string'
        },
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'position',
          type: 'number'
        }
      ],
      sortcolumn: 'position',
      sortdirection: 'asc'
    });

    ctrl.organizationSelected = null;
    ctrl.organizationGridSettings = {
      source: organizationDataAdapter,
      localization: JQWidgetsService.getLocalization($translate.use()),
      width: '100%',
      height: 200,
      sortable: true,
      editable: ctrl.canEdit,
      enabletooltips: true,
      altrows: true,
      columns: [
        {
          text: 'Position',
          datafield: 'position',
          columntype: 'numberinput',
          width: '40',
          validation: function (cell, value) {
            if (value < 0) {
              return {
                result: false,
                message: 'La position doit être positive.'
              };
            }
            return true;
          }
        },
        {
          text: 'Nom',
          datafield: 'organizationName',
          columntype: 'dropdownlist',
          minwidth: 150,
          editable: false
        },
        {
          text: 'Code',
          datafield: 'code',
          columntype: 'textbox',
          width: 150
        }
      ],
      created: function (args) {
        ctrl.organizationGrid = args.instance;
      },
      rowselect: function (event) {
        ctrl.organizationSelected = null;
        if (event.args) {
          ctrl.organizationSelected = event.args.row;
        }
        $log.info('rowselect', ctrl.organizationSelected);
      },
      rowunselect: function (event) {
        //ctrl.organizationSelected = null;
      },
      cellendedit: function (event) {
        if (event.args) {
          $log.info(event.args);
          var args = event.args;
          var row = args.row;

          if (args.datafield === 'code') {
            row.code = args.value;
          } else if (args.datafield === 'position') {
            row.position = args.value;
          }

          $log.info(row);
          if (!_.isNil(ctrl.societyId)) {
            SocietyOrganization.update(
              row,
              function () {
                organizationDataAdapter.dataBind();
              },
              function () {
                organizationDataAdapter.dataBind();
              }
            );
          } else if (!_.isNil(ctrl.teamId)) {
            TeamOrganization.update(
              row,
              function () {
                organizationDataAdapter.dataBind();
              },
              function () {
                organizationDataAdapter.dataBind();
              }
            );
          }
        }
      }
    };

    ctrl.addOrganization = function () {
      $log.info('Add organization');
      $uibModal
        .open({
          templateUrl: 'app/components/organizations/dialog/add.organization.dialog.html',
          controller: 'AddOrganizationDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                return $translate.refresh();
              }
            ],
            societyId: function () {
              return ctrl.societyId;
            },
            teamId: function () {
              return ctrl.teamId;
            },
            availableOrganizations: [
              'Organization',
              function (Organization) {
                return Organization.query({
                  size: 100
                }).$promise;
              }
            ]
          }
        })
        .result.then(function () {
          ctrl.organizationGrid.updatebounddata();
        });
    };

    ctrl.tryRemoveOrganization = function () {
      var content = ctrl.organizationSelected.organizationName;
      if (ctrl.organizationSelected.code) {
        content += ' - ' + ctrl.organizationSelected.code;
      }
      ConfirmDialog.openConfirmRemove(content, function () {
        if (!_.isNil(ctrl.societyId)) {
          SocietyOrganization.delete({ id: ctrl.organizationSelected.id }, function () {
            ctrl.organizationSelected = null;
            ctrl.organizationGrid.clearselection();
            AlertService.success('canceropoleGsoApp.societyOrganization.deleted');
            ctrl.organizationGrid.updatebounddata();
          });
        } else if (!_.isNil(ctrl.teamId)) {
          TeamOrganization.delete({ id: ctrl.organizationSelected.id }, function () {
            ctrl.organizationSelected = null;
            ctrl.organizationGrid.clearselection();
            AlertService.success('canceropoleGsoApp.teamOrganization.deleted');
            ctrl.organizationGrid.updatebounddata();
          });
        }
      });
    };
  }
})();
