(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectPublicationController', SheetProjectPublicationController);

    SheetProjectPublicationController.$inject = ['Principal', 'project', '$stateParams', '$state'];

    function SheetProjectPublicationController(Principal, project, $stateParams, $state) {
        if ($stateParams.tab !== "publication") {
            //update state param
            $stateParams.tab = "publication";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.project = project;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.edition = {
            contact: false,
            otherinformation: false,
            scientificAttachment: false
        };



    }
})();
