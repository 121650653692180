(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('EventAbstract', EventAbstract);

    EventAbstract.$inject = ['$resource'];

    function EventAbstract($resource) {
        var resourceUrl = 'api/event-abstracts/:id';
        var resourceUrl2 = 'api/event-abstracts/:id/all';

        var resource = $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {
                    key: '@key'
                }},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });

        var resource2 = $resource(resourceUrl2, {}, {
            'getAllInformations': {
                method: 'GET'
            },
            'deleteAllInformations': {
                method: 'DELETE'
            }
        });


        resource.getAllInformations = resource2.getAllInformations.bind(resource2);
        resource.deleteAllInformations = resource2.deleteAllInformations.bind(resource2);

        return resource;
    }
})();
