(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('ScientificAttachmentSearch', ScientificAttachmentSearch);

    ScientificAttachmentSearch.$inject = ['$resource'];

    function ScientificAttachmentSearch($resource) {
        var resourceUrl =  'api/_search/scientific-attachments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
