(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventPriceDetailController', EventPriceDetailController);

    EventPriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventPrice', 'MainEvent', 'PersonStatus'];

    function EventPriceDetailController($scope, $rootScope, $stateParams, entity, EventPrice, MainEvent, PersonStatus) {
        var vm = this;

        vm.eventPrice = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventPriceUpdate', function(event, result) {
            vm.eventPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
