(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventDayDeleteController',EventDayDeleteController);

    EventDayDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventDay'];

    function EventDayDeleteController($uibModalInstance, entity, EventDay) {
        var vm = this;

        vm.eventDay = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EventDay.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
