(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('JobEditDialogController', JobEditDialogController);

    JobEditDialogController.$inject = ['$uibModalInstance', 'job', '$log', 'SocietySearch', 'JQWidgetsService', '$translate', 'CustomUtils', '$timeout', 'searchPerson', 'searchSociety', 'PersonSearch', '$state'];

    function JobEditDialogController($uibModalInstance, job, $log, SocietySearch, JQWidgetsService, $translate, CustomUtils, $timeout, searchPerson, searchSociety, PersonSearch, $state) {

        var ctrl = this;
        ctrl.job = job;
        ctrl.searchPerson = searchPerson;
        ctrl.searchSociety = searchSociety;

        if (CustomUtils.isUndefinedOrNull(ctrl.job)) {
            ctrl.title = "Ajouter un lien entre une personne et une structure";
            ctrl.job = {
                startDate: null,
                endDate: null,
                position: 0
            };

        } else {
            ctrl.title = "Editer un lien entre une personne et une structure";
            ctrl.societyName = ctrl.job.societyName;
            ctrl.personName = ctrl.job.personName;
            //delete ctrl.job.societyName;
        }

        ctrl.showDates = false;
        if (ctrl.job.startDate != null || ctrl.job.endDate != null) {
            ctrl.showDates = true;
        }

        var sortFunction = function (a, b) {
            a = a.name;
            b = b.name;
            if (a.toLowerCase() === "autre") {
                return 1;
            } else if (b.toLowerCase() === "autre") {
                return -1;
            }
            return a.toLowerCase().localeCompare(b.toLowerCase());
        };

        var metierSource = {
            datatype: "json",
            datafields: [{
                    name: 'id'
                }, {
                    name: 'name'
                }, {
                    name: 'personStatuses',
                    type: 'array'
                }],
            id: 'id',
            sortcolumn: 'name',
            sortDirection: 'asc',
            url: "api/person-metiers"
        };

        var metierAdapter = new $.jqx.dataAdapter(metierSource, {
            beforeLoadComplete: function (records) {
                records.sort(sortFunction);
                return records;
            }
        });

        ctrl.statusList = null;
        ctrl.metierSettings = {
            source: metierAdapter,
            placeHolder: JQWidgetsService.getLocalization($translate.use()).filterchoosestring,
            width: "100%",
            height: 30,
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            created: function (args) {
                ctrl.metierDropDownList = args.instance;

            },
            bindingComplete: function () {
                if (!CustomUtils.isUndefinedOrNull(ctrl.job.personMetierId)) {
                    $timeout(function () {
                        ctrl.metierDropDownList.selectItem(ctrl.job.personMetierId);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editJobForm.$setDirty();
                    var metier = event.args.item;
                    ctrl.statusList = metier.originalItem.personStatuses;
                    ctrl.statusList.sort(function (s1, s2) {
                        var n1 = s1.name.toLowerCase();
                        var n2 = s2.name.toLowerCase();
                        return n1.localeCompare(n2);
                    });
                    ctrl.statusSettings.source = new $.jqx.dataAdapter(ctrl.statusList);
                    ctrl.job.personMetierId = metier.originalItem.id;
                }
            },
            unselect: function () {
                ctrl.job.personMetierId = null;
            },
            change: function () {
                var selection = ctrl.metierDropDownList.getSelectedItem();
                if (selection === null) {
                    ctrl.job.personMetierId = null;
                }
            }
        };

        ctrl.statusSettings = {
            placeHolder: JQWidgetsService.getLocalization($translate.use()).filterchoosestring,
            width: "100%",
            height: 30,
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            created: function (args) {
                ctrl.statusDropDownList = args.instance;
            },
            bindingComplete: function () {
                if (!CustomUtils.isUndefinedOrNull(ctrl.job.personStatusId)) {
                    $timeout(function () {
                        ctrl.statusDropDownList.selectItem(ctrl.job.personStatusId);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editJobForm.$setDirty();
                    var status = event.args.item;
                    ctrl.job.personStatusId = status.originalItem.id;
                }
            },
            unselect: function () {
                ctrl.job.personStatusId = null;
            },
            change: function () {
                var selection = ctrl.statusDropDownList.getSelectedItem();
                if (selection === null) {
                    ctrl.job.personStatusId = null;
                }
            }
        };

        ctrl.startDateSettings = {
            width: "100%",
            height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.endDateSettings = {
            width: "100%",
            height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.checkDates = function () {
            if (ctrl.showDates === true) {
                $timeout(function () {
                    var startDate = ctrl.job.startDate;
                    var endDate = ctrl.job.endDate;
                    if (startDate != null && endDate != null) {
                        $log.debug("startDate=" + startDate);
                        $log.debug("endDate=" + endDate);
                        $log.debug("startDate < endDate ==>", startDate < endDate);
                        ctrl.editJobForm.$setValidity('previousDate', startDate < endDate);
                    } else {
                        ctrl.editJobForm.$setValidity('previousDate', true);
                    }
                }, 100);
            }
        };

        var societySource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/people',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                var query = "name:" + ctrl.societyComboBoxSettings.searchString;
                query += " or ";
                query += "shortName:" + ctrl.societyComboBoxSettings.searchString;
                SocietySearch.query({
                    query: query,
                    page: 0,
                    size: 8
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            height: 30,
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
                if (!CustomUtils.isUndefinedOrNull(ctrl.societyName)) {
                    $timeout(function () {
                        ctrl.societyComboBox.val(ctrl.societyName);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editJobForm.$setDirty();
                    var item = event.args.item;
                    ctrl.job.societyId = item.originalItem.id;
                }
            }
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'firstName'
                }, {
                    name: 'lastName'
                }, {
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/person',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                if (!CustomUtils.isUndefinedOrNull(ctrl.personName)) {
                    $timeout(function () {
                        ctrl.personComboBox.val(ctrl.personName);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editJobForm.$setDirty();
                    var item = event.args.item;
                    ctrl.job.personId = item.originalItem.id;
                }
            }
        };

        ctrl.addSociety = function () {
            $state.go("addSociety");
        };

        ctrl.ok = function () {
            if (angular.isObject(ctrl.job.personStatusId)) {
                ctrl.job.personStatusId = ctrl.job.personStatusId.id;
            }
            if (angular.isObject(ctrl.job.personMetierId)) {
                ctrl.job.personMetierId = ctrl.job.personMetierId.id;
            }

            $uibModalInstance.close(ctrl.job);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();