(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectFormFieldDetailController', CallForProjectFormFieldDetailController);

    CallForProjectFormFieldDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CallForProjectFormField', 'CallForProjectFormGroup'];

    function CallForProjectFormFieldDetailController($scope, $rootScope, $stateParams, entity, CallForProjectFormField, CallForProjectFormGroup) {
        var vm = this;

        vm.callForProjectFormField = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectFormFieldUpdate', function(event, result) {
            vm.callForProjectFormField = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
