(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('cfpProjectDescriptionEditor', {
                controller: 'cfpProjectDescriptionEditorController',
                bindings: {
                    project: '=',
                    projectForm: '=',
                    callForProject: '<',
                    readOnly: '<',
                    showErrors: '=',
                    showPreview: '<'
                },
                templateUrl: 'app/callforproject/subscription/components/cfp-project-description-editor.html'
            });
})();
