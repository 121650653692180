(function () {
    'use strict';

    angular.module('canceropoleGsoApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.team', {
            parent: 'sheet',
            url: 'team/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.team.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/team/sheet.team.html',
                    controller: 'SheetTeamController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('team');
                        $translatePartialLoader.addPart('scientificAttachment');
                        $translatePartialLoader.addPart('teamOrganization');
                        return $translate.refresh();
                    }],
                team: ['$stateParams', 'Team', function ($stateParams, Team) {
                        return Team.get({id: $stateParams.id}).$promise;
                    }]
            }
        }).state('sheet.team.main', {
            parent: 'sheet.team',
            views: {
                'sheet.team.main': {
                    templateUrl: 'app/sheet/team/tab/sheet.team.main.html',
                    controller: 'SheetTeamMainController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                geosites: ['Geosite', function (Geosite) {
                        return Geosite.query({
                            size: 100
                        }).$promise;
                    }],
                teamTypes: ['TeamType', function (TeamType) {
                        return TeamType.query({
                            size: 100
                        }).$promise;
                    }]
            }
        }).state('sheet.team.presentation', {
            parent: 'sheet.team',
            views: {
                'sheet.team.presentation': {
                    templateUrl: 'app/sheet/team/tab/sheet.team.presentation.html',
                    controller: 'SheetTeamPresentationController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.team.members', {
            parent: 'sheet.team',
            views: {
                'sheet.team.members': {
                    templateUrl: 'app/sheet/team/tab/sheet.team.members.html',
                    controller: 'SheetTeamMembersController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();