(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService'];

    function notificationInterceptor($q, AlertService) {
        var service = {
            response: response,
            responseError: responseError
        };

        return service;

        function response(response) {
            var alertKey = response.headers('X-canceropoleGsoApp-alert');
            if (angular.isString(alertKey)) {
                AlertService.success(alertKey, {param: response.headers('X-canceropoleGsoApp-params')});
            }
            return response;
        }

        function responseError(rejection) {
            var alertKey = rejection.headers('X-canceropoleGsoApp-alert');
            if (angular.isString(alertKey)) {
                AlertService.error(alertKey, {param: rejection.headers('X-canceropoleGsoApp-params')});
            }
            return $q.reject(rejection);
        }
    }
})();
