(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('TeamOrganizationDetailController', TeamOrganizationDetailController);

    TeamOrganizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TeamOrganization', 'Team', 'Organization'];

    function TeamOrganizationDetailController($scope, $rootScope, $stateParams, entity, TeamOrganization, Team, Organization) {
        var vm = this;

        vm.teamOrganization = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:teamOrganizationUpdate', function(event, result) {
            vm.teamOrganization = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
