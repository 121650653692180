(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ScientificAttachmentDetailController', ScientificAttachmentDetailController);

    ScientificAttachmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ScientificAttachment'];

    function ScientificAttachmentDetailController($scope, $rootScope, $stateParams, entity, ScientificAttachment) {
        var vm = this;

        vm.scientificAttachment = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:scientificAttachmentUpdate', function(event, result) {
            vm.scientificAttachment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
