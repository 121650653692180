(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventDayDetailController', EventDayDetailController);

    EventDayDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventDay', 'EventSession', 'MainEvent'];

    function EventDayDetailController($scope, $rootScope, $stateParams, entity, EventDay, EventSession, MainEvent) {
        var vm = this;

        vm.eventDay = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventDayUpdate', function(event, result) {
            vm.eventDay = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
