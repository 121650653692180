(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('MultipleRemoteSearchController', MultipleRemoteSearchController);

    MultipleRemoteSearchController.$inject = ['CustomUtils', '$log', '$scope'];

    function MultipleRemoteSearchController(CustomUtils, $log, $scope) {

        var ctrl = this;
        ctrl.item = null;

        ctrl.name = CustomUtils.removeSpecialChars(ctrl.label);

        if (ctrl.required && CustomUtils.isUndefinedOrNull(ctrl.form)) {
            $log.error("Pas de formulaire définit.");
            ctrl.required = false;
        }

        ctrl.comboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: ctrl.adapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.adapter._source.searchString = searchString;
                ctrl.adapter.dataBind();
            },
            created: function (args) {
                ctrl.comboBox = args.instance;
                if (ctrl.required) {
                    ctrl.form[ctrl.name].$setValidity('required', ctrl.selection.length > 0);
                }
            },
            change: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var item = item.originalItem;
                    ctrl.item = item;
                    ctrl.addItem();
                    ctrl.comboBox.clear();

                } else {
                    ctrl.item = null;
                }
            }
        };

        ctrl.addItem = function () {
            if (ctrl.item != null) {
                var add = true;
                angular.forEach(ctrl.selection, function (item) {
                    if (item.id === ctrl.item.id) {
                        add = false;
                    }
                });

                if (add) {
                    ctrl.selection.push({
                        id: ctrl.item.id,
                        name: ctrl.item.name
                    });
                    ctrl.item = null;
                }
            }
            if (ctrl.required) {
                ctrl.form[ctrl.name].$setValidity('required', ctrl.selection.length > 0);
            }
        };

        ctrl.removeItem = function (itemToRemove) {
            angular.forEach(ctrl.selection, function (item, index) {
                if (itemToRemove.id === item.id) {
                    ctrl.selection.splice(index, 1);
                }
            });
            if (ctrl.required) {
                ctrl.form[ctrl.name].$setValidity('required', ctrl.selection.length > 0);
            }
        };
    }
})();