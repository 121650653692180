(function () {
    'use strict';

    angular.module('canceropoleGsoApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.callforproject', {
            parent: 'sheet',
            url: 'callforproject/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.callforproject.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/callforproject/sheet.callforproject.html',
                    controller: 'SheetCallForProjectController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sheet');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('callForProject');
                    $translatePartialLoader.addPart('callForProjectMail');
                    $translatePartialLoader.addPart('callForProjectMailType');
                    $translatePartialLoader.addPart('callForProjectForm');
                    $translatePartialLoader.addPart('callForProjectFormField');
                    $translatePartialLoader.addPart('cFPFormType');
                    $translatePartialLoader.addPart('cFPFormState');
                    $translatePartialLoader.addPart('criterionType');
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('expertiseState');
                    $translatePartialLoader.addPart('submission');
                    return $translate.refresh();
                }],
                callForProject: ['$stateParams', 'CallForProject', function ($stateParams, CallForProject) {
                    return CallForProject.get({ id: $stateParams.id }).$promise;
                }],
                geosites: ['Geosite', function (Geosite) {
                    return Geosite.query({
                        size: 100
                    });
                }]
            }
        }).state('sheet.callforproject.main', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.main': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.maintab.html',
                    controller: 'SheetCallForProjectMainTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                managements: ['$http', '$stateParams', function ($http, $stateParams) {
                    return $http.get("api/call-for-projects/managements/" + $stateParams.id).then(function (response) {
                        return response.data;
                    });
                }],
                aapTypes: ['CallForProjectType', function (CallForProjectType) {
                    return CallForProjectType.query({ size: 100 }).$promise;
                }]
            }
        }).state('sheet.callforproject.form', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.form': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.form.html',
                    controller: 'SheetCallForProjectFormTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                formFieldsList: ['$http', '$stateParams', 'DateUtils', function ($http, $stateParams, DateUtils) {
                    return $http.get("api/call-for-projects/" + $stateParams.id + "/form/all").then(function (response) {
                        if (response.status === 200) {
                            angular.forEach(response.data, function (formField) {
                                var form = formField.form;
                                if (form.startDate) {
                                    form.startDate = DateUtils.convertDateTimeFromServer(form.startDate);
                                }
                                if (form.endDate) {
                                    form.endDate = DateUtils.convertDateTimeFromServer(form.endDate);
                                }
                            });
                            return response.data;
                        } else {
                            return [];
                        }
                    });
                }]
            }
        }).state('sheet.callforproject.projects', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.projects': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.projectstab.html',
                    controller: 'SheetCallForProjectProjectsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.callforproject.mails', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.mails': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.mails.html',
                    controller: 'SheetCallForProjectMailsTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mails: ['$http', '$stateParams', function ($http, $stateParams) {
                    return $http.get("api/call-for-projects/mails/" + $stateParams.id).then(function (response) {
                        return response.data;
                    });
                }]
            }
        }).state('sheet.callforproject.experts', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.experts': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.experts.html',
                    controller: 'SheetCallForProjectExpertsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.callforproject.expertises', {
            parent: 'sheet.callforproject',
            views: {
                'sheet.callforproject.expertises': {
                    templateUrl: 'app/sheet/callforproject/tab/sheet.callforproject.expertises.html',
                    controller: 'SheetCallForProjectExpertisesTabController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
