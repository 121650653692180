(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('EntityThumbnailController', EntityThumbnailController);

  EntityThumbnailController.$inject = ['$log', '$window', 'CustomUtils', '$state', 'SearchService'];

  function EntityThumbnailController($log, $window, CustomUtils, $state, SearchService) {
    var ctrl = this;

    if (!CustomUtils.isUndefinedOrNull(ctrl.type) && CustomUtils.isUndefinedOrNull(ctrl.entity.type)) {
      ctrl.entity.type = ctrl.type;
    }

    ctrl.selected = false;
    for (var i = 0; i < SearchService.selection.length; i++) {
      var selection = SearchService.selection[i];
      if (selection.id === ctrl.entity.id) {
        ctrl.selected = true;
        break;
      }
    }

    ctrl.redirect = function (stateName, newTab) {
      if (!ctrl.clickable) {
        if (newTab) {
          var url = $state.href(stateName, {
            id: ctrl.entity.id,
            name: ctrl.entity.name,
            tab: 'main'
          });
          $window.open(url, '_blank');
        } else {
          $state.go(stateName, {
            id: ctrl.entity.id,
            name: ctrl.entity.name,
            tab: 'main'
          });
        }
      }
    };

    ctrl.toggleSelect = function () {
      ctrl.selected = !ctrl.selected;
      if (ctrl.selected) {
        SearchService.selection.push(ctrl.entity);
      } else {
        var index = null;
        for (var i = 0; i < SearchService.selection.length; i++) {
          var selection = SearchService.selection[i];
          if (selection.id === ctrl.entity.id) {
            index = i;
            break;
          }
        }
        if (index !== null) {
          SearchService.selection.splice(i, 1);
        }
      }
    };
    //$log.info(ctrl);
  }
})();
