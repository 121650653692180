(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('ExpertisePolarChartController', ExpertisePolarChartController);

    ExpertisePolarChartController.$inject = ['$translate', '$translatePartialLoader', '$http', '$log'];

    function ExpertisePolarChartController($translate, $translatePartialLoader, $http, $log) {

        $translatePartialLoader.addPart('sheet');
        $translate.refresh();

        var ctrl = this;

        ctrl.projectId = null;
        ctrl.formType = null;
        ctrl.max = 0;

        this.$onChanges = function () {
            ctrl.load();
        };

        ctrl.load = function () {
            //il faut que les expertises soit du même type sur le meme appel à projet
            //sur le même projet (et donc le même porteur)
            var expertiseIds = [];
            angular.forEach(ctrl.expertises, function (expertise, index) {
                if (index === 0) {
                    ctrl.projectId = expertise.projectId;
                    ctrl.chartSettings.description = expertise.submissionName;
                    ctrl.chartSettings.title = expertise.personName;
                    ctrl.formType = expertise.type;
                }
                expertiseIds.push(expertise.id);
            });

            $http.post("api/call-for-projects/" + ctrl.aapId + "/expertises/stats/" + ctrl.formType, expertiseIds).then(function (response) {
                var data = response.data;
                ctrl.computeChartExpertises(data.evaluations, data.criterions, data.source);
            });
        };

        ctrl.computeChartExpertises = function (expertises, criterions, source) {
            //var source = [];
            var serieGroup = ctrl.chartSettings.seriesGroups[0];
            serieGroup.series = [];
            var datafields = [{
                    name: 'name'
                }];

            ctrl.max = 0;
            $log.info("criterions=", criterions);
            angular.forEach(criterions, function (criterion) {
                if (criterion.criterionType === "number") {
                    if (_.isString(criterion.criterionConfig)) {
                        criterion.criterionConfig = JSON.parse(criterion.criterionConfig);
                    }
                    var max = criterion.criterionConfig.max;
                    if (max > ctrl.max) {
                        ctrl.max = max;
                    }
                }
            });
            serieGroup.valueAxis.maxValue = ctrl.max + 1; // +1 pour espacer

            for (var i = 0; i < expertises.length; i++) {
                var expertise = expertises[i];
                var expertKey = 'expert_' + expertise.personId;

                datafields.push({
                    name: expertKey
                });

                var serie = {
                    dataField: expertKey,
                    displayText: expertise.expertName,
                    opacity: 0.7,
                    lineWidth: 2,
                    symbolType: 'circle',
                    radius: 2
                };
                $log.info("serie=", serie);
                serieGroup.series.push(serie);
            }

            $log.info("source=", source);
            ctrl.chartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: source,
                datatype: 'json',
                datafields: datafields
            });

            if (!_.isNil(ctrl.chart)) {
                ctrl.chart.setOptions({
                    title: ctrl.chartSettings.title,
                    description: ctrl.chartSettings.description
                })
                ctrl.chart.update();
            }
        };

        ctrl.chartSettings = {
            created: function (args) {
                ctrl.chart = args.instance;
            },
            title: "Porteur",
            description: "Projet",
            enableAnimations: true,
            showLegend: true,
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 5},
            xAxis: {
                dataField: 'name',
                valuesOnTicks: true
            },
            colorScheme: 'scheme01',
            seriesGroups: [{
                    polar: true,
                    startAngle: 0,
                    radius: Number(ctrl.radius),
                    type: 'line',
                    valueAxis: {
                        unitInterval: 1,
                        minValue: 0,
                        maxValue: 5,
                        description: 'Expert Name',
                        formatSettings: {
                            decimalPlaces: 0
                        }
                    },
                    series: []
                }]
        };
    }
})();