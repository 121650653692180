(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('TeamTypeDetailController', TeamTypeDetailController);

    TeamTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TeamType', 'Team'];

    function TeamTypeDetailController($scope, $rootScope, $stateParams, entity, TeamType, Team) {
        var vm = this;

        vm.teamType = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:teamTypeUpdate', function(event, result) {
            vm.teamType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
