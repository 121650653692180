(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('scheduler', {
                controller: 'SchedulerController',
                bindings: {
                    settings: '=',
                    panelType: '@?'
                },
                templateUrl: 'app/components/scheduler/scheduler.html'
            });
})();
