(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('EditCallForProjectFormDialogController', EditCallForProjectFormDialogController);

    EditCallForProjectFormDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', 'JQWidgetsService', '$log', 'CallForProjectForm', 'formFieldsList'];

    function EditCallForProjectFormDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, JQWidgetsService, $log, CallForProjectForm, formFieldsList) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;

        ctrl.forms = [];

        angular.forEach(formFieldsList, function (formFields) {
            ctrl.forms.push(formFields.form);
        });

        ctrl.form = null;

        ctrl.ok = function () {
            ctrl.isSaving = true;
            CallForProjectForm.update(ctrl.form, onSaveSuccess, onSaveError);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError() {
            ctrl.isSaving = false;
        }

        ctrl.formSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.forms,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                    ctrl.form = event.args.item.originalItem;
                }
            },
            created: function (args) {
                ctrl.formsCombo = args.instance;
                if (ctrl.forms.length === 1) {
                    ctrl.formsCombo.selectIndex(0);
                }
            }
        };

    }
})();