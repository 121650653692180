(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SearchBarController', SearchBarController);

    SearchBarController.$inject = ['$translate', '$translatePartialLoader', 'SearchService', '$log', 'CustomUtils'];

    function SearchBarController($translate, $translatePartialLoader, SearchService, $log, CustomUtils) {
        $translatePartialLoader.addPart('searchbar');
        $translate.refresh();

        var ctrl = this;
        ctrl.searchService = SearchService;

        ctrl.reset = function () {
            SearchService.searchResult = null;
        };

        ctrl.change = function () {
            if (!CustomUtils.isEmpty(SearchService.query)) {
                SearchService.loadFirstPage();
            }
        };


    }
})();

