(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('GeositeDeleteController',GeositeDeleteController);

    GeositeDeleteController.$inject = ['$uibModalInstance', 'entity', 'Geosite'];

    function GeositeDeleteController($uibModalInstance, entity, Geosite) {
        var vm = this;

        vm.geosite = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Geosite.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
