(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('homeEvents', {
                controller: 'HomeEventsController',
                bindings: {
                    title: '@',
                    type: '@',
                    items: '<',
                    go: '&',
                    otherItems: '<?',
                    otherTitle: '@?'
                },
                templateUrl: 'app/home/components/home.events.html'
            });
})();
