(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('MainEventDialogController', MainEventDialogController);

    MainEventDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'MainEvent', 'EventManagement', 'EventDay', 'EventRegistration', 'Society', 'Axe'];

    function MainEventDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, MainEvent, EventManagement, EventDay, EventRegistration, Society, Axe) {
        var vm = this;

        vm.mainEvent = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.eventmanagements = EventManagement.query();
        vm.eventdays = EventDay.query();
        vm.eventregistrations = EventRegistration.query();
        vm.societies = Society.query();
        vm.axes = Axe.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mainEvent.id !== null) {
                MainEvent.update(vm.mainEvent, onSaveSuccess, onSaveError);
            } else {
                MainEvent.save(vm.mainEvent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:mainEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.inscriptionLimitDate = false;
        vm.datePickerOpenStatus.inscriptionLimitDateEarlybird = false;
        vm.datePickerOpenStatus.posterLimitDate = false;
        vm.datePickerOpenStatus.oralCommunicationLimitDate = false;

        vm.setAvatar = function ($file, mainEvent) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        mainEvent.avatar = base64Data;
                        mainEvent.avatarContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
