(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('sheetWindowEditor', {
                controller: 'SheetWindowEditor',
                bindings: {
                    winId: '@',
                    winTitle: '@',
                    canEdit: '=',
                    ngModel: '=',
                    saveAction: '=',
                    cancelAction: '='
                },
                templateUrl: 'app/sheet/components/window/sheet.window.editor.html'
            });
})();
