(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventOptionDetailController', EventOptionDetailController);

    EventOptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventOption', 'MainEvent', 'EventRegistration'];

    function EventOptionDetailController($scope, $rootScope, $stateParams, entity, EventOption, MainEvent, EventRegistration) {
        var vm = this;

        vm.eventOption = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventOptionUpdate', function(event, result) {
            vm.eventOption = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
