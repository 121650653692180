(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('IndexSearch', IndexSearch);

    IndexSearch.$inject = ['$resource'];

    function IndexSearch($resource) {
        var resourceUrl = 'api/elasticsearch/index';

        return $resource(resourceUrl, {}, {
            'indexAll': {
                method: 'POST',
                transformResponse: function (data) {
                    return {content: data};
                }
            }
        });
    }
})();
