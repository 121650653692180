(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddCallForProjectFormDialogController', AddCallForProjectFormDialogController);

    AddCallForProjectFormDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', 'JQWidgetsService', '$log', 'CallForProjectForm', 'existingFormTypes', '$timeout', 'templates', '$http', 'CustomUtils'];

    function AddCallForProjectFormDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, JQWidgetsService, $log, CallForProjectForm, existingFormTypes, $timeout, templates, $http, CustomUtils) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;
        ctrl.form = {
            name: null,
            template: false, // n'est pas un template
            callForProjectId: callForProject.id,
            state: 'Offline'
        };

        ctrl.ok = function () {
            ctrl.isSaving = true;
            if (CustomUtils.isUndefinedOrNull(ctrl.selectedTemplate)) {
                CallForProjectForm.save(ctrl.form, onSaveSuccess, onSaveError);
            } else {
                $http.post("api/call-for-project/cloneform/" + ctrl.selectedTemplate.id, ctrl.form).then(onSaveSuccess, onSaveError);
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError() {
            ctrl.isSaving = false;
        }

        ctrl.typeSource = [];

        if (existingFormTypes.indexOf('LI') === -1) {
            var label = $translate.instant('canceropoleGsoApp.CFPFormType.LI');
            if (!ctrl.callForProject.privateSpace) {
                label = $translate.instant('canceropoleGsoApp.CFPFormType.FS');
            }
            ctrl.typeSource.push({
                label: label,
                value: 'LI'
            });
        }
        if (ctrl.callForProject.privateSpace && existingFormTypes.indexOf('EP') === -1) {
            ctrl.typeSource.push({
                label: $translate.instant('canceropoleGsoApp.CFPFormType.EP'),
                value: 'EP'
            });
        }
        ctrl.typeSource.sort(function (type1, type2) {
            var label1 = type1.label.toLowerCase();
            var label2 = type2.label.toLowerCase();
            return label1.localeCompare(label2);
        });

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.typeSource,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.typeSource.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.templateSource = templates;

        ctrl.templateSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.templateSource,
            change: function (event) {
                if (event.args && event.args.item) {
                    ctrl.editForm.$setDirty();
                    ctrl.selectedTemplate = event.args.item.originalItem;
                }
            }
        };
    }
})();