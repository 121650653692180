(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventRegistrationMailDetailController', EventRegistrationMailDetailController);

    EventRegistrationMailDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventRegistrationMail', 'EventRegistration', 'MainEventMail'];

    function EventRegistrationMailDetailController($scope, $rootScope, $stateParams, entity, EventRegistrationMail, EventRegistration, MainEventMail) {
        var vm = this;

        vm.eventRegistrationMail = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventRegistrationMailUpdate', function(event, result) {
            vm.eventRegistrationMail = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
