(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('ExportSubmissionsDialogController', ExportSubmissionsDialogController);

    ExportSubmissionsDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'submissions', 'DataUtils', '$timeout', 'callForProject', 'CallForProjectService', 'formFieldsList', 'JQWidgetsService', 'formTypes', 'exportType'];

    function ExportSubmissionsDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, submissions, DataUtils, $timeout, callForProject, CallForProjectService, formFieldsList, JQWidgetsService, formTypes, exportType) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.submissions = submissions;
        ctrl.callForProject = callForProject;
        ctrl.formFieldsList = formFieldsList;
        ctrl.selectedForm = null;
        ctrl.exportType = exportType;

        var prefix = 'canceropoleGsoApp.submission.export.columns.';

        ctrl.synthesisColumns = [{
            text: $translate.instant(prefix + 'date'),
            datafield: 'date',
            visible: true
        }, {
            text: $translate.instant(prefix + 'funded'),
            datafield: 'funded',
            visible: true
        }, {
            text: $translate.instant(prefix + 'personFirstName'),
            datafield: 'personFirstName',
            visible: true
        }, {
            text: $translate.instant(prefix + 'personLastName'),
            datafield: 'personLastName',
            visible: true
        }, {
            text: $translate.instant(prefix + 'submissionTitle'),
            datafield: 'submissionTitle',
            visible: true
        }, {
            text: $translate.instant(prefix + 'askedBudget'),
            datafield: 'askedBudget',
            visible: true
        }, {
            text: $translate.instant(prefix + 'acceptedBudget'),
            datafield: 'acceptedBudget',
            visible: true
        }, {
            text: $translate.instant(prefix + 'askedDuration'),
            datafield: 'askedDuration',
            visible: true
        }, {
            text: $translate.instant(prefix + 'acceptedDuration'),
            datafield: 'acceptedDuration',
            visible: true
        }, {
            text: $translate.instant(prefix + 'axe'),
            datafield: 'axe',
            visible: true
        }, {
            text: $translate.instant(prefix + 'geosite'),
            datafield: 'geosite',
            visible: true
        }];
        if (ctrl.callForProject.privateSpace) {
            ctrl.synthesisColumns.splice(1, 0, {
                text: $translate.instant(prefix + 'keep'),
                datafield: 'keep',
                visible: true
            });
        }

        if (ctrl.exportType.type === 'synthesis') {
            ctrl.columns = ctrl.synthesisColumns;
        }

        ctrl.forms = [];
        angular.forEach(ctrl.formFieldsList, function (formField) {
            for (var i = 0; i < formTypes.length; i++) {
                var formType = formTypes[i];
                if (formType === formField.form.type) {
                    ctrl.forms.push({
                        label: CallForProjectService.getFormTypeLabel(ctrl.callForProject, formField.form.type),
                        value: formField.form.type
                    });
                    break;
                }
            }
        });

        ctrl.typeSettings = {
            height: 30,
            width: 300,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: 'label',
            valueMember: 'value',
            source: ctrl.forms,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.exportForm.$setDirty();
                    var item = event.args.item;
                    ctrl.selectedForm = item;
                    if (ctrl.exportType.type === 'xls') {
                        ctrl.getFields();
                    } else {
                        ctrl.getGroups();
                    }
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.forms.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.getFields = function () {
            ctrl.columns = ctrl.synthesisColumns;

            ctrl.specifics = [{
                text: $translate.instant(prefix + 'acronym'),
                datafield: 'acronym',
                visible: true
            }, {
                text: $translate.instant(prefix + 'keyword'),
                datafield: 'keyword',
                visible: true
            }, {
                text: $translate.instant(prefix + 'partner'),
                datafield: 'partner',
                visible: true
            }, {
                text: $translate.instant(prefix + 'abstract'),
                datafield: 'description',
                visible: true
            }, {
                text: $translate.instant(prefix + 'societyName'),
                datafield: 'societyName',
                visible: true
            }, {
                text: $translate.instant(prefix + 'city'),
                datafield: 'city',
                visible: true
            }, {
                text: $translate.instant(prefix + 'email'),
                datafield: 'email',
                visible: true
            }, {
                text: $translate.instant(prefix + 'phone'),
                datafield: 'phone',
                visible: true
            }, {
                text: $translate.instant(prefix + 'portable'),
                datafield: 'portable',
                visible: true
            }, {
                text: $translate.instant(prefix + 'metier'),
                datafield: 'metier',
                visible: true
            }, {
                text: $translate.instant(prefix + 'status'),
                datafield: 'status',
                visible: true
            }, {
                text: $translate.instant(prefix + 'detail'),
                datafield: 'detail',
                visible: true
            }, {
                text: $translate.instant(prefix + 'unitDirector'),
                datafield: 'unitDirector',
                visible: true
            }, {
                text: $translate.instant(prefix + 'team'),
                datafield: 'teamName',
                visible: true
            }, {
                text: $translate.instant(prefix + 'teamDirector'),
                datafield: 'teamDirector',
                visible: true
            }];
            ctrl.columns = ctrl.columns.concat(ctrl.specifics);

            angular.forEach(ctrl.formFieldsList, function (formField) {
                if (formField.form.type === ctrl.selectedForm.value) {
                    var fields = formField.fields;
                    angular.forEach(fields, function (field) {
                        ctrl.columns.push({
                            text: field.label,
                            datafield: 'field_' + field.id,
                            visible: true
                        });
                    });
                }
            });
        };

        ctrl.getGroups = function () {
            var formId = null;
            for (var i = 0; i < ctrl.formFieldsList.length; i++) {
                var formField = ctrl.formFieldsList[i];
                if (formField.form.type === ctrl.selectedForm.value) {
                    formId = formField.form.id;
                    break;
                }
            }

            ctrl.columns = [{
                text: $translate.instant(prefix + 'header'),
                datafield: 'header_' + formId,
                visible: true
            }, {
                text: $translate.instant(prefix + 'infoPorteur'),
                datafield: 'info_porteur',
                visible: true
            }, {
                text: $translate.instant(prefix + 'projet'),
                datafield: "projet",
                visible: true
            }];

            angular.forEach(ctrl.formFieldsList, function (formField) {
                if (formField.form.type === ctrl.selectedForm.value) {
                    var groups = formField.groups;
                    angular.forEach(groups, function (group) {
                        ctrl.columns.push({
                            text: group.name,
                            datafield: 'group_' + group.id,
                            visible: true
                        });
                    });
                }
            });
            ctrl.columns.push({
                text: $translate.instant(prefix + 'footer'),
                datafield: 'footer_' + formId,
                visible: true
            });
        };

        ctrl.ok = function () {
            var selectedColumns = {};
            angular.forEach(ctrl.columns, function (column) {
                if (column.visible === true) {
                    selectedColumns[column.datafield] = column.text;
                }
            });

            var data = [];
            angular.forEach(ctrl.submissions, function (submission) {
                data.push(submission.id);
            });

            var toSend = {
                type: exportType.type,
                columns: selectedColumns,
                data: data
            };

            $log.info(toSend);

            DataUtils.downloadFileFromPostUrl('api/call-for-projects/submissions/export?allAttachment=true&expert=false', toSend, exportType.fileName);

            $uibModalInstance.close(ctrl.result);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
