(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('RemoveMainEventDialogController', RemoveMainEventDialogController);

    RemoveMainEventDialogController.$inject = ['$http', '$uibModalInstance', 'mainevent', '$log', 'dryDeleteResult', 'AlertService', 'MainEvent', 'CustomUtils'];

    function RemoveMainEventDialogController($http, $uibModalInstance, mainevent, $log, dryDeleteResult, AlertService, MainEvent, CustomUtils) {

        var ctrl = this;
        ctrl.mainevent = mainevent;
        ctrl.dryDeleteResult = [];
        if (!CustomUtils.isEmpty(dryDeleteResult)) {
            ctrl.dryDeleteResult = dryDeleteResult.split("\n");
        }

        $log.info(ctrl.dryDeleteResult);

        ctrl.ok = function () {
            if (ctrl.dryDeleteResult.length === 0) {
                //suppression simple
                MainEvent.delete({id: ctrl.mainevent.id}, function () {
                    $uibModalInstance.close();
                }, function () {
                    AlertService.error("Problème lors de la suppression de l'événement.");
                });

            } else {
                $http({
                    method: 'DELETE',
                    url: 'api/main-events/' + ctrl.mainevent.id + "/all"
                }).success(function () {
                    $uibModalInstance.close();
                }).error(function () {
                    AlertService.error("Problème lors de la suppression de l'événement.");
                });
            }

        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();