(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('nl2br', nl2br);

    nl2br.$inject = ['$sce', 'CustomUtils'];

    function nl2br($sce, CustomUtils) {
        return nl2brFilter;

        function nl2brFilter(msg, is_xhtml) {
            if (CustomUtils.isEmpty(msg)) {
                return '';
            }
            var is_xhtml = is_xhtml || true;
            var breakTag = (is_xhtml) ? '<br />' : '<br>';
            var msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            return $sce.trustAsHtml(msg);
        }
    }
})();
