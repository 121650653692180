(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventSessionDialogController', EventSessionDialogController);

    EventSessionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventSession', 'EventAbstract', 'EventDay', 'EventRegistration'];

    function EventSessionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EventSession, EventAbstract, EventDay, EventRegistration) {
        var vm = this;

        vm.eventSession = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.eventabstracts = EventAbstract.query();
        vm.eventdays = EventDay.query();
        vm.eventregistrations = EventRegistration.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.eventSession.id !== null) {
                EventSession.update(vm.eventSession, onSaveSuccess, onSaveError);
            } else {
                EventSession.save(vm.eventSession, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:eventSessionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
