(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SocietyDialogController', SocietyDialogController);

    SocietyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Society', 'Geosite', 'MainEvent', 'CallForProject', 'SocietyType', 'Person', 'ResponsibleFunction'];

    function SocietyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Society, Geosite, MainEvent, CallForProject, SocietyType, Person, ResponsibleFunction) {
        var vm = this;

        vm.society = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.geosites = Geosite.query();
        vm.mainevents = MainEvent.query();
        vm.callforprojects = CallForProject.query();
        vm.societytypes = SocietyType.query();
        vm.people = Person.query();
        vm.responsiblefunctions = ResponsibleFunction.query();
        vm.societies = Society.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.society.id !== null) {
                Society.update(vm.society, onSaveSuccess, onSaveError);
            } else {
                Society.save(vm.society, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:societyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setAvatar = function ($file, society) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        society.avatar = base64Data;
                        society.avatarContentType = $file.type;
                    });
                });
            }
        };

    }
})();
