(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddPersonAxeDialogController', AddPersonAxeDialogController);

    AddPersonAxeDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'personAxeInfo', '$log', 'PersonSearch', 'axe', 'JQWidgetsService', '$timeout', 'CustomUtils', 'PersonAxe'];

    function AddPersonAxeDialogController($translate, $translatePartialLoader, $uibModalInstance, personAxeInfo, $log, PersonSearch, axe, JQWidgetsService, $timeout, CustomUtils, PersonAxe) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.isSaving = false;
        ctrl.personAxeInfo = personAxeInfo;
        ctrl.axe = axe;

        $log.info("personAxeInfo", personAxeInfo);
        $log.info("axe", axe);

        ctrl.typeSource = [{
                label: $translate.instant('canceropoleGsoApp.PersonAxeType.COORDINATOR'),
                value: 'COORDINATOR'
            }, {
                label: $translate.instant('canceropoleGsoApp.PersonAxeType.IN_CHARGE'),
                value: 'IN_CHARGE'
            }];

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.typeSource,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (CustomUtils.isEmpty(ctrl.personAxeInfo.type) && ctrl.typeSource.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.dateSettings = {
            width: "100%",
            height: 30,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            },
            created: function () {
                $timeout(function () {
                    ctrl.checkDates();
                }, 100);
            }
        };

        ctrl.checkDates = function () {
            var startDate = ctrl.personAxeInfo.startDate;
            var endDate = ctrl.personAxeInfo.endDate;

            $log.debug("startDate", startDate);
            $log.debug("endDate", endDate);
            $log.debug("startDate < endDate ==>", startDate < endDate);

            var validity = true;
            if (!CustomUtils.isUndefinedOrNull(endDate)) {
                validity = startDate < endDate;
            }
            ctrl.editForm.$setValidity('previousDate', validity);
        };

        ctrl.ok = function () {
            if (ctrl.isSaving === false) {
                ctrl.isSaving = true;

                var toSave = {
                    id: ctrl.personAxeInfo.personAxeId,
                    type: ctrl.personAxeInfo.type,
                    startDate: ctrl.personAxeInfo.startDate,
                    endDate: ctrl.personAxeInfo.endDate,
                    axeId: ctrl.axe.id,
                    personId: ctrl.personAxeInfo.personId
                };

                $log.info(toSave);

                if (CustomUtils.isUndefinedOrNull(toSave.id)) {
                    //creation
                    PersonAxe.save(toSave, onSaveSuccess, onSaveError);
                } else {
                    //modif
                    PersonAxe.update(toSave, onSaveSuccess, onSaveError);
                }
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError(response) {
            ctrl.isSaving = false;
            $log.error(response);
        }

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                if (!CustomUtils.isUndefinedOrNull(ctrl.personAxeInfo.name)) {
                    ctrl.personComboBox.val(ctrl.personAxeInfo.name);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    ctrl.personAxeInfo.personId = item.originalItem.id;
                }
            },
            unselect: function () {
                ctrl.personAxeInfo.personId = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.personAxeInfo.personId = null;
                }
            }
        };
    }
})();