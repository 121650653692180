(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('AddMainEventDialogController', AddMainEventDialogController);

    AddMainEventDialogController.$inject = ['$scope', 'ModelConfig', '$uibModalInstance', 'DataUtils', 'MainEvent', 'axes', '$log', 'SocietySearch', '$http'];

    function AddMainEventDialogController($scope, ModelConfig, $uibModalInstance, DataUtils, MainEvent, axes, $log, SocietySearch, $http) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.axes = axes;

        vm.mainEvent = {
            publique: false,
            freeRegistration: false,
            billManagement: true,
            hasOral: false,
            hasPoster: false,
            axes: angular.copy(vm.axes),
            lang: 'fr',
            societies: []
        };

        vm.typesSettings = {
            width: "100%",
            height: 30,
            autoDropDownHeight: true,
            placeHolder: "Veuillez choisir",
            source: ModelConfig.mainEvent.types
        };

        var societySource = {
            datatype: 'json',
            datafields: [{
                name: 'name'
            }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                var query = "name:" + vm.societySettings.searchString;
                query += ' OR shortName:' + vm.societySettings.searchString;
                query += ' OR shortName:' + vm.societySettings.searchString + '*';
                SocietySearch.query({
                    query: query,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        var defaultSociety = "gso";

        vm.societySettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            //multiSelect: true,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.societySettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                vm.societyComboBox = args.instance;
                vm.societyComboBox.val(defaultSociety);
                vm.societySettings.search(defaultSociety);
            },
            bindingComplete: function () {
                if (defaultSociety) {
                    vm.societyComboBox.selectIndex(0);
                    defaultSociety = null;
                }
            }
        };

        vm.societyEventChangeHandler = function () {
            vm.mainEvent.societies = [];
            var selection = vm.societyComboBox.getSelectedItem();
            if (selection && selection.originalItem) {
                vm.mainEvent.societies.push(selection.originalItem);
            }
        };

        vm.axesSettings = {
            width: "100%",
            height: 160,
            displayMember: "name",
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: vm.axes,
            created: function (args) {
                vm.axeList = args.instance;
                angular.forEach(vm.mainEvent.axes, function (axe) {
                    vm.axeList.checkItem(axe.id);
                });
            },
            checkChange: function (event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;
                    for (var i = 0; i < vm.mainEvent.axes.length; i++) {
                        var axe = vm.mainEvent.axes[i];
                        if (axe.id === args.value) {
                            index = i;
                            break;
                        }
                    }


                    if (args.checked === true && index === null) {
                        vm.mainEvent.axes.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        vm.mainEvent.axes.splice(index, 1);
                    }
                }
            }
        };

        vm.startDateSettings = {
            width: "100%",
            height: 30,
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.endDateSettings = {
            height: 30,
            width: "100%",
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.inscriptionLimitDateSettings = {
            height: 30,
            width: "100%",
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.inscriptionLimitDateEarlybirdSettings = {
            height: 30,
            width: "100%",
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.posterLimitDateSettings = {
            height: 30,
            width: "100%",
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.oralCommunicationLimitDateSettings = {
            height: 30,
            width: "100%",
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                vm.checkDates();
            }
        };

        vm.checkDates = function () {
            //var result = DateUtils.compare(vm.mainEvent.startDate, vm.mainEvent.endDate);
            //$log.info(result);
        };

        vm.toggleFree = function () {
            $log.info("vm.mainEvent.freeRegistration", vm.mainEvent.freeRegistration);
            if (vm.mainEvent.freeRegistration === true) {
                vm.mainEvent.billManagement = false;
            }
        };

        vm.togglePoster = function () {
            $log.info("vm.mainEvent.hasPoster", vm.mainEvent.hasPoster);

        };

        vm.toggleOral = function () {
            $log.info("vm.mainEvent.hasOral", vm.mainEvent.hasOral);

        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            MainEvent.save(vm.mainEvent, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess(result) {
            $scope.$emit('canceropoleGsoApp:mainEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.inscriptionLimitDate = false;
        vm.datePickerOpenStatus.inscriptionLimitDateEarlybird = false;
        vm.datePickerOpenStatus.posterLimitDate = false;
        vm.datePickerOpenStatus.oralCommunicationLimitDate = false;

        vm.setAvatar = function ($file, mainEvent) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        mainEvent.avatar = base64Data;
                        mainEvent.avatarContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
