(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SubmissionMailDetailController', SubmissionMailDetailController);

    SubmissionMailDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SubmissionMail', 'Submission', 'CallForProjectMail'];

    function SubmissionMailDetailController($scope, $rootScope, $stateParams, entity, SubmissionMail, Submission, CallForProjectMail) {
        var vm = this;

        vm.submissionMail = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:submissionMailUpdate', function(event, result) {
            vm.submissionMail = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
