(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetTeamController', SheetTeamController);

    SheetTeamController.$inject = ['Principal', 'Team', '$stateParams', 'SheetHistoryService', '$state', 'team', '$translate', '$log', 'AlertService', 'ConfirmDialog'];

    function SheetTeamController(Principal, Team, $stateParams, SheetHistoryService, $state, team, $translate, $log, AlertService, ConfirmDialog) {

        var vm = this;
        vm.team = team;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.edition = {
            title: false
        };

        var sheetHistory = {
            name: vm.team.name,
            type: 'team',
            id: vm.team.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.tabs = [{
                title: $translate.instant("sheet.team.main.tab"),
                route: "sheet.team.main"
            }, {
                title: $translate.instant("sheet.team.presentation.tab"),
                route: "sheet.team.presentation"
            }, {
                title: $translate.instant("sheet.team.members.tab"),
                route: "sheet.team.members"
            }
        ];

        var activeTab = $stateParams.tab;

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.route.endsWith(activeTab)) {
                vm.activeTabIndex = index;
            }
        });

        $state.go(vm.tabs[vm.activeTabIndex].route);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.save = function () {
            Team.update(vm.team, function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });
        };

        vm.tryRemoveTeam = function () {
            ConfirmDialog.openConfirmRemove(vm.team.name, function () {
                Team.delete({id: vm.team.id}, function (response) {
                    AlertService.success("canceropoleGsoApp.team.deleted");
                    $state.go("home");
                }, function (error) {
                    AlertService.error("canceropoleGsoApp.teams.errors.delete");
                    $log.error(error.statusText);
                });
            });
        };

    }
})();
