(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('footer', {
                controller: 'FooterController',
                bindings: {},
                templateUrl: 'app/components/footer/footer.html'
            });
})();
