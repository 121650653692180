/* global _ */

(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$stateParams', '$q'];

    function RegisterController($translate, $timeout, Auth, LoginService, $stateParams, $q) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.context = $stateParams.context;

        $timeout(function () {
            angular.element('#login').focus();
        });

        function register() {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                var promise = $q.when(true);
                if (_.isNil(vm.context)) {
                    promise = Auth.createAccount(vm.registerAccount);
                } else {
                    promise = Auth.createAccountWithContext(vm.registerAccount, vm.context);
                }

                promise.then(function () {
                    vm.success = 'OK';
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            }
        }
    }
})();
