(function () {
  'use strict';

  angular.module('canceropoleGsoApp').filter('capitalize', capitalize);

  String.prototype.toCapitalize = function () {
    return this.toLowerCase().replace(/(?:^|\s|-)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  function capitalize() {
    return function (input) {
      if (input != null) {
        return input.toCapitalize();
      }
      return input;
    };
  }
})();
