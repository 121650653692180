(function() {
    'use strict';
    angular
        .module('canceropoleGsoApp')
        .factory('CallForProject', CallForProject);

    CallForProject.$inject = ['$resource', 'DateUtils'];

    function CallForProject ($resource, DateUtils) {
        var resourceUrl =  'api/call-for-projects/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expertiseStartDate = DateUtils.convertDateTimeFromServer(data.expertiseStartDate);
                        data.expertiseEndDate = DateUtils.convertDateTimeFromServer(data.expertiseEndDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
