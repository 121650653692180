(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectFormGroupDetailController', CallForProjectFormGroupDetailController);

    CallForProjectFormGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CallForProjectFormGroup', 'CallForProjectForm', 'CallForProjectFormField'];

    function CallForProjectFormGroupDetailController($scope, $rootScope, $stateParams, entity, CallForProjectFormGroup, CallForProjectForm, CallForProjectFormField) {
        var vm = this;

        vm.callForProjectFormGroup = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectFormGroupUpdate', function(event, result) {
            vm.callForProjectFormGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
