(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('FileAttachementEditDialogController', FileAttachementEditDialogController);

  FileAttachementEditDialogController.$inject = [
    '$timeout',
    '$scope',
    'showToExpert',
    '$uibModalInstance',
    'DataUtils',
    'entity',
    'FileAttachement'
  ];

  function FileAttachementEditDialogController($timeout, $scope, showToExpert, $uibModalInstance, DataUtils, entity, FileAttachement) {
    var vm = this;

    vm.fileAttachement = entity;
    vm.clear = clear;
    vm.byteSize = DataUtils.byteSize;
    vm.openFile = DataUtils.openFile;
    vm.save = save;
    vm.showToExpert = showToExpert;

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function save() {
      vm.isSaving = true;
      $uibModalInstance.close(vm.fileAttachement);
    }

    vm.setContent = function ($file, fileAttachement) {
      if ($file) {
        DataUtils.toBase64($file, function (base64Data) {
          $scope.$apply(function () {
            fileAttachement.name = $file.name;
            fileAttachement.content = base64Data;
            fileAttachement.contentContentType = $file.type;
            fileAttachement.fileSize = DataUtils.size(vm.fileAttachement.content);
          });
        });
      }
    };
  }
})();
