(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('PublicationTypeSearch', PublicationTypeSearch);

    PublicationTypeSearch.$inject = ['$resource'];

    function PublicationTypeSearch($resource) {
        var resourceUrl =  'api/_search/publication-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
