/* global _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('CallForProjectSpaceStepTwoController', CallForProjectSpaceStepTwoController);

  CallForProjectSpaceStepTwoController.$inject = [
    'Principal',
    '$filter',
    'allInformations',
    '$log',
    'personMetierEntities',
    'CallForProjectService',
    '$state',
    '$uibModal',
    'AlertService',
    'CustomUtils',
    '$http',
    '$stateParams',
    'ConfirmDialog',
    '$translate'
  ];

  function CallForProjectSpaceStepTwoController(
    Principal,
    $filter,
    allInformations,
    $log,
    personMetierEntities,
    CallForProjectService,
    $state,
    $uibModal,
    AlertService,
    CustomUtils,
    $http,
    $stateParams,
    ConfirmDialog,
    $translate
  ) {
    var vm = this;
    vm.spaceKey = $stateParams.key;
    vm.isSaving = false;
    vm.editCoordonnees = false;
    vm.editProject = false;
    vm.showValidityError = false;

    vm.metiers = personMetierEntities;
    vm.managements = allInformations.managements;
    vm.callForProject = allInformations.callForProject;
    vm.coordonnees = allInformations.coordonnee;
    vm.project = allInformations.project;
    vm.groups = allInformations.groups;
    vm.submission = allInformations.submission;
    vm.form = CallForProjectService.getStructuredForm(allInformations, 'EP');
    vm.confirmSend = false;

    vm.isAdmin = function () {
      return Principal.hasAnyAuthority(['ROLE_ADMIN']);
    };

    vm.canEdit = function () {
      if (vm.isAdmin()) {
        return true;
      }
      if (
        vm.isOpenForm(vm.form) === true &&
        CustomUtils.isUndefinedOrNull(vm.submission.stepTwoDate) &&
        CustomUtils.isUndefinedOrNull(vm.submission.funded)
      ) {
        return true;
      }
      return false;
    };

    vm.isOpenForm = function () {
      return CallForProjectService.isOpenForForm(vm.form);
    };

    vm.reload = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.canUpload = function () {
      if (vm.isAdmin()) {
        return true;
      }

      if (
        vm.isOpenForm(vm.form) === true &&
        CustomUtils.isUndefinedOrNull(vm.submission.stepTwoDate) &&
        CustomUtils.isUndefinedOrNull(vm.submission.funded)
      ) {
        return Principal.isAuthenticated();
      }
      return false;
    };

    vm.cancelEdition = vm.reload;

    vm.canSaveForm = function (form) {
      var angularForm = vm[form.formName];
      if (angularForm && angularForm.$valid && !angularForm.$invalid) {
        if (vm.canSaveProject() === true) {
          return true;
        }
      }
      return false;
    };

    vm.trySaveFormResponse = function (form, checkAllInformation) {
      vm.showValidityError = false;
      var send = !vm.isSaving && vm.canSaveProject();
      if (send && checkAllInformation) {
        send = vm.confirmSend && vm.canSaveForm(form);
      }
      if (send) {
        if (checkAllInformation) {
          var title = $translate.instant('canceropoleGsoApp.submission.confirmSaveTitle');
          var msg = $translate.instant('canceropoleGsoApp.submission.confirmSaveContent');
          ConfirmDialog.openYesNo(title, msg, function (result) {
            if (result === true) {
              vm.saveFormResponse(form, checkAllInformation);
            }
          });
        } else {
          vm.saveFormResponse(form, checkAllInformation);
        }
      } else {
        vm.showValidityError = true;
      }
    };

    vm.saveFormResponse = function (form, checkAllInformation) {
      vm.submit = checkAllInformation;
      vm.isSaving = true;

      vm.saveProject();

      var angularForm = vm[form.formName];
      var responses = [];
      angular.forEach(form.groups, function (group) {
        angular.forEach(group.fields, function (field) {
          var value = null;
          if (field.type === 'checkbox') {
            value = angular.toJson(field.value);
          } else if (field.type === 'ifYes') {
            if (field.valueRadio === false) {
              value = 'false';
            } else {
              value = _.escape(field.textValue);
            }
          } else if (field.type === 'ifNo') {
            if (field.valueRadio === true) {
              value = 'true';
            } else {
              value = _.escape(field.textValue);
            }
          } else if (field.type === 'date') {
            field.value = $filter('date')(field.value, 'MM/dd/yyyy');
            value = field.value;
          } else if (field.type === 'editor') {
            value = field.value;
          } else if (angularForm[field.name]) {
            value = _.escape(angularForm[field.name].$viewValue);
          } else if (field.type === 'table') {
            value = angular.toJson(field.value);
          }
          if (!CustomUtils.isUndefinedOrNull(value)) {
            var response = {
              fieldId: field.id,
              value: value.toString()
            };
            if (field.response && field.response.id) {
              response.id = field.response.id;
            }
            responses.push(response);
          }
        });
      });

      $log.info(responses);

      var url = 'api/submission/responses/' + +vm.spaceKey;
      if (checkAllInformation) {
        url += '?stepTwo=true';
      }

      $http.put(url, responses).then(function (response) {
        if (response.status === 200) {
          AlertService.success($translate.instant('canceropoleGsoApp.submission.canSave'));
          vm.reload();
        } else {
          AlertService.error($translate.instant('canceropoleGsoApp.submission.cannotSave'));
        }
        vm.isSaving = false;
      });
    };

    vm.exportSubmission = function () {
      $uibModal.open({
        templateUrl: 'app/sheet/callforproject/dialog/export-submissions.dialog.html',
        controller: 'ExportSubmissionsDialogController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'md',
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('callForProjectForm');
              $translatePartialLoader.addPart('cFPFormType');
              return $translate.refresh();
            }
          ],
          submissions: function () {
            return [vm.submission];
          },
          exportType: function () {
            return {
              type: 'pdf',
              fileName: vm.callForProject.name + '.pdf'
            };
          },
          callForProject: function () {
            return vm.callForProject;
          },
          formTypes: function () {
            return ['EP'];
          },
          formFieldsList: [
            '$http',
            '$stateParams',
            'DateUtils',
            function ($http, $stateParams, DateUtils) {
              return $http.get('api/call-for-projects/' + vm.callForProject.id + '/form/all').then(function (response) {
                if (response.status === 200) {
                  return response.data;
                } else {
                  return [];
                }
              });
            }
          ]
        }
      });
    };
  }
})();
