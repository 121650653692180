(function () {
  'use strict';

  angular.module('canceropoleGsoApp').service('LinkEditorDialog', LinkEditorDialog);

  LinkEditorDialog.$inject = ['$uibModal'];

  function LinkEditorDialog($uibModal) {
    this.open = function (onOk, onCancel) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/components/editor/link.editor.dialog.html',
        controller: 'LinkEditorDialogController',
        controllerAs: 'ctrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          mainTranslatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('dialog');
              return $translate.refresh();
            }
          ]
        }
      });
      return modalInstance.result;
    };
  }
})();
