(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AggregationsController', SearchBarController);

    SearchBarController.$inject = ['$translate', '$scope', '$translatePartialLoader', 'SearchService', 'CustomUtils'];

    function SearchBarController($translate, $scope, $translatePartialLoader, SearchService, CustomUtils) {
        $translatePartialLoader.addPart('aggregation');
        $translate.refresh();

        var ctrl = this;
        ctrl.searchService = SearchService;

        SearchService.aggregationList = [];

        ctrl.filteredClass = function (filterType, filterKey, filterValue) {
            var filtered = ctrl.searchService.isFiltered(filterType, filterKey, filterValue);
            if (filtered === true) {
                return 'aggregation-filtered';
            } else if (_.isArray(filtered)) {
                return 'aggregation-not-filtered';
            }
            return '';
        };

        ctrl.getAggResult = function (aggregations) {
            var aggregationList = [];
            for (var index in aggregations) {
                var aggregation = aggregations[index];
                var buckets = aggregation.buckets;


                if (!_.isEmpty(buckets)) {

                    aggregationList.push({
                        name: index,
                        values: buckets
                    });

                    var currentTypeFilter = ctrl.searchService.getCurrentTypeFilter();
                    console.log('currentTypeFilter=', currentTypeFilter);
                    if (!_.isNil(currentTypeFilter)) {
                        for (var i = 0; i < buckets.length; i++) {
                            var bucket = buckets[i];
                            if (bucket.key === currentTypeFilter) {
                                aggregationList = aggregationList.concat(ctrl.getAggResult(bucket));
                            }
                        }
                    }
                }

            }
            return aggregationList;
        };

        $scope.$watch(function () {
            return SearchService.searchResult;
        }, function (searchResult) {
            if (searchResult) {
                SearchService.total = searchResult.hits.total;
                var aggregations = searchResult.aggregations;

                var aggregationList = ctrl.getAggResult(aggregations);
                SearchService.aggregationList = aggregationList;
                /*SearchService.aggregationList = aggregationList.sort(function (a, b) {
                 var a = a.name;
                 var b = b.name;

                 if (a < b) {
                 return -1;
                 } else if (a > b) {
                 return 1;
                 }
                 return 0;
                 });*/

            }
        });
    }
})();
