(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ValorizationTypeDetailController', ValorizationTypeDetailController);

    ValorizationTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ValorizationType', 'Valorization'];

    function ValorizationTypeDetailController($scope, $rootScope, $stateParams, entity, ValorizationType, Valorization) {
        var vm = this;

        vm.valorizationType = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:valorizationTypeUpdate', function(event, result) {
            vm.valorizationType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
