(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('TeamDialogController', TeamDialogController);

    TeamDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Team', 'Geosite', 'TeamType', 'Society', 'Person', 'ScientificAttachment', 'ResponsibleFunction'];

    function TeamDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Team, Geosite, TeamType, Society, Person, ScientificAttachment, ResponsibleFunction) {
        var vm = this;

        vm.team = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.geosites = Geosite.query();
        vm.teamtypes = TeamType.query();
        vm.societies = Society.query();
        vm.people = Person.query();
        vm.scientificattachments = ScientificAttachment.query();
        vm.responsiblefunctions = ResponsibleFunction.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.team.id !== null) {
                Team.update(vm.team, onSaveSuccess, onSaveError);
            } else {
                Team.save(vm.team, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:teamUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setAvatar = function ($file, team) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        team.avatar = base64Data;
                        team.avatarContentType = $file.type;
                    });
                });
            }
        };

    }
})();
