(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ResultThumbnailController', ResultThumbnailController);

    ResultThumbnailController.$inject = ['SearchService'];

    function ResultThumbnailController(SearchService) {

        var ctrl = this;
        ctrl.searchService = SearchService;


    }
})();
