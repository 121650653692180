(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('cfpProjectDescriptionEditorController', cfpProjectDescriptionEditorController);

    cfpProjectDescriptionEditorController.$inject = ['CustomUtils', 'JQWidgetsService', '$scope', '$log', 'Principal'];

    function cfpProjectDescriptionEditorController(CustomUtils, JQWidgetsService, $scope, $log, Principal) {
        var ctrl = this;

        $scope.$watch('$ctrl.project.description', function (oldValue, newValue) {
            if (oldValue !== newValue) {
                ctrl.preventMaxLength();
            }
        });

        //a l'initialisation si readonly alors on n'affiche pas le bouton de preview
        if (ctrl.readOnly === true) {
            ctrl.showPreviewButton = false;
        } else {
            ctrl.showPreviewButton = ctrl.showPreview;
        }

        if (CustomUtils.isUndefinedOrNull(ctrl.showPreview)) {
            ctrl.showPreviewButton = false;
        }

        ctrl.togglePreview = function () {
            ctrl.readOnly = !ctrl.readOnly;
        };

        ctrl.isAdmin = function () {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                return true;
            }
            return false;
        };

        ctrl.preventMaxLength = function () {
            if (!CustomUtils.isEmpty(ctrl.callForProject.descMaxLength) &&
                    !CustomUtils.isUndefinedOrNull(ctrl.project.description)) {
                var nbChar = CustomUtils.countCharInHtmlString(ctrl.project.description);
                var left = ctrl.callForProject.descMaxLength - nbChar;
                if (left <= 0) {
                    var lengthToKeep = ctrl.project.description.length - Math.abs(left);
                    ctrl.project.description = ctrl.project.description.substring(0, lengthToKeep);
                }
            }
        };

        var tools = "bold italic underline | left center right justify | characters | exposant indice | ul ol | clean";
        if (ctrl.isAdmin()) {
            tools += " | html";
        }

        ctrl.editorSettings = {
            width: '100%',
            height: 400,
            tools: tools,
            created: function (event) {
                ctrl.editor = event.instance;
            },
            createCommand: JQWidgetsService.getEditorCommand(ctrl.model)
        };

        ctrl.countLeftCharacters = function () {
            if (ctrl.project.description) {
                var nbChar = CustomUtils.countCharInHtmlString(ctrl.project.description);
                return ctrl.callForProject.descMaxLength - nbChar;
            } else {
                return ctrl.callForProject.descMaxLength;
            }
        };
    }
})();
