(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('responsible', {
                controller: 'ResponsibleController',
                bindings: {
                    underEdition: '<',
                    entity: '<'
                },
                templateUrl: 'app/components/responsible/responsible.html'
            });
})();
