(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventAbstractDetailController', EventAbstractDetailController);

    EventAbstractDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'EventAbstract', 'Axe', 'AbstractPerson', 'AbstractSociety', 'EventSession'];

    function EventAbstractDetailController($scope, $rootScope, $stateParams, DataUtils, entity, EventAbstract, Axe, AbstractPerson, AbstractSociety, EventSession) {
        var vm = this;

        vm.eventAbstract = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventAbstractUpdate', function(event, result) {
            vm.eventAbstract = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
