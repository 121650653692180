(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('PublicationEditDialogController', PublicationEditDialogController);

    PublicationEditDialogController.$inject = ['$uibModalInstance', 'publication', '$log', 'CustomUtils', 'DataUtils', '$scope', 'publicationTypes', 'JQWidgetsService', '$translate', '$timeout', 'DateUtils'];

    function PublicationEditDialogController($uibModalInstance, publication, $log, CustomUtils, DataUtils, $scope, publicationTypes, JQWidgetsService, $translate, $timeout, DateUtils) {

        var ctrl = this;
        DataUtils.openFile;
        ctrl.byteSize = DataUtils.byteSize;

        ctrl.publication = publication;
        ctrl.publicationTypes = publicationTypes;

        ctrl.openFile = function () {
            DataUtils.openFile(ctrl.publication.documentContentType, ctrl.publication.document);
        };

        if (CustomUtils.isUndefinedOrNull(ctrl.publication.id)) {
            ctrl.title = "Ajouter une publication";
        } else {
            ctrl.title = "Editer une publication";
        }

        ctrl.dateSettings = {
            width: "100%",
            height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {

            }
        };

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.publicationTypes,
            change: function (event) {
                if (event.args && event.args.item) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                    ctrl.publication.publicationTypeId = event.args.item.value;
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.publicationTypes.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.setDocument = function ($file) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        ctrl.publication.document = base64Data;
                        ctrl.publication.documentContentType = $file.type;
                    });
                });
            }
        };

        ctrl.ok = function () {
            if (CustomUtils.isDefined(ctrl.publication.webSite)) {
                if (!ctrl.publication.webSite.startsWith("http")) {
                    ctrl.publication.webSite = "http://" + ctrl.publication.webSite;
                }
            }
            $uibModalInstance.close(ctrl.publication);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();