(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SearchResultBarController', SearchResultBarController);

    SearchResultBarController.$inject = ['$translate', '$scope', '$translatePartialLoader', 'SearchService', 'JQWidgetsService'];

    function SearchResultBarController($translate, $scope, $translatePartialLoader, SearchService, JQWidgetsService) {
        $translatePartialLoader.addPart('searchresultbar');
        $translatePartialLoader.addPart('aggregation');
        $translate.refresh();

        var ctrl = this;
        ctrl.searchService = SearchService;

        var sortValues = [{
                value: 'pertinence',
                label: 'Pertinence'
            }, {
                value: 'name',
                label: 'Nom'
            }];

        var defaultIndex = 0;
        angular.forEach(sortValues, function (sortValue, index) {
            if (sortValue.value === ctrl.searchService.sortBy) {
                defaultIndex = index;
            }
        });

        ctrl.sortSettings = {
            height: 30,
            width: 100,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: 'label',
            valueMember: 'value',
            source: [{
                    value: 'pertinence',
                    label: 'Pertinence'
                }, {
                    value: 'name',
                    label: 'Nom'
                }],
            created: function (args) {
                ctrl.sortDropDown = args.instance;
                ctrl.sortDropDown.selectIndex(defaultIndex);
            },
            change: function (event) {
                if (event.args && event.args.item) {
                    ctrl.searchService.sortBy = event.args.item.value;
                }
                ctrl.searchService.loadFirstPage();
            }
        };

        ctrl.getExportButtonTranslation = function () {
            var numSelection = ctrl.searchService.selection.length;
            if (numSelection > 0) {
                return numSelection + " " + $translate.instant("searchresultbar.export");
            } else {
                return $translate.instant("searchresultbar.exportAll");
            }
        };
    }
})();

