(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('CustomJob', CustomJob);

    CustomJob.$inject = ['$resource', 'DateUtils'];

    function CustomJob($resource, DateUtils) {
        var resourceUrl = 'api/jobs/society/:id';
        var resourceUrl2 = 'api/jobs/person/:id';

        var resource = $resource(resourceUrl, {}, {
            'queryBySociety': {
                method: 'GET',
                isArray: true,
                params: {
                    size: '@size',
                    startDate: '@startDate',
                    endDate: '@endDate'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    return data;
                }
            }
        });

        var resource2 = $resource(resourceUrl2, {}, {
            'queryByPerson': {
                method: 'GET',
                isArray: true,
                params: {
                    size: '@size',
                    startDate: '@startDate',
                    endDate: '@endDate'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    return data;
                }
            }
        });

        resource.queryByPerson = resource2.queryByPerson.bind(resource2);

        return resource;
    }
})();
