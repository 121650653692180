(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetCallForProjectController', SheetCallForProjectController);

  SheetCallForProjectController.$inject = [
    'Principal',
    '$stateParams',
    'CallForProject',
    '$translate',
    'callForProject',
    '$state',
    '$http',
    'AlertService',
    '$uibModal'
  ];

  function SheetCallForProjectController(
    Principal,
    $stateParams,
    CallForProject,
    $translate,
    callForProject,
    $state,
    $http,
    AlertService,
    $uibModal
  ) {
    var vm = this;
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
    vm.callForProject = callForProject;
    vm.edition = {
      title: false
    };

    vm.edit = function (win) {
      vm.edition[win] = true;
    };

    vm.save = function () {
      CallForProject.update(vm.callForProject, vm.load, vm.load);
    };

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.cancel = vm.load;

    vm.tryRemove = function () {
      $uibModal.open({
        templateUrl: 'app/components/dialog/dry-remove-entity.dialog.html',
        controller: 'DryRemoveEntityDialogController',
        controllerAs: 'ctrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('dialog');
              return $translate.refresh();
            }
          ],
          entity: function () {
            return vm.callForProject;
          },
          deletionMsgKey: function () {
            return 'canceropoleGsoApp.callForProject.delete.question';
          },
          dryDeleteResult: function () {
            return $http.delete('api/call-for-projects/' + vm.callForProject.id + '/dry').then(function (response) {
              return response.data;
            });
          },
          dryInfos: function () {
            return [
              {
                key: 'abstractPersonCount',
                msg: 'abstract(s) liés à cette personne.'
              },
              { key: 'submissions', msg: 'soumission(s) liées' },
              { key: 'experts', msg: 'expert(s) liés' },
              { key: 'forms', msg: 'formulaire(s) liés' },
              { key: 'managements', msg: 'organisateur(s) liés' }
            ];
          },
          onConfirmDeletion: [
            '$q',
            function ($q) {
              return function () {
                var defer = $q.defer();
                CallForProject.delete(
                  { id: vm.callForProject.id },
                  function () {
                    AlertService.success('canceropoleGsoApp.callForProject.deleted');
                    defer.resolve();
                  },
                  function () {
                    AlertService.error('canceropoleGsoApp.callForProject.errors.delete');
                    defer.reject();
                  }
                );
                return defer.promise;
              };
            }
          ]
        }
      });
    };

    vm.loadTabs = function () {
      vm.tabs = [
        {
          title: $translate.instant('sheet.callforproject.informations.tab'),
          route: 'sheet.callforproject.main'
        },
        {
          title: $translate.instant('sheet.callforproject.form.tab'),
          route: 'sheet.callforproject.form'
        },
        {
          title: $translate.instant('sheet.callforproject.projects.tab'),
          route: 'sheet.callforproject.projects'
        }
      ];

      if (vm.callForProject.managed === true) {
        vm.tabs.splice(2, 0, {
          title: $translate.instant('sheet.callforproject.mails.tab'),
          route: 'sheet.callforproject.mails'
        });
        vm.tabs.splice(4, 0, {
          title: $translate.instant('sheet.callforproject.experts.tab'),
          route: 'sheet.callforproject.experts'
        });
        vm.tabs.splice(5, 0, {
          title: $translate.instant('sheet.callforproject.expertises.tab'),
          route: 'sheet.callforproject.expertises'
        });
      }

      var activeTab = $stateParams.tab;

      vm.activeTabIndex = 0;
      angular.forEach(vm.tabs, function (tab, index) {
        if (tab.route.endsWith(activeTab)) {
          vm.activeTabIndex = index;
        }
      });

      $state.go(vm.tabs[vm.activeTabIndex].route);
    };

    $translate('sheet.callforproject.informations.tab').then(function () {
      vm.loadTabs();
    });
  }
})();
