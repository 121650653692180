(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PersonMetierDeleteController', PersonMetierDeleteController);

    PersonMetierDeleteController.$inject = ['$uibModalInstance', 'entity', 'PersonMetier'];

    function PersonMetierDeleteController($uibModalInstance, entity, PersonMetier) {
        var vm = this;

        vm.personMetier = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            PersonMetier.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
