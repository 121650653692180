(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
        .controller('CallForProjectExpertController', CallForProjectExpertController);

    CallForProjectExpertController.$inject = ['Principal', '$rootScope', 'expertInformation', 'callForProject', 'JQWidgetsService', '$translate', '$stateParams', '$http', 'CustomUtils', 'CallForProjectService', '$state', 'AlertService', 'DateUtils', 'DataUtils', '$log', 'ConfirmDialog', '$uibModal', 'JhiLanguageService'];

    function CallForProjectExpertController(Principal, $root, expertInformation, callForProject, JQWidgetsService, $translate, $stateParams, $http, CustomUtils, CallForProjectService, $state, AlertService, DateUtils, DataUtils, $log, ConfirmDialog, $uibModal, JhiLanguageService) {

        //cache la barre de navigation
        $root.hideNavBar = !Principal.isAuthenticated();
        var vm = this;
        vm.callForProject = callForProject;
        vm.expert = expertInformation.expert;
        vm.criterions = expertInformation.criterions;
        vm.expertises = expertInformation.expertises;

        vm.lang = vm.callForProject.lang;

        angular.forEach(vm.expertises, function (expertise) {
            angular.forEach(vm.criterions, function (criterion) {
                expertise["criterion_" + criterion.fieldId] = null;
                angular.forEach(expertInformation.evaluations, function (evaluation) {
                    if (evaluation.expertiseId === expertise.id && evaluation.fieldId === criterion.fieldId) {
                        expertise["criterion_" + criterion.fieldId] = evaluation.content.value;
                    }
                });
            });
        });

        vm.isAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        vm.canEditExpertResponse = function () {
            return vm.isOpen() && vm.selectedExpertise.state !== 'DONE';
        };

        vm.canEditResponse = function () {
            return false;
        };

        vm.isOpen = function () {
            if (CustomUtils.isDefined(vm.callForProject.expertiseStartDate) && CustomUtils.isDefined(vm.callForProject.expertiseEndDate)) {
                return DateUtils.inRange(new Date(), vm.callForProject.expertiseStartDate, vm.callForProject.expertiseEndDate);
            }
            return false;
        };

        vm.reload = function () {
            $state.go($state.current, {}, { reload: true });
        };

        var porteurCellRenderer = function (row, column, value, html, cell, resource) {
            var newValue = value;
            if (vm.callForProject.showCoordinatorToExpert === false) {
                newValue = 'XXXXXXXXX';
            }
            if (value.length > 0 && vm.isAdmin() && (CustomUtils.isDefined(resource.personId) || CustomUtils.isDefined(resource.projectId))) {
                var url = "";
                if (column === 'personName') {
                    if (CustomUtils.isDefined(resource.personId)) {
                        url = "#/sheet/person/" + resource.personId + "?tab=main";
                    } else {
                        return html;
                    }
                } else if (column === 'name') {
                    url = "#/sheet/project/" + resource.projectId + "?tab=main";
                }
                var link = "<a href=\"" + url + "\" title=\"" + newValue + "\">" + newValue + "</a>";
                return html.replace(value, link);
            } else {
                return html.replace(value, newValue);
            }
        };

        var customSort = function (column, direction) {
            var records = this.records;
            if (direction === 'ascending' || direction === 'asc') {
                direction = true;
            }
            if (direction === 'descending' || direction === 'desc') {
                direction = false;
            }
            if (direction !== null) {
                records.sort(function (value1, value2) {
                    if (column === 'personName') {
                        value1 = String(value1["personLastName"]).toLowerCase();
                        value2 = String(value2["personLastName"]).toLowerCase();
                    } else if (column === 'expertStartDate' || column === 'submissionDate') {
                        value1 = value1[column];
                        value2 = value2[column];
                        if (direction === true) {
                            return DateUtils.compare(value1, value2);
                        } else {
                            return DateUtils.compare(value2, value1);
                        }
                    } else {
                        value1 = String(value1[column]).toLowerCase();
                        value2 = String(value2[column]).toLowerCase();
                    }
                    if (direction === true) {
                        return value1.localeCompare(value2);
                    } else {
                        return value2.localeCompare(value1);
                    }
                });
                this.records = records;
            }
            if (vm.grid) {
                vm.grid.refreshdata();
            }
        };

        var expertiseSource = {
            datatype: "json",
            datafields: [
                { name: 'id', type: 'number' },
                { name: 'name', type: 'string' },
                { name: 'personId', type: 'number' },
                { name: 'personName', type: 'string' },
                { name: 'personFirstName', type: 'string' },
                { name: 'personLastName', type: 'string' },
                { name: 'acronym', type: 'string' },
                { name: 'state', type: 'string' },
                { name: 'type', type: 'string' },
                { name: 'submissionId', type: 'number' },
                { name: 'axeId', type: 'number' },
                { name: 'axeName', type: 'string' },
                { name: 'askedBudget', type: 'number' },
                { name: 'askedDuration', type: 'number' },
                { name: 'generalEvaluation', type: 'string' },
                { name: 'generalNotation', type: 'number' },
                { name: 'generalAdvice', type: 'string' },
                { name: 'site', type: 'string' },
                { name: 'totalCriterion', type: 'float' }
            ],
            id: 'id',
            localdata: vm.expertises,
            sort: customSort
        };

        vm.totalCriterion = 0;
        angular.forEach(vm.criterions, function (criterion) {
            var type = null;
            if (criterion.criterionType === 'number' || criterion.criterionType === 'onlyNumber') {
                type = 'number';
                if (CustomUtils.isDefined(criterion.criterionConfig)) {
                    vm.totalCriterion += JSON.parse(criterion.criterionConfig).max;
                }
            } else if (criterion.criterionType === 'yesNo' || criterion.criterionType === 'onlyYesNo') {
                type = 'bool';
            }
            if (!_.isNil(type)) {
                expertiseSource.datafields.push({
                    name: "criterion_" + criterion.fieldId,
                    type: type
                });
            }
        });

        var expertiseDataAdapter = new $.jqx.dataAdapter(expertiseSource, {
            beforeLoadComplete: function (records) {
                angular.forEach(records, function (record) {
                    record.personName = record.personFirstName + " " + record.personLastName;
                    record.totalCriterion = 0;
                    for (var key in record) {
                        if (key.startsWith("criterion_")) {
                            var value = record[key];
                            if (angular.isNumber(value)) {
                                record.totalCriterion += value;
                            }
                        }
                    }
                });
            }
        });

        vm.loadGridSettings = function () {
            vm.expertisesGridSettings = {
                width: '100%',
                height: 500,
                source: expertiseDataAdapter,
                showaggregates: true,
                columnsresize: true,
                sortable: true,
                filterable: true,
                showfilterrow: true,
                altrows: true,
                showstatusbar: true,
                statusbarheight: 70,
                //selectionmode: 'multiplerows',
                localization: JQWidgetsService.getLocalization($translate.use()),
                columns: [{
                    text: $translate.instant('sheet.callforproject.projects.grid.coordinator'),
                    datafield: 'personName',
                    width: 180,
                    pinned: true,
                    cellsrenderer: porteurCellRenderer,
                    cellsalign: 'center',
                    align: 'center'
                }, CallForProjectService.getExpertiseStateColumn(), {
                    text: $translate.instant('sheet.callforproject.projects.grid.axe'),
                    datafield: 'axeName',
                    filterType: 'checkedlist',
                    width: 180
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.projectName'),
                    datafield: 'name',
                    minwidth: 200
                }],
                columngroups: [{
                    text: $translate.instant('sheet.callforproject.expertises.grid.criteria'),
                    align: 'center',
                    name: "evaluationGroup"
                }],
                created: function (args) {
                    vm.grid = args.instance;
                    vm.grid.sortby('personName', 'asc');
                    if (CustomUtils.isDefined($stateParams.expertiseId)) {
                        var boundIndex = vm.grid.getrowboundindexbyid($stateParams.expertiseId);
                        if (boundIndex !== -1) {
                            vm.grid.selectrow(boundIndex);
                        }
                    }
                },
                rowselect: function (event) {
                    vm.selectedExpertise = event.args.row;
                    $stateParams.expertiseId = vm.selectedExpertise.id;
                    $state.go($state.current.name, $stateParams, {
                        notify: false
                    });
                    if (CustomUtils.isUndefinedOrNull(vm.selectedExpertise.generalNotation)) {
                        vm.selectedExpertise.generalNotation = 0;
                    }
                    $http.get("api/expert/space/" + $stateParams.key + "/" + vm.selectedExpertise.id).then(function (result) {
                        if (result.data) {
                            vm.form = CallForProjectService.getStructuredForm(result.data, vm.selectedExpertise.type, true);
                            vm.project = result.data.project;
                            vm.coordonnee = result.data.coordonnee;
                            vm.initDefaultCriterionValues();
                        }
                    });
                }
            };

            //ajout des colonnes des critères
            var meanKey = '<b>' + $translate.instant('sheet.callforproject.expertises.grid.mean') + '</b>';

            angular.forEach(vm.criterions, function (criterion) {
                var columnType = null;
                var aggregates = [];
                var filterType = '';

                if (criterion.criterionType === 'number' || criterion.criterionType === 'onlyNumber') {
                    columnType = 'string';
                    filterType = 'number';

                    var aggregate = {};
                    aggregate[meanKey] = function (aggregatedValue, currentValue, column, record) {
                        if (currentValue) {
                            var moy = currentValue / vm.expertises.length;
                            aggregatedValue += moy;
                        }
                        return aggregatedValue;
                    };

                    aggregates.push(aggregate);
                } else if (criterion.criterionType === 'yesNo' || criterion.criterionType === 'onlyYesNo') {
                    columnType = 'checkbox';
                    filterType = 'bool';

                    var yesKey = '<b>' + $translate.instant('global.yes') + '</b>';
                    var noKey = '<b>' + $translate.instant('global.no') + '</b>';

                    var yesAggregate = {};
                    yesAggregate[yesKey] = function (aggregatedValue, currentValue) {
                        if (currentValue === true) {
                            return aggregatedValue + 1;
                        }
                        return aggregatedValue;
                    };

                    var noAggregate = {};
                    noAggregate[noKey] = function (aggregatedValue, currentValue) {
                        if (currentValue === false) {
                            return aggregatedValue + 1;
                        }
                        return aggregatedValue;
                    };
                    aggregates.push(yesAggregate, noAggregate);
                }

                if (!_.isNil(columnType)) {
                    vm.expertisesGridSettings.columns.push({
                        text: criterion.criterionLabel,
                        datafield: 'criterion_' + criterion.fieldId,
                        filterType: filterType,
                        columnType: columnType,
                        rendered: function (element) {
                            $(element).jqxTooltip({ position: 'mouse', content: $(element).text() });
                        },
                        aggregates: aggregates,
                        columngroup: 'evaluationGroup',
                        width: 100,
                        cellsalign: 'center',
                        align: 'center'
                    });
                }
            });

            var totalMeanAgg = {};
            totalMeanAgg[meanKey] = function (aggregatedValue, currentValue, column, record) {
                if (currentValue) {
                    var moy = currentValue / vm.expertises.length;
                    aggregatedValue += moy;
                }
                return aggregatedValue;
            };

            vm.expertisesGridSettings.columns.push({
                text: 'Total / ' + vm.totalCriterion,
                datafield: 'totalCriterion',
                width: 100,
                aggregates: [totalMeanAgg],
                filtertype: 'number',
                cellsformat: 'f1',
                cellsalign: 'center',
                align: 'center'
            });

            vm.expertisesGridSettings.columns.push({
                text: $translate.instant('sheet.callforproject.experts.expertGrid.notation'),
                datafield: 'generalNotation',
                columnType: 'custom',
                filterType: 'checkedlist',
                createwidget: function (row, column, value, htmlElement) {
                    var rating = $('<div style="margin-top:2px;margin-left:8px;"></div>');
                    $(htmlElement).append(rating);
                    rating.jqxRating({
                        height: 30,
                        count: 4,
                        value: value,
                        disabled: true
                    });
                },
                initwidget: function (row, column, value, htmlElement) {
                    $(htmlElement).children().jqxRating('setValue', value);
                },
                width: 100,
                cellsalign: 'center',
                align: 'center'
            });
        };

        vm.loadGridSettings();

        vm.initDefaultCriterionValues = function () {
            if (vm.form && vm.form.groups) {
                angular.forEach(vm.form.groups, function (group) {
                    angular.forEach(group.fields, function (field) {
                        if (CustomUtils.isUndefinedOrNull(field.criterionValue)) {
                            if (field.criterionType === 'number' ||
                                field.criterionType === 'onlyNumber') {
                                field.criterionValue = 0;
                            }
                        }
                    });
                });
            }
        };

        vm.trySaveEvaluation = function () {
            var title;
            if (vm.expertForm.$invalid || vm.selectedExpertise.generalNotation === 0) {
                title = $translate.instant('canceropoleGsoApp.expertise.confirmIncompleteTitle');
                var content = $translate.instant('canceropoleGsoApp.expertise.confirmIncompleteContent');

                ConfirmDialog.openYesNo(title, content, function (result) {
                    if (result) {
                        vm.saveEvaluation("INPROGRESS");
                    }
                });
            } else {
                title = $translate.instant('canceropoleGsoApp.expertise.confirmCompleteTitle');
                var message = $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent1');
                message += $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent2');
                message += "<br/><br/>";
                message += '<div class="alert alert-danger" role="alert">';
                message += $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent3');
                message += "</div>";
                ConfirmDialog.openYesNo(title, message, function (result) {
                    if (result) {
                        vm.saveEvaluation("DONE");
                    } else {
                        vm.saveEvaluation("INPROGRESS");
                    }
                });
            }
        };

        vm.tryValidateEvaluation = function () {
            if (vm.expertForm.$invalid || vm.selectedExpertise.generalNotation === 0) {
                AlertService.openError($translate.instant('canceropoleGsoApp.expertise.correctIncompleteContent'));
            } else {
                var title = $translate.instant('canceropoleGsoApp.expertise.confirmCompleteTitle');
                var message = $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent1');
                message += $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent2');
                message += "<br/><br/>";
                message += '<div class="alert alert-danger" role="alert">';
                message += $translate.instant('canceropoleGsoApp.expertise.confirmCompleteContent3');
                message += "</div>";
                ConfirmDialog.openYesNo(title, message, function (result) {
                    if (result) {
                        vm.saveEvaluation("DONE");
                    } else {
                        vm.saveEvaluation("INPROGRESS");
                    }
                });
            }
        };

        vm.saveEvaluation = function (state) {
            var evaluations = [];
            angular.forEach(vm.form.groups, function (group) {
                angular.forEach(group.fields, function (field) {
                    if (angular.isFunction(field.getEvaluation)) {
                        var evaluation = field.getEvaluation();
                        if (evaluation !== null) {
                            var responseEvaluation = {
                                expertiseId: vm.selectedExpertise.id,
                                callForProjectFormFieldId: field.id,
                                content: JSON.stringify(evaluation)
                            };
                            evaluations.push(responseEvaluation);
                        }
                    }
                });
            });

            var toSend = {
                expertiseId: vm.selectedExpertise.id,
                generalEvaluation: vm.selectedExpertise.generalEvaluation,
                generalNotation: vm.selectedExpertise.generalNotation,
                generalAdvice: vm.selectedExpertise.generalAdvice,
                state: state,
                evaluations: evaluations
            };
            $http.post('api/expert/space/' + $stateParams.key, toSend).then(function (response) {
                AlertService.success($translate.instant('canceropoleGsoApp.expertise.evaluationSaved'));
                vm.reload();
            });
        };

        vm.exportExpertise = function (type) {
            var fileName = "Expertise_" + vm.expert.expertName + "_" + vm.selectedExpertise.personName + "." + type;

            var toSend = {
                type: type,
                data: [vm.selectedExpertise.id]
            };
            $log.info("tosend=", toSend);

            var url = 'api/call-for-projects/' + vm.callForProject.id + '/expertises/export';

            DataUtils.downloadFileFromPostUrl(url, toSend, fileName);
        };

        vm.exportProject = function (type) {
            var columns = {};
            columns["info_porteur"] = "Description Administrative";
            columns.projet = "Bloc projet";

            angular.forEach(vm.form.groups, function (group) {
                var datafield = "group_" + group.id;
                columns[datafield] = group.name;
            });

            var fileName = vm.selectedExpertise.personName + '.zip';
            var toSend = {
                type: type,
                columns: columns,
                data: [vm.selectedExpertise.submissionId]
            };
            $log.info("tosend=", toSend);
            DataUtils.downloadFileFromPostUrl("api/call-for-projects/submissions/export", toSend, fileName);
        };

        vm.removeExpertise = function () {
            var nbExpertise = expertiseDataAdapter.records.length;
            $log.info(nbExpertise);
            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/remove-expert.dialog.html',
                controller: 'RemoveExpertDialogController',
                controllerAs: 'ctrl',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expert');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    callForProject: function () {
                        return vm.callForProject;
                    },
                    submission: function () {
                        return {
                            id: vm.selectedExpertise.submissionId,
                            name: vm.selectedExpertise.name
                        };
                    },
                    experts: function () {
                        return [{
                            id: vm.expert.id,
                            personName: vm.expert.expertName
                        }];
                    }
                }
            }).result.then(function () {
                if (nbExpertise > 1) {
                    vm.reload();
                } else if (vm.isAdmin()) {
                    $state.go("sheet.callforproject.expertises", {
                        id: vm.callForProject.id,
                        tab: 'expertises'
                    });
                }
            });

        };

        vm.loadAttachment = function () {
            vm.attachments = [];
            if (CustomUtils.isDefined(vm.callForProject.expertManualId)) {
                $http.get("api/public/file-attachements/expertNotice/" + vm.callForProject.id).then(function (response) {
                    if (response.status === 200) {
                        vm.attachments = response.data;
                    }
                });
            }
        };

        vm.openAttachment = function (attachment) {
            DataUtils.downloadAttachment(attachment.id, attachment.path);
        };

        vm.loadAttachment();
    }
})();
