(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('SubmissionSearch', SubmissionSearch);

    SubmissionSearch.$inject = ['$resource'];

    function SubmissionSearch($resource) {
        var resourceUrl =  'api/_search/submissions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
