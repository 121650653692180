(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('AxesController', AxesController);

  AxesController.$inject = [
    'CustomUtils',
    'axes',
    'DateUtils',
    '$log',
    'PersonAxe',
    'ConfirmDialog',
    '$translate',
    '$state',
    '$stateParams',
    '$uibModal'
  ];

  function AxesController(CustomUtils, axes, DateUtils, $log, PersonAxe, ConfirmDialog, $translate, $state, $stateParams, $uibModal) {
    var vm = this;
    vm.axes = axes;

    var result = [];
    angular.forEach(vm.axes, function (axe) {
      var axeInfo = null;
      for (var i = 0; i < result.length; i++) {
        var aAxeInfo = result[i];
        if (aAxeInfo.id === axe.id) {
          axeInfo = aAxeInfo;
        }
      }
      if (axeInfo === null) {
        axeInfo = {
          id: axe.id,
          name: axe.name,
          current: [],
          history: []
        };
        result.push(axeInfo);
      }
      if (axe.personId) {
        var info = {
          personId: axe.personId,
          name: axe.personName,
          type: axe.type,
          personAxeId: axe.personAxeId
        };
        var startDate = null;
        var endDate = null;

        if (!CustomUtils.isUndefinedOrNull(axe.startDate)) {
          startDate = new Date(axe.startDate);
        }
        if (!CustomUtils.isUndefinedOrNull(axe.endDate)) {
          endDate = new Date(axe.endDate);
        }

        info.startDate = startDate;
        info.endDate = endDate;

        if (CustomUtils.isUndefinedOrNull(axe.endDate)) {
          axeInfo.current.push(info);
        } else {
          axeInfo.history.push(info);
        }
      }
    });

    angular.forEach(result, function (axe) {
      axe.history.sort(function (h1, h2) {
        var endDate1 = h1.endDate;
        var endDate2 = h2.endDate;
        return DateUtils.compare(endDate1, endDate2);
      });
    });
    vm.axes = result;

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.tryRemovePerson = function (person) {
      var text = $translate.instant('canceropoleGsoApp.PersonAxeType.' + person.type) + ' - ' + person.name;
      ConfirmDialog.openConfirmRemove(text, function () {
        vm.removePerson(person);
      });
    };

    vm.addPerson = function (axe) {
      $log.info(axe);
      vm.editPerson(axe, {
        personId: null,
        name: null,
        type: null,
        personAxeId: null,
        startDate: null,
        endDate: null
      });
    };

    vm.editPerson = function (axe, personAxeInfo) {
      $log.info(arguments);
      $uibModal
        .open({
          templateUrl: 'app/axes/dialog/add-personAxe.dialog.html',
          controller: 'AddPersonAxeDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('personAxe');
                $translatePartialLoader.addPart('personAxeType');
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('dialog');
                return $translate.refresh();
              }
            ],
            axe: function () {
              return axe;
            },
            personAxeInfo: function () {
              return personAxeInfo;
            }
          }
        })
        .result.then(function () {
          vm.load();
        });
    };

    vm.removePerson = function (person) {
      PersonAxe.delete({ id: person.personAxeId }, function () {
        vm.load();
      });
    };
  }
})();
