(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('MainEventMailDetailController', MainEventMailDetailController);

    MainEventMailDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'MainEventMail', 'MainEvent'];

    function MainEventMailDetailController($scope, $rootScope, $stateParams, DataUtils, entity, MainEventMail, MainEvent) {
        var vm = this;

        vm.mainEventMail = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:mainEventMailUpdate', function(event, result) {
            vm.mainEventMail = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
