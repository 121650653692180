(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('CallForProjectSearch', CallForProjectSearch);

    CallForProjectSearch.$inject = ['$resource'];

    function CallForProjectSearch($resource) {
        var resourceUrl =  'api/_search/call-for-projects/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
