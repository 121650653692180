(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('SheetTitleController', SheetTitleController);

    SheetTitleController.$inject = ['Principal', '$state', 'DataUtils', '$scope'];

    function SheetTitleController(Principal, $state, DataUtils, $scope) {
        var ctrl = this;
        ctrl.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        ctrl.titleEdition = false;

        ctrl.editTitle = function () {
            ctrl.titleEdition = true;
        };

        ctrl.trySave = function () {
            if (ctrl.save) {
                ctrl.save();
            }
            ctrl.titleEdition = false;
        };

        ctrl.tryRemove = function () {
            if (ctrl.remove) {
                ctrl.remove();
            }
            ctrl.titleEdition = false;
        };

        ctrl.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        ctrl.cancel = ctrl.load;

        ctrl.titleEditorSettings = {
            width: '100%',
            height: 100,
            tools: "bold italic underline | font | color | html"
        };

        ctrl.byteSize = DataUtils.byteSize;
        ctrl.setAvatar = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        ctrl.entity.avatar = base64Data;
                        ctrl.entity.avatarContentType = $file.type;
                    });
                });
            }
        };
    }
})();