(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .factory('EscapeService', EscapeService);

    EscapeService.$inject = ['$http', '$q', '$log'];

    function EscapeService($http, $q, $log) {

        var service = {
            parameters: null,
            properties: null,
            get: function () {
                var that = this;
                if (this.parameters === null || this.properties === null) {
                    return $http.get("api/public/escape").success(function (response) {
                        that.parameters = response.parameters;
                        that.properties = response.properties;
                        return that.parameters;
                    });
                } else {
                    return $q.when(true).then(function () {
                        return that.parameters;
                    });
                }
            },
            getProperties: function () {
                var that = this;
                return this.get().then(function () {
                    return that.properties;
                });
            },
            getProperty: function (key) {
                var that = this;
                return this.get().then(function () {
                    for (var prop in that.properties) {
                        if (prop === key) {
                            return that.properties[key];
                        }
                    }
                    $log.warn("Property '%s' does not exists", key);
                    return null;
                });
            },
            getParameters: function () {
                var that = this;
                return this.get().then(function () {
                    return that.parameters;
                });
            },
            getParameter: function (key) {
                var that = this;
                return this.get().then(function () {
                    for (var i = 0; i < that.parameters.length; i++) {
                        var param = that.parameters[i];
                        if (param.key === key) {
                            return param;
                        }
                    }
                    $log.warn("Parameter %s does not exists", key);
                    return null;
                });
            }
        };

        return service;

    }

})();
