(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('sientificAttachment', {
                controller: 'ScientificAttachmentComponentController',
                bindings: {
                    entity: '=',
                    save: '='
                },
                templateUrl: 'app/components/scientificAttachment/scientific-attachment.html'
            });
})();
