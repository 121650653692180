(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ExpertDetailController', ExpertDetailController);

    ExpertDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Expert', 'Person', 'CallForProject', 'Expertise'];

    function ExpertDetailController($scope, $rootScope, $stateParams, entity, Expert, Person, CallForProject, Expertise) {
        var vm = this;

        vm.expert = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:expertUpdate', function(event, result) {
            vm.expert = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
