(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('priceTab', {
                controller: 'PriceTabController',
                bindings: {
                    prices: '<',
                    mainEvent: '<',
                    showFree: '@'
                },
                templateUrl: 'app/sheet/mainevent/components/price-tab.html'
            });
})();
