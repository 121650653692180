(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('EventAbstractSearch', EventAbstractSearch);

    EventAbstractSearch.$inject = ['$resource'];

    function EventAbstractSearch($resource) {
        var resourceUrl =  'api/_search/event-abstracts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
