(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('ExportExpertisesDialogController', ExportExpertisesDialogController);

    ExportExpertisesDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'fields', 'DataUtils', '$timeout'];

    function ExportExpertisesDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, fields, DataUtils, $timeout) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;

        ctrl.exportType = 'simple';
        ctrl.outType = 'pdf';
        ctrl.formFieldsList = fields;
        ctrl.fieldsToExport = [];

        angular.forEach(ctrl.formFieldsList, function (formField) {
            ctrl.fieldsToExport.push({
                'id': formField.id,
                'label': formField.label,
                'visible': true
            });
        });

        ctrl.fieldsToExport.push({
            'id': 'generalNotation',
            'label': $translate.instant('canceropoleGsoApp.expertise.generalNotation'),
            'visible': true
        });

        ctrl.fieldsToExport.push({
            'id': 'generalAdvice',
            'label': $translate.instant('canceropoleGsoApp.expertise.generalAdvice'),
            'visible': true
        });

        ctrl.fieldsToExport.push({
            'id': 'generalEvaluation',
            'label': $translate.instant('canceropoleGsoApp.expertise.generalEvaluation'),
            'visible': true
        });

        ctrl.ok = function () {
            var result = [];
            angular.forEach(ctrl.fieldsToExport, function (field) {
                if (field.visible) {
                    result.push(field.id);
                }
            });

            $uibModalInstance.close({
                type: ctrl.exportType,
                out: ctrl.outType,
                fields: result
            });
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
