(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ResponsibleFunctionDetailController', ResponsibleFunctionDetailController);

    ResponsibleFunctionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ResponsibleFunction', 'Team'];

    function ResponsibleFunctionDetailController($scope, $rootScope, $stateParams, entity, ResponsibleFunction, Team) {
        var vm = this;

        vm.responsibleFunction = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:responsibleFunctionUpdate', function(event, result) {
            vm.responsibleFunction = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
