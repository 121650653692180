(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('OrderRefDialogController', OrderRefDialogController);

    OrderRefDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'billRef', 'orderRef', 'participations'];

    function OrderRefDialogController($translate, $translatePartialLoader, $uibModalInstance, billRef, orderRef, participations) {

        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.participations = participations;
        ctrl.participationId = ctrl.participations[0].id;
        ctrl.orderRef = orderRef;
        ctrl.billRef = billRef;
        ctrl.societyName = null;
        ctrl.societyAddress = null;
        ctrl.societyAddress2 = null;
        ctrl.societyCp = null;
        ctrl.societyCity = null;

        ctrl.ok = function () {
            var participationSelected = _.find(ctrl.participations, function (o) {
                return o.id === ctrl.participationId;
            });

            $uibModalInstance.close({
                participation: participationSelected,
                orderRef: ctrl.orderRef,
                billRef: ctrl.billRef,
                societyName: ctrl.societyName,
                societyAddress: ctrl.societyAddress,
                societyAddress2: ctrl.societyAddress2,
                societyCp: ctrl.societyCp,
                societyCity: ctrl.societyCity
            });
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();