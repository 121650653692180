(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetCallForProjectExpertsTabController', SheetCallForProjectExpertsTabController);

  SheetCallForProjectExpertsTabController.$inject = [
    '$log',
    '$stateParams',
    'JQWidgetsService',
    'SheetHistoryService',
    '$state',
    'callForProject',
    '$translate',
    'Principal',
    '$filter',
    'CallForProjectService',
    '$uibModal',
    'CallForProject',
    'DateUtils',
    'CustomUtils'
  ];

  function SheetCallForProjectExpertsTabController(
    $log,
    $stateParams,
    JQWidgetsService,
    SheetHistoryService,
    $state,
    callForProject,
    $translate,
    Principal,
    $filter,
    CallForProjectService,
    $uibModal,
    CallForProject,
    DateUtils,
    CustomUtils
  ) {
    if ($stateParams.tab !== 'experts') {
      //update state param
      $stateParams.tab = 'experts';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.callForProject = callForProject;
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
    vm.selectedSubmission = null;
    vm.selectedExpert = null;

    var sheetHistory = {
      name: vm.callForProject.name,
      type: 'callforproject',
      id: vm.callForProject.id
    };
    SheetHistoryService.add(sheetHistory);

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    var submissionSource = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'date', type: 'date' },
        { name: 'name', type: 'string' },
        { name: 'projectId', type: 'number' },
        { name: 'privateKey', type: 'number' },
        { name: 'keep', type: 'bool' },
        { name: 'funded', type: 'bool' },
        { name: 'acceptedBudget', type: 'number' },
        { name: 'acceptedDuration', type: 'number' },
        { name: 'askedDuration', type: 'number' },
        { name: 'askedBudget', type: 'number' },
        { name: 'personId', type: 'number' },
        { name: 'personFirstName', type: 'String' },
        { name: 'personLastName', type: 'String' },
        { name: 'personName', type: 'String' },
        { name: 'geositeId', type: 'number' },
        { name: 'geositeName', type: 'String' },
        { name: 'axeId', type: 'number' },
        { name: 'axeName', type: 'String' }
      ],
      id: 'id',
      url: 'api/call-for-projects/project-submissions/' + vm.callForProject.id
    };

    vm.submissionDataAdapter = new $.jqx.dataAdapter(submissionSource, {
      beforeLoadComplete: function (records) {
        angular.forEach(records, function (record) {
          record.personName = record.personFirstName + ' ' + record.personLastName;
        });
      }
    });

    var linkCellRenderer = function (row, column, value, html, cell, resource) {
      if (value.length > 0) {
        var url = '';
        var strValue = '';
        if (column === 'personFirstName' || column === 'personLastName') {
          if (CustomUtils.isDefined(resource.personId)) {
            url = '#/sheet/person/' + resource.personId + '?tab=main';
            strValue = value;
          } else {
            return html;
          }
        } else if (column === 'name') {
          url = '#/sheet/project/' + resource.projectId + '?tab=main';
          strValue = value;
        } else if (column === 'startDate') {
          url = '#/callforproject/' + vm.callForProject.id + '/expert?key=' + resource.key;
          strValue = $filter('date')(value, 'dd/MM/yyyy HH:mm');
        }
        var link = '<a href="' + url + '" title="' + value + '">' + strValue + '</a>';
        return html.replace(value, link);
      }
      return '';
    };

    var typeCellRenderer = function (row, column, value, html, cell, resource) {
      if (value.length > 0) {
        var label = CallForProjectService.getFormTypeLabel(vm.callForProject, value);
        return html.replace(value, label);
      }
      return '';
    };

    vm.dateTimeInput = null;

    vm.dateTimeSettings = {
      culture: 'fr-FR',
      formatString: 'dd/MM/yyyy',
      height: 30,
      selectionMode: 'range',
      created: function (args) {
        vm.dateTimeInput = args.instance;
        vm.dateTimeInput.setRange(vm.callForProject.expertiseStartDate, vm.callForProject.expertiseEndDate);
        vm.dateTimeInitialized = true;
      },
      change: function (event) {
        var selection = vm.dateTimeInput.getRange();
        if (selection.from != null && vm.dateTimeInitialized === true) {
          vm.callForProject.expertiseStartDate = selection.from;
          vm.callForProject.expertiseEndDate = selection.to;

          CallForProject.update(vm.callForProject);
        }
      }
    };

    vm.submissionGridSettings = {
      width: '100%',
      height: 500,
      source: vm.submissionDataAdapter,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      altrows: true,
      showstatusbar: true,
      statusbarheight: 25,
      showaggregates: true,
      selectionmode: 'checkbox',
      localization: JQWidgetsService.getLocalization($translate.use()),
      columns: [
        {
          text: $translate.instant('sheet.callforproject.projects.grid.lastName'),
          datafield: 'personLastName',
          width: 110,
          cellsrenderer: linkCellRenderer,
          pinned: true
        },
        {
          text: $translate.instant('sheet.callforproject.projects.grid.firstName'),
          datafield: 'personFirstName',
          width: 110,
          cellsrenderer: linkCellRenderer,
          pinned: true
        },
        {
          text: $translate.instant('sheet.callforproject.projects.grid.axe'),
          datafield: 'axeName',
          filtertype: 'checkedlist'
        },
        {
          text: $translate.instant('sheet.callforproject.projects.grid.projectName'),
          datafield: 'name',
          minwidth: 300,
          cellsrenderer: linkCellRenderer
        }
      ],
      created: function (args) {
        vm.submissionGrid = args.instance;
        vm.loadExperts();
      },
      rowselect: function (event) {
        vm.loadExperts();
      },
      rowunselect: function (event) {
        vm.loadExperts();
      }
    };

    vm.loadExperts = function () {
      var submissions = vm.getSelectedSubmissions();

      var ids = [];
      angular.forEach(submissions, function (submission) {
        ids.push(submission.id);
      });

      vm.expertGridSettings.source = new $.jqx.dataAdapter(
        {
          datatype: 'json',
          url: 'api/experts/callforproject/' + vm.callForProject.id + '?submission=' + ids
        },
        {
          beforeLoadComplete: function (records) {
            angular.forEach(records, function (record) {
              record.personName = record.personFirstName + ' ' + record.personLastName;
            });
          }
        }
      );

      vm.clearSelection();
    };

    vm.getSelectedSubmissions = function () {
      vm.selectedSubmissionIndexes = vm.submissionGrid.getselectedrowindexes();

      vm.selectedSubmissions = [];
      angular.forEach(vm.selectedSubmissionIndexes, function (selectedIndex) {
        var expertise = vm.submissionGridSettings.source.records[selectedIndex];
        vm.selectedSubmissions.push(expertise);
      });
      return vm.selectedSubmissions;
    };

    vm.clearSelection = function () {
      vm.selectedExpert = null;
      if (CustomUtils.isDefined(vm.expertGrid)) {
        vm.expertGrid.clearselection();
      }
    };

    vm.expertGridSettings = {
      width: '100%',
      height: 500,
      source: [],
      editable: vm.canEdit,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      altrows: true,
      showstatusbar: true,
      statusbarheight: 25,
      selectionmode: 'checkbox',
      showaggregates: true,
      localization: JQWidgetsService.getLocalization($translate.use()),
      columns: [
        {
          text: $translate.instant('sheet.callforproject.experts.expertGrid.lastName'),
          datafield: 'personLastName',
          width: 110,
          cellsrenderer: linkCellRenderer,
          editable: false,
          pinned: true
        },
        {
          text: $translate.instant('sheet.callforproject.experts.expertGrid.firstName'),
          datafield: 'personFirstName',
          width: 110,
          cellsrenderer: linkCellRenderer,
          editable: false,
          pinned: true
        },
        {
          text: $translate.instant('sheet.callforproject.experts.expertGrid.type'),
          datafield: 'type',
          filterType: 'checkedlist',
          cellsrenderer: typeCellRenderer,
          width: 150,
          editable: false,
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: $translate.instant('sheet.callforproject.projects.grid.projectName'),
          datafield: 'submissionName',
          cellsrenderer: function (row, column, value, html, cell, resource) {
            if (!CustomUtils.isEmpty(value)) {
              var url = '#/sheet/project/' + resource.submissionId + '?tab=main';
              var strValue = value;

              var link = '<a href="' + url + '" title="' + value + '">' + strValue + '</a>';
              return html.replace(value, link);
            }
            return '';
          },
          minwidth: 160
        },
        {
          text: $translate.instant('sheet.callforproject.experts.expertGrid.startDate'),
          datafield: 'startDate',
          filterType: 'date',
          cellsrenderer: linkCellRenderer,
          width: 135,
          editable: false,
          cellsalign: 'center',
          align: 'center'
        }
      ],
      created: function (args) {
        vm.expertGrid = args.instance;
        vm.expertsSelection = vm.getExpertSelection();
      },
      rowselect: function () {
        vm.expertsSelection = vm.getExpertSelection();
      },
      rowunselect: function () {
        vm.expertsSelection = vm.getExpertSelection();
      }
    };

    vm.getExpertSelection = function () {
      var result = [];
      var indexes = vm.expertGrid.getselectedrowindexes();

      angular.forEach(indexes, function (index) {
        var expert = vm.expertGrid.getrowdata(index);
        result.push(expert);
      });

      return result;
    };

    vm.addExpert = function () {
      if (vm.selectedSubmissions.length > 0) {
        $uibModal
          .open({
            templateUrl: 'app/sheet/callforproject/dialog/add-expert.dialog.html',
            controller: 'AddExpertDialogController',
            controllerAs: 'ctrl',
            size: 'md',
            backdrop: 'static',
            resolve: {
              translatePartialLoader: [
                '$translate',
                '$translatePartialLoader',
                function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('expert');
                  $translatePartialLoader.addPart('global');
                  return $translate.refresh();
                }
              ],
              callForProject: function () {
                return vm.callForProject;
              },
              submissions: function () {
                return vm.selectedSubmissions;
              }
            }
          })
          .result.then(function () {
            vm.loadExperts();
          });
      }
    };

    vm.sendMailToExperts = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/callforproject/dialog/send-callforproject-mail.dialog.html',
          controller: 'SendCallForProjectMailDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('expert');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }
            ],
            callForProject: function () {
              return vm.callForProject;
            },
            experts: function () {
              return vm.getExpertSelection();
            },
            existingsMails: [
              '$http',
              function ($http) {
                return $http.get('api/call-for-projects/mails/' + vm.callForProject.id).then(function (response) {
                  return response.data;
                });
              }
            ]
          }
        })
        .result.then(function () {
          vm.loadExperts();
        });
    };

    vm.removeExperts = function () {
      if (vm.getExpertSelection().length > 0) {
        var experts = vm.getExpertSelection();
        $uibModal
          .open({
            templateUrl: 'app/sheet/callforproject/dialog/remove-expert.dialog.html',
            controller: 'RemoveExpertDialogController',
            controllerAs: 'ctrl',
            backdrop: 'static',
            size: 'md',
            resolve: {
              translatePartialLoader: [
                '$translate',
                '$translatePartialLoader',
                function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('expert');
                  $translatePartialLoader.addPart('global');
                  return $translate.refresh();
                }
              ],
              callForProject: function () {
                return vm.callForProject;
              },
              submission: function () {
                return vm.selectedSubmissions[0];
              },
              experts: function () {
                return experts;
              }
            }
          })
          .result.then(function () {
            vm.loadExperts();
          });
      }
    };
  }
})();
