(function () {
  'use strict';

  angular.module('canceropoleGsoApp').service('CharactersDialog', CharactersDialog);

  CharactersDialog.$inject = ['$uibModal'];

  function CharactersDialog($uibModal) {
    this.open = function (onOk, onCancel) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/components/characters/characters.dialog.html',
        controller: 'CharactersDialogController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          mainTranslatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('dialog');
              return $translate.refresh();
            }
          ],
          context: function () {
            return {};
          }
        }
      });
      return modalInstance.result;
    };
  }
})();
