(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('AvatarSelectionController', AvatarSelectionController);

    AvatarSelectionController.$inject = ['$translate', '$translatePartialLoader', 'DataUtils', '$scope'];

    function AvatarSelectionController($translate, $translatePartialLoader, DataUtils, $scope) {
        $translatePartialLoader.addPart('global');
        $translate.refresh();
        var ctrl = this;

        ctrl.byteSize = DataUtils.byteSize;

        ctrl.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        ctrl.file = base64Data;
                        ctrl.fileContentType = $file.type;
                    });
                });
            }
        };

    }
})();