(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('sheetWindowMap', {
                controller: 'SheetWindowMap',
                bindings: {
                    winId: '@',
                    panelTitle: '@',
                    markerMessage: '@',
                    type: '@?',
                    canEdit: '=',
                    entity: '=',
                    saveAction: '=',
                    cancelAction: '='
                },
                templateUrl: 'app/sheet/components/window/sheet.window.map.html'
            });
})();

