(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('uploadFile', {
                controller: 'UploadFileController',
                bindings: {
                    url: '@',
                    fileAttachement: '=',
                    onSuccess: '=',
                    onError: '=?',
                    onRemove: '='
                },
                templateUrl: 'app/components/upload/upload-file.html'
            });
})();
