(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('axesTab', {
                controller: 'AxesTabController',
                bindings: {
                    axes: '<',
                    add: '=',
                    edit: '=',
                    remove: '='
                },
                templateUrl: 'app/axes/components/axes-tab.html'
            });
})();
