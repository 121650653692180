(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('aggregations', {
                controller: 'AggregationsController',
                templateUrl: 'app/search/aggregations/aggregations.html'
            });
})();

