(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('call-for-project-form', {
            parent: 'entity',
            url: '/call-for-project-form',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'canceropoleGsoApp.callForProjectForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-form/call-for-project-forms.html',
                    controller: 'CallForProjectFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectForm');
                    $translatePartialLoader.addPart('cFPFormType');
                    $translatePartialLoader.addPart('cFPFormState');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('call-for-project-form-detail', {
            parent: 'entity',
            url: '/call-for-project-form/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'canceropoleGsoApp.callForProjectForm.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-form/call-for-project-form-detail.html',
                    controller: 'CallForProjectFormDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectForm');
                    $translatePartialLoader.addPart('cFPFormType');
                    $translatePartialLoader.addPart('cFPFormState');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CallForProjectForm', function($stateParams, CallForProjectForm) {
                    return CallForProjectForm.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('call-for-project-form.new', {
            parent: 'call-for-project-form',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form/call-for-project-form-dialog.html',
                    controller: 'CallForProjectFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                template: false,
                                type: null,
                                state: null,
                                header: null,
                                footer: null,
                                startDate: null,
                                endDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form', null, { reload: true });
                }, function() {
                    $state.go('call-for-project-form');
                });
            }]
        })
        .state('call-for-project-form.edit', {
            parent: 'call-for-project-form',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form/call-for-project-form-dialog.html',
                    controller: 'CallForProjectFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CallForProjectForm', function(CallForProjectForm) {
                            return CallForProjectForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('call-for-project-form.delete', {
            parent: 'call-for-project-form',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-form/call-for-project-form-delete-dialog.html',
                    controller: 'CallForProjectFormDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CallForProjectForm', function(CallForProjectForm) {
                            return CallForProjectForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-form', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
