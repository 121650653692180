(function() {
  'use strict';

  angular.module('canceropoleGsoApp').controller('LinkEditorDialogController', LinkEditorDialogController);

  LinkEditorDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance'];

  function LinkEditorDialogController($translate, $translatePartialLoader, $uibModalInstance) {
    $translatePartialLoader.addPart('dialog');
    $translate.refresh();

    var ctrl = this;

    ctrl.model = {
      url: '',
      label: '',
      blank: false
    };

    ctrl.save = function() {
      $uibModalInstance.close(ctrl.model);
    };
    ctrl.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
