(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('AddCallForProjectDialogController', AddCallForProjectDialogController);

  AddCallForProjectDialogController.$inject = [
    '$scope',
    '$uibModalInstance',
    'DataUtils',
    'CallForProject',
    'axes',
    'JQWidgetsService',
    '$translate',
    'SocietySearch',
    'aapTypes'
  ];

  function AddCallForProjectDialogController(
    $scope,
    $uibModalInstance,
    DataUtils,
    CallForProject,
    axes,
    JQWidgetsService,
    $translate,
    SocietySearch,
    aapTypes
  ) {
    var vm = this;
    vm.clear = clear;
    vm.byteSize = DataUtils.byteSize;
    vm.openFile = DataUtils.openFile;
    vm.save = save;
    vm.goToSheet = false;
    vm.axes = axes;

    vm.callForProject = {
      name: name.firstName,
      year: name.lastName,
      showCoordinatorToExpert: true,
      askBudget: false,
      askDuration: false,
      askAcronym: false,
      askAxe: false,
      askKeyword: false,
      askPartner: false,
      askUnitDirector: false,
      askTeamName: false,
      askTeamDirector: false,
      askDescription: false,
      publique: false,
      managed: true,
      privateSpace: false,
      canSaveSubmission: false,
      lang: 'fr',
      callForProjectTypeId: '',
      axes: angular.copy(vm.axes),
      societies: []
    };

    var yearsSource = [];
    for (var i = 2010; i <= 2040; i++) {
      yearsSource.push(i.toString());
    }

    vm.yearsSettings = {
      source: yearsSource,
      width: '100%',
      height: 32,
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use())
    };

    aapTypes.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    vm.typesSettings = {
      displayMember: 'name',
      valueMember: 'id',
      source: {
        localdata: aapTypes,
        datatype: 'json',
        datafields: [
          {
            name: 'id',
            type: 'number'
          },
          {
            name: 'name',
            type: 'string'
          }
        ]
      },
      width: '100%',
      height: 32,
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use())
    };

    var societySource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    vm.societyDataAdapter = new $.jqx.dataAdapter(societySource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        var query = 'name:' + source.searchString;
        query += ' OR shortName:' + source.searchString;
        query += ' OR shortName:' + source.searchString + '*';
        SocietySearch.query(
          {
            query: query,
            page: 0,
            size: 20
          },
          function (result) {
            callback({
              records: result
            });
          }
        );
      }
    });

    vm.axesSettings = {
      width: '100%',
      height: 160,
      displayMember: 'name',
      valueMember: 'id',
      multiple: true,
      checkboxes: true,
      source: vm.axes,
      created: function (args) {
        vm.axeList = args.instance;
        angular.forEach(vm.callForProject.axes, function (axe) {
          vm.axeList.checkItem(axe.id);
        });
      },
      checkChange: function (event) {
        if (event.args) {
          var args = event.args;

          var index = null;
          for (var i = 0; i < vm.callForProject.axes.length; i++) {
            var axe = vm.callForProject.axes[i];
            if (axe.id === args.value) {
              index = i;
              break;
            }
          }

          if (args.checked === true && index === null) {
            vm.callForProject.axes.push({
              id: args.value,
              name: args.label
            });
          } else if (args.checked === false && index !== null) {
            vm.callForProject.axes.splice(index, 1);
          }
        }
      }
    };

    vm.saveAndGoToSheet = function () {
      vm.goToSheet = true;
      vm.save();
    };

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function save() {
      vm.isSaving = true;

      if (vm.callForProject.id !== null) {
        CallForProject.update(vm.callForProject, onSaveSuccess, onSaveError);
      } else {
        CallForProject.save(vm.callForProject, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit('canceropoleGsoApp:callForProjectUpdate', result);
      $uibModalInstance.close({
        callForProject: result,
        goToSheet: vm.goToSheet
      });
      vm.isSaving = false;
    }

    function onSaveError() {
      vm.isSaving = false;
    }

    vm.setAvatar = function ($file, callForProject) {
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        DataUtils.toBase64($file, function (base64Data) {
          $scope.$apply(function () {
            callForProject.avatar = base64Data;
            callForProject.avatarContentType = $file.type;
          });
        });
      }
    };
  }
})();
