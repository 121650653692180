(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('geosite', {
                    parent: 'entity',
                    url: '/geosite',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.geosite.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/geosite/geosites.html',
                            controller: 'GeositeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('geosite');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('geosite-detail', {
                    parent: 'entity',
                    url: '/geosite/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.geosite.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/geosite/geosite-detail.html',
                            controller: 'GeositeDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('geosite');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Geosite', function ($stateParams, Geosite) {
                                return Geosite.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('geosite.new', {
                    parent: 'geosite',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/geosite/geosite-dialog.html',
                                controller: 'GeositeDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('geosite', null, {reload: true});
                            }, function () {
                                $state.go('geosite');
                            });
                        }]
                })
                .state('geosite.edit', {
                    parent: 'geosite',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/geosite/geosite-dialog.html',
                                controller: 'GeositeDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['Geosite', function (Geosite) {
                                            return Geosite.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('geosite', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('geosite.delete', {
                    parent: 'geosite',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/geosite/geosite-delete-dialog.html',
                                controller: 'GeositeDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['Geosite', function (Geosite) {
                                            return Geosite.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('geosite', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
