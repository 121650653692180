(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('MergePeopleController', MergePeopleController);

    MergePeopleController.$inject = ['MainEventSearch', '$log', '$http', '$stateParams', 'CallForProjectSearch', 'JQWidgetsService', 'PersonSearch', '$translate', '$timeout'];

    function MergePeopleController(MainEventSearch, $log, $http, $stateParams, CallForProjectSearch, JQWidgetsService, PersonSearch, $translate, $timeout) {
        var vm = this;

        var aapType = {
            label: $translate.instant('mergePeople.select.aap'),
            value: 'AAP'
        };

        var eveType = {
            label: $translate.instant('mergePeople.select.eve'),
            value: 'EVE'
        };

        if ($stateParams.eve) {
            vm.mergeType = eveType;
        } else {
            vm.mergeType = aapType;
        }

        vm.leftComboBox = null;
        vm.aapOrEve = null;
        vm.leftPerson = null;
        vm.leftPeople = [];

        vm.peopleComboBox = null;
        vm.person = null;
        vm.result = null;

        vm.typeDropDownListSettings = {
            width: 200,
            height: 30,
            autoDropDownHeight: true,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            source: [aapType, eveType],
            created: function (args) {
                vm.typeComboBox = args.instance;
                vm.typeComboBox.selectItem(vm.mergeType);
            },
            select: function (event) {
                vm.leftPeople = [];
            }
        };

        vm.leftDataAdapter = new $.jqx.dataAdapter({
            url: 'api/search',
            datatype: 'json',
            id: 'id',
            data: {
                size: '20'
            },
            datafields: [{
                name: 'id',
                type: 'number'
            }, {
                name: 'name',
                type: 'string'
            }]
        }, {
                loadServerData: function (serverdata, source, callback) {
                    var query = "name:" + vm.leftComboBoxSettings.searchString;
                    if (vm.mergeType === aapType) {
                        CallForProjectSearch.query({
                            query: query,
                            page: 0,
                            size: 20
                        }, function (result) {
                            callback({
                                records: result
                            });
                        });
                    } else {
                        MainEventSearch.query({
                            query: query,
                            page: 0,
                            size: 20
                        }, function (result) {
                            callback({
                                records: result
                            });
                        });
                    }
                }
            });

        vm.leftComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            width: 400,
            height: 30,
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            placeHolder: $translate.instant('mergePeople.searchPlaceholder'),
            source: vm.leftDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.leftComboBoxSettings.searchString = searchString;
                vm.leftDataAdapter.dataBind();
            },
            created: function (args) {
                vm.leftComboBox = args.instance;
                var leftName = $stateParams.aap;
                if (!leftName) {
                    leftName = $stateParams.eve;
                }
                if (leftName) {
                    vm.leftComboBox.val(leftName);
                    vm.leftComboBoxSettings.search(leftName);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var aapOrEve = item.originalItem;
                    vm.aapOrEve = aapOrEve;
                    vm.retrievePeople();
                }
            },
            change: function () {
                var selection = vm.leftComboBox.getSelectedItem();
                if (selection !== null && vm.aapOrEve !== null && vm.aapOrEve !== selection.originalItem) {
                    vm.aapOrEve = selection.originalItem;
                    vm.retrievePeople();
                }
            },
            bindingComplete: function () {
                if (vm.leftComboBox.items.length > 0) {
                    vm.leftComboBox.selectIndex(0);
                }
            }
        };

        vm.leftPeopleComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            autoComplete: true,
            searchMode: 'containsignorecase',
            created: function (args) {
                vm.leftPeopleComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                    vm.leftPerson = person;
                }
            },
            change: function () {
                var selection = vm.leftPeopleComboBox.getSelectedItem();
                if (selection !== null) {
                    vm.leftPerson = selection.originalItem;

                    var name = vm.leftPerson.lastName + ' ' + vm.leftPerson.firstName;
                    vm.peopleComboBox.val(name);
                    vm.peopleComboBoxSettings.search(name);
                }
            }
        };

        var peopleSource = {
            url: 'api/search',
            datatype: 'json',
            id: 'id',
            data: {
                size: '20'
            },
            datafields: [{
                name: 'id',
                type: 'number'
            }, {
                name: 'firstName',
                type: 'string'
            }, {
                name: 'lastName',
                type: 'string'
            }]
        };

        var peopleDataAdapter = new $.jqx.dataAdapter(peopleSource, {
            loadServerData: function (serverdata, source, callback) {
                var query = vm.peopleComboBoxSettings.searchString;
                PersonSearch.query({
                    query: query,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.lastName + ' ' + item.firstName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        vm.peopleComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: peopleDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.peopleComboBoxSettings.searchString = searchString;
                peopleDataAdapter.dataBind();
            },
            created: function (args) {
                vm.peopleComboBox = args.instance;
                if (vm.person) {
                    var name = vm.person.lastName + ' ' + vm.person.firstName;
                    vm.peopleComboBoxSettings.search(name);
                    vm.peopleComboBox.val(name);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                    vm.person = person;
                    vm.merge();
                }
            },
            unselect: function () {
                vm.person = null;
            },
            change: function () {
                var selection = vm.peopleComboBox.getSelectedItem();
                if (selection !== null) {
                    vm.person = selection.originalItem;
                }
            },
            bindingComplete: function () {
                if (peopleDataAdapter.records.length > 0) {
                    vm.peopleComboBox.selectIndex(0);
                } else {
                    vm.person = null;
                }
            }
        };

        vm.merge = function () {
            vm.result = angular.copy(vm.person);
            vm.mergeField(vm.result, 'lastName', vm.leftPerson.lastName);
            vm.mergeField(vm.result, 'firstName', vm.leftPerson.firstName);
            vm.mergeField(vm.result, 'email', vm.leftPerson.email);
            vm.mergeField(vm.result, 'phone', vm.leftPerson.phone);
            vm.mergeField(vm.result, 'portable', vm.leftPerson.portable);

            vm.tryMergeJob(vm.result, vm.leftPerson);
        };

        vm.mergeField = function (obj, field, value) {
            if ((!obj[field] && value) || (obj[field] !== value)) {
                obj[field] = value;
            }
        };

        vm.copyField = function (field, fromUser) {
            if (fromUser) {
                vm.result[field] = vm.leftPerson[field];
            } else {
                vm.result[field] = vm.person[field];
            }
        };

        vm.tryMergeJob = function (result, leftPerson) {
            vm.sameSociety = false;
            vm.sameMetier = false;
            vm.sameStatus = false;
            if (result.job) {
                vm.sameSociety = result.job.societyId === leftPerson.societyId;
                vm.sameMetier = result.job.metier === leftPerson.metier;
                vm.sameStatus = result.job.status === leftPerson.status;
            }
            if (!vm.sameSociety || !vm.sameMetier || !vm.sameStatus) {
                vm.result.jobToMerge = {
                    metier: leftPerson.metier,
                    personMetierId: leftPerson.metierId,
                    status: leftPerson.status,
                    personStatusId: leftPerson.statusId,
                    societyId: leftPerson.societyId,
                    societyName: leftPerson.societyName
                };
            }
        };

        vm.mergeJob = function (newJob) {
            if (newJob) {
                vm.result.job = vm.result.jobToMerge;
                vm.result.job.position = 0;
                vm.result.appendJob = true;
            }
            delete vm.result.jobToMerge;
        };

        vm.retrievePeople = function () {
            vm.leftPeople = [];

            var url = 'api/merge/';
            if (vm.mergeType === aapType) {
                url += 'aap/';
            } else {
                url += 'eve/';
            }
            url += vm.aapOrEve.id;
            url += '/people';


            $http.get(url).then(function (response) {
                angular.forEach(response.data, function (item) {
                    item.name = item.lastName + ' ' + item.firstName;
                });
                vm.leftPeople = response.data;
                vm.leftPeopleComboBoxSettings.source = new $.jqx.dataAdapter({
                    localdata: vm.leftPeople,
                    datatype: 'json',
                    datafields:[{
                        name:'id'
                    },{
                        name:'firstName'
                    },{
                        name:'lastName'
                    },{
                        name:'metier'
                    },{
                        name:'metierId'
                    },{
                        name:'name'
                    },{
                        name:'email'
                    },{
                        name:'personFirstName'
                    },{
                        name:'personId'
                    },{
                        name:'personLastName'
                    },{
                        name:'phone'
                    },{
                        name:'societyId'
                    },{
                        name:'societyName'
                    },{
                        name:'status'
                    },{
                        name:'statusId'
                    }]
                });
            });
        };

        vm.save = function () {
            $http.put('api/merge/person', vm.result).then(function () {
                $timeout(function () {
                    peopleDataAdapter.dataBind();
                }, 1000);
            });
        };
    }
})();
