(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SubmissionResponseDeleteController',SubmissionResponseDeleteController);

    SubmissionResponseDeleteController.$inject = ['$uibModalInstance', 'entity', 'SubmissionResponse'];

    function SubmissionResponseDeleteController($uibModalInstance, entity, SubmissionResponse) {
        var vm = this;

        vm.submissionResponse = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SubmissionResponse.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
