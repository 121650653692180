(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ValorizationEditDialogController', ValorizationEditDialogController);

    ValorizationEditDialogController.$inject = ['$uibModalInstance', 'valorization', '$log', 'JQWidgetsService', 'Valorization', '$translate', 'valorizationTypes'];

    function ValorizationEditDialogController($uibModalInstance, valorization, $log, JQWidgetsService, Valorization, $translate, valorizationTypes) {

        var ctrl = this;
        ctrl.valorization = valorization;
        ctrl.valorizationTypes = valorizationTypes;

        if (ctrl.valorization.id) {
            ctrl.title = "Editer une valorisation";
        } else {
            ctrl.title = "Ajouter une valorisation";
        }

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.valorizationTypes,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            }
        };

        ctrl.editorSettings = {
            width: '100%',
            height: 300,
            tools: "bold italic underline | font | color | html"
        };

        ctrl.onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        };

        ctrl.onSaveError = function () {
            ctrl.isSaving = false;
        };

        ctrl.ok = function () {
            ctrl.isSaving = true;
            if (ctrl.valorization.id !== null) {
                Valorization.update(ctrl.valorization, ctrl.onSaveSuccess, ctrl.onSaveError);
            } else {
                Valorization.save(ctrl.valorization, ctrl.onSaveSuccess, ctrl.onSaveError);
            }
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();