(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('MetierEditDialogController', MetierEditDialogController);

  MetierEditDialogController.$inject = [
    '$translate',
    '$translatePartialLoader',
    '$uibModalInstance',
    'metier',
    '$log',
    'CustomUtils',
    'PersonMetier',
    'AlertService',
    '$uibModal'
  ];

  function MetierEditDialogController(
    $translate,
    $translatePartialLoader,
    $uibModalInstance,
    metier,
    $log,
    CustomUtils,
    PersonMetier,
    AlertService,
    $uibModal
  ) {
    $translatePartialLoader.addPart('dialog');
    $translate.refresh();

    var ctrl = this;
    ctrl.personMetier = metier;

    ctrl.title = 'Ajouter un métier';
    if (!CustomUtils.isUndefinedOrNull(ctrl.personMetier)) {
      ctrl.title = 'Modifier le métier ' + ctrl.personMetier.name;
    }

    $log.info(metier);

    ctrl.statusListInitialized = false;
    ctrl.statusSettings = {
      width: '100%',
      //autoHeight: true,
      multiple: true,
      source: new $.jqx.dataAdapter({
        datatype: 'json',
        datafields: [
          {
            name: 'id'
          },
          {
            name: 'name'
          }
        ],
        id: 'id',
        url: 'api/person-statuses?size=1000'
      }),
      displayMember: 'name',
      valueMember: 'id',
      created: function (args) {
        ctrl.statusListBox = args.instance;
      },
      bindingComplete: function () {
        ctrl.selectStatus();
        ctrl.statusListInitialized = true;
      },
      change: function (args) {
        if (ctrl.statusListInitialized === true) {
          ctrl.editMetierForm.$setDirty();
        }
      }
    };

    ctrl.selectStatus = function () {
      if (!CustomUtils.isUndefinedOrNull(ctrl.personMetier)) {
        angular.forEach(ctrl.personMetier.personStatuses, function (status) {
          ctrl.statusListBox.selectItem(status.id);
        });
      }
    };

    ctrl.addStatus = function () {
      $uibModal
        .open({
          templateUrl: 'app/metier/dialog/status.edit.dialog.html',
          controller: 'StatusEditDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                return $translate.refresh();
              }
            ],
            status: function () {
              return null;
            }
          }
        })
        .result.then(function (status) {
          ctrl.statusListBox.addItem(status);
        });
    };

    ctrl.close = function (result) {
      $uibModalInstance.close(result);
    };

    ctrl.showErrors = function () {
      AlertService.error('Problème lors de la sauvegarde en base');
    };

    ctrl.ok = function () {
      var selectedItems = ctrl.statusListBox.getSelectedItems();
      $log.info(selectedItems);

      var statusList = [];
      angular.forEach(selectedItems, function (status) {
        statusList.push({
          id: status.value,
          name: status.label
        });
      });

      ctrl.personMetier.personStatuses = statusList;

      if (ctrl.personMetier.id) {
        PersonMetier.update(ctrl.personMetier, ctrl.close, ctrl.showErrors);
      } else {
        PersonMetier.save(ctrl.personMetier, ctrl.close, ctrl.showErrors);
      }
    };
    ctrl.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
