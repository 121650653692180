(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PersonMetierDialogController', PersonMetierDialogController);

    PersonMetierDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PersonMetier', 'PersonStatus'];

    function PersonMetierDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PersonMetier, PersonStatus) {
        var vm = this;

        vm.personMetier = entity;
        vm.clear = clear;
        vm.save = save;
        vm.personstatuses = PersonStatus.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.personMetier.id !== null) {
                PersonMetier.update(vm.personMetier, onSaveSuccess, onSaveError);
            } else {
                PersonMetier.save(vm.personMetier, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:personMetierUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
