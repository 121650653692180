(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('leaflet-maps', {
            parent: 'app',
            url: '/map',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'map.title'
            },
            params: {},
            views: {
                'content@': {
                    templateUrl: 'app/leaflet-maps/leaflet-maps.html',
                    controller: 'LeafletMapsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('map');
                        $translatePartialLoader.addPart('entities');
                        return $translate.refresh();
                    }],
                availableTypes: ['SearchService', function (SearchService) {
                        var typeQuery = {
                            from: 0,
                            size: 0,
                            query: {
                                query_string: {
                                    query: "*"
                                }
                            },
                            filter: {
                                exists: {
                                    field: "lat"
                                }
                            },
                            aggs: {
                                type: {
                                    terms: {
                                        field: "_type"
                                    }
                                }
                            }
                        };
                        return SearchService.makeSearch(typeQuery).$promise;
                    }]
            }
        });
    }
})();

