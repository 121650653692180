(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PersonAxeDetailController', PersonAxeDetailController);

    PersonAxeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PersonAxe', 'Axe', 'Person'];

    function PersonAxeDetailController($scope, $rootScope, $stateParams, entity, PersonAxe, Axe, Person) {
        var vm = this;

        vm.personAxe = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:personAxeUpdate', function(event, result) {
            vm.personAxe = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
