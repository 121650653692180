(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('AbstractSocietyDeleteController',AbstractSocietyDeleteController);

    AbstractSocietyDeleteController.$inject = ['$uibModalInstance', 'entity', 'AbstractSociety'];

    function AbstractSocietyDeleteController($uibModalInstance, entity, AbstractSociety) {
        var vm = this;

        vm.abstractSociety = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AbstractSociety.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
