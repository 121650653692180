(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('ExceptionnalEventAbstractSearch', ExceptionnalEventAbstractSearch);

    ExceptionnalEventAbstractSearch.$inject = ['$resource'];

    function ExceptionnalEventAbstractSearch($resource) {
        var resourceUrl =  'api/_search/exceptionnal-event-abstracts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
