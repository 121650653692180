(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SendExpertMailDialogController', SendExpertMailDialogController);

    SendExpertMailDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'JQWidgetsService', '$timeout', 'mailInfo', 'mails'];

    function SendExpertMailDialogController($translate, $translatePartialLoader, $uibModalInstance, JQWidgetsService, $timeout, mailInfo, mails) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.mailInfo = mailInfo;

        ctrl.expTemplates = [];
        angular.forEach(mails, function (templateAvailable) {
            if (templateAvailable.type.startsWith("G_EXP")) {
                ctrl.expTemplates.push({
                    label: templateAvailable.title,
                    value: templateAvailable.type
                });
            }
        });

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.expTemplates,
            change: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.expTemplates.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.selectedType);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();