(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('MainEventDetailController', MainEventDetailController);

    MainEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'MainEvent', 'EventManagement', 'EventDay', 'EventRegistration', 'Society', 'Axe'];

    function MainEventDetailController($scope, $rootScope, $stateParams, DataUtils, entity, MainEvent, EventManagement, EventDay, EventRegistration, Society, Axe) {
        var vm = this;

        vm.mainEvent = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:mainEventUpdate', function(event, result) {
            vm.mainEvent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
