(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('person-metier', {
                parent: 'entity',
                url: '/person-metier',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.personMetier.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person-metier/person-metiers.html',
                        controller: 'PersonMetierController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('personMetier');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('person-metier-detail', {
                parent: 'entity',
                url: '/person-metier/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.personMetier.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/person-metier/person-metier-detail.html',
                        controller: 'PersonMetierDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('personMetier');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PersonMetier', function ($stateParams, PersonMetier) {
                        return PersonMetier.get({ id: $stateParams.id }).$promise;
                    }]
                }
            })
            .state('person-metier.new', {
                parent: 'person-metier',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/person-metier/person-metier-dialog.html',
                        controller: 'PersonMetierDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    nameEn: null,
                                    groupName: null,
                                    groupName2: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('person-metier', null, { reload: true });
                    }, function () {
                        $state.go('person-metier');
                    });
                }]
            })
            .state('person-metier.edit', {
                parent: 'person-metier',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/person-metier/person-metier-dialog.html',
                        controller: 'PersonMetierDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PersonMetier', function (PersonMetier) {
                                return PersonMetier.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('person-metier', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('person-metier.delete', {
                parent: 'person-metier',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/person-metier/person-metier-delete-dialog.html',
                        controller: 'PersonMetierDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PersonMetier', function (PersonMetier) {
                                return PersonMetier.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('person-metier', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
