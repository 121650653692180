(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('event-abstract', {
                parent: 'entity',
                url: '/event-abstract',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.eventAbstract.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/event-abstract/event-abstracts.html',
                        controller: 'EventAbstractController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eventAbstract');
                        $translatePartialLoader.addPart('abstractType');
                        $translatePartialLoader.addPart('abstractFinalType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('event-abstract-detail', {
                parent: 'entity',
                url: '/event-abstract/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.eventAbstract.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/event-abstract/event-abstract-detail.html',
                        controller: 'EventAbstractDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eventAbstract');
                        $translatePartialLoader.addPart('abstractType');
                        $translatePartialLoader.addPart('abstractFinalType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'EventAbstract', function ($stateParams, EventAbstract) {
                        return EventAbstract.get({ id: $stateParams.id }).$promise;
                    }]
                }
            })
            .state('event-abstract.new', {
                parent: 'event-abstract',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-abstract/event-abstract-dialog.html',
                        controller: 'EventAbstractDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    theme: null,
                                    registrationKey: null,
                                    abstractText: null,
                                    poster: null,
                                    posterContentType: null,
                                    reference: null,
                                    comments: null,
                                    assignCandidate: null,
                                    type: null,
                                    finalType: null,
                                    accepted: null,
                                    creationMailDate: null,
                                    validationMailDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('event-abstract', null, { reload: true });
                    }, function () {
                        $state.go('event-abstract');
                    });
                }]
            })
            .state('event-abstract.edit', {
                parent: 'event-abstract',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-abstract/event-abstract-dialog.html',
                        controller: 'EventAbstractDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['EventAbstract', function (EventAbstract) {
                                return EventAbstract.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('event-abstract', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('event-abstract.delete', {
                parent: 'event-abstract',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-abstract/event-abstract-delete-dialog.html',
                        controller: 'EventAbstractDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['EventAbstract', function (EventAbstract) {
                                return EventAbstract.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('event-abstract', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
