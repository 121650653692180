(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('mailField', {
                controller: 'MailFieldController',
                bindings: {
                    panelTitle: '@',
                    editable: '@',
                    id: '<',
                    mailTitle: '<',
                    content: '<',
                    templateType: '@?',
                    onSave: '=',
                    onRemove: '=?',
                    onCancel: '=?'
                },
                templateUrl: 'app/components/mail/mail-field.html'
            });
})();
