(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('CallForProjectFormSearch', CallForProjectFormSearch);

    CallForProjectFormSearch.$inject = ['$resource'];

    function CallForProjectFormSearch($resource) {
        var resourceUrl =  'api/_search/call-for-project-forms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
