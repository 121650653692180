(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('abstractDisplay', {
                controller: 'AbstractDisplay',
                bindings: {
                    aid: '<'
                },
                templateUrl: 'app/components/abstracts/abstract-display.html'
            });
})();
