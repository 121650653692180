(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'CustomUtils', 'AlertService', '$log'];

    function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, JhiLanguageService, CustomUtils, AlertService, $log) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_EXPERT', 'ROLE_RESEARCHER'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;

        vm.associateToPerson = true;

        //edition (not creation)
        if (vm.user.$promise) {
            vm.user.$promise.then(function (result) {
                if (result.personId) {
                    vm.associateToPerson = true;
                } else if (result.firstName) {
                    vm.associateToPerson = false;
                }
            });
        }

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'firstName'
                }, {
                    name: 'lastName'
                }, {
                    name: 'id'
                }, {
                    name: 'email'
                }],
            id: 'id',
            url: 'api/_search/people',
            data: {
                size: '20'
            }
        };

        vm.personComboBoxSettings = {
            displayMember: 'lastName',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: new $.jqx.dataAdapter(personSource, {
                autoBind: false,
                beforeLoadComplete: function (records) {
                    for (var i = 0; i < records.length; i++) {
                        var record = records[i];
                        var firstName = record.firstName;
                        var lastName = record.lastName;
                        var name = CustomUtils.concatWith(firstName, lastName, " ");
                        record.name = name;
                    }
                    return records;
                }
            }),
            renderer: function (index) {
                var item = vm.personComboBoxSettings.source.records[index];
                return item.firstName + ' ' + item.lastName;
            },
            renderSelectedItem: function (index) {
                var label = '';
                var item = vm.personComboBoxSettings.source.records[index];
                if (!CustomUtils.isUndefinedOrNull(item)) {
                    label = item.firstName + ' ' + item.lastName;
                }
                return label;
            },
            search: function (searchString) {
                var toSearch = "firstName:" + searchString + " or lastName:" + searchString;
                toSearch = toSearch.replace('/','//');
                vm.personComboBoxSettings.source._source.data.query = toSearch;
                vm.personComboBoxSettings.source.dataBind();
            },
            created: function (args) {
                vm.personComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var personId = item.value;
                    var personItem = item.originalItem;
                    vm.user.firstName = personItem.firstName;
                    vm.user.lastName = personItem.lastName;
                    vm.user.personId = personId;

                    if (CustomUtils.isUndefinedOrNull(vm.user.email)) {
                        if (!CustomUtils.isUndefinedOrNull(personItem.email)) {
                            vm.user.email = personItem.email;
                        }
                    } else if (!angular.equals(vm.user.email, personItem.email)) {
                        AlertService.warning("userManagement.person.notSameEmail", {
                            annuaire: personItem.email,
                            form: vm.user.email
                        });
                    }
                }
            }
        };

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;

            if (!vm.associateToPerson) {
                vm.user.personId = null;
            }

            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
