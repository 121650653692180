(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('DryRemoveEntityDialogController', DryRemoveEntityDialogController);

    DryRemoveEntityDialogController.$inject = ['$uibModalInstance', 'entity', 'dryDeleteResult', 'dryInfos', 'onConfirmDeletion', 'deletionMsgKey', '$state'];

    function DryRemoveEntityDialogController($uibModalInstance, entity, dryDeleteResult, dryInfos, onConfirmDeletion, deletionMsgKey, $state) {

        var ctrl = this;
        ctrl.entity = entity;
        ctrl.dryDeleteResult = [];
        ctrl.deletionMsgKey = deletionMsgKey;

        ctrl.appendDryCount = function (key, message) {
            var count = dryDeleteResult[key];
            if (count > 0) {
                var msg = 'il y a ' + count + ' ' + message;
                ctrl.dryDeleteResult.push(msg);
            }
        };

        angular.forEach(dryInfos, function (dryInfo) {
            ctrl.appendDryCount(dryInfo.key, dryInfo.msg);
        });

        ctrl.ok = function () {
            onConfirmDeletion().then(function () {
                $uibModalInstance.close();
                $state.go('home');
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

})();
