(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddGroupeDialogController', AddGroupeDialogController);

    AddGroupeDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'groupe', '$log', 'CustomUtils', '$http'];

    function AddGroupeDialogController($translate, $translatePartialLoader, $uibModalInstance, groupe, $log, CustomUtils, $http) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.groupe = groupe;
        ctrl.title = "Ajouter un groupe";
        if (CustomUtils.isDefined(ctrl.groupe.id)) {
            ctrl.title = "Editer un groupe";
        }


        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.groupe);
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
