(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('fieldTable', {
                controller: 'FieldTableController',
                bindings: {
                    id: '@',
                    name: '@',
                    readOnly: '<',
                    model: '=',
                    required: '@',
                    config: '='
                },
                templateUrl: 'app/components/form/callforproject/field-table.html'
            });
})();
