(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('SendMainEventMailDialogController', SendMainEventMailDialogController);

    SendMainEventMailDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'existingsMails', 'JQWidgetsService', '$timeout', 'people', 'CustomUtils'];

    function SendMainEventMailDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, existingsMails, JQWidgetsService, $timeout, people, CustomUtils) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.people = people;
        ctrl.existingsMails = existingsMails;
        angular.forEach(ctrl.existingsMails, function (mail) {
            mail.translatedTitle = $translate.instant('canceropoleGsoApp.MainEventMailType.' + mail.type);
        });

        ctrl.selectedType = {};

        ctrl.peopleWithMail = [];
        ctrl.peopleWithoutMail = [];
        angular.forEach(ctrl.people, function (person) {
            if (CustomUtils.isEmpty(person.email)) {
                ctrl.peopleWithoutMail.push(person);
            } else {
                ctrl.peopleWithMail.push(person);
            }
        });

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: 'translatedTitle',
            source: ctrl.existingsMails,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.existingsMails.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.ok = function () {
            $uibModalInstance.close({
                registrations: ctrl.peopleWithMail,
                type: ctrl.selectedType
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
