(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('MainEventMailDialogController', MainEventMailDialogController);

    MainEventMailDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'MainEventMail', 'MainEvent'];

    function MainEventMailDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, MainEventMail, MainEvent) {
        var vm = this;

        vm.mainEventMail = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.mainevents = MainEvent.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mainEventMail.id !== null) {
                MainEventMail.update(vm.mainEventMail, onSaveSuccess, onSaveError);
            } else {
                MainEventMail.save(vm.mainEventMail, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:mainEventMailUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
