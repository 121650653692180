(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('main-event', {
                    parent: 'entity',
                    url: '/main-event',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.mainEvent.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/main-event/main-events.html',
                            controller: 'MainEventController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('mainEvent');
                                $translatePartialLoader.addPart('mainEventLanguage');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('main-event-detail', {
                    parent: 'entity',
                    url: '/main-event/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.mainEvent.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/main-event/main-event-detail.html',
                            controller: 'MainEventDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('mainEvent');
                                $translatePartialLoader.addPart('mainEventLanguage');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'MainEvent', function ($stateParams, MainEvent) {
                                return MainEvent.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('main-event.new', {
                    parent: 'main-event',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/main-event/main-event-dialog.html',
                                controller: 'MainEventDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            type: null,
                                            description: null,
                                            location: null,
                                            lng: null,
                                            lat: null,
                                            callingCode: null,
                                            phone: null,
                                            email: null,
                                            webSite: null,
                                            startDate: null,
                                            endDate: null,
                                            inscriptionLimitDate: null,
                                            inscriptionLimitDateEarlybird: null,
                                            posterLimitDate: null,
                                            oralCommunicationLimitDate: null,
                                            expectedParticipation: null,
                                            estimatedBudget: null,
                                            expectedCost: null,
                                            expectedIncome: null,
                                            result: null,
                                            expectedProgram: null,
                                            globalProgram: null,
                                            detailProgram: null,
                                            registrationMail: null,
                                            confirmRegistrationMail: null,
                                            publique: false,
                                            locationInformation: null,
                                            avatar: null,
                                            avatarContentType: null,
                                            billAddress: null,
                                            prefix: null,
                                            rejectAbstractMail: null,
                                            rejectAbstractMail2: null,
                                            maxAbstractChar: null,
                                            freeRegistration: false,
                                            hasPoster: null,
                                            hasOral: null,
                                            city: null,
                                            lang: null,
                                            billManagement: false,
                                            askAddress: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('main-event', null, {reload: true});
                            }, function () {
                                $state.go('main-event');
                            });
                        }]
                })
                .state('main-event.edit', {
                    parent: 'main-event',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/main-event/main-event-dialog.html',
                                controller: 'MainEventDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['MainEvent', function (MainEvent) {
                                            return MainEvent.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('main-event', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('main-event.delete', {
                    parent: 'main-event',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/main-event/main-event-delete-dialog.html',
                                controller: 'MainEventDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['MainEvent', function (MainEvent) {
                                            return MainEvent.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('main-event', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
