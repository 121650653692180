(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('DatabaseService', DatabaseService);

    DatabaseService.$inject = ['$http'];

    function DatabaseService($http) {
        return {
            get: get,
            makeDump: makeDump,
            removeDump: removeDump
        };

        function get() {
            return $http.get('api/database/dump').then(function (response) {
                return response.data;
            });
        }

        function makeDump() {
            return $http.post('api/database/dump').then(function (response) {
                return response;
            });
        }

        function removeDump(name) {
            return $http.delete('api/database/dump?fileName=' + name).then(function (response) {
                return response;
            });
        }
    }
})();
