(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ManagementDialogController', ManagementDialogController);

    ManagementDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'PersonSearch', 'ModelConfig'];

    function ManagementDialogController($translate, $translatePartialLoader, $uibModalInstance, PersonSearch, ModelConfig) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.personId = null;
        ctrl.type = null;

        ctrl.ok = function () {
            $uibModalInstance.close({
                personId: ctrl.personId,
                type: ctrl.type
            });
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                    ctrl.personId = person.id;
                }
            },
            unselect: function () {
                ctrl.personId = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.personId = null;
                }
            }
        };

        ctrl.managementTypeDataAdapter = new $.jqx.dataAdapter({
            dataType: 'array',
            localdata: ModelConfig.mainEvent.management.types,
            datafields: [{
                    name: 'label',
                    type: 'string'
                }, {
                    name: 'value',
                    type: 'string'
                }]
        });

        ctrl.managementTypeSettings = {
            source: ctrl.managementTypeDataAdapter,
            width: '100%',
            height: 30,
            displayMember: 'label',
            valueMember: 'value',
            autoDropDownHeight: true,
            //selectedIndex: ModelConfig.mainEvent.management.getIndex(value),
            renderer: function (index, label, value) {
                return $translate.instant(label);
            },
            selectionRenderer: function (element, index, label, value) {
                return '<span style="line-height:30px">' + $translate.instant(label) + '</span>';
            }
        };
    }
})();