(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('timeline', {
                controller: 'TimelineController',
                bindings: {
                    events: '='
                },
                templateUrl: 'app/components/timeline/timeline.html'
            });
})();
