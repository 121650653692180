(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('multipleRemoteSearch', {
                controller: 'MultipleRemoteSearchController',
                bindings: {
                    label: '@',
                    placeHolder: '@',
                    selection: '=',
                    adapter: '=',
                    form: '=',
                    required: '<'
                },
                templateUrl: 'app/components/jqwidgets/multiple-remote-search.html'
            });
})();
