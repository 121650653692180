(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ParticipationEditDialogController', ParticipationEditDialogController);

    ParticipationEditDialogController.$inject = ['$uibModalInstance', 'participation', '$log', 'CustomUtils', '$timeout', 'searchPerson', 'searchProject', 'PersonSearch', '$state'];

    function ParticipationEditDialogController($uibModalInstance, participation, $log, CustomUtils, $timeout, searchPerson, searchProject, PersonSearch, $state) {

        var ctrl = this;
        ctrl.participation = participation;
        ctrl.searchPerson = searchPerson;
        ctrl.searchProject = searchProject;

        if (CustomUtils.isUndefinedOrNull(ctrl.participation)) {
            ctrl.title = "Lier une personne à un projet";
            ctrl.participation = {
                startDate: null,
                endDate: null
            };

        } else {
            ctrl.title = "Editer un lien entre une personne et un projet";
            ctrl.personName = ctrl.participation.name;
        }

        ctrl.showDates = false;
        if (CustomUtils.isDefined(ctrl.participation.startDate) || CustomUtils.isDefined(ctrl.participation.endDate)) {
            ctrl.showDates = true;
        }

        ctrl.startDateSettings = {
            width: "100%",
            height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.endDateSettings = {
            width: "100%",
            height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.checkDates = function () {
            if (ctrl.showDates === true) {
                $timeout(function () {
                    var startDate = ctrl.participation.startDate;
                    var endDate = ctrl.participation.endDate;
                    if (CustomUtils.isDefined(startDate) && CustomUtils.isDefined(endDate)) {
                        $log.debug("startDate=" + startDate);
                        $log.debug("endDate=" + endDate);
                        $log.debug("startDate < endDate ==>", startDate < endDate);
                        ctrl.editForm.$setValidity('previousDate', startDate < endDate);
                    } else {
                        ctrl.editForm.$setValidity('previousDate', true);
                    }
                }, 100);
            }
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'firstName'
                }, {
                    name: 'lastName'
                }, {
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/person',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                if (CustomUtils.isDefined(ctrl.personName)) {
                    $timeout(function () {
                        ctrl.personComboBox.val(ctrl.personName);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var item = event.args.item;
                    if (CustomUtils.isDefined(item)) {
                        ctrl.participation.personId = item.originalItem.id;
                    }
                }
            }
        };

        ctrl.addSociety = function () {
            $state.go("addSociety");
        };

        ctrl.ok = function () {
            if (angular.isObject(ctrl.participation.personStatusId)) {
                ctrl.participation.personStatusId = ctrl.participation.personStatusId.id;
            }
            if (angular.isObject(ctrl.participation.personMetierId)) {
                ctrl.participation.personMetierId = ctrl.participation.personMetierId.id;
            }

            $uibModalInstance.close(ctrl.participation);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();