(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('TeamMemberEditDialogController', TeamMemberEditDialogController);

    TeamMemberEditDialogController.$inject = ['$uibModalInstance', 'teamMember', '$log', 'TeamSearch', 'JQWidgetsService', '$translate', '$timeout', 'searchPerson', 'searchTeam', 'PersonSearch', '$state'];

    function TeamMemberEditDialogController($uibModalInstance, teamMember, $log, TeamSearch, JQWidgetsService, $translate, $timeout, searchPerson, searchTeam, PersonSearch, $state) {

        var ctrl = this;
        ctrl.teamMember = teamMember;
        ctrl.searchPerson = searchPerson;
        ctrl.searchTeam = searchTeam;

        if (_.isNil(ctrl.teamMember)) {
            ctrl.title = 'Ajouter un lien entre une personne et une équipe';
            ctrl.teamMember = {
                startDate: null,
                endDate: null,
                position: 0
            };

        } else {
            ctrl.title = 'Editer un lien entre une personne et une équipe';
            ctrl.societyName = ctrl.teamMember.societyName;
            ctrl.personName = ctrl.teamMember.personName;
        }

        ctrl.showDates = false;
        if (!_.isNil(ctrl.teamMember.startDate) || !_.isNil(ctrl.teamMember.endDate)) {
            ctrl.showDates = true;
        }

        var sortFunction = function (a, b) {
            a = a.name;
            b = b.name;
            if (a.toLowerCase() === 'autre') {
                return 1;
            } else if (b.toLowerCase() === 'autre') {
                return -1;
            }
            return a.toLowerCase().localeCompare(b.toLowerCase());
        };

        var metierSource = {
            datatype: 'json',
            datafields: [{
                    name: 'id'
                }, {
                    name: 'name'
                }, {
                    name: 'personStatuses',
                    type: 'array'
                }],
            id: 'id',
            sortcolumn: 'name',
            sortDirection: 'asc',
            url: "api/person-metiers"
        };

        var metierAdapter = new $.jqx.dataAdapter(metierSource, {
            beforeLoadComplete: function (records) {
                records.sort(sortFunction);
                return records;
            }
        });

        ctrl.statusList = null;
        ctrl.metierSettings = {
            source: metierAdapter,
            placeHolder: JQWidgetsService.getLocalization($translate.use()).filterchoosestring,
            width: "100%",
            height: 30,
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            created: function (args) {
                ctrl.metierDropDownList = args.instance;

            },
            bindingComplete: function () {
                if (!_.isNil(ctrl.teamMember.personMetierId)) {
                    $timeout(function () {
                        ctrl.metierDropDownList.selectItem(ctrl.teamMember.personMetierId);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var metier = event.args.item;
                    ctrl.statusList = metier.originalItem.personStatuses;
                    ctrl.statusList.sort(function (s1, s2) {
                        var n1 = s1.name.toLowerCase();
                        var n2 = s2.name.toLowerCase();
                        return n1.localeCompare(n2);
                    });
                    ctrl.statusSettings.source = new $.jqx.dataAdapter(ctrl.statusList);
                    ctrl.teamMember.personMetierId = metier.originalItem.id;
                }
            },
            unselect: function () {
                ctrl.teamMember.personMetierId = null;
            },
            change: function () {
                var selection = ctrl.metierDropDownList.getSelectedItem();
                if (selection === null) {
                    ctrl.teamMember.personMetierId = null;
                }
            }
        };

        ctrl.statusSettings = {
            placeHolder: JQWidgetsService.getLocalization($translate.use()).filterchoosestring,
            width: "100%",
            height: 30,
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            created: function (args) {
                ctrl.statusDropDownList = args.instance;
            },
            bindingComplete: function () {
                if (!_.isNil(ctrl.teamMember.personStatusId)) {
                    $timeout(function () {
                        ctrl.statusDropDownList.selectItem(ctrl.teamMember.personStatusId);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var status = event.args.item;
                    ctrl.teamMember.personStatusId = status.originalItem.id;
                }
            },
            unselect: function () {
                ctrl.teamMember.personStatusId = null;
            },
            change: function () {
                var selection = ctrl.statusDropDownList.getSelectedItem();
                if (selection === null) {
                    ctrl.teamMember.personStatusId = null;
                }
            }
        };

        ctrl.startDateSettings = {
            width: "100%", height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.endDateSettings = {
            width: "100%", height: 30,
            showTimeButton: false,
            formatString: "dd/MM/yyyy",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.checkDates = function () {
            if (ctrl.showDates === true) {
                $timeout(function () {
                    var startDate = ctrl.teamMember.startDate;
                    var endDate = ctrl.teamMember.endDate;
                    if (!_.isNil(startDate) && !_.isNil(endDate)) {
                        $log.debug("startDate=" + startDate);
                        $log.debug("endDate=" + endDate);
                        $log.debug("startDate < endDate ==>", startDate < endDate);
                        ctrl.editForm.$setValidity('previousDate', startDate < endDate);
                    } else {
                        ctrl.editForm.$setValidity('previousDate', true);
                    }
                }, 100);
            }
        };

        var teamSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/people',
            data: {
                size: '20'}
        };

        var teamDataAdapter = new $.jqx.dataAdapter(teamSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                TeamSearch.query({
                    query: ctrl.teamComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.teamComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            height: 30,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: teamDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.teamComboBoxSettings.searchString = searchString;
                teamDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.teamComboBox = args.instance;
                if (!_.isNil(ctrl.teamName)) {
                    $timeout(function () {
                        ctrl.teamComboBox.val(ctrl.teamName);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var item = event.args.item;
                    ctrl.teamMember.societyId = item.originalItem.id;
                }
            }
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'firstName'
                }, {
                    name: 'lastName'
                }, {
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/person',
            data: {
                size: '20'}
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                if (!_.isNil(ctrl.personName)) {
                    $timeout(function () {
                        ctrl.personComboBox.val(ctrl.personName);
                    }, 100);
                }
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var item = event.args.item;
                    ctrl.teamMember.personId = item.originalItem.id;
                }
            }
        };

        ctrl.addTeam = function () {
            $state.go("addTeam");
        };

        ctrl.ok = function () {
            if (angular.isObject(ctrl.teamMember.personStatusId)) {
                ctrl.teamMember.personStatusId = ctrl.teamMember.personStatusId.id;
            }
            if (angular.isObject(ctrl.teamMember.personMetierId)) {
                ctrl.teamMember.personMetierId = ctrl.teamMember.personMetierId.id;
            }

            $uibModalInstance.close(ctrl.teamMember);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();