(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('AddPersonDialogController', AddPersonDialogController);

    AddPersonDialogController.$inject = ['$scope', '$uibModalInstance', 'DataUtils', 'Person', '$filter', 'name', '$log', '$translate', 'JQWidgetsService'];

    function AddPersonDialogController($scope, $uibModalInstance, DataUtils, Person, $filter, name, $log, $translate, JQWidgetsService) {
        var vm = this;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.goToSheet = false;

        $log.info(name);

        vm.person = {
            firstName: name.firstName,
            lastName: name.lastName,
            subType: 'normal',
            publique: true
        };

        vm.civilites = [{
            name: $translate.instant('canceropoleGsoApp.Civilite.mr'),
            value: 'mr'
        }, {
            name: $translate.instant('canceropoleGsoApp.Civilite.mme'),
            value: 'mme'
        }];

        vm.civiliteSettings = {
            displayMember: 'name',
            valueMember: 'value',
            height: 34,
            width: '100%',
            autoDropDownHeight: true,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            source: vm.civilites
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            vm.person.firstName = $filter('capitalize')(vm.person.firstName);
            vm.person.lastName = $filter('uppercase')(vm.person.lastName);

            if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('canceropoleGsoApp:personUpdate', result);
            $uibModalInstance.close({
                person: result,
                goToSheet: vm.goToSheet
            });
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.setAvatar = function ($file, person) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        person.avatar = base64Data;
                        person.avatarContentType = $file.type;
                    });
                });
            }
        };
    }
})();
