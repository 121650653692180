(function () {
  'use strict';

  angular.module('canceropoleGsoApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('abstract-society', {
        parent: 'entity',
        url: '/abstract-society',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.abstractSociety.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/abstract-society/abstract-societies.html',
            controller: 'AbstractSocietyController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('abstractSociety');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }
          ]
        }
      })
      .state('abstract-society-detail', {
        parent: 'entity',
        url: '/abstract-society/{id}',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.abstractSociety.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/abstract-society/abstract-society-detail.html',
            controller: 'AbstractSocietyDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('abstractSociety');
              return $translate.refresh();
            }
          ],
          entity: [
            '$stateParams',
            'AbstractSociety',
            function ($stateParams, AbstractSociety) {
              return AbstractSociety.get({ id: $stateParams.id }).$promise;
            }
          ]
        }
      })
      .state('abstract-society.new', {
        parent: 'abstract-society',
        url: '/new',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-society/abstract-society-dialog.html',
                controller: 'AbstractSocietyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: function () {
                    return {
                      name: null,
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-society', null, { reload: true });
                },
                function () {
                  $state.go('abstract-society');
                }
              );
          }
        ]
      })
      .state('abstract-society.edit', {
        parent: 'abstract-society',
        url: '/{id}/edit',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-society/abstract-society-dialog.html',
                controller: 'AbstractSocietyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: [
                    'AbstractSociety',
                    function (AbstractSociety) {
                      return AbstractSociety.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-society', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      })
      .state('abstract-society.delete', {
        parent: 'abstract-society',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-society/abstract-society-delete-dialog.html',
                controller: 'AbstractSocietyDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                  entity: [
                    'AbstractSociety',
                    function (AbstractSociety) {
                      return AbstractSociety.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-society', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      });
  }
})();
