(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventRegistrationDialogController', EventRegistrationDialogController);

    EventRegistrationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventRegistration', 'Person', 'Society', 'EventSession', 'MainEvent', 'PersonMetier', 'PersonStatus', 'EventOption'];

    function EventRegistrationDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, EventRegistration, Person, Society, EventSession, MainEvent, PersonMetier, PersonStatus, EventOption) {
        var vm = this;

        vm.eventRegistration = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.people = Person.query();
        vm.societies = Society.query();
        vm.eventsessions = EventSession.query();
        vm.mainevents = MainEvent.query();
        vm.personmetiers = PersonMetier.query();
        vm.personstatuses = PersonStatus.query();
        vm.eventoptions = EventOption.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.eventRegistration.id !== null) {
                EventRegistration.update(vm.eventRegistration, onSaveSuccess, onSaveError);
            } else {
                EventRegistration.save(vm.eventRegistration, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('canceropoleGsoApp:eventRegistrationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.registrationDate = false;
        vm.datePickerOpenStatus.deletedAt = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
