(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetSocietyFollowedTabController', SheetSocietyFollowedTabController);

    SheetSocietyFollowedTabController.$inject = ['$stateParams', '$state', 'societyEntity'];

    function SheetSocietyFollowedTabController($stateParams, $state, society) {
        if ($stateParams.tab !== "followed") {
            //update state param
            $stateParams.tab = "followed";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }
        var vm = this;
        vm.society = society;

    }
})();
