(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('yesNo', yesNo);

    function yesNo() {
        return yesNoFilter;

        function yesNoFilter(input) {
            return input ? 'Yes' : 'No';
        }
    }
})();
