(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetSocietyController', SheetSocietyController);

    SheetSocietyController.$inject = ['Principal', 'Society', '$stateParams', '$translate', '$state', 'SheetHistoryService', 'AlertService', 'geosites', 'societyTypes', 'societyEntity', '$log', 'ConfirmDialog'];

    function SheetSocietyController(Principal, Society, $stateParams, $translate, $state, SheetHistoryService, AlertService, geosites, societyTypes, societyEntity, $log, ConfirmDialog) {
        var vm = this;

        vm.society = societyEntity;

        vm.edition = {
            title: false
        };

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.save = function () {
            Society.update(vm.society, vm.load, vm.load);
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.tabs = [{
                title: $translate.instant("sheet.society.main.tab"),
                route: "sheet.society.main"
            }, {
                title: $translate.instant("sheet.society.presentation.tab"),
                route: "sheet.society.presentation"
            }, {
                title: $translate.instant("sheet.society.followed.tab"),
                route: "sheet.society.followed"
            }, {
                title: $translate.instant("sheet.society.teams.tab"),
                route: "sheet.society.team"
            }, {
                title: $translate.instant("sheet.society.employees.tab"),
                route: "sheet.society.employee"
            }
        ];

        if (vm.society.level === 'ENTITY') {
            vm.tabs.push({
                title: $translate.instant("sheet.society.structure.tab"),
                route: "sheet.society.structure"
            });
        }

        var activeTab = $stateParams.tab;

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.route.endsWith(activeTab)) {
                vm.activeTabIndex = index;
            }
        });

        var sheetHistory = {
            name: vm.society.name,
            type: 'society',
            icon: vm.society.level,
            id: vm.society.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.save = function () {
            Society.update(vm.society, vm.load, vm.load);
        };

        vm.tryRemoveSociety = function () {
            ConfirmDialog.openConfirmRemove(vm.society.name, function () {
                Society.delete({id: vm.society.id}, function (response) {
                    AlertService.success("canceropoleGsoApp.society.deleted");
                    $state.go("home");
                }, function (error) {
                    AlertService.error("canceropoleGsoApp.society.errors.delete");
                    $log.error(error.statusText);
                });
            });
        };

        $state.go(vm.tabs[vm.activeTabIndex].route);
    }
})();
