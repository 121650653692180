(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ExportAbstractsDialogController', ExportAbstractsDialogController);

    ExportAbstractsDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'abstracts', '$http', 'DataUtils', 'AlertService', 'DateUtils', 'ModelConfig', '$filter'];

    function ExportAbstractsDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, abstracts, $http, DataUtils, AlertService, DateUtils, ModelConfig, $filter) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.columns = [{
                text: "Id",
                datafield: "id",
                visible: true
            }, {
                text: "Ref",
                datafield: "ref",
                visible: true
            }, {
                text: "Axe",
                datafield: "axeName",
                visible: true
            }, {
                text: "Session",
                datafield: "sessionName",
                visible: true
            }, {
                text: "Type",
                datafield: "type",
                visible: true
            }, {
                text: "Décision",
                datafield: "accepted",
                visible: true
            }, {
                text: "Type final",
                datafield: "finalType",
                visible: true
            }, {
                text: "Nom",
                datafield: "name",
                visible: true
            }, {
                text: "Métier",
                datafield: "speakerMetier",
                visible: true
            }, {
                text: "Statut",
                datafield: "speakerStatus",
                visible: true
            }, {
                text: "Email",
                datafield: "speakerEmail",
                visible: true
            }, {
                text: "Ville",
                datafield: "speakerCity",
                visible: true
            }, {
                text: "Affiliation de l'orateur",
                datafield: "speakerSociety",
                visible: true
            }, {
                text: "Titre",
                datafield: "title",
                visible: true
            }, {
                text: "Affiliations des auteurs",
                datafield: "societies",
                visible: true
            }, {
                text: "Nom de tous les auteurs",
                datafield: "peoples",
                visible: true
            }, {
                text: "Mots clés",
                datafield: "theme",
                visible: true
            }, {
                text: "Affectation potentielle",
                datafield: "assignCandidate",
                visible: true
            }, {
                text: "Commentaire",
                datafield: "comments",
                visible: true
            }, {
                text: "Abstract",
                datafield: "abstractContent",
                visible: true
            }];

        ctrl.ok = function () {
            var selectedColumns = {};
            angular.forEach(ctrl.columns, function (column) {
                if (column.visible === true) {
                    selectedColumns[column.text] = column.datafield;
                }
            });

            ctrl.result = {
                columns: selectedColumns,
                data: abstracts
            };

            $log.info(ctrl.result);

            DataUtils.downloadFileFromPostUrl("api/event-abstracts/xls", ctrl.result, "Abstracts.xls");

            /*$http({
             url: "api/event-registrations/export",
             method: "POST",
             data: ctrl.result
             }).then(function (response) {
             if (response.data) {
             var data = response.data;
             if (data.errors) {
             AlertService.error(data.errors);
             } else {
             DataUtils.downloadFile(response.data.content, "Participations.xls");
             }
             }
             });*/

            $uibModalInstance.close(ctrl.result);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();