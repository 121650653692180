(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectDetailController', CallForProjectDetailController);

    CallForProjectDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'CallForProject', 'Society', 'Axe', 'FileAttachement', 'CallForProjectType'];

    function CallForProjectDetailController($scope, $rootScope, $stateParams, DataUtils, entity, CallForProject, Society, Axe, FileAttachement, CallForProjectType) {
        var vm = this;

        vm.callForProject = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectUpdate', function(event, result) {
            vm.callForProject = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
