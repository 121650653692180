(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('fieldEditorController', fieldEditorController);

    fieldEditorController.$inject = ['CustomUtils', 'JQWidgetsService', '$scope', '$log', 'Principal'];

    function fieldEditorController(CustomUtils, JQWidgetsService, $scope, $log, Principal) {
        var ctrl = this;

        $scope.$watch('$ctrl.model', function (oldValue, newValue) {
            if (oldValue !== newValue) {
                ctrl.preventMaxLength();
                if (ctrl.required == "false" && CustomUtils.isEmpty(ctrl.model)) {
                    ctrl.model = "<div></div>";
                }
            }
        });

        if (!CustomUtils.isEmpty(ctrl.placeHolder) &&
                CustomUtils.isEmpty(ctrl.model)) {
            ctrl.model = ctrl.placeHolder;
        } else if (ctrl.required == "false" && CustomUtils.isEmpty(ctrl.model)) {
            ctrl.model = "<div></div>";
        }

        if (CustomUtils.isEmpty(ctrl.nbLine)) {
            ctrl.nbLine = 8;
        }

        ctrl.isAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        ctrl.preventMaxLength = function () {
            if (!CustomUtils.isEmpty(ctrl.maxlength) &&
                    !CustomUtils.isUndefinedOrNull(ctrl.model)) {
                var nbChar = CustomUtils.countCharInHtmlString(ctrl.model);
                var left = ctrl.maxlength - nbChar;
                if (left <= 0) {
                    var lengthToKeep = ctrl.model.length - Math.abs(left);
                    ctrl.model = ctrl.model.substring(0, lengthToKeep);
                }
            }
        };

        var tools = 'bold italic underline | left center right justify | characters | exposant indice | ul ol | clean';
        if (ctrl.isAdmin()) {
            tools += ' | html';
        }

        ctrl.editorSettings = {
            width: '100%',
            height: 50 + 14 * ctrl.nbLine, //hauteur barre outils et bordeure + hauteur ligne * nbLine
            tools: tools,
            pasteMode: 'text',
            created: function (event) {
                ctrl.editor = event.instance;
            },
            createCommand: JQWidgetsService.getEditorCommand(ctrl.model)
        };

        ctrl.countLeftCharacters = function () {
            if (ctrl.model) {
                var nbChar = CustomUtils.countCharInHtmlString(ctrl.model);
                return ctrl.maxlength - nbChar;
            } else {
                return ctrl.maxlength;
            }
        };
    }
})();
