(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('ElasticsearchController', ElasticSearchController);

    ElasticSearchController.$inject = ['ConfirmDialog', 'AlertService', '$http', '$translate', 'CustomUtils', 'JQWidgetsService', '$translate', 'stats', 'indices', '$state', 'mappings', '$log'];

    function ElasticSearchController(ConfirmDialog, AlertService, $http, translate, CustomUtils, JQWidgetsService, $translate, stats, indices, $state, mappings, $log) {
        var vm = this;
        vm.stats = stats;
        vm.indices = indices;
        vm.mappings = mappings;

        vm.filters = {
            person: "Person",
            project: 'Project',
            society: "Society",
            eventabstract: "EventAbstract",
            team: "Team",
            callforproject: "CallForProject",
            user: "User",
            mainevent: "MainEvent"
        };

        vm.classes = [];

        vm.Model = {
            clusterName: null,
            status: null,
            indices: [],
            memoryPercent: 0
        };
        vm.loading = false;

        vm.indexDropDownSettings = {
            width: 200,
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            source: vm.classes,
            created: function (args) {
                vm.indexDropDown = args.instance;
            }
        };

        vm.loadStats = function () {
            vm.Model = {};
            vm.Model.clusterName = vm.stats.cluster_name;
            vm.Model.status = vm.stats.status;
            if (vm.Model.status === 'yellow') {
                vm.Model.statusClass = "warning";
            } else if (vm.status === 'red') {
                vm.Model.statusClass = "danger";
            } else {
                vm.Model.statusClass = "info";
            }

            vm.Model.nodes = vm.stats.nodes;

            vm.Model.memoryAvailable = vm.Model.nodes.jvm.mem.heap_max_in_bytes;
            vm.Model.memoryUsed = vm.Model.nodes.jvm.mem.heap_used_in_bytes;
            vm.Model.memoryPercent = vm.Model.memoryUsed / vm.Model.memoryAvailable * 100;

            vm.Model.totalCount = vm.stats.indices.docs.count;
        };

        vm.loadIndices = function () {
            console.log("indices");
            console.log(vm.indices);

            var indices = [];
            for (var key in vm.indices.indices) {
                var currentIndices = vm.indices.indices[key];
                var className = null;
                for (var filterKey in vm.filters) {
                    if (filterKey === key) {
                        className = vm.filters[filterKey];
                        break;
                    }
                }
                if (className !== null) {
                    var indice = {
                        index: key,
                        count: currentIndices.total.docs.count,
                        percent: (currentIndices.total.docs.count * 100) / vm.Model.totalCount
                    };
                    indices.push(indice);
                    vm.classes.push(className);
                }
            }
            CustomUtils.sortByKey(indices, 'count', true);

            vm.Model.indices = indices;
            vm.indexDropDownSettings.source = vm.classes;

            var dataAdapter = new $.jqx.dataAdapter({
                localdata: vm.Model.indices,
                datatype: "json",
                datafields: [
                    {name: 'index'},
                    {name: 'count'},
                    {name: 'percent'}
                ]
            });
            vm.chartSettings.source = dataAdapter;

        };

        vm.index = function () {

            var index = vm.selectedIndex;
            if (_.isNil(vm.selectedIndex)) {
                index = "";
            }

            $http.post("api/elasticsearch/index", {
                object: index
            }).then(function (response) {
                AlertService.success(response.data);
            });
        };

        vm.refresh = function () {
            $state.reload();
        };

        vm.tryDeleteIndex = function () {
            var msg = 'tous les index';
            if (!_.isNil(vm.selectedIndex)) {
                msg = vm.selectedIndex;
            }
            ConfirmDialog.openConfirmRemove(msg, function () {
                vm.deleteIndex();
            });
        };

        vm.deleteIndex = function () {
            $http.delete("api/elasticsearch/index/" + vm.selectedIndex).then(function (response) {
                AlertService.success(response.data);
                $state.reload();
            });
        };

        vm.memorySettings = {
            height: 21,
            width: 350,
            showText: true,
            colorRanges: [{
                    stop: 60,
                    color: "#22e52f"
                }, {
                    stop: 80,
                    color: "#f7a21b"
                }, {
                    stop: 100,
                    color: "#ed280e"
                }]
        };

        vm.chartSettings = {
            source: [],
            title: '',
            description: '',
            enableAnimations: true,
            showLegend: true,
            colorScheme: 'scheme05',
            padding: {left: 10, top: 10, right: 10, bottom: 10},
            xAxis: {
                dataField: 'index',
                labels: {
                    angle: 90
                },
                flip: false
            },
            seriesGroups: [{
                    type: 'column',
                    showLabels: true,
                    orientation: 'horizontal',
                    valueAxis: {
                        visible: true,
                        flip: true
                    },
                    series: [{
                            dataField: 'count',
                            displayText: translate.instant("elasticsearch.indicesSerieLegend")
                        }
                    ]
                }
            ],
            click: function (e) {
                vm.selectedIndex = null;
                vm.mapping = null;
                if (!_.isNil(e.args)) {
                    var row = vm.chartSettings.source.records[e.args.elementIndex];
                    $log.debug(row);
                    vm.mapping = _.find(vm.mappings, function (aMapping) {
                        return !_.isNil(aMapping[row.index]);
                    });
                    if (!_.isNil(vm.mapping)) {
                        vm.selectedIndex = row.index;
                        vm.mapping = vm.mapping[row.index];

                    }
                }
            }
        };
        vm.mapping = null;
        vm.selectedIndex = null;
        vm.loadStats();
        vm.loadIndices();
    }
})();
