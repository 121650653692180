(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.mainevent', {
            parent: 'sheet',
            url: 'mainevent/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.mainevent.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/mainevent/sheet.mainevent.html',
                    controller: 'SheetMainEventController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('mainEvent');
                        $translatePartialLoader.addPart('mainEventMail');
                        $translatePartialLoader.addPart('eventManagement');
                        $translatePartialLoader.addPart('eventRegistration');
                        $translatePartialLoader.addPart('eventSession');
                        $translatePartialLoader.addPart('eventPrice');
                        $translatePartialLoader.addPart('personStatus');
                        $translatePartialLoader.addPart('personMetier');
                        $translatePartialLoader.addPart('eventOption');
                        $translatePartialLoader.addPart('mainEventMailType');
                        return $translate.refresh();
                    }],
                entity: ['$stateParams', 'MainEvent', function ($stateParams, MainEvent) {
                        return MainEvent.get({id: $stateParams.id}).$promise;
                    }]
            }
        }).state('sheet.mainevent.main', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.main': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.main.html',
                    controller: 'SheetMainEventMainTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.mainevent.prices', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.prices': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.prices.html',
                    controller: 'SheetMainEventPricesTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                personStatus: ['PersonStatus', function (PersonStatus) {
                        return PersonStatus.query({
                            size: 1000
                        }).$promise;
                    }],
                prices: ['EventRegistration', '$stateParams', function (EventRegistration, $stateParams) {
                        return EventRegistration.getPrices({id: $stateParams.id}).$promise;
                    }]
            }
        }).state('sheet.mainevent.sessions', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.sessions': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.sessions.html',
                    controller: 'SheetMainEventSessionsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.mainevent.options', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.options': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.options.html',
                    controller: 'SheetMainEventOptionsTabController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                optionsParticipations: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get('api/main-events/' + $stateParams.id + '/options').then(function (response) {
                            return response.data;
                        });
                    }]
            }
        }).state('sheet.mainevent.abstracts', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.abstracts': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.abstracts.html',
                    controller: 'SheetMainEventAbstractsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.mainevent.statistics', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.statistics': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.statistics.html',
                    controller: 'SheetMainEventStatisticsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.mainevent.mails', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.mails': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.mails.html',
                    controller: 'SheetMainEventMailsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.mainevent.participations', {
            parent: 'sheet.mainevent',
            views: {
                'sheet.mainevent.participations': {
                    templateUrl: 'app/sheet/mainevent/tab/sheet.mainevent.participations.html',
                    controller: 'SheetMainEventParticipationsTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('exportEventRegistrationsFromSheet', {
            parent: 'sheet.mainevent.main',
            //url: '/export',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                registrations: null,
                type: null,
                mainEventId: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var previousState = {
                        name: $state.current.name,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    $uibModal.open({
                        templateUrl: 'app/manifestation/dialog/export-eventRegistrations.dialog.html',
                        controller: 'ExportEventRegistrationsDialogController',
                        controllerAs: 'ctrl',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            eventRegistrations: function () {
                                return $stateParams.registrations;
                            },
                            exportType: function () {
                                return $stateParams.type;
                            },
                            eventDays: ['EventRegistration', function (EventRegistration) {
                                    return EventRegistration.getSessions({id: $stateParams.mainEventId}).$promise;
                                }],
                            eventOptions: ['$http', function ($http) {
                                    return $http.get('api/public/main-events/' + $stateParams.id + '/options').then(function (response) {
                                        return response.data;
                                    });
                                }]
                        }
                    }).result.then(function () {
                        $state.go(previousState.name, previousState.params);
                    }, function () {
                        $state.go(previousState.name, previousState.params);
                    });
                }]
        });
    }
})();