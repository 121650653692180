(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('ElasticSearchService', ElasticSearchService);

    ElasticSearchService.$inject = ['$resource'];

    function ElasticSearchService($resource) {
        var resourceUrl = 'api/elasticsearch/info/:type';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();
