(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES', 'tmhDynamicLocale'];

    function JhiLanguageService($q, $http, $translate, LANGUAGES, tmhDynamicLocale) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent,
            changeLanguage: changeLanguage,
            changeToPreferedLanguage: changeToPreferedLanguage
        };

        return service;

        function getAll() {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent() {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }

        function changeLanguage(languageKey) {
            var promise = $q.when(true);
            var used = $translate.use();
            if (used !== languageKey) {
                promise = $translate.use(languageKey);
                tmhDynamicLocale.set(languageKey);
            }
            return promise;
        }

        function changeToPreferedLanguage() {
            this.changeLanguage($translate.preferredLanguage());
        }
    }
})();
