(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('CallForProjectController', CallForProjectController);
  CallForProjectController.$inject = [
    'JQWidgetsService',
    '$translate',
    '$http',
    'CallForProjectService',
    'DateUtils',
    'CustomUtils',
    '$uibModal',
    '$state'
  ];
  function CallForProjectController(JQWidgetsService, $translate, $http, CallForProjectService, DateUtils, CustomUtils, $uibModal, $state) {
    var vm = this;
    vm.selectedTemplates = null;

    var templatesSources = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'type', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'privateSpace', type: 'bool' },
        { name: 'callForProjectId', type: 'number' }
      ],
      id: 'id',
      url: 'api/call-for-projects/form/templates'
    };
    var templatesDataAdapter = new $.jqx.dataAdapter(templatesSources);

    var typeCellRenderer = function (row, column, value, html, cell, resource) {
      if (value.length > 0) {
        var label = CallForProjectService.getFormTypeLabel(resource, value);
        return html.replace(value, label);
      }
      return '';
    };

    var nbEtapeCellRenderer = function (row, column, value, html, cell, resource) {
      if (value) {
        return html.replace(value, '2');
      }
      return html.replace(value, '1');
    };

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.templatesGridSettings = {
      width: '100%',
      height: 500,
      source: templatesDataAdapter,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      altrows: true,
      //selectionmode: 'multiplerows',
      localization: JQWidgetsService.getLocalization($translate.use()),
      columns: [
        {
          text: 'nom',
          datafield: 'name',
          width: '50%',
          minwidth: 100,
          pinned: true,
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: 'Type',
          datafield: 'type',
          minwidth: 100,
          cellsrenderer: typeCellRenderer,
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: 'Nb Etape',
          datafield: 'privateSpace',
          width: 130,
          cellsrenderer: nbEtapeCellRenderer,
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center'
        }
      ],
      rowselect: function (event) {
        vm.selectedTemplates = event.args.row;
        vm.callForProject = {
          id: vm.selectedTemplates.callForProjectId
        };

        $http.get('api/call-for-projects/' + vm.selectedTemplates.callForProjectId + '/form/all').then(function (response) {
          vm.formInformation = null;
          if (response.status === 200) {
            angular.forEach(response.data, function (formField) {
              var form = formField.form;
              if (form.id === vm.selectedTemplates.id) {
                if (form.startDate) {
                  form.startDate = DateUtils.convertDateTimeFromServer(form.startDate);
                }
                if (form.endDate) {
                  form.endDate = DateUtils.convertDateTimeFromServer(form.endDate);
                }
                vm.formInformation = CallForProjectService.loadFormField(formField);
              }
            });
          }
        });
      }
    };

    vm.editForm = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/callforproject/dialog/edit-form.dialog.html',
          controller: 'EditCallForProjectFormDialogController',
          controllerAs: 'ctrl',
          size: 'sm',
          backdrop: 'static',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('callForProjectForm');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }
            ],
            callForProject: function () {
              return vm.callForProject;
            },
            formFieldsList: function () {
              return [vm.formInformation];
            }
          }
        })
        .result.then(function () {
          vm.load();
        });
    };
  }
})();
