(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectDialogController', CallForProjectDialogController);

    CallForProjectDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'CallForProject', 'Society', 'Axe', 'FileAttachement', 'CallForProjectType'];

    function CallForProjectDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, CallForProject, Society, Axe, FileAttachement, CallForProjectType) {
        var vm = this;

        vm.callForProject = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.societies = Society.query();
        vm.axes = Axe.query();
        vm.expertmanuals = FileAttachement.query({filter: 'callforproject-is-null'});
        $q.all([vm.callForProject.$promise, vm.expertmanuals.$promise]).then(function() {
            if (!vm.callForProject.expertManualId) {
                return $q.reject();
            }
            return FileAttachement.get({id : vm.callForProject.expertManualId}).$promise;
        }).then(function(expertManual) {
            vm.expertmanuals.push(expertManual);
        });
        vm.callforprojecttypes = CallForProjectType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.callForProject.id !== null) {
                CallForProject.update(vm.callForProject, onSaveSuccess, onSaveError);
            } else {
                CallForProject.save(vm.callForProject, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:callForProjectUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setAvatar = function ($file, callForProject) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        callForProject.avatar = base64Data;
                        callForProject.avatarContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.expertiseStartDate = false;
        vm.datePickerOpenStatus.expertiseEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
