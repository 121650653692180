(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PersonMetierDetailController', PersonMetierDetailController);

    PersonMetierDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PersonMetier', 'PersonStatus'];

    function PersonMetierDetailController($scope, $rootScope, $stateParams, entity, PersonMetier, PersonStatus) {
        var vm = this;

        vm.personMetier = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:personMetierUpdate', function(event, result) {
            vm.personMetier = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
