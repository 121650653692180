(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectFollowedController', SheetProjectFollowedController);

    SheetProjectFollowedController.$inject = ['Principal', 'project', '$stateParams', '$state', 'JQWidgetsService', '$translate', 'CustomUtils', '$log'];

    function SheetProjectFollowedController(Principal, project, $stateParams, $state, JQWidgetsService, $translate, CustomUtils, $log) {
        if ($stateParams.tab !== "followed") {
            //update state param
            $stateParams.tab = "followed";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.project = project;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.selectedSubmission = null;
        vm.submissionsDataAdapter = new $.jqx.dataAdapter({
            dataType: "json",
            url: "api/project/submissions/" + vm.project.id,
            dataFields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'submissionDate',
                    type: 'date'
                }, {
                    name: 'privateKey',
                    type: 'number'
                }, {
                    name: 'keep',
                    type: 'bool'
                }, {
                    name: 'funded',
                    type: 'bool'
                }, {
                    name: 'askedBudget',
                    type: 'number'
                }, {
                    name: 'askedDuration',
                    type: 'number'
                }, {
                    name: 'acceptedBudget',
                    type: 'number'
                }, {
                    name: 'acceptedDuration',
                    type: 'number'
                }, {
                    name: 'finalDuration',
                    type: 'number'
                }, {
                    name: 'conventionStart',
                    type: 'date'
                }, {
                    name: 'conventionEnd',
                    type: 'date'
                }, {
                    name: 'returnBilanDate',
                    type: 'date'
                }, {
                    name: 'callForProjectId',
                    type: 'number'
                }, {
                    name: 'callForProjectName',
                    type: 'string'
                }, {
                    name: 'year',
                    type: 'number'
                }, {
                    name: 'societies',
                    type: 'string'
                }, {
                    name: 'geosite',
                    type: 'string'
                }, {
                    name: 'axeName',
                    type: 'string'
                }],
            id: 'id',
            sortcolumn: 'submissionDate',
            sortdirection: 'desc'
        });
        vm.submissionsGridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: vm.submissionsDataAdapter,
            enabletooltips: true,
            height: 500,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                vm.participationsGrid = args.instance;
            },
            columns: [{
                    text: 'AAP',
                    datafield: 'callForProjectName',
                    width: 150,
                    filtertype: 'checkedlist',
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (CustomUtils.isDefined(object.callForProjectId)) {
                            var link = '<a href="#/sheet/callforproject/' + object.callForProjectId + '?tab=main">';
                            link += value;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    },
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true
                }, {
                    text: 'Année',
                    datafield: 'year',
                    width: 80,
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true
                }, {
                    text: 'Demandé',
                    datafield: 'askedBudget',
                    columngroup: 'budget',
                    filtertype: 'number',
                    cellsformat: 'c',
                    width: 130,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Obtenu',
                    datafield: 'acceptedBudget',
                    columngroup: 'budget',
                    cellsformat: 'c',
                    width: 130,
                    filtertype: 'number',
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Demandée',
                    datafield: 'askedDuration',
                    columngroup: 'duration',
                    width: 130,
                    filtertype: 'number',
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Financée',
                    datafield: 'acceptedDuration',
                    columngroup: 'duration',
                    width: 130,
                    filtertype: 'number',
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'nom',
                    datafield: 'name',
                    minwidth: 130,
                    cellsalign: 'center',
                    align: 'center'
                }],
            columngroups: [{
                    text: 'Financement',
                    name: 'budget',
                    align: 'center'
                }, {
                    text: 'Durée (en Mois)',
                    name: 'duration',
                    align: 'center'
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    vm.selectedSubmission = args.row;
                }
            }
        };

        vm.addExternalSubmission = function () {
            $log.info("todo");
        };
    }
})();
