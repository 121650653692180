(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetCallForProjectMainTabController', SheetCallForProjectMainTabController);

  SheetCallForProjectMainTabController.$inject = [
    'JQWidgetsService',
    '$translate',
    '$stateParams',
    'CallForProject',
    'SheetHistoryService',
    '$state',
    'callForProject',
    'AlertService',
    'Principal',
    'PersonSearch',
    'SocietySearch',
    'Axe',
    'managements',
    'CallForProjectManagement',
    'CustomUtils',
    'DataUtils',
    '$http',
    'aapTypes'
  ];

  function SheetCallForProjectMainTabController(
    JQWidgetsService,
    $translate,
    $stateParams,
    CallForProject,
    SheetHistoryService,
    $state,
    callForProject,
    AlertService,
    Principal,
    PersonSearch,
    SocietySearch,
    Axe,
    managements,
    CallForProjectManagement,
    CustomUtils,
    DataUtils,
    $http,
    aapTypes
  ) {
    if ($stateParams.tab !== 'main') {
      //update state param
      $stateParams.tab = 'main';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.callForProject = callForProject;
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
    vm.axes = Axe.query({
      page: 0,
      size: 1000,
      sort: 'name,asc'
    });
    vm.managements = managements;
    vm.managers = [];
    vm.expertNoticeType = 'expertNotice';

    aapTypes.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    vm.edition = {
      informations: false,
      notices: false
    };

    var sheetHistory = {
      name: vm.callForProject.name,
      type: 'callforproject',
      id: vm.callForProject.id
    };
    SheetHistoryService.add(sheetHistory);

    vm.changePublicState = function () {
      CallForProject.update(vm.callForProject, vm.load, vm.load);
    };

    vm.personDataAdapter = new $.jqx.dataAdapter(
      {
        datatype: 'json',
        datafields: [
          {
            name: 'name'
          }
        ],
        id: 'id',
        url: 'api/_search/',
        data: {
          size: '20'
        }
      },
      {
        // jshint ignore:line
        loadServerData: function (serverdata, source, callback) {
          PersonSearch.query(
            {
              query: source.searchString,
              page: 0,
              size: 20
            },
            function (result) {
              angular.forEach(result, function (person) {
                person.name = person.firstName + ' ' + person.lastName;
              });
              callback({
                records: result
              });
            }
          );
        }
      }
    );

    vm.typesSettings = {
      displayMember: 'name',
      valueMember: 'id',
      autoDropDownHeight: true,
      source: {
        localdata: aapTypes,
        datatype: 'json',
        datafields: [
          {
            name: 'id',
            type: 'number'
          },
          {
            name: 'name',
            type: 'string'
          }
        ]
      },
      width: '100%',
      height: 32,
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use())
    };

    vm.getPublicState = function () {
      if (vm.callForProject.publique) {
        return $translate.instant('diffusion.online');
      } else {
        return $translate.instant('diffusion.offline');
      }
    };

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    var onSaveSuccess = function (result) {
      AlertService.success('OK');
      vm.load();
    };

    var onSaveError = function (result) {
      AlertService.error('KO');
      vm.load();
    };

    vm.edit = function (win) {
      vm.edition[win] = true;
      vm.managers = [];
      angular.forEach(vm.managements, function (management) {
        vm.managers.push({
          id: management.personId,
          firstName: management.personFirstName,
          lastName: management.perosnLastName,
          name: management.personFirstName + ' ' + management.personLastName,
          managerId: management.id
        });
      });
    };

    vm.saveManagement = function (promise) {
      //check management to delete
      angular.forEach(vm.managements, function (management) {
        var found = false;

        for (var i = 0; i < vm.managers.length; i++) {
          var manager = vm.managers[i];
          if (management.personId === manager.id) {
            found = true;
            break;
          }
        }

        if (!found) {
          promise = promise.then(function () {
            return CallForProjectManagement.delete({
              id: management.id
            }).$promise;
          });
        }
      });

      angular.forEach(vm.managers, function (manager) {
        var toSave = {
          callForProjectId: vm.callForProject.id,
          type: 'manager',
          personId: manager.id
        };

        if (CustomUtils.isUndefinedOrNull(manager.managerId)) {
          promise = promise.then(function () {
            return CallForProjectManagement.save(toSave).$promise;
          });
        } else {
          toSave.id = manager.managerId;
          promise = promise.then(function () {
            return CallForProjectManagement.update(toSave).$promise;
          });
        }
      });
      return promise;
    };

    vm.save = function (win) {
      var promise = CallForProject.update(vm.callForProject).$promise;

      if (win === 'informations') {
        promise = vm.saveManagement(promise);
      }

      promise.then(onSaveSuccess, onSaveError);
    };

    vm.cancel = vm.load;

    vm.getAxeField = function () {
      if (vm.callForProject.lang === 'en') {
        return 'nameEn';
      }
      return 'name';
    };

    vm.axesSettings = {
      width: '100%',
      displayMember: vm.getAxeField(),
      height: 200,
      valueMember: 'id',
      multiple: true,
      checkboxes: true,
      source: vm.axes,
      created: function (args) {
        vm.axeList = args.instance;
        angular.forEach(vm.callForProject.axes, function (axe) {
          vm.axeList.checkItem(axe.id);
        });
      },
      checkChange: function (event) {
        if (event.args) {
          var args = event.args;

          var index = null;
          for (var i = 0; i < vm.callForProject.axes.length; i++) {
            var axe = vm.callForProject.axes[i];
            if (axe.id === args.value) {
              index = i;
              break;
            }
          }

          if (args.checked === true && index === null) {
            vm.callForProject.axes.push({
              id: args.value,
              name: args.label
            });
          } else if (args.checked === false && index !== null) {
            vm.callForProject.axes.splice(index, 1);
          }
        }
      }
    };

    vm.societyDataAdapter = new $.jqx.dataAdapter(
      {
        datatype: 'json',
        datafields: [
          {
            name: 'name'
          }
        ],
        id: 'id',
        url: 'api/_search/',
        data: {
          size: '20'
        }
      },
      {
        loadServerData: function (serverdata, source, callback) {
          SocietySearch.query(
            {
              query: source.searchString,
              page: 0,
              size: 20
            },
            function (result) {
              callback({
                records: result
              });
            }
          );
        }
      }
    );

    var yearsSource = [];
    for (var i = 2010; i <= 2040; i++) {
      yearsSource.push(i.toString());
    }

    vm.yearsSettings = {
      source: yearsSource
    };

    vm.langSettings = {
      source: ['fr', 'en'],
      autoDropDownHeight: true
    };

    vm.openAttachment = function (attachment) {
      DataUtils.downloadAttachment(attachment.id, attachment.path);
    };

    vm.removeAttachment = function (attachment) {
      if (CustomUtils.isDefined(attachment) && CustomUtils.isDefined(attachment.id)) {
        $http
          .delete('api/call-for-project/' + vm.callForProject.id + '/attachment/' + attachment.id + '?type=' + vm.expertNoticeType)
          .then(function () {
            vm.load();
          });
      } else {
        vm.load();
      }
    };

    vm.loadAttachment = function () {
      vm.attachments = [];
      vm.expertNoticeAttachement = {
        name: 'Notice'
      };
      if (CustomUtils.isDefined(vm.callForProject.expertManualId)) {
        $http.get('api/public/file-attachements/' + vm.expertNoticeType + '/' + vm.callForProject.id).then(function (response) {
          if (response.status === 200) {
            vm.attachments = response.data;
            for (var i = 0; i < vm.attachments.length; i++) {
              var attachment = vm.attachments[i];
              if (attachment.type === vm.expertNoticeType) {
                vm.expertNoticeAttachement = attachment;
              }
            }
          }
        });
      }
    };

    vm.loadAttachment();
  }
})();
