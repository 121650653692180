(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('society', {
                    parent: 'entity',
                    url: '/society',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.society.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/society/societies.html',
                            controller: 'SocietyController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('society');
                                $translatePartialLoader.addPart('societyLevel');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('society-detail', {
                    parent: 'entity',
                    url: '/society/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.society.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/society/society-detail.html',
                            controller: 'SocietyDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('society');
                                $translatePartialLoader.addPart('societyLevel');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Society', function ($stateParams, Society) {
                                return Society.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('society.new', {
                    parent: 'society',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/society/society-dialog.html',
                                controller: 'SocietyDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            address: null,
                                            address2: null,
                                            cp: null,
                                            city: null,
                                            callingCode: null,
                                            phone: null,
                                            fax: null,
                                            email: null,
                                            webSite: null,
                                            shortDescription: null,
                                            longDescription: null,
                                            privateDescription: null,
                                            lng: null,
                                            lat: null,
                                            avatar: null,
                                            avatarContentType: null,
                                            publique: false,
                                            shortName: null,
                                            level: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('society', null, {reload: true});
                            }, function () {
                                $state.go('society');
                            });
                        }]
                })
                .state('society.edit', {
                    parent: 'society',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/society/society-dialog.html',
                                controller: 'SocietyDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['Society', function (Society) {
                                            return Society.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('society', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('society.delete', {
                    parent: 'society',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/society/society-delete-dialog.html',
                                controller: 'SocietyDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['Society', function (Society) {
                                            return Society.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('society', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
