(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetPersonMainTabController', SheetPersonMainTabController);

    SheetPersonMainTabController.$inject = ['Principal', '$log', '$stateParams', '$state', 'Person', 'CustomUtils', 'AlertService', 'personEntity', 'ScientificAttachment'];

    function SheetPersonMainTabController(Principal, $log, $stateParams, $state, Person, CustomUtils, AlertService, personEntity, ScientificAttachment) {
        if ($stateParams.tab !== "main") {
            //update state param
            $stateParams.tab = "main";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: personEntity,
            currentSocieties: [],
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                contact: false,
                scientificAttachment: false
            }
        };

        vm.Model.fullAddress = vm.Model.entity.address;
        if (vm.Model.entity.address2) {
            vm.Model.fullAddress += " - " + vm.Model.entity.address2;
        }
        if (vm.Model.entity.cp) {
            vm.Model.fullAddress += " - " + vm.Model.entity.cp;
        }
        if (vm.Model.entity.city) {
            vm.Model.fullAddress += " - " + vm.Model.entity.city;
        }

        CustomUtils.getCallingCodes().then(function (result) {
            vm.callingCodeSettings.source = result;
        });

        vm.callingCodeSettings = {
            created: function (args) {
                vm.callingCodeComboBox = args.instance;
                //vm.callingCodeComboBox.val(vm.Model.entity.callingCode);
            },
            renderer: function (element, label, value) {
                return "+" + label;
            },
            selectionRenderer: function (htmlElement, label, value) {
                var htmlString = htmlElement[0].innerHTML;
                return '<div style="position: relative; margin-left: 3px; margin-top: 8px;">+' + htmlString + '</div>';
            }
        };

        var onSaveSuccess = function (result) {
            AlertService.success("OK");
            vm.load();
        };

        var onSaveError = function (result) {
            AlertService.error("KO");
            vm.load();
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.edit = function (win) {
            vm.Model.edition[win] = true;
        };

        vm.save = function (win) {
            if (win === 'contact') {
                if (!CustomUtils.isEmpty(vm.Model.entity.website) && !vm.Model.entity.website.startsWith("http")) {
                    vm.Model.entity.website = "http://" + vm.Model.entity.website;
                }
            }
            var promise = Person.update(vm.Model.entity).$promise;
            promise.then(onSaveSuccess, onSaveError);
        };


        vm.scientificAttachments = ScientificAttachment.query();

        vm.scientificListBoxSettings = {
            width: "100%",
            height: 200,
            displayMember: "name",
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: vm.scientificAttachments,
            created: function (args) {
                vm.scientificList = args.instance;
                angular.forEach(vm.Model.entity.scientificAttachments, function (scientificAttachment) {
                    vm.scientificList.checkItem(scientificAttachment.id);
                });
            },
            checkChange: function (event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;

                    for (var i = 0; i < vm.Model.entity.scientificAttachments.length; i++) {
                        var attachement = vm.Model.entity.scientificAttachments[i];
                        if (attachement.id === args.value) {
                            index = i;
                            break;
                        }
                    }

                    if (args.checked === true && index === null) {
                        vm.Model.entity.scientificAttachments.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        vm.Model.entity.scientificAttachments.splice(index, 1);
                    }
                }
            }
        };

        /*$http.get("api/mailjet/list/contacts").then(function (result) {
         $log.info(result);
         });*/


    }
})();
