(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetTeamPresentationController', SheetTeamPresentationController);

    SheetTeamPresentationController.$inject = ['Principal', '$translate', '$stateParams', 'CustomUtils', '$state', 'Team', 'AlertService', 'team', '$log'];

    function SheetTeamPresentationController(Principal, $translate, $stateParams, CustomUtils, $state, Team, AlertService, team, $log) {
        if ($stateParams.tab !== "presentation") {
            //update state param
            $stateParams.tab = "presentation";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.team = team;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.edition = {

        };

        $log.info(vm);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.save = function (win) {

            Team.update(vm.team, function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });

        };



    }
})();
