(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('TeamMemberDetailController', TeamMemberDetailController);

    TeamMemberDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TeamMember', 'PersonStatus', 'PersonMetier', 'Team', 'Person'];

    function TeamMemberDetailController($scope, $rootScope, $stateParams, entity, TeamMember, PersonStatus, PersonMetier, Team, Person) {
        var vm = this;

        vm.teamMember = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:teamMemberUpdate', function(event, result) {
            vm.teamMember = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
