(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('abstractGrid', {
                controller: 'AbstractGridController',
                bindings: {
                    grid: '=',
                    canEdit: '@',
                    mainEvent: '<',
                    getSelection: '=?',
                    filterSession: '=?',
                    autosavestate: '@',
                    totalChartSources: '=?',
                    finalTotalChartSources: '=?',
                    id: '@'
                },
                templateUrl: 'app/components/grid/abstract-grid.html'
            });
})();
