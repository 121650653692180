(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PublicationTypeDetailController', PublicationTypeDetailController);

    PublicationTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PublicationType'];

    function PublicationTypeDetailController($scope, $rootScope, $stateParams, entity, PublicationType) {
        var vm = this;

        vm.publicationType = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:publicationTypeUpdate', function(event, result) {
            vm.publicationType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
