(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet', {
            parent: 'app',
            url: '/sheet/',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                type: null,
                id: null,
                name: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/sheet.html',
                    controller: 'SheetController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        return $translate.refresh();
                    }]
            }
        });
    }
})();