(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SubmissionDialogController', SubmissionDialogController);

    SubmissionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Submission', 'Project', 'CallForProject', 'Geosite', 'Axe', 'PersonSocietyInput', 'SubmissionMail', 'FileAttachement'];

    function SubmissionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Submission, Project, CallForProject, Geosite, Axe, PersonSocietyInput, SubmissionMail, FileAttachement) {
        var vm = this;

        vm.submission = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.projects = Project.query();
        vm.callforprojects = CallForProject.query();
        vm.geosites = Geosite.query();
        vm.axes = Axe.query();
        vm.personsocietyinputs = PersonSocietyInput.query({filter: 'submission-is-null'});
        $q.all([vm.submission.$promise, vm.personsocietyinputs.$promise]).then(function() {
            if (!vm.submission.personSocietyInputId) {
                return $q.reject();
            }
            return PersonSocietyInput.get({id : vm.submission.personSocietyInputId}).$promise;
        }).then(function(personSocietyInput) {
            vm.personsocietyinputs.push(personSocietyInput);
        });
        vm.submissionmails = SubmissionMail.query();
        vm.fileattachements = FileAttachement.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.submission.id !== null) {
                Submission.update(vm.submission, onSaveSuccess, onSaveError);
            } else {
                Submission.save(vm.submission, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:submissionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.modificationDate = false;
        vm.datePickerOpenStatus.conventionStartDate = false;
        vm.datePickerOpenStatus.conventionEndDate = false;
        vm.datePickerOpenStatus.returnBilanDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
