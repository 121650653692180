(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('AbstractDialogController', AbstractDialogController);

    AbstractDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'PersonSearch', '$log', 'SocietySearch', '$scope', 'existingSocieties', 'existingAuthors', '$filter', 'selectedAuthor', 'CustomUtils'];

    function AbstractDialogController($translate, $translatePartialLoader, $uibModalInstance, PersonSearch, $log, SocietySearch, $scope, existingSocieties, existingAuthors, $filter, selectedAuthor, CustomUtils) {

        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.firstName = null;
        ctrl.lastName = null;
        ctrl.speaker = false;
        ctrl.personId = null;
        ctrl.civilite = 'mr';

        ctrl.add = CustomUtils.isUndefinedOrNull(selectedAuthor);
        if (ctrl.add) {
            ctrl.title = $translate.instant('canceropoleGsoApp.eventAbstract.addAuthor');
        } else {
            ctrl.title = $translate.instant('canceropoleGsoApp.eventAbstract.editAuthor');
        }

        ctrl.result = null;
        ctrl.existingSocieties = [];
        if (existingSocieties) {
            ctrl.existingSocieties = existingSocieties;
        }

        ctrl.existingAuthors = existingAuthors;
        ctrl.canEditSpeaker = function () {
            if (ctrl.existingAuthors) {
                for (var i = 0; i < ctrl.existingAuthors.length; i++) {
                    var existingAuthor = ctrl.existingAuthors[i];
                    if (existingAuthor.speaker) {
                        if (ctrl.add) {
                            return false;
                        } else if (existingAuthor.id !== selectedAuthor.id) {
                            return false;
                        }
                    }
                }
            }
            return true;
        };

        ctrl.abstractSocieties = [];

        $scope.$watchCollection('ctrl.abstractSocieties', function (abstractSocieties) {
            ctrl.addPersonForm.$setValidity('societiesCount', abstractSocieties.length > 0);
        });

        ctrl.civiliteSettings = {
            height: 30,
            width: 60,
            autoDropDownHeight: true,
            displayMember: 'label',
            valueMember: 'value',
            created: function (args) {
                ctrl.civiliteDropdownList = args.instance;
            },
            source: [{
                label: $translate.instant('canceropoleGsoApp.Civilite.mr'),
                value: 'mr'
            }, {
                label: $translate.instant('canceropoleGsoApp.Civilite.mme'),
                value: 'mme'
            }]
        };

        ctrl.ok = function () {
            angular.forEach(ctrl.abstractSocieties, function (abstractSociety) {
                if (abstractSociety.city) {
                    abstractSociety.name += " - " + abstractSociety.city;
                }
            });

            ctrl.firstName = $filter('capitalize')(ctrl.firstName);
            ctrl.lastName = $filter('uppercase')(ctrl.lastName);

            ctrl.result = {
                "person": {
                    "firstName": ctrl.firstName,
                    "lastName": ctrl.lastName,
                    "speaker": ctrl.speaker,
                    "personId": ctrl.personId,
                    "civilite": ctrl.civilite
                },
                "abstractSocieties": ctrl.abstractSocieties
            };

            if (!ctrl.add) {
                ctrl.result.person.id = selectedAuthor.id;
                ctrl.result.person.position = selectedAuthor.position;
                ctrl.result.person.abstractEventId = selectedAuthor.abstractEventId;
            }

            $log.info("result=", ctrl.result);
            $uibModalInstance.close(ctrl.result);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                name: 'name'
            }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                    ctrl.personId = person.id;
                    ctrl.firstName = person.firstName;
                    ctrl.lastName = person.lastName;
                    ctrl.civilite = person.civilite;
                }
            },
            unselect: function () {
                ctrl.personId = null;
                ctrl.firstName = null;
                ctrl.lastName = null;
                ctrl.civilite = 'mr';
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.personId = null;
                    ctrl.firstName = null;
                    ctrl.lastName = null;
                    ctrl.civilite = 'mr';
                }
            }
        };

        var societySource = {
            datatype: 'json',
            datafields: [{
                name: 'name'
            }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: ctrl.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            source: societyDataAdapter,
            placeHolder: $translate.instant('subscription.form.society.searchPlaceHolder'),
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
            },
            change: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var society = item.originalItem;
                    ctrl.society = society;
                    ctrl.addSociety();
                    ctrl.societyComboBox.clear();

                } else {
                    ctrl.society = null;
                }
            }
        };
        ctrl.reuseSociety = function (existingSociety) {
            var add = true;
            angular.forEach(ctrl.abstractSocieties, function (society) {
                if (society.name.toLowerCase() === existingSociety.name.toLowerCase()) {
                    add = false;
                }
            });
            if (add === true) {
                ctrl.society = {
                    name: existingSociety.name,
                    eventAbstractId: existingSociety.eventAbstractId,
                    societyId: existingSociety.societyId
                };
                ctrl.addSociety();
            }
        };
        ctrl.addSociety = function () {
            if (ctrl.society != null) {
                var add = true;
                angular.forEach(ctrl.abstractSocieties, function (society) {
                    if (society.name.toLowerCase() === ctrl.society.name.toLowerCase()) {
                        add = false;
                    }
                });
                if (add === true) {
                    ctrl.abstractSocieties.push({
                        name: ctrl.society.name,
                        eventAbstractId: ctrl.society.eventAbstractId,
                        societyId: ctrl.society.societyId
                    });
                    ctrl.society = null;
                }
            }
        };
        ctrl.removeSociety = function (societyToDelete) {
            angular.forEach(ctrl.abstractSocieties, function (society, index) {
                if (societyToDelete.name.toLowerCase() === society.name.toLowerCase()) {
                    ctrl.abstractSocieties.splice(index, 1);
                }
            });
        };

        ctrl.initSelectedPerson = function () {
            if (ctrl.add) {
                return;
            }
            $log.info("selectedAuthor=", selectedAuthor);

            ctrl.civilite = selectedAuthor.civilite;
            ctrl.firstName = selectedAuthor.firstName;
            ctrl.lastName = selectedAuthor.lastName;
            ctrl.speaker = selectedAuthor.speaker;
            ctrl.personId = selectedAuthor.personId;

            ctrl.abstractSocieties = selectedAuthor.abstractSocieties;
        };

        ctrl.initSelectedPerson();
    }
})();
