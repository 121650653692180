(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SelectPersonDialogController', SelectPersonDialogController);

    SelectPersonDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'PersonSearch', 'JQWidgetsService', 'CustomUtils', '$uibModal'];

    function SelectPersonDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, PersonSearch, JQWidgetsService, CustomUtils, $uibModal) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.selectedPerson = null;

        ctrl.ok = function () {
            ctrl.isSaving = true;
            $uibModalInstance.close(ctrl.selectedPerson);
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    ctrl.selectedPerson = item.originalItem;
                }
            },
            unselect: function () {
                ctrl.selectedPerson = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.selectedPerson = null;
                }
            }
        };
    }
})();