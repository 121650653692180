(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('MainEventSearch', MainEventSearch);

    MainEventSearch.$inject = ['$resource'];

    function MainEventSearch($resource) {
        var resourceUrl =  'api/_search/main-events/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
