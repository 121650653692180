(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ExpertiseDeleteController',ExpertiseDeleteController);

    ExpertiseDeleteController.$inject = ['$uibModalInstance', 'entity', 'Expertise'];

    function ExpertiseDeleteController($uibModalInstance, entity, Expertise) {
        var vm = this;

        vm.expertise = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Expertise.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
