(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetSocietyEmployeeTabController', SheetSocietyEmployeeTabController);

  SheetSocietyEmployeeTabController.$inject = [
    '$log',
    '$stateParams',
    '$state',
    'DateUtils',
    'CustomUtils',
    'societyEntity',
    'Principal',
    'JQWidgetsService',
    '$translate',
    '$uibModal',
    'Job',
    'AlertService',
    'ConfirmDialog'
  ];

  function SheetSocietyEmployeeTabController(
    $log,
    $stateParams,
    $state,
    DateUtils,
    CustomUtils,
    society,
    Principal,
    JQWidgetsService,
    $translate,
    $uibModal,
    Job,
    AlertService,
    ConfirmDialog
  ) {
    if ($stateParams.tab !== 'employee') {
      //update state param
      $stateParams.tab = 'employee';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.society = society;
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

    var customSort = function (column, direction) {
      var records = vm.jobsGridSettings.source.records;

      if (direction === 'ascending' || direction === 'asc') {
        direction = true;
      }
      if (direction === 'descending' || direction === 'desc') {
        direction = false;
      }

      if (direction != null) {
        records.sort(function (value1, value2) {
          if (column === 'startDate' || column === 'endDate') {
            value1 = value1[column];
            value2 = value2[column];
            if (direction === true) {
              return DateUtils.compareWithUndefinedDate(value1, value2);
            } else {
              return DateUtils.compareWithUndefinedDate(value2, value1);
            }
          } else if (column === 'name') {
            value1 = String(value1['personLastName']).toLowerCase();
            value2 = String(value2['personLastName']).toLowerCase();
          } else {
            value1 = String(value1[column]).toLowerCase();
            value2 = String(value2[column]).toLowerCase();
          }
          if (direction === true) {
            return value1.localeCompare(value2);
          } else {
            return value2.localeCompare(value1);
          }
        });
        vm.jobsGridSettings.source.records = records;
      }
      if (vm.jobsGrid) {
        vm.jobsGrid.refreshdata();
      }
    };

    var jobSource = {
      datatype: 'json',
      url: 'api/jobs/society/' + vm.society.id,
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'jobLabel',
          type: 'string'
        },
        {
          name: 'position',
          type: 'number'
        },
        {
          name: 'startDate',
          type: 'date'
        },
        {
          name: 'endDate',
          type: 'date'
        },
        {
          name: 'societyId',
          type: 'number'
        },
        {
          name: 'personId',
          type: 'number'
        },
        {
          name: 'personFirstName',
          type: 'string'
        },
        {
          name: 'personLastName',
          type: 'string'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'personPhone',
          type: 'string'
        },
        {
          name: 'personPortable',
          type: 'string'
        },
        {
          name: 'personEmail',
          type: 'string'
        },
        {
          name: 'metierId',
          type: 'number'
        },
        {
          name: 'metierName',
          type: 'string'
        },
        {
          name: 'statusId',
          type: 'number'
        },
        {
          name: 'statusName',
          type: 'string'
        }
      ],
      sort: customSort,
      id: 'id'
    };

    var jobsDataAdapter = new $.jqx.dataAdapter(jobSource, {
      autoBind: false,
      beforeLoadComplete: function (records) {
        vm.employeesNb = records.length;
        for (var i = 0; i < records.length; i++) {
          var record = records[i];
          var firstName = record.personFirstName;
          var lastName = record.personLastName;
          var name = CustomUtils.concatWith(firstName, lastName, ' ');
          record.name = name;
        }
        return records;
      }
    });

    var personNameRenderer = function (row, column, value, html, cell, object) {
      var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
      link += value;
      link += '</a>';

      var result = html.replace(value, link);
      return result;
    };

    vm.applyCurrentJobFilter = function () {
      vm.currentJobFilterGroup = new $.jqx.filter();
      var filter_or_operator = 1;

      var filtercondition = 'NULL';
      var filter = vm.currentJobFilterGroup.createfilter('datefilter', null, filtercondition);
      vm.currentJobFilterGroup.addfilter(filter_or_operator, filter);
      vm.jobsGrid.addfilter('endDate', vm.currentJobFilterGroup);
      vm.jobsGrid.applyfilters();
    };

    vm.removeCurrentJobFilter = function () {
      vm.jobsGrid.removefilter('endDate', vm.currentJobFilterGroup);
      vm.jobsGrid.applyfilters();
    };

    vm.toggleEndDateFilter = function () {
      var filters = vm.jobsGrid.getfilterinformation();
      var found = false;
      _.forEach(filters, function (filter) {
        if (filter.filter === vm.currentJobFilterGroup) {
          found = true;
        }
      });
      if (found) {
        vm.endDateFilterState = '';
        vm.removeCurrentJobFilter();
      } else {
        vm.endDateFilterState = 'active';
        vm.applyCurrentJobFilter();
      }
    };

    vm.jobSelected = null;
    vm.jobsGridSettings = {
      width: '100%',
      source: jobsDataAdapter,
      localization: JQWidgetsService.getLocalization($translate.use()),
      height: 550,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      showstatusbar: true,
      statusbarheight: 30,
      showaggregates: true,
      altrows: true,
      created: function (args) {
        vm.jobsGrid = args.instance;
        vm.toggleEndDateFilter();
      },
      rowselect: function (event) {
        vm.jobSelected = null;
        if (event.args) {
          vm.jobSelected = event.args.row;
        }
      },
      columns: [
        {
          text: $translate.instant('sheet.society.employees.name'),
          datafield: 'name',
          width: 210,
          pinned: true,
          cellsrenderer: personNameRenderer,
          aggregates: [
            {
              '<b>Total</b>': function (total) {
                return ++total;
              }
            }
          ]
        },
        {
          text: 'Libellé',
          datafield: 'jobLabel',
          minwidth: 180
        },
        {
          text: 'Téléphone',
          datafield: 'personPhone',
          cellsalign: 'center',
          align: 'center',
          width: 120
        },
        {
          text: 'Portable',
          datafield: 'personPortable',
          cellsalign: 'center',
          align: 'center',
          width: 120
        },
        {
          text: 'Email',
          datafield: 'personEmail',
          cellsrenderer: function (row, column, value, html) {
            if (!CustomUtils.isEmpty(value)) {
              var link = '<a href="mailto:' + value + '">';
              link += value;
              link += '</a>';

              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          width: 220
        },
        {
          text: 'Métier',
          datafield: 'metierName',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          width: 200
        },
        {
          text: 'Statut',
          datafield: 'statusName',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          width: 200
        },
        {
          text: 'Début',
          datafield: 'startDate',
          columntype: 'date',
          filtertype: 'date',
          cellsformat: 'dd/MM/yyyy',
          cellsalign: 'center',
          align: 'center',
          filterable: true,
          width: 150
        },
        {
          text: 'Fin',
          datafield: 'endDate',
          columntype: 'date',
          filtertype: 'date',
          cellsformat: 'dd/MM/yyyy',
          cellsalign: 'center',
          align: 'center',
          filterable: true,
          width: 150
        }
      ]
    };

    vm.addJob = function () {
      vm.editJob();
    };

    vm.tryEditJob = function () {
      if (!_.isNil(vm.jobSelected)) {
        vm.editJob(vm.jobSelected);
      }
    };

    vm.editJob = function (data) {
      $uibModal
        .open({
          templateUrl: 'app/sheet/person/dialog/job.edit.dialog.html',
          controller: 'JobEditDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                $translatePartialLoader.addPart('job');
                return $translate.refresh();
              }
            ],
            job: function () {
              if (!CustomUtils.isUndefinedOrNull(data)) {
                return {
                  id: data.id,
                  position: data.position,
                  startDate: data.startDate,
                  endDate: data.endDate,
                  personId: data.personId,
                  societyId: data.societyId,
                  personMetierId: data.metierId,
                  personStatusId: data.statusId,
                  societyName: data.societyName,
                  personName: data.name,
                  label: data.label
                };
              }
            },
            searchPerson: true,
            searchSociety: false
          }
        })
        .result.then(function (result) {
          if (CustomUtils.isUndefinedOrNull(result.id)) {
            result.societyId = vm.society.id;

            Job.save(
              result,
              function () {
                AlertService.success('Information sauvegardée');
                vm.jobsGrid.updatebounddata();
              },
              function () {
                AlertService.error('Impossible de sauvegarder en base.');
              }
            );
          } else {
            Job.update(
              result,
              function () {
                AlertService.success('Information mise à jour.');
                vm.jobsGrid.updatebounddata();
              },
              function () {
                AlertService.error('Impossible de sauvegarder en base.');
              }
            );
          }
        });
    };

    vm.tryRemoveJob = function () {
      var content = vm.jobSelected.name;
      ConfirmDialog.openConfirmRemoveLink(content, function () {
        Job.delete({ id: vm.jobSelected.id }, function () {
          AlertService.success('canceropoleGsoApp.job.deleted');
          vm.jobSelected = null;
          vm.jobsGrid.clearselection();
          vm.jobsGrid.updatebounddata();
        });
      });
    };
  }
})();
