(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SubmissionDetailController', SubmissionDetailController);

    SubmissionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Submission', 'Project', 'CallForProject', 'Geosite', 'Axe', 'PersonSocietyInput', 'SubmissionMail', 'FileAttachement'];

    function SubmissionDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Submission, Project, CallForProject, Geosite, Axe, PersonSocietyInput, SubmissionMail, FileAttachement) {
        var vm = this;

        vm.submission = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:submissionUpdate', function(event, result) {
            vm.submission = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
