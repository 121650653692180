(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddModeratorDialogController', AddModeratorDialogController);

    AddModeratorDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'session', 'participations', '$log'];

    function AddModeratorDialogController($translate, $translatePartialLoader, $uibModalInstance, session, participations, $log) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.session = session;
        ctrl.participations = participations;

        ctrl.selection = null;
        ctrl.settings = {
            altrows: true,
            width: "100%",
            height: 400,
            sortable: true,
            selectionmode: 'checkbox',
            source: ctrl.participations,
            created: function (args) {
                ctrl.grid = args.instance;
            },
            rowselect: function () {
                ctrl.selection = ctrl.getSelection();
            },
            rowunselect: function () {
                ctrl.selection = ctrl.getSelection();
            },
            columns: [{
                    text: 'Prénom',
                    datafield: 'firstName',
                    width: '50%'
                }, {
                    text: 'Nom',
                    datafield: 'lastName'
                }]
        };

        ctrl.getSelection = function () {
            var result = [];
            if (ctrl.grid != null) {
                angular.forEach(ctrl.grid.getselectedrowindexes(), function (selectedIndex) {
                    var abstract = ctrl.participations[selectedIndex];
                    result.push(abstract);
                });
            }
            return result;
        };

        ctrl.ok = function () {
            var selection = ctrl.getSelection();
            var moderators = [];

            angular.forEach(selection, function (selection) {
                moderators.push({
                    sessionId: session.id,
                    firstName: selection.firstName,
                    lastName: selection.lastName,
                    personId: selection.personId,
                    registrationId: selection.id
                });
            });

            $uibModalInstance.close(moderators);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();