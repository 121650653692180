(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('entityThumbnail', {
                controller: 'EntityThumbnailController',
                bindings: {
                    entity: '<',
                    clickable: '@?',
                    type: '@?'
                },
                templateUrl: 'app/components/thumbnails/entity-thumbnail.html'
            });
})();
