(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('AbstractGridController', AbstractGridController);
  AbstractGridController.$inject = [
    '$translate',
    '$translatePartialLoader',
    '$http',
    'CustomUtils',
    'DateUtils',
    'JQWidgetsService',
    'AbstractService',
    'AlertService',
    'ConfirmDialog',
    'Principal'
  ];
  function AbstractGridController(
    $translate,
    $translatePartialLoader,
    $http,
    CustomUtils,
    DateUtils,
    JQWidgetsService,
    AbstractService,
    AlertService,
    ConfirmDialog,
    Principal
  ) {
    $translatePartialLoader.addPart('sheet');
    $translate.refresh();
    var ctrl = this;

    if (CustomUtils.isUndefinedOrNull(ctrl.autosavestate)) {
      ctrl.autosavestate = false;
    }

    var gridEditable = false;
    if (ctrl.canEdit && Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
      gridEditable = true;
    }

    ctrl.abstractsSource = {
      datatype: 'json',
      url: 'api/main-events/' + ctrl.mainEvent.id + '/event-abstracts',
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'title',
          type: 'string'
        },
        {
          name: 'axeName',
          type: 'string'
        },
        {
          name: 'sessionId',
          type: 'number'
        },
        {
          name: 'sessionName',
          type: 'string'
        },
        {
          name: 'sessionStartDate',
          type: 'date'
        },
        {
          name: 'firstName',
          type: 'string'
        },
        {
          name: 'lastName',
          type: 'string'
        },
        {
          name: 'personId',
          type: 'number'
        },
        {
          name: 'type',
          type: 'string',
          map: 'type'
        },
        {
          name: 'finalType',
          type: 'string',
          map: 'finalType'
        },
        {
          name: 'reference',
          type: 'string',
          map: 'ref'
        },
        {
          name: 'comments',
          type: 'string',
          map: 'comments'
        },
        {
          name: 'assignCandidate',
          type: 'string',
          map: 'assignCandidate'
        },
        {
          name: 'registrationKey',
          type: 'string'
        },
        {
          name: 'speakerCity',
          type: 'string'
        },
        {
          name: 'speakerMetier',
          type: 'string'
        },
        {
          name: 'speakerStatus',
          type: 'string'
        },
        {
          name: 'accepted',
          type: 'bool',
          map: 'accepted'
        }
      ],
      sortcolumn: 'sessionStartDate',
      sortdirection: 'desc',
      id: 'id'
    };

    ctrl.totalChartSources = [];
    ctrl.finalTotalChartSources = [];

    var abstractsDataAdapter = new $.jqx.dataAdapter(ctrl.abstractsSource, {
      beforeLoadComplete: function (results, records) {
        ctrl.totalChartSources = [];
        ctrl.finalTotalChartSources = [];

        angular.forEach(records, function (record, index) {
          var sessionStartDate = DateUtils.convertDateTimeFromServer(record.sessionStartDate);
          var finalRecord = results[index];

          finalRecord.sessionStartDate = sessionStartDate;

          if (finalRecord.type !== 'I') {
            ctrl.iterateChartItem(finalRecord.axeName, finalRecord.type, ctrl.totalChartSources);
            ctrl.iterateChartItem('Total', finalRecord.type, ctrl.totalChartSources);
          }

          ctrl.iterateChartItem(finalRecord.axeName, finalRecord.finalType, ctrl.finalTotalChartSources);
          ctrl.iterateChartItem('Total', finalRecord.finalType, ctrl.finalTotalChartSources);

          var sortAxe = function (c1, c2) {
            if (!CustomUtils.isUndefinedOrNull(c1.axe)) {
              return c1.axe.localeCompare(c2.axe);
            }
            return 1;
          };

          ctrl.totalChartSources.sort(sortAxe);
          ctrl.finalTotalChartSources.sort(sortAxe);
        });
      }
    });

    ctrl.iterateChartItem = function (axe, type, chartItems) {
      if (!CustomUtils.isUndefinedOrNull(type)) {
        var chartItem = null;
        for (var i = 0; i < chartItems.length; i++) {
          var aChartItem = chartItems[i];
          if (aChartItem.axe === axe) {
            chartItem = aChartItem;
          }
        }
        if (CustomUtils.isUndefinedOrNull(chartItem)) {
          chartItem = {
            axe: axe,
            P: 0,
            OP: 0,
            O: 0,
            A: 0,
            I: 0,
            total: 0
          };
          chartItems.push(chartItem);
        }
        chartItem[type]++;
        chartItem.total++;
      }
    };

    var typeRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderFinalType(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    var acceptedRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderAccepted(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    ctrl.selectedIndexes = [];
    ctrl.settings = {
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      source: abstractsDataAdapter,
      autosavestate: ctrl.autosavestate,
      autoloadstate: ctrl.autosavestate,
      enabletooltips: true,
      height: 540,
      selectionmode: 'checkbox',
      groupable: true,
      columnsresize: true,
      columnsreorder: true,
      altrows: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      showstatusbar: true,
      statusbarheight: 25,
      showaggregates: true,
      editable: gridEditable,
      created: function (args) {
        ctrl.grid = args.instance;
        ctrl.selectedIndexes = ctrl.grid.getselectedrowindexes();
      },
      columns: [
        {
          text: 'Id',
          pinned: true,
          datafield: 'id',
          editable: false,
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.id)) {
              var link = '<a href="#/sheet/eventabstract/' + object.id + '">';
              link += object.id;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          cellsalign: 'center',
          align: 'center',
          width: 50
        },
        {
          text: 'Ref',
          pinned: true,
          datafield: 'reference',
          columntype: 'textbox',
          cellsalign: 'center',
          align: 'center',
          width: 70
        },
        {
          text: 'Thématique',
          pinned: true,
          datafield: 'axeName',
          filtertype: 'checkedlist',
          editable: false,
          width: 80
        },
        {
          text: 'Session',
          pinned: true,
          datafield: 'sessionName',
          filtertype: 'checkedlist',
          editable: false,
          width: 100
        },
        {
          text: 'Type',
          pinned: false,
          datafield: 'type',
          cellsrenderer: typeRenderer,
          filtertype: 'checkedlist',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxDropDownList({
              //dropDownWidth: 250,
              renderer: function (index, label, value) {
                var images = AbstractService.renderFinalType(value);
                if (CustomUtils.isEmpty(images)) {
                  return value;
                }
                return images;
              }
            });
          },
          cellsalign: 'center',
          align: 'center',
          editable: true,
          columntype: 'dropdownlist',
          createEditor: function (row, value, editor) {
            var types = AbstractService.getAvailableCommunicationType(ctrl.mainEvent, true);
            editor.jqxDropDownList({
              source: types,
              placeHolder: 'Type',
              dropDownHeight: 145,
              renderer: function (index, label, value) {
                return AbstractService.renderFinalType(value, true);
              },
              selectionRenderer: function (event, index, label, value) {
                return AbstractService.renderFinalType(value, true);
              }
            });
          },
          width: 60
        },
        {
          text: 'Décision',
          pinned: false,
          datafield: 'accepted',
          cellsrenderer: acceptedRenderer,
          columntype: 'dropdownlist',
          createEditor: function (row, value, editor) {
            var finalTypes = ['OK', 'KO'];
            editor.jqxDropDownList({
              source: finalTypes,
              placeHolder: 'Décision',
              dropDownHeight: 70,
              renderer: function (index, label, value) {
                return AbstractService.renderAccepted(value, true);
              },
              selectionRenderer: function (event, index, label, value) {
                return AbstractService.renderAccepted(value, true);
              }
            });
          },
          cellsalign: 'center',
          align: 'center',
          filtertype: 'checkedlist',
          width: 60
        },
        {
          text: 'Type final',
          pinned: false,
          datafield: 'finalType',
          cellsrenderer: typeRenderer,
          columntype: 'dropdownlist',
          createEditor: function (row, value, editor) {
            var finalTypes = AbstractService.getAvailableFinalType(ctrl.mainEvent, true);
            editor.jqxDropDownList({
              source: finalTypes,
              placeHolder: 'Type',
              dropDownHeight: 175,
              renderer: function (index, label, value) {
                return AbstractService.renderFinalType(value, true);
              },
              selectionRenderer: function (event, index, label, value) {
                return AbstractService.renderFinalType(value, true);
              }
            });
          },
          filtertype: 'checkedlist',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxDropDownList({
              //dropDownWidth: 250,
              renderer: function (index, label, value) {
                var images = AbstractService.renderFinalType(value);
                if (CustomUtils.isEmpty(images)) {
                  return value;
                }
                return images;
              }
            });
          },
          cellsalign: 'center',
          align: 'center',
          editable: true,
          width: 60
        },
        {
          text: 'Nom',
          datafield: 'lastName',
          editable: false,
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.personId)) {
              var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
              link += object.lastName;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          }
        },
        {
          text: 'Prénom',
          datafield: 'firstName',
          editable: false,
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.personId)) {
              var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
              link += object.firstName;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          }
        },
        {
          text: 'Metier',
          datafield: 'speakerMetier',
          filtertype: 'checkedlist',
          editable: false,
          width: 130,
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: 'Statut',
          datafield: 'speakerStatus',
          filtertype: 'checkedlist',
          editable: false,
          width: 130,
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: 'Ville',
          datafield: 'speakerCity',
          editable: false,
          width: 160,
          cellsalign: 'center',
          align: 'center'
        },
        {
          text: 'Titre',
          datafield: 'title',
          editable: false,
          minwidth: 200,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                aggregatedValue++;
                return aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Affectation potentielle',
          datafield: 'assignCandidate',
          editable: true,
          width: 140
        },
        {
          text: 'Commentaires',
          datafield: 'comments',
          editable: true,
          width: 140
        }
      ],
      rowselect: function (event) {
        ctrl.selectedIndexes = ctrl.grid.getselectedrowindexes();
        if (ctrl.selectedIndexes.length === 1) {
          ctrl.selectedAbstract = ctrl.getAbstractsSelection()[0];
        } else {
          ctrl.selectedAbstract = null;
        }
      },
      rowunselect: function () {
        ctrl.selectedIndexes = ctrl.grid.getselectedrowindexes();
      },
      cellendedit: function (event) {
        if (event.args) {
          var args = event.args;
          var abstractId = args.row.id;
          var result = {};
          switch (args.datafield) {
            case 'reference':
              var ref = args.value;
              var existingRef = args.row.reference;

              if (existingRef !== ref && !(CustomUtils.isEmpty(existingRef) && CustomUtils.isEmpty(ref))) {
                result[abstractId] = ref;
                $http.post('api/event-abstracts/refs', result).then(function (result) {
                  var data = result.data;
                  if (data.length === 1 && data[0] === abstractId) {
                    AlertService.success("L'abstract " + abstractId + ' a été mis à jour avec la référence ' + ref);
                  } else {
                    AlertService.error("Impossible de mettre à jour l'abtract " + abstractId + ' avec la référence ' + ref);
                  }
                });
              }
              break;
            case 'finalType':
              var finalType = args.value;
              var existingFinalType = args.row.finalType;

              if (existingFinalType !== finalType) {
                result[abstractId] = finalType;

                if (finalType === 'P') {
                  var name = args.row.firstName + ' ' + args.row.lastName;
                  ConfirmDialog.openYesNo(
                    'Confirmer l&apos;envoi du mail ?',
                    'La communication ' +
                      abstractId +
                      ' de ' +
                      name +
                      ' est de type final poster.<br/>Voulez vous envoyer le mail de confirmation ?',
                    function (userChoise) {
                      ctrl.sendFinalTypeToServer(abstractId, finalType, userChoise);
                    }
                  );
                } else {
                  ctrl.sendFinalTypeToServer(abstractId, finalType, false);
                }
              }
              break;
            case 'type':
              var type = args.value;
              var existingType = args.row.type;

              if (existingType !== type) {
                result[abstractId] = type;

                $http.post('api/event-abstracts/types', result).then(function (result) {
                  var data = result.data;
                  if (data.length === 1 && data[0] === abstractId) {
                    AlertService.success("L'abstract " + abstractId + " a été mis à jour avec le type '" + type + "'");
                    abstractsDataAdapter.dataBind();
                  } else {
                    AlertService.error("Impossible de mettre à jour l'abtract " + abstractId + " avec le type '" + type + "'");
                  }
                });
              }
              break;
            case 'accepted':
              var accepted = args.value;
              var existingAccepted = args.row.accepted;

              if (existingAccepted !== accepted && !(CustomUtils.isEmpty(existingAccepted) && CustomUtils.isEmpty(accepted))) {
                if (accepted === 'KO') {
                  var name = args.row.firstName + ' ' + args.row.lastName;
                  ConfirmDialog.openYesNo(
                    'Confirmer l&apos;envoi du mail ?',
                    'La communication ' +
                      abstractId +
                      ' de ' +
                      name +
                      ' n&apos;a pas été retenue.<br/>Voulez vous envoyer le mail à l&apos;orateur ?',
                    function (userChoise) {
                      ctrl.sendAcceptationToServer(abstractId, accepted, userChoise);
                    }
                  );
                } else {
                  ctrl.sendAcceptationToServer(abstractId, accepted, false);
                }
              }
              break;
            case 'comments':
              var post = {
                id: abstractId,
                type: 'comments',
                value: args.value
              };

              $http.post('api/event-abstract/comment', post).then(function () {
                ctrl.grid.updatebounddata();
              });
              break;
            case 'assignCandidate':
              var postAssign = {
                id: abstractId,
                type: 'assign',
                value: args.value
              };

              $http.post('api/event-abstract/comment', postAssign).then(function () {
                ctrl.grid.updatebounddata();
              });
              break;
          }
        }
      }
    };

    ctrl.getAbstractsSelection = function () {
      var abstracts = [];
      angular.forEach(ctrl.selectedIndexes, function (selectedIndex) {
        var abstract = abstractsDataAdapter.records[selectedIndex];
        abstracts.push(abstract);
      });
      return abstracts;
    };

    ctrl.sendFinalTypeToServer = function (abstractId, finalType, sendMail) {
      var result = {};
      result[abstractId] = finalType;
      $http.post('api/event-abstracts/finalTypes?sendMail=' + sendMail, result).then(function (result) {
        var data = result.data;
        if (data.length === 1 && data[0] === abstractId) {
          AlertService.success("L'abstract " + abstractId + " a été mis à jour avec le type final '" + finalType + "'");
          abstractsDataAdapter.dataBind();
        } else {
          AlertService.error("Impossible de mettre à jour l'abtract " + abstractId + " avec le type final '" + finalType + "'");
        }
      });
    };

    ctrl.sendAcceptationToServer = function (abstractId, accepted, sendMail) {
      var result = {};
      result[abstractId] = accepted === 'OK' ? true : false;

      $http.post('api/event-abstracts/accepteds?sendMail=' + sendMail, result).then(function (result) {
        var data = result.data;
        angular.forEach(data, function (message, id) {
          if (id == abstractId) {
            AlertService.info(message);
          }
        });
      });
    };

    ctrl.filterSession = function (value) {
      if (ctrl.grid) {
        ctrl.grid.clearfilters();

        if (!CustomUtils.isUndefinedOrNull(value)) {
          var filtergroup = new $.jqx.filter();
          var filter_or_operator = 1;
          var filter = filtergroup.createfilter('stringfilter', value, 'equal');
          filtergroup.addfilter(filter_or_operator, filter);

          ctrl.grid.addfilter('sessionName', filtergroup);
          ctrl.grid.applyfilters();
        }
      }
    };

    ctrl.getSelection = function () {
      var abstracts = [];
      angular.forEach(ctrl.selectedIndexes, function (selectedIndex) {
        var abstract = abstractsDataAdapter.records[selectedIndex];
        abstracts.push(abstract);
      });
      return abstracts;
    };
  }
})();
