(function () {
  'use strict';

  angular.module('canceropoleGsoApp').directive('jhNotStartingWithNumber', function () {
    function link(scope, elem, attrs, ngModel) {
      ngModel.$parsers.push(function (viewValue) {
        if (viewValue && viewValue.match(/^[A-Za-z]\w*$/)) {
          return viewValue;
        } else if (viewValue.length === 0) {
          return viewValue;
        }

        // keep the model value as it is
        var transformedValue = ngModel.$modelValue;
        if (!transformedValue) {
          transformedValue = '';
        }
        ngModel.$setViewValue(transformedValue);
        ngModel.$render();
        return transformedValue;
      });
    }

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };
  });
})();
