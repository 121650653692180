(function () {
  'use strict';

  angular.module('canceropoleGsoApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('abstract-person', {
        parent: 'entity',
        url: '/abstract-person',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.abstractPerson.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/abstract-person/abstract-people.html',
            controller: 'AbstractPersonController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('abstractPerson');
              $translatePartialLoader.addPart('civilite');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }
          ]
        }
      })
      .state('abstract-person-detail', {
        parent: 'entity',
        url: '/abstract-person/{id}',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.abstractPerson.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/abstract-person/abstract-person-detail.html',
            controller: 'AbstractPersonDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('abstractPerson');
              $translatePartialLoader.addPart('civilite');
              return $translate.refresh();
            }
          ],
          entity: [
            '$stateParams',
            'AbstractPerson',
            function ($stateParams, AbstractPerson) {
              return AbstractPerson.get({ id: $stateParams.id }).$promise;
            }
          ]
        }
      })
      .state('abstract-person.new', {
        parent: 'abstract-person',
        url: '/new',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-person/abstract-person-dialog.html',
                controller: 'AbstractPersonDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: function () {
                    return {
                      firstName: null,
                      lastName: null,
                      position: null,
                      speaker: null,
                      civilite: null,
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-person', null, { reload: true });
                },
                function () {
                  $state.go('abstract-person');
                }
              );
          }
        ]
      })
      .state('abstract-person.edit', {
        parent: 'abstract-person',
        url: '/{id}/edit',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-person/abstract-person-dialog.html',
                controller: 'AbstractPersonDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: [
                    'AbstractPerson',
                    function (AbstractPerson) {
                      return AbstractPerson.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-person', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      })
      .state('abstract-person.delete', {
        parent: 'abstract-person',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/abstract-person/abstract-person-delete-dialog.html',
                controller: 'AbstractPersonDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                  entity: [
                    'AbstractPerson',
                    function (AbstractPerson) {
                      return AbstractPerson.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('abstract-person', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      });
  }
})();
