(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('PublicationDialogController', PublicationDialogController);

    PublicationDialogController.$inject = ['$timeout', '$scope', 'CustomUtils', '$uibModalInstance', 'DataUtils', 'entity', 'Publication', 'PublicationType', 'Person', 'Project', 'Society'];

    function PublicationDialogController($timeout, $scope, CustomUtils, $uibModalInstance, DataUtils, entity, Publication, PublicationType, Person, Project, Society) {
        var vm = this;

        vm.publication = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.publicationtypes = PublicationType.query();
        vm.people = Person.query();
        vm.projects = Project.query();
        vm.societies = Society.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (!CustomUtils.isEmpty(vm.publication.webSite)) {
                if (!vm.publication.webSite.startsWith("http")) {
                    vm.publication.webSite = "http://" + vm.publication.webSite;
                }
            }

            if (vm.publication.id !== null) {
                Publication.update(vm.publication, onSaveSuccess, onSaveError);
            } else {
                Publication.save(vm.publication, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('canceropoleGsoApp:publicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.pubDate = false;

        vm.setDocument = function ($file, publication) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        publication.document = base64Data;
                        publication.documentContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
