(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('organizations', {
                controller: 'OrganizationsController',
                bindings: {
                    teamId: '@?',
                    societyId: '@?',
                    canEdit: '@?'
                },
                templateUrl: 'app/components/organizations/organizations.html'
            });
})();
