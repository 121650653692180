(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('searchbar', {
                controller: 'SearchBarController',
                templateUrl: 'app/search/bar/searchbar.html'
            });
})();

