(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .filter('range', range);

    function range() {
        return rangeFilter;

        function rangeFilter(input, total) {
            total = parseInt(total);
            for (var i = 0; i < total; i++)
                input.push(i);
            return input;
        }
    }
})();
