(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('SheetMainEventAbstractsTabController', SheetMainEventAbstractsTabController);

  SheetMainEventAbstractsTabController.$inject = [
    '$scope',
    'DataUtils',
    '$stateParams',
    'JQWidgetsService',
    'CustomUtils',
    '$translate',
    '$filter',
    'AlertService',
    '$http',
    '$log',
    'Principal',
    'AbstractService',
    '$uibModal',
    'ConfirmDialog',
    'entity',
    'EventSession',
    '$state'
  ];

  function SheetMainEventAbstractsTabController(
    $scope,
    DataUtils,
    $stateParams,
    JQWidgetsService,
    CustomUtils,
    $translate,
    $filter,
    AlertService,
    $http,
    $log,
    Principal,
    AbstractService,
    $uibModal,
    ConfirmDialog,
    entity,
    EventSession,
    $state
  ) {
    if ($stateParams.tab !== 'abstracts') {
      //update state param
      $stateParams.tab = 'abstracts';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.Model = {
      id: $stateParams.id,
      entity: entity,
      editAbstractComment: false,
      editAssignCandidate: false,
      canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN'])
    };

    vm.selectedAbstract = null;
    vm.abstractGrid = null;

    vm.openAbstract = function (type) {
      var abstractIds = vm.getAbstractIdSelection();

      if (type === 'xls') {
        $uibModal.open({
          templateUrl: 'app/manifestation/dialog/export-abstracts.dialog.html',
          controller: 'ExportAbstractsDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            abstracts: function () {
              return abstractIds;
            }
          }
        });
      } else if (type === 'docx') {
        DataUtils.downloadFileFromPostUrl('api/event-abstracts/docx', abstractIds, 'Abstracts.docx');
      } else {
        DataUtils.downloadFileFromPostUrl('api/event-abstracts/pdf', abstractIds, 'Abstracts.pdf');
      }
    };

    vm.getAbstractsSelection = null;
    vm.isSingleSelection = function () {
      if (vm.hasSelection()) {
        vm.selectedAbstract = vm.getAbstractsSelection()[0];
        return vm.getAbstractsSelection().length === 1;
      }
      vm.selectedAbstract = null;
      return false;
    };

    vm.hasSelection = function () {
      if (vm.getAbstractsSelection != null) {
        var selection = vm.getAbstractsSelection();
        if (selection.length > 0) {
          return true;
        }
      }
      return false;
    };

    vm.getAbstractIdSelection = function () {
      var ids = [];
      var abstracts = vm.getAbstractsSelection();
      angular.forEach(abstracts, function (abstract) {
        ids.push(abstract.id);
      });
      return ids;
    };

    vm.openPrivateSpace = function () {
      var selections = vm.getAbstractsSelection();
      if (selections.length === 1) {
        var key = selections[0].registrationKey;
        $state.go('SubscriptionSpaceController', {
          id: vm.Model.id,
          key: key
        });
      }
    };

    vm.openAbstractSheet = function () {
      var selections = vm.getAbstractsSelection();
      if (selections.length === 1) {
        var abstractId = selections[0].id;
        $state.go('sheet.eventabstract', {
          id: abstractId
        });
      }
    };

    vm.cloneAbstracts = function () {
      var abstractIds = vm.getAbstractIdSelection();
      $http.post('api/event-abstracts/clone', abstractIds).then(function (result) {
        vm.abstractGrid.updatebounddata();
        angular.forEach(abstractIds, function (abstractId) {
          AlertService.success("L'abstract a été dupliqué " + abstractId);
        });
      });
    };

    vm.totalChartSources = [];
    vm.totalChartSettings = {
      source: new $.jqx.dataAdapter({
        localdata: vm.totalChartSources,
        autoBind: false
      }),
      title: 'Dépôt',
      description: '',
      backgroundColor: '#FAFAFA',
      localization: JQWidgetsService.getLocalization($translate.use()),
      padding: { left: 5, top: 5, right: 5, bottom: 5 },
      titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
      showToolTips: true,
      showLegend: true,
      colorScheme: 'scheme05',
      created: function (args) {
        vm.totalChart = args.instance;
        $scope.$watchCollection('vm.totalChartSources', function (newValues) {
          if (!CustomUtils.isUndefinedOrNull(newValues)) {
            var dataAdapter = new $.jqx.dataAdapter({
              localdata: newValues
            });
            vm.totalChartSettings.source = dataAdapter;
          }
        });
      },
      toolTipFormatFunction: function (value, itemIndex, serie) {
        return serie.displayText + ':' + value;
      },
      xAxis: {
        dataField: 'axe',
        displayText: 'Axe',
        showLabels: true,
        formatFunction: function (value) {
          if (angular.isString(value)) {
            if (value.length === 0) {
              value = 'Sans Axe';
            }
            if (value.indexOf('Axe') === -1) {
              value = $filter('limitTo')(value, 10) + '...';
            } else {
              value = $filter('limitTo')(value, 5);
            }
          }
          return value;
        },
        gridLines: { visible: true },
        flip: false
      },
      valueAxis: {
        formatFunction: function (value) {
          return value;
        }
      },
      seriesGroups: [
        {
          type: 'column',
          columnsMaxWidth: 50,
          series: [
            {
              dataField: 'O',
              displayText: 'Communication',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'P',
              displayText: 'Poster',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'OP',
              displayText: 'Communication & Poster',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'total',
              displayText: 'Total',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            }
          ]
        }
      ]
    };

    vm.finalTotalChartSources = [];
    vm.finalTotalChartSettings = {
      source: new $.jqx.dataAdapter({
        localdata: vm.finalTotalChartSources,
        autoBind: false
      }),
      title: 'Final',
      description: '',
      backgroundColor: '#FAFAFA',
      localization: JQWidgetsService.getLocalization($translate.use()),
      padding: { left: 5, top: 5, right: 5, bottom: 5 },
      titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
      showToolTips: true,
      showLegend: true,
      colorScheme: 'scheme05',
      created: function (args) {
        vm.finalTotalChart = args.instance;
        $log.info('Chart created');
        $scope.$watchCollection('vm.finalTotalChartSources', function (newValues) {
          if (!CustomUtils.isUndefinedOrNull(newValues)) {
            var dataAdapter = new $.jqx.dataAdapter({
              localdata: newValues
            });
            vm.finalTotalChartSettings.source = dataAdapter;
          }
        });
      },
      toolTipFormatFunction: function (value, itemIndex, serie) {
        return serie.displayText + ':' + value;
      },
      xAxis: {
        dataField: 'axe',
        displayText: 'Axe',
        showLabels: true,
        formatFunction: function (value) {
          if (angular.isString(value)) {
            if (value.length === 0) {
              value = 'Sans Axe';
            }
            if (value.indexOf('Axe') === -1) {
              value = $filter('limitTo')(value, 10) + '...';
            } else {
              value = $filter('limitTo')(value, 5);
            }
          }
          return value;
        },
        gridLines: { visible: true },
        flip: false
      },
      valueAxis: {
        formatFunction: function (value) {
          return value;
        }
      },
      seriesGroups: [
        {
          type: 'column',
          columnsMaxWidth: 50,
          series: [
            {
              dataField: 'O',
              displayText: 'Communication',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'P',
              displayText: 'Poster',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'A',
              displayText: 'Pas de communication',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'I',
              displayText: 'Invité',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            },
            {
              dataField: 'total',
              displayText: 'Total',
              labels: {
                visible: true,
                verticalAlignment: 'top',
                offset: { x: 0, y: -20 }
              }
            }
          ]
        }
      ]
    };

    vm.tempComments = '';
    vm.cancelComments = function () {
      vm.tempComments = '';
      vm.Model.editAbstractComment = false;
    };
    vm.editComments = function () {
      vm.tempComments = vm.selectedAbstract.comments;
      vm.Model.editAbstractComment = true;
    };
    vm.saveComments = function () {
      var post = {
        id: vm.selectedAbstract.id,
        type: 'comments',
        value: vm.tempComments
      };

      $http.post('api/event-abstract/comment', post).then(function () {
        vm.selectedAbstract.comments = vm.tempComments;
        vm.Model.editAbstractComment = false;
        vm.abstractGrid.updatebounddata();
      });
    };

    vm.tempAssignCandidate = '';
    vm.cancelAssignCandidate = function () {
      vm.tempAssignCandidate = '';
      vm.Model.editAssignCandidate = false;
    };
    vm.editAssignCandidate = function () {
      vm.tempAssignCandidate = vm.selectedAbstract.assignCandidate;
      vm.Model.editAssignCandidate = true;
    };
    vm.saveAssignCandidate = function () {
      var post = {
        id: vm.selectedAbstract.id,
        type: 'assign',
        value: vm.tempAssignCandidate
      };

      $http.post('api/event-abstract/comment', post).then(function () {
        vm.selectedAbstract.assignCandidate = vm.tempAssignCandidate;
        vm.Model.editAssignCandidate = false;
        vm.abstractGrid.updatebounddata();
      });
    };

    vm.openAutomaticRef = function () {
      var abstracts = vm.getAbstractsSelection();
      $uibModal
        .open({
          templateUrl: 'app/sheet/mainevent/dialog/auto-abstract-ref.edit.dialog.html',
          controller: 'AutoAbstractRefDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            abstracts: function () {
              return abstracts;
            }
          }
        })
        .result.then(function () {
          vm.abstractGrid.updatebounddata();
        });
    };

    vm.openBooklet = function () {
      var abstractIds = vm.getAbstractIdSelection();
      DataUtils.downloadFileFromPostUrl('api/event-abstracts/booklet/pdf', abstractIds, 'Index.doc');
    };
  }
})();
