(function () {
  'use strict';

  angular.module('canceropoleGsoApp').directive('capitalized', capitalized);

  function capitalized() {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(function (input) {
          return input ? input.toCapitalize() : '';
        });
        element.css('text-transform', 'capitalize');
      }
    };
  }
})();
