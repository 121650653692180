(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventRegistrationMailDeleteController',EventRegistrationMailDeleteController);

    EventRegistrationMailDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventRegistrationMail'];

    function EventRegistrationMailDeleteController($uibModalInstance, entity, EventRegistrationMail) {
        var vm = this;

        vm.eventRegistrationMail = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EventRegistrationMail.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
