(function () {
    'use strict';

    angular.module('canceropoleGsoApp').factory('GeoService', GeoService);

    function GeoService(CustomUtils) {
        var iconsMarkers = {};

        var service = {
            toPointWkt: toPointWkt,
            lngLatFromWkt: lngLatFromWkt,
            iconMarker: iconMarker,
            initIconsMarkers: initIconsMarkers
        };

        return service;

        function toPointWkt(lng, lat) {
            return 'POINT(' + lng + ' ' + lat + ')';
        }

        function lngLatFromWkt(wktPoint) {
            var re = /(-?\d+.?-?\d+)/g;
            var result = re.exec(wktPoint);

            if (result) {
                var lng = parseFloat(result[0]);
                result = re.exec(wktPoint);
                if (result) {
                    var lat = parseFloat(result[0]);
                    return {
                        lng: lng,
                        lat: lat
                    };
                }

            }
            return null;
        }

        function iconMarker(type) {
            if (!CustomUtils.isUndefinedOrNull(type)) {
                if (iconsMarkers.hasOwnProperty(type)) {
                    return iconsMarkers[type];
                }

                iconsMarkers[type] = {
                    iconUrl: 'content/images/markers/' + type + '.png',
                    iconSize: [30, 49],
                    iconAnchor: [15, 49],
                    shadowUrl: 'content/images/marker_shadow.png',
                    shadowSize: [41, 49],
                    shadowAnchor: [12, 49]
                };
                return iconsMarkers[type];
            }

            return {};
        }

        function initIconsMarkers(types) {
            if (!CustomUtils.isUndefinedOrNull(types)) {
                if (!angular.isArray(types)) {
                    types = [types];
                }

                for (var i = 0; i < types.length; i++) {
                    var type = types[i];

                    if (!iconsMarkers.hasOwnProperty(type)) {
                        iconsMarkers[type] = {
                            iconUrl: 'assets/images/markers/' + type + '.png',
                            iconSize: [30, 49],
                            iconAnchor: [15, 49],
                            shadowUrl: 'assets/images/marker_shadow.png',
                            shadowSize: [41, 49],
                            shadowAnchor: [12, 49]
                        };
                    }
                }
            }
            return iconsMarkers;
        }
    }
})();
