(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('StatusEditDialogController', StatusEditDialogController);

    StatusEditDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'status', '$log', 'CustomUtils', 'PersonStatus', 'AlertService'];

    function StatusEditDialogController($translate, $translatePartialLoader, $uibModalInstance, status, $log, CustomUtils, PersonStatus, AlertService) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.personStatus = status;

        ctrl.title = "Ajouter un statut";
        if (!CustomUtils.isUndefinedOrNull(ctrl.personStatus)) {
            ctrl.title = "Modifier le statut " + ctrl.personStatus.name;
        }

        ctrl.close = function (result) {
            $uibModalInstance.close(result);
        };

        ctrl.showErrors = function () {
            AlertService.error("Problème lors de la sauvegarde en base");
        };


        ctrl.ok = function () {
            if (ctrl.personStatus.id) {
                PersonStatus.update(ctrl.personStatus, ctrl.close, ctrl.showErrors);
            } else {
                PersonStatus.save(ctrl.personStatus, ctrl.close, ctrl.showErrors);
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();