(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectFormDetailController', CallForProjectFormDetailController);

    CallForProjectFormDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'CallForProjectForm', 'CallForProject', 'CallForProjectFormField'];

    function CallForProjectFormDetailController($scope, $rootScope, $stateParams, DataUtils, entity, CallForProjectForm, CallForProject, CallForProjectFormField) {
        var vm = this;

        vm.callForProjectForm = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectFormUpdate', function(event, result) {
            vm.callForProjectForm = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
