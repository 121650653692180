(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('AddCallForProjectFormFieldDialogController', AddCallForProjectFormFieldDialogController);

    AddCallForProjectFormFieldDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'formField', 'JQWidgetsService', '$log', 'CallForProjectFormField', 'CustomUtils', 'groups', 'DateUtils', '$scope'];

    function AddCallForProjectFormFieldDialogController($translate, $translatePartialLoader, $uibModalInstance, formField, JQWidgetsService, $log, CallForProjectFormField, CustomUtils, groups, DateUtils, $scope) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.isSaving = false;
        ctrl.groups = groups;
        //callForProjectFormGroupId

        ctrl.formField = formField;

        ctrl.numberConfig = {
            min: 0,
            max: 10
        };
        ctrl.multipleChoiseConfig = {
            values: null
        };

        ctrl.yesNoLabels = {
            yes: 'Yes',
            no: 'No'
        };

        ctrl.legendEditorSettings = {
            tools: 'bold italic underline | color | left center right | outdent indent | ul ol | link | clean | html',
            pasteMode: 'text',
            height: 150
        };

        ctrl.textMaxLength = false;
        if (!CustomUtils.isUndefinedOrNull(ctrl.formField.maxLength)) {
            ctrl.textMaxLength = true;
        }

        if (!CustomUtils.isUndefinedOrNull(ctrl.formField) && !CustomUtils.isUndefinedOrNull(ctrl.formField.config)) {
            if (ctrl.formField.type === 'checkbox' || ctrl.formField.type === 'combobox' || ctrl.formField.type === 'radio') {
                var strValues = "";
                angular.forEach(ctrl.formField.config.values, function (item, index) {
                    if (index > 0) {
                        strValues += ";";
                    }
                    strValues += item.label;
                });

                ctrl.multipleChoiseConfig = {
                    values: strValues
                };
            } else if (ctrl.formField.type === 'number' || ctrl.formField.type === 'onlyNumber') {
                if (angular.isString(ctrl.formField.config)) {
                    ctrl.numberConfig = JSON.parse(ctrl.formField.config);
                }
            } else if (ctrl.formField.type === 'ifYes' || ctrl.formField.type === 'ifNo' || ctrl.formField.type === 'yesNo') {
                if (angular.isString(ctrl.formField.config)) {
                    ctrl.yesNoLabels = JSON.parse(ctrl.formField.config);
                }
                if (angular.isDefined(ctrl.formField.config)) {
                    ctrl.yesNoLabels = ctrl.formField.config;
                }
            }
        }

        ctrl.ok = function () {
            ctrl.isSaving = true;

            //label for empty field type
            if (ctrl.formField.type === 'empty') {
                ctrl.formField.label = ctrl.formField.criterionLabel;
            }

            if (ctrl.textMaxLength === false) {
                if (!CustomUtils.isUndefinedOrNull(ctrl.formField.maxLength)) {
                    delete ctrl.formField.maxLength;
                }
            }

            //name
            ctrl.formField.name = CustomUtils.removeSpecialChars(ctrl.formField.label);
            ctrl.formField.name += "_" + ctrl.formField.callForProjectFormGroupId;

            //config
            if (ctrl.formField.type === 'number') {
                ctrl.formField.config = JSON.stringify(ctrl.numberConfig);
            } else if (ctrl.formField.type === 'combobox' || ctrl.formField.type === 'checkbox' || ctrl.formField.type === 'radio') {
                var values = ctrl.multipleChoiseConfig.values.split(';');
                var finalValues = [];
                angular.forEach(values, function (label) {
                    label = label.trim();
                    var value = CustomUtils.removeSpaces(label);
                    value = CustomUtils.removeQuotes(value);
                    if (!CustomUtils.isEmpty(value)) {
                        finalValues.push({
                            value: value,
                            label: label
                        });
                    }
                });
                ctrl.multipleChoiseConfig.values = finalValues;
                ctrl.formField.config = JSON.stringify(ctrl.multipleChoiseConfig);
                if (!CustomUtils.isEmpty(ctrl.formField.defaultValue)) {
                    ctrl.formField.defaultValue = ctrl.formField.defaultValue.trim();
                }
            } else if (ctrl.formField.type === 'date') {
                if (ctrl.formField.defaultValue) {
                    var dateString = ctrl.formField.defaultValue.split('/');
                    ctrl.formField.defaultValue = new Date(dateString[2], dateString[1] - 1, dateString[0]);
                    ctrl.formField.defaultValue = DateUtils.convertLocalDateToServer(ctrl.formField.defaultValue);
                }
            } else if (ctrl.formField.type === 'ifYes' || ctrl.formField.type === 'ifNo' || ctrl.formField.type === 'yesNo') {
                ctrl.formField.config = JSON.stringify(ctrl.yesNoLabels);
            } else if (ctrl.formField.type === 'table') {
                _.each(ctrl.formField.config.cols, function (col) {
                    CustomUtils.removeObjectKeys(col, function (key) {
                        return key.startsWith('$$');
                    });
                });
                ctrl.formField.config = JSON.stringify(ctrl.formField.config);
            } else if (ctrl.formField.type === 'empty') {
                ctrl.formField.required = false;
            }

            if (ctrl.formField.criterionType === 'number' || ctrl.formField.criterionType === 'onlyNumber') {
                ctrl.formField.criterionConfig = JSON.stringify(ctrl.formField.criterionConfig);
            }

            if (ctrl.formField.id) {
                CallForProjectFormField.update(ctrl.formField, onSaveSuccess, onSaveError);
            } else {
                CallForProjectFormField.save(ctrl.formField, onSaveSuccess, onSaveError);
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError() {
            ctrl.isSaving = false;
        }

        ctrl.typeSource = [{
            label: $translate.instant('canceropoleGsoApp.FormFieldType.text'),
            value: 'text'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.editor'),
            value: 'editor'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.textarea'),
            value: 'textarea'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.combobox'),
            value: 'combobox'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.checkbox'),
            value: 'checkbox'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.radio'),
            value: 'radio'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.date'),
            value: 'date'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.number'),
            value: 'number'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.yesNo'),
            value: 'yesNo'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.ifYes'),
            value: 'ifYes'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.ifNo'),
            value: 'ifNo'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.table'),
            value: 'table'
        }, {
            label: $translate.instant('canceropoleGsoApp.FormFieldType.empty'),
            value: 'empty'
        }];
        ctrl.typeSource.sort(function (type1, type2) {
            var label1 = type1.label.toLowerCase();
            var label2 = type2.label.toLowerCase();
            return label1.localeCompare(label2);
        });

        ctrl.typeSettings = {
            width: 300,
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.typeSource,
            change: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                }
            }
        };

        ctrl.criterionTypeSource = [{
            label: $translate.instant('canceropoleGsoApp.CriterionType.number'),
            value: 'number'
        }, {
            label: $translate.instant('canceropoleGsoApp.CriterionType.yesNo'),
            value: 'yesNo'
        }, {
            label: $translate.instant('canceropoleGsoApp.CriterionType.onlyComment'),
            value: 'onlyComment'
        }, {
            label: $translate.instant('canceropoleGsoApp.CriterionType.onlyNumber'),
            value: 'onlyNumber'
        }, {
            label: $translate.instant('canceropoleGsoApp.CriterionType.onlyYesNo'),
            value: 'onlyYesNo'
        }];
        ctrl.criterionTypeSource.sort(function (type1, type2) {
            var label1 = type1.label.toLowerCase();
            var label2 = type2.label.toLowerCase();
            return label1.localeCompare(label2);
        });

        ctrl.criterionTypeSettings = {
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: 'label',
            valueMember: 'value',
            source: ctrl.criterionTypeSource,
            change: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    if (event.args.item) {
                        var item = event.args.item;
                        if (item.value === 'number' || item.value === 'onlyNumber') {
                            if (angular.isUndefined(ctrl.formField.criterionConfig)) {
                                ctrl.formField.criterionConfig = {
                                    max: 10,
                                    step: 0.1
                                };
                            }
                        } else {
                            ctrl.formField.criterionConfig = null;
                        }
                    }
                }
            }
        };

        ctrl.groupSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.groups,
            change: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                }
            }
        };

        ctrl.dateSettings = {
            culture: 'fr-FR',
            width: '220px',
            height: '25px'
        };

        if (!_.isNil(ctrl.formField.criterionConfig) && !_.isObject(ctrl.formField.criterionConfig)) {
            ctrl.formField.criterionConfig = JSON.parse(ctrl.formField.criterionConfig);
            if (_.isNumber(ctrl.formField.criterionConfig)) {
                ctrl.formField.criterionConfig = {
                    max: ctrl.formField.criterionConfig,
                    step: 1
                };
            }
        }
    }
})();
