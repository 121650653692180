(function () {
    'use strict';

    angular.module('canceropoleGsoApp').factory('GeocoderDataGouv', GeocoderDataGouv);

    function GeocoderDataGouv($http) {
        var service = {
            geocode: geocode
        };

        return service;

        function geocode(address, outerCallback) {
            $http.get('https://api-adresse.data.gouv.fr/search/?q=' + address)
                    .success(function (response) {
                        var coordinate = {};
                        var err = null;
                        var success = true;

                        if (response.features.length === 0) {
                            success = false;
                            err = new Error('Address cannot be fount');
                        } else {
                            var coordinates = response.features[0].geometry.coordinates;
                            var lng = coordinates[0];
                            var lat = coordinates[1];

                            coordinate = {
                                lng: lng,
                                lat: lat
                            };
                        }

                        outerCallback({
                            success: success,
                            err: err,
                            coordinate: coordinate
                        });
                    })
                    .error(function () {
                        outerCallback({
                            success: false,
                            err: new Error('Geocode was not successful'),
                            results: null
                        });
                    });
        }
    }
})();

