(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LinkSubscriptionSocietyDialogController', LinkSubscriptionSocietyDialogController);

    LinkSubscriptionSocietyDialogController.$inject = ['$uibModalInstance', '$log', 'SocietySearch', 'toSearch'];

    function LinkSubscriptionSocietyDialogController($uibModalInstance, $log, SocietySearch, toSearch) {

        var ctrl = this;

        ctrl.toSearch = toSearch;
        ctrl.society = null;
        ctrl.result = null;

        var societySource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: ctrl.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    ctrl.result = result;
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
                ctrl.societyComboBox.val(ctrl.toSearch);
                ctrl.societyComboBoxSettings.search(ctrl.toSearch);
            }
        };

        ctrl.onSelect = function (society) {
            ctrl.society = society;
        };

        ctrl.ok = function () {
            $uibModalInstance.close({
                id: ctrl.society.id,
                name: ctrl.society.name,
                city: ctrl.society.city
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();