/* globals L, _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetWindowMap', SheetWindowMap);

  SheetWindowMap.$inject = ['$scope', 'GeocoderDataGouv', 'AlertService', '$log', 'GeoService', '$translate', '$translatePartialLoader'];

  function SheetWindowMap($scope, GeocoderDataGouv, AlertService, $log, GeoService, $translate, $translatePartialLoader) {
    $translatePartialLoader.addPart('map');
    $translate.refresh();

    var that = this;

    this.defaultPosition = {
      lng: 1.432632,
      lat: 43.600865,
      zoom: 9
    };

    this.position = this.defaultPosition;

    if (this.panelTitle === null) {
      this.panelTitle = 'map.window.title';
    }

    this.underEdition = false;

    this.load = function () {
      if (that.entity) {
        that.searchAddress = '';
        if (that.entity.address) {
          that.searchAddress = that.entity.address;
        }
        if (that.entity.cp) {
          if (that.searchAddress.length > 0) {
            that.searchAddress += ' - ';
          }
          that.searchAddress += that.entity.cp;
        }
        if (that.entity.city) {
          if (that.searchAddress.length > 0) {
            that.searchAddress += ' - ';
          }
          that.searchAddress += that.entity.city;
        }
        if (that.entity.lat) {
          var lng = parseFloat(that.entity.lng);
          var lat = parseFloat(that.entity.lat);
          that.setCenter(lng, lat);
          that.setMarker(lng, lat, that.markerMessage);
        } else {
          that.setMarker(that.defaultPosition.lng, that.defaultPosition.lat);
        }
      }
    };

    $scope.$watch('$ctrl.entity', that.load, true);

    this.edit = function () {
      this.underEdition = true;
      that.load();
    };

    this.saveEdition = function () {
      this.entity.lng = this.lng;
      this.entity.lat = this.lat;
      if (this.saveAction) {
        this.saveAction(this.winId);
      }
      this.underEdition = false;
    };

    this.cancelEdition = function () {
      that.load();
      if (this.cancelAction) {
        this.cancelAction(this.winId);
      }
      this.underEdition = false;
    };

    this.searchLocalization = function () {
      GeocoderDataGouv.geocode(this.searchAddress, function (result) {
        if (result.success) {
          var coordinate = result.coordinate;
          var lng = coordinate.lng;
          var lat = coordinate.lat;

          if (lng !== null && lat !== null) {
            that.lng = lng;
            that.lat = lat;
            that.setMarker(lng, lat);
            that.setCenter(lng, lat);
          }
        } else {
          AlertService.warning('sheet.no.result');
        }
      });
    };

    this.setMarker = function (lng, lat, message) {
      this.markerGroup.clearLayers();

      var marker = L.marker([lat, lng], {
        icon: L.icon(GeoService.iconMarker(this.type)),
        message: message,
        draggable: this.underEdition
      });
      if (this.underEdition) {
        marker.on('dragend', function (e) {
          var position = this.getLatLng();
          that.lng = position.lng;
          that.lat = position.lat;
        });
      }

      marker.addTo(this.markerGroup);
    };

    this.setCenter = function (lng, lat, zoom) {
      that.map.setView([lat, lng], zoom);
    };

    this.map = L.map('map', {
      maxZoom: 18
    }).setView([this.position.lat, this.position.lng], this.position.zoom);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    this.markerGroup = L.markerClusterGroup();
    this.map.addLayer(this.markerGroup);
  }
})();
