(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddCallForProjectFormGroupDialogController', AddCallForProjectFormGroupDialogController);

    AddCallForProjectFormGroupDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'formGroup', 'JQWidgetsService', '$log', 'CallForProjectFormGroup', 'existingGroups', 'CustomUtils', '$http'];

    function AddCallForProjectFormGroupDialogController($translate, $translatePartialLoader, $uibModalInstance, formGroup, JQWidgetsService, $log, CallForProjectFormGroup, existingGroups, CustomUtils, $http) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.isSaving = false;

        ctrl.formGroup = formGroup;
        ctrl.existingGroups = existingGroups;
        ctrl.maxPosition = ctrl.existingGroups.length;

        if (CustomUtils.isUndefinedOrNull(ctrl.formGroup.id)) {
            ctrl.maxPosition++;
        }

        if (CustomUtils.isUndefinedOrNull(ctrl.existingGroups)) {
            ctrl.existingGroups = [];
        }

        ctrl.groupTypeSource = [{
                label: 'Bleu',
                value: 'PRIMARY'
            }, {
                label: 'Vert',
                value: 'SUCCESS'
            }, {
                label: 'Bleu ciel',
                value: 'INFO'
            }, {
                label: 'Jaune',
                value: 'WARNING'
            }, {
                label: 'Rouge',
                value: 'DANGER'
            }];


        ctrl.ok = function () {
            ctrl.isSaving = true;

            //insertion dans la liste des groupes si creation
            var toSend = [];
            if (CustomUtils.isUndefinedOrNull(ctrl.formGroup.id)) {
                //creation
                ctrl.existingGroups.splice(ctrl.formGroup.position - 1, 0, ctrl.formGroup);
                for (var i = 0; i < ctrl.existingGroups.length; i++) {
                    var existingGroup = ctrl.existingGroups[i];
                    existingGroup.position = i + 1;
                }
                toSend = ctrl.existingGroups;
            } else {

                //update
                toSend = [];
                for (var i = 0; i < ctrl.existingGroups.length; i++) {
                    var existingGroup = ctrl.existingGroups[i];
                    if (existingGroup.id !== ctrl.formGroup.id) {
                        toSend.push(existingGroup);
                    }
                }
                for (var i = 0; i < toSend.length; i++) {
                    var existingGroup = toSend[i];
                    existingGroup.position = i + 1;
                }

                toSend.splice(ctrl.formGroup.position - 1, 0, ctrl.formGroup);

                for (var i = 0; i < toSend.length; i++) {
                    var existingGroup = toSend[i];
                    existingGroup.position = i + 1;
                }
            }

            angular.forEach(ctrl.existingGroups, function (group) {
                $log.info("group=" + group.id + ", position = " + group.position);
            });

            $log.info("formGroups=", ctrl.formGroup);

            $http.put("api/call-for-projects/groups/createOrUpdate", toSend).then(onSaveSuccess, onSaveError);

        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError() {
            ctrl.isSaving = false;
        }

        ctrl.groupTypeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.groupTypeSource,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            }
        };

    }
})();
