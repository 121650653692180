(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .controller('SchedulerController', SchedulerController);
    SchedulerController.$inject = ['$scope', '$translate', '$translatePartialLoader', '$state', 'JQWidgetsService'];
    function SchedulerController($scope, $translate, $translatePartialLoader, $state, JQWidgetsService) {

        $translatePartialLoader.addPart('scheduler');
        $translate.refresh();

        if (!this.panelType) {
            this.panelType = "default";
        }

        angular.extend(this.settings, {
            localization: JQWidgetsService.getLocalization($translate.use()),
            editDialog: false,
            editDialogCreate: function (event) {
                var dialog = event.args.dialog;
                var fields = event.args.fields;
                var appointment = event.args.appointment;
                // hide repeat option
                fields.repeatContainer.hide();
                // hide status option
                fields.statusContainer.hide();
                // hide timeZone option
                fields.timeZoneContainer.hide();
                // hide color option
                fields.colorContainer.hide();
                // hide description option
                fields.descriptionContainer.hide();
            }
        });

        $scope.$on('contextMenuItemClick', function (firstArg, menu) {
            var args = menu.args;
            switch (args.item.id) {
                case "open":
                    var appointment = args.appointment;
                    if (appointment !== null) {
                        var id = appointment.id;
                        var name = appointment.subject;
                        $state.go('sheet', {
                            type: 'mainevent',
                            id: id,
                            name: name
                        });
                    }
                    return true;
                case "manage":
                    var appointment = args.appointment;
                    if (appointment !== null) {
                        var id = appointment.id;
                        $state.go('manifestation', {
                            event: id
                        });
                    }
                    return true;
            }
        });
        $scope.$on('contextMenuOpen', function (firstArg, event) {
            var menu = event.args.menu;
            menu.jqxMenu('hideItem', 'createAppointment');
            menu.jqxMenu('hideItem', 'editAppointment');
            if (event.args.appointment) {
                menu.jqxMenu('showItem', 'open');
                menu.jqxMenu('showItem', 'manage');
            } else {
                menu.jqxMenu('hideItem', 'open');
                menu.jqxMenu('hideItem', 'manage');
            }
        });
        $scope.$on('contextMenuCreate', function (firstArg, event) {
            var source = event.args.settings.source;
            source.push({
                id: "open",
                label: $translate.instant("scheduler.contextmenu.gotosheet")
            });
            source.push({
                id: "manage",
                label: $translate.instant("scheduler.contextmenu.manage")});
        });
        /*
         * rafraichissement du calendrier lorsque l'on change la langue.
         * ajoute des comportements non voulu
         * var that = this;
         this.settings.ready = function () {
         $rootScope.$on('$translateChangeSuccess', function (event, value) {
         console.log("Use language " + value.language);

         var settings = that.settings;
         console.log(settings);

         settings.localization = schedulerTranslation[value.language];
         if (settings.refresh) {
         settings.refresh('localization');
         settings.apply('render');
         }
         });

         };*/
    }
})();