(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('formField', {
                controller: 'FormFieldController',
                bindings: {
                    groups: '<',
                    field: '<',
                    editable: '<',
                    readOnly: '<?',
                    showRequired: '=',
                    showEvaluation: '<?',
                    editEvaluation: '<?'
                },
                templateUrl: 'app/components/form/callforproject/form-field.html'
            });
})();
