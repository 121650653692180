(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('FooterController', FooterController);

    FooterController.$inject = ['EscapeService', '$log'];

    function FooterController(EscapeService, $log) {
        var ctrl = this;

        EscapeService.getProperty("footer").then(function (param) {
            ctrl.footer = param;
        });
    }
})();