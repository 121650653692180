/* global _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('ManifestationAbstractController', ManifestationAbstractController);

  ManifestationAbstractController.$inject = [
    '$stateParams',
    '$log',
    'AlertService',
    'previousState',
    'DataUtils',
    'mainEventEntity',
    'eventAbstractEntity',
    'sessionEntities',
    '$filter',
    '$uibModal',
    '$state',
    '$scope',
    'CustomUtils',
    'ModelConfig',
    'ConfirmDialog',
    '$q',
    'CharactersDialog',
    '$timeout',
    'AbstractService',
    'Principal',
    '$translate',
    '$http'
  ];

  function ManifestationAbstractController(
    $stateParams,
    $log,
    AlertService,
    previousState,
    DataUtils,
    mainEventEntity,
    eventAbstractEntity,
    sessionEntities,
    $filter,
    $uibModal,
    $state,
    $scope,
    CustomUtils,
    ModelConfig,
    ConfirmDialog,
    $q,
    CharactersDialog,
    $timeout,
    AbstractService,
    Principal,
    $translate,
    $http
  ) {
    var vm = this;
    vm.mainEvent = mainEventEntity;
    vm.axes = vm.mainEvent.axes;
    vm.daySessions = sessionEntities;

    vm.sessions = [];
    vm.nbMaxCharacters = 2000; //default value
    if (vm.mainEvent.maxAbstractChar) {
      vm.nbMaxCharacters = vm.mainEvent.maxAbstractChar;
    }

    vm.people = [];
    vm.abstractSocieties = [];

    vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);

    vm.confirmPreview = false;

    //pour savoir si la sauvegarde est en cours.
    vm.isSaving = false;

    angular.forEach(vm.daySessions, function (daySession) {
      angular.forEach(daySession.eventSessions, function (eventSession) {
        if (eventSession.sessionType === ModelConfig.eventSession.sessionType.normal) {
          vm.sessions.push(eventSession);
        }
      });
    });
    vm.sessions.sort(function (s1, s2) {
      var date1 = s1.startDate.getTime();
      var date2 = s2.startDate.getTime();
      return date1 - date2;
    });

    vm.oralCommunicationIsOpen = function (withoutAdmin) {
      return AbstractService.oralCommunicationDepositIsOpen(vm.mainEvent, withoutAdmin);
    };

    vm.posterIsOpen = function (withoutAdmin) {
      return AbstractService.posterDepositIsOpen(vm.mainEvent, withoutAdmin);
    };

    vm.canEdit = function () {
      if (vm.isAdmin) {
        return true;
      }
      if (vm.eventAbstract.type === 'P') {
        return vm.posterIsOpen();
      } else if (vm.eventAbstract.type === 'O') {
        return vm.oralCommunicationIsOpen();
      } else if (vm.eventAbstract.type === 'OP') {
        return vm.oralCommunicationIsOpen() && vm.posterIsOpen();
      }
      return false;
    };

    vm.canShowPreview = function () {
      if (!vm.isSaving) {
        if (vm.abstractForm.$valid) {
          return true;
        } else if (vm.abstractForm.$error && vm.abstractForm.$error.required) {
          var requiredTab = vm.abstractForm.$error.required;
          for (var key in requiredTab) {
            var required = requiredTab[key];
            if (required.$name !== 'confirmPreview') {
              return false;
            }
          }
          return true;
        }
      }

      return false;
    };

    vm.checkSpeakers = function () {
      var nbSpeekers = 0;
      angular.forEach(vm.people, function (person) {
        if (person.speaker) {
          nbSpeekers++;
        }
      });
      if (nbSpeekers !== 1) {
        alert('One and only one speaker is allowed');
        return false;
      }
      return true;
    };

    vm.canSave = function () {
      if (vm.canEdit()) {
        if (!vm.abstractForm.$invalid && !vm.isSaving) {
          return true;
        }
      }
      return false;
    };

    var pChoise = {
      text: $translate.instant('canceropoleGsoApp.AbstractType.P'),
      value: 'P'
    };
    var oChoise = {
      text: $translate.instant('canceropoleGsoApp.AbstractType.O'),
      value: 'O'
    };
    var opChoise = {
      text: $translate.instant('canceropoleGsoApp.AbstractType.OP'),
      value: 'OP'
    };
    var inviteChoise = {
      text: $translate.instant('canceropoleGsoApp.AbstractType.I'),
      value: 'I'
    };

    //initialisation de la liste de type de communication
    //pour un administrateur, peut importe la date.
    var defaultType = 'OP';
    vm.posterOrOralSource = [];
    if (vm.isAdmin) {
      if (vm.mainEvent.hasPoster) {
        vm.posterOrOralSource.push(pChoise);
        defaultType = 'P';
      }
      if (vm.mainEvent.hasOral) {
        vm.posterOrOralSource.push(oChoise);
        defaultType = 'O';
      }
      if (vm.mainEvent.hasOral && vm.mainEvent.hasPoster) {
        vm.posterOrOralSource.push(opChoise);
        defaultType = 'OP';
      }
      vm.posterOrOralSource.push(inviteChoise);
    } else {
      if (vm.posterIsOpen()) {
        vm.posterOrOralSource.push(pChoise);
        defaultType = 'P';
      }
      if (vm.oralCommunicationIsOpen()) {
        vm.posterOrOralSource.push(oChoise);
        defaultType = 'O';
      }
      if (vm.posterIsOpen() && vm.oralCommunicationIsOpen()) {
        vm.posterOrOralSource.push(opChoise);
        defaultType = 'OP';
      }
    }

    if (eventAbstractEntity === null) {
      vm.eventAbstract = {
        id: null,
        registrationKey: Number($stateParams.key),
        abstractText: '',
        type: defaultType
      };
      vm.creation = true;
    } else {
      //initialisation pour modification
      vm.creation = false;
      vm.eventAbstract = eventAbstractEntity.eventAbstract;
      vm.people = eventAbstractEntity.abstractPeople;

      angular.forEach(vm.people, function (person) {
        person.abstractSocieties = [];
        angular.forEach(eventAbstractEntity.abstractSocieties, function (society) {
          if (person.id === society.abstractPersonId) {
            person.abstractSocieties.push(society);
          }
        });
      });

      $log.info(vm.eventAbstract);
      $log.info(vm.people);
      $log.info(vm.abstractSocieties);
    }

    $scope.$watchCollection('vm.people', function (peoples) {
      var atLeastOne = false;
      for (var i = 0; i < peoples.length; i++) {
        var person = peoples[i];
        if (person.speaker === true) {
          atLeastOne = true;
          break;
        }
      }
      vm.abstractForm.$setValidity('peopleCount', atLeastOne);
    });

    $scope.$watch('vm.eventAbstract.abstractText', function (newValue) {
      if (!CustomUtils.isEmpty(newValue)) {
        vm.nbCharacters = CustomUtils.countCharInHtmlString(newValue);
      } else {
        vm.nbCharacters = 0;
      }
      vm.abstractForm.$setValidity('nbCharacters', vm.nbCharacters > 0);
      vm.abstractForm.$setValidity('nbMaxCharacters', vm.nbCharacters <= vm.nbMaxCharacters);
    });

    vm.posterOrOralSettings = {
      source: vm.posterOrOralSource,
      displayMember: 'text',
      valueMember: 'value',
      autoDropDownHeight: true,
      created: function (args) {},
      select: function (event) {
        var args = event.args;
        if (args && args.item) {
          var item = args.item;
          var value = item.value;

          if (value === 'P') {
            vm.eventAbstract.sessionId = null;
          }
        }
      }
    };

    vm.sessionSettings = {
      source: vm.sessions,
      displayMember: 'name',
      valueMember: 'id',
      autoDropDownHeight: true,
      renderer: function (index, label, value) {
        var session = vm.sessions[index];
        var startDate = $filter('date')(session.startDate, 'dd/MM/yyyy HH:mm');
        return startDate + ' - ' + label;
      },
      created: function () {
        if (vm.sessions.length === 1) {
          vm.eventAbstract.sessionId = vm.sessions[0].id;
        }
      }
    };

    var displayMember = 'name';
    if (vm.mainEvent.lang === 'en') {
      displayMember = 'nameEn';
    }

    vm.axeSettings = {
      source: vm.axes,
      autoDropDownHeight: true,
      displayMember: displayMember,
      valueMember: 'id',
      created: function () {
        if (vm.axes.length === 1) {
          vm.eventAbstract.axeId = vm.axes[0].id;
        }
      }
    };

    vm.getAxeLabel = function () {
      for (var i = 0; i < vm.axes.length; i++) {
        var axe = vm.axes[i];
        if (axe.id === vm.eventAbstract.axeId) {
          return axe[displayMember];
        }
      }
      return '';
    };

    vm.getSessionLabel = function () {
      for (var i = 0; i < vm.sessions.length; i++) {
        var session = vm.sessions[i];
        if (session.id === vm.eventAbstract.sessionId) {
          return session.name;
        }
      }
      return '';
    };

    vm.openPersonDialog = function (person) {
      $uibModal
        .open({
          templateUrl: 'app/manifestation/abstract/dialog/abstract.dialog.html',
          controller: 'AbstractDialogController',
          controllerAs: 'ctrl',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            existingSocieties: function () {
              return vm.abstractSocieties;
            },
            existingAuthors: function () {
              return vm.people;
            },
            selectedAuthor: function () {
              return person;
            }
          }
        })
        .result.then(
          function (result) {
            var person = result.person;
            var abstractSocieties = result.abstractSocieties;

            var position = null;
            if (CustomUtils.isUndefinedOrNull(person.id)) {
              //ajout
              var personName = person.firstName + person.lastName;
              angular.forEach(vm.people, function (aPerson, index) {
                var aName = aPerson.firstName + aPerson.lastName;
                if (aName.toLowerCase() === personName.toLowerCase()) {
                  position = index;
                  _.merge(aPerson, person);
                }
              });
              if (position === null) {
                person.abstractSocieties = abstractSocieties;
                vm.people.push(person);
              }
            } else {
              angular.forEach(vm.people, function (aPerson, index) {
                if (aPerson.id === person.id) {
                  position = index;
                }
              });
              if (position !== null) {
                //modification
                person.abstractSocieties = abstractSocieties;
                vm.people[position] = person;
              }
            }
            vm.computeIndexes();
          },
          function () {
            $log.info('Cancel');
          }
        );
    };

    vm.computeIndexes = function () {
      //construction de la liste des societes
      vm.abstractSocieties = [];
      angular.forEach(vm.people, function (aPerson) {
        angular.forEach(aPerson.abstractSocieties, function (aPersonAbstractSociety) {
          var alreadyInList = false;
          angular.forEach(vm.abstractSocieties, function (society) {
            if (society.name.toLowerCase() === aPersonAbstractSociety.name.toLowerCase()) {
              alreadyInList = true;
            }
          });
          if (!alreadyInList) {
            vm.abstractSocieties.push(aPersonAbstractSociety);
          }
        });
      });

      //calcul des index
      angular.forEach(vm.people, function (aPerson) {
        aPerson.societyIndexes = [];
        angular.forEach(aPerson.abstractSocieties, function (aPersonSociety) {
          angular.forEach(vm.abstractSocieties, function (anAbstractSociety, index) {
            if (aPersonSociety.name.toLowerCase() === anAbstractSociety.name.toLowerCase()) {
              aPerson.societyIndexes.push(index + 1);
            }
          });
        });

        //on reordonne les index
        aPerson.societyIndexes.sort(function (a, b) {
          return a - b;
        });
      });
    };

    vm.tryRemoveSociety = function (society) {
      if (vm.canEdit()) {
        ConfirmDialog.openConfirmRemove(
          society.name,
          function () {
            vm.removeSociety(society);
          },
          function () {
            $log.info('Annuler');
          }
        );
      }
    };

    vm.removeSociety = function (abstractSociety, doNotComputeIndex) {
      angular.forEach(vm.people, function (aPerson) {
        angular.forEach(aPerson.abstractSocieties, function (aPersonAbstractSociety, aSocietyIndex) {
          if (aPersonAbstractSociety.name.toLowerCase() === abstractSociety.name.toLowerCase()) {
            aPerson.abstractSocieties.splice(aSocietyIndex, 1);
          }
        });

        //si la personne n'a plus de société associée on la supprime.
        if (aPerson.abstractSocieties.length === 0) {
          vm.removePerson(aPerson, true);
        }
      });

      if (!doNotComputeIndex) {
        vm.computeIndexes();
      }
    };

    vm.tryRemovePerson = function (person) {
      var fullName = person.firstName + ' ' + person.lastName;
      ConfirmDialog.openConfirmRemove(
        fullName,
        function () {
          vm.removePerson(person);
        },
        function () {
          $log.info('Annuler');
        }
      );
    };

    vm.removePerson = function (person, doNotComputeIndex) {
      var personName = person.firstName + person.lastName;
      var indexToRemove = null;
      for (var i = 0; i < vm.people.length; i++) {
        var aPerson = vm.people[i];
        var aName = aPerson.firstName + aPerson.lastName;
        if (personName.toLowerCase() === aName.toLowerCase()) {
          indexToRemove = i;
          break;
        }
      }
      vm.people.splice(indexToRemove, 1);

      //reorder
      angular.forEach(vm.people, function (aPerson, index) {
        aPerson.position = index + 1;
      });

      if (!doNotComputeIndex) {
        vm.computeIndexes();
      }
    };

    //fix issue #62 et #817
    //pb bug avec l'editeur jqwidgets.
    vm.removeTagsWithXMLNamespace = function (html) {
      html = html.replaceAll(/<o:p>\s*<\/o:p>/g, '');
      html = html.replaceAll(/<o:p>[\s\S]*?<\/o:p>/g, '&nbsp;');
      html = html.replaceAll(/<\/?\w+:[^>]*>/gi, '');

      return html.replaceAll('text-decoration-line', 'text-decoration');
    };

    vm.sendAbstract = function (save) {
      if (!vm.checkSpeakers()) {
        return;
      }
      vm.isSaving = true;

      //fix issue #62
      vm.eventAbstract.abstractText = vm.removeTagsWithXMLNamespace(vm.eventAbstract.abstractText);

      var abstractPeople = [];

      angular.forEach(vm.people, function (aPersonWithSociety, index) {
        var abstractPerson = {
          firstName: aPersonWithSociety.firstName,
          lastName: aPersonWithSociety.lastName,
          position: index + 1,
          speaker: aPersonWithSociety.speaker,
          personId: aPersonWithSociety.personId,
          id: aPersonWithSociety.id,
          civilite: aPersonWithSociety.civilite
        };

        abstractPeople.push({
          abstractPersonDTO: abstractPerson,
          abstractSocieties: aPersonWithSociety.abstractSocieties
        });
      });

      if (vm.eventAbstract.type === 'I') {
        vm.eventAbstract.accepted = true;
        vm.eventAbstract.finalType = 'I';
      }

      var allAbstract = {
        eventAbstract: vm.eventAbstract,
        abstractPeople: abstractPeople
      };

      if (save) {
        $http.put('api/event-abstracts/all', allAbstract).then(function (result) {
          var savedMsg = $translate.instant('canceropoleGsoApp.eventAbstract.saved');
          AlertService.success(savedMsg);
          vm.isSaving = false;
          if (CustomUtils.isEmpty(previousState.name)) {
            return $state.reload();
          } else {
            return $state.go(previousState.name, previousState.params);
          }
        }, vm.showError);
      } else {
        DataUtils.downloadFileFromPostUrl('api/public/event-abstracts/pdf/preview', allAbstract, 'preview.pdf');
        vm.isSaving = false;
      }
    };

    vm.cancel = function () {
      $state.go(previousState.name, previousState.params);
    };

    vm.showError = function (error) {
      AlertService.error(error);
      vm.isSaving = false;
    };

    vm.editorSettings = {
      width: '100%',
      height: 300,
      tools: 'bold italic underline | characters | exposant indice | ul ol | clean | html',
      pasteMode: 'text',
      created: function (event) {
        vm.editor = event.instance;
      },
      createCommand: function (name) {
        switch (name) {
          case 'characters':
            return {
              type: 'button',
              tooltip: 'Caractères spéciaux',
              init: function (widget) {
                widget.jqxButton({ height: 25, width: 25 });
                widget.html("<span style='line-height: 25px;'><b>&Omega;</b></span>");
              },
              action: function (widget, editor) {
                CharactersDialog.open().then(function (character) {
                  vm.editor.execute('insertHTML', character);
                  var value = vm.editor.val();
                  vm.eventAbstract.abstractText = value;
                });
              }
            };
          case 'exposant':
            return {
              type: 'button',
              tooltip: 'Exposant',
              init: function (widget) {
                widget.jqxButton({ height: 25, width: 25 });
                widget.html('<span style="line-height: 25px;"><span class="glyphicon glyphicon-superscript"></span></span>');
              },
              action: function (widget, editor) {
                var selection = vm.editor.selection.getText();
                if (!CustomUtils.isEmpty(selection)) {
                  vm.editor.execute('insertHTML', '<sup>' + selection + '</sup>');
                }
              }
            };
          case 'indice':
            return {
              type: 'button',
              tooltip: 'Indice',
              init: function (widget) {
                widget.jqxButton({ height: 25, width: 25 });
                widget.html('<span style="line-height: 25px;"><span class="glyphicon glyphicon-subscript"></span></span>');
              },
              action: function (widget, editor) {
                var selection = vm.editor.selection.getText();
                if (!CustomUtils.isEmpty(selection)) {
                  vm.editor.execute('insertHTML', '<sub>' + selection + '</sub>');
                }
              }
            };
        }
      }
    };

    vm.alreadySortable = false;
    vm.refreshSortable = function () {
      if ($('#authors').length > 0 && !vm.alreadySortable) {
        $('#authors').jqxSortable();
        $('#authors').on('stop', function (event) {
          var spanItem = event.args.item;
          var spanItems = spanItem.parent().children();
          $log.debug('spanItems=', spanItems);
          $log.debug('people=', vm.people);

          angular.forEach(spanItems, function (personSpan, index) {
            var spanId = personSpan.id;
            var name = spanId.split('_');
            var firstName = name[0];
            var lastName = name[1];
            name = {
              firstName: firstName,
              lastName: lastName
            };
            $log.debug('search for person ', name);

            angular.forEach(vm.people, function (person) {
              if (vm.equalsNames(name, person)) {
                person.position = index + 1;
                //newOrder.push(person);
              }
            });
          });
          vm.people.sort(function (a, b) {
            return a.position - b.position;
          });
          vm.computeIndexes();
          $scope.$apply();
        });
        vm.alreadySortable = true;
      }
    };

    vm.equalsNames = function (person1, person2) {
      var firstName1 = person1.firstName.toLowerCase();
      var firstName2 = person2.firstName.toLowerCase();

      var lastName1 = person1.lastName.toLowerCase();
      var lastName2 = person2.lastName.toLowerCase();

      return firstName1 === firstName2 && lastName1 === lastName2;
    };

    if (vm.canEdit()) {
      $scope.$watchCollection('vm.people', function () {
        $timeout(vm.refreshSortable, 500);
      });
    }

    vm.computeIndexes();
  }
})();
