(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LinkPersonDialogController', LinkPersonDialogController);

    LinkPersonDialogController.$inject = ['$uibModalInstance', 'abstractPerson', '$log', 'PersonSearch', 'AlertService', 'AbstractPerson'];

    function LinkPersonDialogController($uibModalInstance, abstractPerson, $log, PersonSearch, AlertService, AbstractPerson) {

        var ctrl = this;

        ctrl.abstractPerson = abstractPerson;

        ctrl.person = null;
        ctrl.result = null;

        $log.info(ctrl.abstractPerson);

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });

                    ctrl.result = result;

                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                var toSearch = ctrl.abstractPerson.firstName + " " + ctrl.abstractPerson.lastName;
                ctrl.personComboBox.val(toSearch);
                ctrl.personComboBoxSettings.search(toSearch);
            },
            select: function () {
            },
            unselect: function () {
                ctrl.personId = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.personId = null;
                }
            }
        };

        ctrl.onSelect = function (person) {
            ctrl.person = person;
            $log.info("Selection", ctrl.person);
            $log.info("for", ctrl.abstractPerson);
        };

        ctrl.ok = function () {
            ctrl.abstractPerson.personId = ctrl.person.id;
            AbstractPerson.update(ctrl.abstractPerson, function () {
                AlertService.success("Association effectuée.");
                $uibModalInstance.close();
            }, function () {
                AlertService.error("Problème lors de l'association.");
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();