(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetController', SheetController);

    SheetController.$inject = ['$state', '$stateParams', 'SheetHistoryService'];

    function SheetController($state, $stateParams, SheetHistoryService) {
        var type = $stateParams.type;
        var id = $stateParams.id;
        var lastSheet = SheetHistoryService.getLast();

        if (type !== null && id !== null) {
            if (lastSheet) {
                if (lastSheet.type === type && lastSheet.id === id) {
                    $state.go('.' + lastSheet.type, {id: lastSheet.id});
                    return;
                }
            }
            $state.go('.' + type, {id: id});
        } else if (lastSheet) {
            $state.go('.' + lastSheet.type, {id: lastSheet.id});
        }
    }
})();

