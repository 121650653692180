(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventRegistrationDetailController', EventRegistrationDetailController);

    EventRegistrationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventRegistration', 'Person', 'Society', 'EventSession', 'MainEvent', 'PersonMetier', 'PersonStatus', 'EventOption'];

    function EventRegistrationDetailController($scope, $rootScope, $stateParams, entity, EventRegistration, Person, Society, EventSession, MainEvent, PersonMetier, PersonStatus, EventOption) {
        var vm = this;

        vm.eventRegistration = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventRegistrationUpdate', function(event, result) {
            vm.eventRegistration = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
