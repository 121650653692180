(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('PersonSocietyInputDetailController', PersonSocietyInputDetailController);

    PersonSocietyInputDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PersonSocietyInput', 'Person', 'Society', 'PersonMetier', 'PersonStatus', 'Submission'];

    function PersonSocietyInputDetailController($scope, $rootScope, $stateParams, entity, PersonSocietyInput, Person, Society, PersonMetier, PersonStatus, Submission) {
        var vm = this;

        vm.personSocietyInput = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:personSocietyInputUpdate', function(event, result) {
            vm.personSocietyInput = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
