(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SessionEditDialogController', SessionEditDialogController);

    SessionEditDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'session', 'ModelConfig', 'eventDays', '$log', '$filter', 'DateUtils'];

    function SessionEditDialogController($translate, $translatePartialLoader, $uibModalInstance, session, ModelConfig, eventDays, $log, $filter, DateUtils) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.session = session;
        ctrl.eventDays = eventDays;
        ctrl.eventDay = ctrl.eventDays[0];


        for (var i = 0; i < ctrl.eventDays.length; i++) {
            var eventDay = ctrl.eventDays[i];
            eventDay.name = $filter('date')(eventDay.startDate, 'EEEE dd MMMM');
            if (ctrl.session != null && ctrl.session.dayId === eventDay.id) {
                ctrl.eventDay = eventDay;
            }
        }

        if (ctrl.session == null) {
            ctrl.session = {
                mandatory: false
            };

            var start = ctrl.eventDay.startDate;
            var after = new Date(start.getTime());
            after.setHours(after.getHours() + 1);
            ctrl.startDate = start;
            ctrl.endDate = after;
        } else {
            ctrl.startDate = ctrl.session.startDate;
            ctrl.endDate = ctrl.session.endDate;
        }

        ctrl.daysSettings = {
            width: "100%",
            height: 30,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.eventDays,
            select: function (event) {
                if (event.args && event.args.item) {
                    var originalItem = event.args.item.originalItem;
                    var startDate = originalItem.startDate;
                    var endDate = originalItem.endDate;

                    if (DateUtils.compareWithoutTime(startDate, ctrl.startDate) !== 0 &&
                            DateUtils.compareWithoutTime(endDate, ctrl.endDate) !== 0) {
                        var date = new Date(startDate);
                        date.setHours(ctrl.startDate.getHours());
                        date.setMinutes(ctrl.startDate.getMinutes());
                        date.setSeconds(ctrl.startDate.getSeconds());

                        ctrl.startDate = date;

                        date = new Date(endDate);
                        date.setHours(ctrl.endDate.getHours());
                        date.setMinutes(ctrl.endDate.getMinutes());
                        date.setSeconds(ctrl.endDate.getSeconds());

                        ctrl.endDate = date;
                    }

                }
            }
        };

        var sessionTypes = [];
        for (var key in ModelConfig.eventSession.sessionType) {
            var value = ModelConfig.eventSession.sessionType[key];
            var type = {
                label: "canceropoleGsoApp.eventSession.types." + key,
                value: value
            };
            sessionTypes.push(type);
        }

        ctrl.checkDates = function () {
            var startDate = ctrl.startDate;
            var endDate = ctrl.endDate;

            $log.debug(startDate, endDate, startDate < endDate);
            ctrl.editSessionForm.$setValidity('previousDate', startDate < endDate);
        };

        ctrl.sessionTypeDataAdapter = new $.jqx.dataAdapter({
            localdata: sessionTypes,
            datafields: [{
                    name: 'label',
                    type: 'string'
                }, {
                    name: 'value',
                    type: 'string'
                }]
        });

        ctrl.sessionTypeSettings = {
            width: "'100%'",
            height: 30,
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.sessionTypeDataAdapter,
            renderer: function (index, label, value) {
                return '<span style="line-height:30px">' + $translate.instant(label) + '</span>';
            },
            selectionRenderer: function (element, index, label, value) {
                return '<span style="line-height:30px">' + $translate.instant(label) + '</span>';
            }
        };

        ctrl.dateSettings = {
            width: "100%",
            height: 30,
            formatString: 'dd/MM/yyyy HH:mm',
            //min: 'ctrl.eventDay.startDate',
            //max: 'ctrl.session.endDate',
            change: ctrl.checkDates,
            culture: "fr-FR",
            showTimeButton: true
        };

        ctrl.ok = function () {
            ctrl.session.dayId = ctrl.eventDay.id;
            ctrl.session.startDate = ctrl.startDate;
            ctrl.session.endDate = ctrl.endDate;
            $uibModalInstance.close(ctrl.session);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();