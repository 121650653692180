(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventSessionDetailController', EventSessionDetailController);

    EventSessionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventSession', 'EventAbstract', 'EventDay', 'EventRegistration'];

    function EventSessionDetailController($scope, $rootScope, $stateParams, entity, EventSession, EventAbstract, EventDay, EventRegistration) {
        var vm = this;

        vm.eventSession = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:eventSessionUpdate', function(event, result) {
            vm.eventSession = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
