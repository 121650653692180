(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('TeamDetailController', TeamDetailController);

    TeamDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Team', 'Geosite', 'TeamType', 'Society', 'Person', 'ScientificAttachment', 'ResponsibleFunction'];

    function TeamDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Team, Geosite, TeamType, Society, Person, ScientificAttachment, ResponsibleFunction) {
        var vm = this;

        vm.team = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:teamUpdate', function(event, result) {
            vm.team = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
