(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetSocietyPresentationTabController', SheetSocietyPresentationTabController);

    SheetSocietyPresentationTabController.$inject = ['$log', '$stateParams', '$state', 'societyEntity', 'Society', 'AlertService', 'Principal'];

    function SheetSocietyPresentationTabController($log, $stateParams, $state, society, Society, AlertService, Principal) {
        if ($stateParams.tab !== "presentation") {
            //update state param
            $stateParams.tab = "presentation";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.society = society;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.save = function () {
            Society.update(vm.society, function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });
        };

    }
})();