(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('abstractAccepted', abstractAccepted);

    abstractAccepted.$inject = ['AbstractService'];

    function abstractAccepted(AbstractService) {

        return abstractAccepted;

        function abstractAccepted(input) {
            if (input != null) {
                return AbstractService.renderAccepted(input);
            }
            return "";
        }
    }
})();


