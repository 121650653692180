(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('bytes', bytes);

    function bytes() {
        return bytes;

        function bytes(bytes, precision, hideUnit) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes))
                return '-';
            if (typeof precision === 'undefined')
                precision = 1;
            var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
                    number = Math.floor(Math.log(bytes) / Math.log(1024));
            var result = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);
            if (!hideUnit) {
                result += ' ' + units[number];
            }

            return result;
        }
    }
})();
