(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('abstractType', abstractType);

    abstractType.$inject = ['AbstractService'];

    function abstractType(AbstractService) {

        return abstractType;

        function abstractType(input) {
            if (input != null) {
                return AbstractService.renderFinalType(input);
            }
            return "";
        }
    }
})();


