(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('manifestation', {
            parent: 'app',
            url: '/manifestation?event',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'manifestation.pageTitle'
            },
            params: {
                event: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/manifestation/manifestation.html',
                    controller: 'ManifestationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('manifestation');
                    $translatePartialLoader.addPart('mainEvent');
                    $translatePartialLoader.addPart('eventRegistration');
                    return $translate.refresh();
                }]
            }
        }).state('manifestationSubscription', {
            parent: 'app',
            url: '/manifestation/subscription/:id',
            data: {
                authorities: [],
                ownLanguage: true
            },
            params: {},
            views: {
                'content@': {
                    templateUrl: 'app/manifestation/subscription/subscription.html',
                    controller: 'ManifestationSubscriptionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('entities');
                    $translatePartialLoader.addPart('civilite');
                    $translatePartialLoader.addPart('eventPrice');
                    $translatePartialLoader.addPart('mainEvent');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MainEvent', function ($stateParams, MainEvent) {
                    return MainEvent.get({ id: $stateParams.id }).$promise;
                }],
                prices: ['$stateParams', 'EventRegistration', function ($stateParams, EventRegistration) {
                    return EventRegistration.getPrices({ id: $stateParams.id }).$promise;
                }],
                eventOptions: ['$stateParams', '$http', function ($stateParams, $http) {
                    return $http.get('api/public/main-events/' + $stateParams.id + '/options').then(function (response) {
                        return response.data;
                    });
                }],
                lang: ['JhiLanguageService', 'entity', function (JhiLanguageService, entity) {
                    return JhiLanguageService.changeLanguage(entity.lang);
                }]
            }
        }).state('manifestationSubscriptionConfirmation', {
            parent: 'app',
            url: '/manifestation/subscription/{id}/confirmation',
            data: {
                authorities: [],
                ownLanguage: true
            },
            params: {
                confirmation: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/manifestation/subscription/subscriptionConfirm.html',
                    controller: 'ManifestationSubscriptionConfirmController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('subscription');
                    return $translate.refresh();
                }]

            }
        }).state('SubscriptionSpaceController', {
            parent: 'app',
            url: '/manifestation/{id}/space?key',
            data: {
                authorities: [],
                ownLanguage: true
            },
            params: {},
            views: {
                'content@': {
                    templateUrl: 'app/manifestation/subscription/subscription.space.html',
                    controller: 'SubscriptionSpaceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('sheet');
                    $translatePartialLoader.addPart('civilite');
                    $translatePartialLoader.addPart('fileAttachement');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('eventAbstract');
                    $translatePartialLoader.addPart('mainEventMailType');
                    return $translate.refresh();
                }],
                registrationEntity: ['$stateParams', 'EventRegistration', function ($stateParams, EventRegistration) {
                    return EventRegistration.getByKey({ id: $stateParams.key }).$promise;
                }],
                mainEventEntity: ['$stateParams', 'MainEvent', function ($stateParams, MainEvent) {
                    return MainEvent.get({ id: $stateParams.id }).$promise;
                }],
                personMetierEntities: ['PersonMetier', function (PersonMetier) {
                    return PersonMetier.query().$promise;
                }],
                sessionEntities: ['$stateParams', 'EventRegistration', function ($stateParams, EventRegistration) {
                    return EventRegistration.getSessions({ id: $stateParams.id }).$promise;
                }],
                axeEntities: ['Axe', function (Axe) {
                    return Axe.query().$promise;
                }],
                eventOptions: ['$stateParams', '$http', function ($stateParams, $http) {
                    return $http.get('api/public/main-events/' + $stateParams.id + '/options').then(function (response) {
                        return response.data;
                    });
                }],
                prices: ['EventRegistration', '$stateParams', function (EventRegistration, $stateParams) {
                    return EventRegistration.getPrices({ id: $stateParams.id }).$promise;
                }],
                managements: ['$stateParams', '$http', function ($stateParams, $http) {
                    return $http.get('api/public/main-events/' + $stateParams.id + '/managements').then(function (response) {
                        return response.data;
                    });
                }],
                lang: ['JhiLanguageService', 'mainEventEntity', function (JhiLanguageService, mainEventEntity) {
                    return JhiLanguageService.changeLanguage(mainEventEntity.lang);
                }]
            }
        }).state('createAbstract', {
            parent: 'app',
            url: '/manifestation/abstract/creation/{id}?key&abstractId',
            data: {
                authorities: [],
                ownLanguage: true
            },
            params: {
                id: null,
                key: null,
                abstractId: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/manifestation/abstract/manifestation.abstract.html',
                    controller: 'ManifestationAbstractController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('eventAbstract');
                    $translatePartialLoader.addPart('subscription');
                    $translatePartialLoader.addPart('abstractType');
                    $translatePartialLoader.addPart('civilite');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                mainEventEntity: ['$stateParams', 'MainEvent', function ($stateParams, MainEvent) {
                    return MainEvent.get({ id: $stateParams.id }).$promise;
                }],
                eventAbstractEntity: ['$stateParams', 'EventAbstract', function ($stateParams, EventAbstract) {
                    if ($stateParams.abstractId != null) {
                        return EventAbstract.getAllInformations({ id: $stateParams.abstractId }).$promise;
                        //return EventAbstract.get({id: $stateParams.abstractId}).$promise;
                    } else {
                        return null;
                    }
                }],
                axeEntities: ['Axe', function (Axe) {
                    return Axe.query().$promise;
                }],
                sessionEntities: ['$stateParams', 'EventRegistration', function ($stateParams, EventRegistration) {
                    return EventRegistration.getSessions({ id: $stateParams.id }).$promise;
                }],
                previousState: ['$state', function ($state) {
                    var currentState = {
                        name: $state.current.name,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentState;
                }],
                lang: ['JhiLanguageService', 'mainEventEntity', function (JhiLanguageService, mainEventEntity) {
                    return JhiLanguageService.changeLanguage(mainEventEntity.lang);
                }]

            }
        }).state('exportEventRegistrations', {
            parent: 'manifestation',
            url: '/export',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                registrations: null,
                type: null,
                mainEventId: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/manifestation/dialog/export-eventRegistrations.dialog.html',
                    controller: 'ExportEventRegistrationsDialogController',
                    controllerAs: 'ctrl',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        eventRegistrations: function () {
                            return $stateParams.registrations;
                        },
                        exportType: function () {
                            return $stateParams.type;
                        },
                        eventDays: ['EventRegistration', function (EventRegistration) {
                            return EventRegistration.getSessions({ id: $stateParams.mainEventId }).$promise;
                        }]
                    }
                }).result.then(function () {

                }, function () {
                    //$state.go('^');
                });
            }]
        });
    }
})();
