(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('addTableField', {
                controller: 'AddTableFieldController',
                bindings: {
                    config: '='
                },
                templateUrl: 'app/sheet/callforproject/components/add-table-field.html'
            });
})();
