(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AxesTabController', AxesTabController);

    AxesTabController.$inject = ['$log', '$stateParams', 'PersonAxe', '$uibModal', 'CustomUtils', '$state', 'CallForProjectFormField', 'JQWidgetsService', '$translate'];

    function AxesTabController($log, $stateParams, PersonAxe, $uibModal, CustomUtils, $state, CallForProjectFormField, JQWidgetsService, $translate) {
        var ctrl = this;

        if (!CustomUtils.isEmpty($stateParams.id)) {
            for (var i = 0; i < ctrl.axes.length; i++) {
                if ($stateParams.id == ctrl.axes[i].id) {
                    ctrl.activeIndex = i;
                    break;
                }
            }
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        ctrl.onTabSelect = function (axe) {
            ctrl.changeUrlState(axe);
        };

        ctrl.changeUrlState = function (axe) {
            if ($stateParams.id !== axe.id) {
                $stateParams.id = axe.id;
                $state.transitionTo($state.current.name, $stateParams, {notify: false});
            }
        };

        $log.info(ctrl);

    }
})();
