(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('AddSocietyDialogController', AddSocietyDialogController);

    AddSocietyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'Society', '$log', 'geosites'];

    function AddSocietyDialogController($scope, $stateParams, $uibModalInstance, DataUtils, Society, $log, geosites) {
        var vm = this;

        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.goToSheet = false;

        $log.info("stateParams=", $stateParams);

        vm.society = {
            publique: true,
            name: $stateParams.name,
            level: 'STRUCTURE'
        };

        vm.geositeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            height: 30,
            source: {
                localdata: geosites,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                vm.geositeDropDown = event.instance;
            }
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.society.id !== null) {
                Society.update(vm.society, onSaveSuccess, onSaveError);
            } else {
                Society.save(vm.society, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('canceropoleGsoApp:societyUpdate', result);
            result.goToSheet = vm.goToSheet;
            var closeResult = {
                society: result,
                goToSheet: vm.goToSheet
            };
            $uibModalInstance.close(closeResult);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.setAvatar = function ($file, society) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        society.avatar = base64Data;
                        society.avatarContentType = $file.type;
                    });
                });
            }
        };

    }
})();
