(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .factory('FileAttachementSearch', FileAttachementSearch);

    FileAttachementSearch.$inject = ['$resource'];

    function FileAttachementSearch($resource) {
        var resourceUrl =  'api/_search/file-attachements/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
