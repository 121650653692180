(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetTeamMainController', SheetTeamMainController);

    SheetTeamMainController.$inject = ['Principal', 'SocietySearch', '$stateParams', 'CustomUtils', 'Society', '$state', 'Team', 'AlertService', 'geosites', 'teamTypes', 'team', 'ScientificAttachment', 'PersonSearch', 'JQWidgetsService', '$translate'];

    function SheetTeamMainController(Principal, SocietySearch, $stateParams, CustomUtils, Society, $state, Team, AlertService, geosites, teamTypes, team, ScientificAttachment, PersonSearch, JQWidgetsService, $translate) {
        if ($stateParams.tab !== "main") {
            //update state param
            $stateParams.tab = "main";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.team = team;
        vm.geosites = geosites;
        vm.teamTypes = teamTypes;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.edition = {
            contact: false,
            otherinformation: false,
            scientificAttachment: false
        };

        if (vm.team.societyId) {
            Society.get({id: vm.team.societyId}, function (result) {
                vm.society = result;
            });
        }

        vm.fullAddress = vm.team.address;
        if (vm.team.address2) {
            vm.fullAddress += ' - ' + vm.team.address2;
        }
        if (vm.team.cp) {
            vm.fullAddress += ' - ' + vm.team.cp;
        }
        if (vm.team.city) {
            vm.fullAddress += ' - ' + vm.team.city;
        }

        vm.geosite = {};
        if (vm.team.geositeId) {
            for (var i = 0; i < vm.geosites.length; i++) {
                var aGeosite = vm.geosites[i];
                if (aGeosite.id === vm.team.geositeId) {
                    vm.geosite = aGeosite;
                    break;
                }
            }
        }
        vm.teamType = {};
        if (vm.team.teamTypeId) {
            for (var i = 0; i < vm.teamTypes.length; i++) {
                var aTeamType = vm.teamTypes[i];
                if (aTeamType.id === vm.team.teamTypeId) {
                    vm.teamType = aTeamType;
                    break;
                }
            }
        }

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.save = function (win) {
            if (win === 'otherinformation') {
                if (vm.geosite.id) {
                    vm.team.geositeId = vm.geosite.id;
                }
                if (vm.teamType.id) {
                    vm.team.teamTypeId = vm.teamType.id;
                }
            } else if (win === 'contact') {
                if (!CustomUtils.isEmpty(vm.team.webSite) && !vm.team.webSite.startsWith("http")) {
                    vm.team.webSite = "http://" + vm.team.webSite;
                }
            }
            Team.update(vm.team, function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });

        };

        CustomUtils.getCallingCodes().then(function (result) {
            vm.callingCodeSettings.source = result;
        });
        vm.callingCodeSettings = {
            created: function (args) {
                vm.callingCodeComboBox = args.instance;
            },
            renderer: function (element, label, value) {
                return "+" + label;
            },
            selectionRenderer: function (htmlElement, label, value) {
                var htmlString = htmlElement[0].innerHTML;
                return '<div style="position: relative; margin-left: 3px; margin-top: 8px;">+' + htmlString + '</div>';
            }
        };

        vm.geositeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            height: 30,
            source: {
                localdata: vm.geosites,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                vm.geositeDropDown = event.instance;
            }
        };

        vm.teamTypeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            height: 30,
            source: {
                localdata: teamTypes,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                vm.teamTypeDropDown = event.instance;
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter({
            url: 'api/search',
            datatype: 'json',
            id: 'id',
            data: {
                size: '20'
            },
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }]
        }, {
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: vm.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });


        vm.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                vm.societyComboBox = args.instance;
                if (vm.society) {
                    vm.societyComboBox.val(vm.society.name);
                    vm.societyComboBoxSettings.search(vm.society.name);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var society = item.originalItem;
                    vm.society = society;
                    vm.team.societyId = vm.society.id;
                    if (!CustomUtils.isEmpty(society.address)) {
                        vm.team.address = society.address;
                    }
                    if (!CustomUtils.isEmpty(society.address2)) {
                        vm.team.address2 = society.address2;
                    }
                    if (!CustomUtils.isEmpty(society.cp)) {
                        vm.team.cp = society.cp;
                    }
                    if (!CustomUtils.isEmpty(society.city)) {
                        vm.team.city = society.city;
                    }
                }
            },
            unselect: function () {
                vm.society = null;
            },
            change: function () {
                var selection = vm.societyComboBox.getSelectedItem();
                if (_.isNil(selection)) {
                    vm.team.societyId = null;
                } else {
                    vm.society = selection.originalItem;
                    vm.team.societyId = vm.society.id;
                }
            }
        };


        vm.scientificAttachments = ScientificAttachment.query();

        vm.scientificListBoxSettings = {
            width: "100%",
            height: 200,
            displayMember: "name",
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: vm.scientificAttachments,
            created: function (args) {
                vm.scientificList = args.instance;
                angular.forEach(vm.team.scientificAttachments, function (scientificAttachment) {
                    vm.scientificList.checkItem(scientificAttachment.id);
                });
            },
            checkChange: function (event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;
                    for (var i = 0; i < vm.team.scientificAttachments.length; i++) {
                        var attachement = vm.team.scientificAttachments[i];
                        if (attachement.id === args.value) {
                            index = i;
                            break;
                        }
                    }

                    if (args.checked === true && index === null) {
                        vm.team.scientificAttachments.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        vm.team.scientificAttachments.splice(index, 1);
                    }
                }
            }
        };
    }
})();
