(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('RemoveExpertDialogController', RemoveExpertDialogController);

    RemoveExpertDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', '$http', '$log', 'experts', 'submission', 'AlertService'];

    function RemoveExpertDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, $http, $log, experts, submission, AlertService) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;
        ctrl.submission = submission;
        ctrl.experts = experts;

        ctrl.ok = function () {
            ctrl.isSaving = true;

            var ids = [];
            angular.forEach(ctrl.experts, function (expert) {
                ids.push(expert.id);
            });

            $http.put("api/experts/unassign/" + +ctrl.submission.id, ids).then(onSaveSuccess, onSaveError);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            AlertService.success("Suppression effectuée.");
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError(response) {
            ctrl.isSaving = false;
            $log.error(response);
        }

    }
})();