(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('cfpFormProject', {
                controller: 'cfpFormProjectController',
                bindings: {
                    submission: '<',
                    callForProject: '<',
                    project: '<',
                    form: '<',
                    readOnly: '<',
                    canSaveProject: '=',
                    saveProject: '=',
                    expert: '<?'
                },
                templateUrl: 'app/callforproject/subscription/components/cfp-form-project.html'
            });
})();
