(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('TimelineController', TimelineController);

    TimelineController.$inject = ['$translate', '$translatePartialLoader'];

    function TimelineController($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('timeline');
        $translate.refresh();

    }
})();