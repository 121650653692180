(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventStatisticsTabController', SheetMainEventStatisticsTabController);

    SheetMainEventStatisticsTabController.$inject = ['$translate', '$stateParams', '$state', '$http'];

    function SheetMainEventStatisticsTabController($translate, $stateParams, $state, $http) {
        if ($stateParams.tab !== "statistics") {
            //update state param
            $stateParams.tab = "statistics";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.mainEventId = $stateParams.id;

        vm.source = {
            datatype: "json",
            datafields: [{
                    name: "key",
                    type: "string"
                }, {
                    name: "value",
                    type: "number"
                }, {
                    name: "percent",
                    type: "number"
                }]
        };

        vm.refresh = function () {
            $http.get("api/main-events/" + vm.mainEventId + "/statistics").success(function (result) {

                var geolocSource = angular.copy(vm.source);
                geolocSource.localdata = result.geo;
                vm.geoLocSettings.source = new $.jqx.dataAdapter(geolocSource);

                var citiesSource = angular.copy(vm.source);
                citiesSource.localdata = result.cities;
                vm.citiesSettings.source = new $.jqx.dataAdapter(citiesSource);

                var connectedSource = angular.copy(vm.source);
                connectedSource.localdata = result.connected;
                vm.connectedSettings.source = new $.jqx.dataAdapter(connectedSource);

                var metierSource = angular.copy(vm.source);
                metierSource.localdata = result.metier;
                vm.metierSettings.source = new $.jqx.dataAdapter(metierSource);

                var statusSource = angular.copy(vm.source);
                statusSource.localdata = result.status;
                vm.statusSettings.source = new $.jqx.dataAdapter(statusSource);

                var groupMetierSource = angular.copy(vm.source);
                groupMetierSource.localdata = result.groupMetier;
                vm.groupMetierSettings.source = new $.jqx.dataAdapter(groupMetierSource);

                var groupMetierSource2 = angular.copy(vm.source);
                groupMetierSource2.localdata = result.groupMetier2;
                vm.groupMetier2Settings.source = new $.jqx.dataAdapter(groupMetierSource2);

                var groupStatusSource = angular.copy(vm.source);
                groupStatusSource.localdata = result.groupStatus;
                vm.groupStatusSettings.source = new $.jqx.dataAdapter(groupStatusSource);

                var groupStatusSource2 = angular.copy(vm.source);
                groupStatusSource2.localdata = result.groupStatus2;
                vm.groupStatus2Settings.source = new $.jqx.dataAdapter(groupStatusSource2);
            });
        };

        vm.formatToolTip = function (dataItem) {
            var html = '<div style="text-align:left">';
            if (dataItem.key != null) {
                html += '<b>' + dataItem.key + "</b><br/>";
            }
            html += 'Nombre :' + dataItem.value + "<br/>";
            html += 'Pourcentage :' + dataItem.percent + "%<br/>";
            html += '</div>';
            return html;
        };

        vm.geoLocSettings = {
            title: "Répartition géographique",
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme02',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.geoLocSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.geolocChart = args.instance;
            }
        };

        vm.citiesSettings = {
            title: "Participants",
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme02',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.citiesSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.geolocChart = args.instance;
            }
        };

        vm.connectedSettings = {
            title: "Participant / Annuaire",
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme01',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.connectedSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.connectedChart = args.instance;
            }
        };

        vm.metierSettings = {
            title: "Métier",
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme01',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.metierSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.metierChart = args.instance;
            }
        };

        vm.statusSettings = {
            title: "Statut",
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme04',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.statusSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.statusChart = args.instance;
            }
        };

        vm.groupMetierSettings = {
            title: $translate.instant('canceropoleGsoApp.personMetier.groupName'),
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme01',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.groupMetierSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.groupMetierChart = args.instance;
            }
        };

        vm.groupMetier2Settings = {
            title: $translate.instant('canceropoleGsoApp.personMetier.groupName2'),
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme01',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.groupMetier2Settings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.groupMetier2Chart = args.instance;
            }
        };

        vm.groupStatusSettings = {
            title: $translate.instant('canceropoleGsoApp.personStatus.groupName'),
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme04',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.groupStatusSettings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.groupMetierChart = args.instance;
            }
        };

        vm.groupStatus2Settings = {
            title: $translate.instant('canceropoleGsoApp.personStatus.groupName2'),
            description: "",
            enableAnimations: true,
            showLegend: true,
            showBorderLine: true,
            legendPosition: {left: 520, top: 140, width: 100, height: 100},
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 0, top: 0, right: 0, bottom: 10},
            colorScheme: 'scheme04',
            toolTipFormatFunction: function (value, itemIndex, serie, group, categoryValue, categoryAxis) {
                var dataItem = vm.groupStatus2Settings.source.records[itemIndex];
                return vm.formatToolTip(dataItem);
            },
            seriesGroups: [{
                    type: 'pie',
                    showLabels: true,
                    series: [{
                            dataField: 'percent',
                            displayText: 'key',
                            labelRadius: 120,
                            initialAngle: 15,
                            radius: 100,
                            centerOffset: 0,
                            formatSettings: {sufix: '%', decimalPlaces: 2}
                        }
                    ]
                }
            ],
            created: function (args) {
                vm.groupMetierChart = args.instance;
            }
        };

        vm.refresh();

    }
})();