(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('HomeEventsController', HomeEventsController);

    HomeEventsController.$inject = [];

    function HomeEventsController() {
        var ctrl = this;

        ctrl.getPublicDesc = function (item) {
            if (item.publique) {
                return 'Publique';
            }
            return 'Non publique';
        };
    }
})();