/* global _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('CallForProjectSubscriptionController', CallForProjectSubscriptionController);

  CallForProjectSubscriptionController.$inject = [
    'entity',
    '$log',
    'AlertService',
    '$stateParams',
    'SocietySearch',
    'PersonSearch',
    'formFieldsList',
    'TeamSearch',
    'CustomUtils',
    '$timeout',
    'JQWidgetsService',
    '$rootScope',
    'Principal',
    '$http',
    '$state',
    '$translate',
    'PersonMetier',
    'CallForProjectService',
    'managements',
    'JhiLanguageService',
    '$filter',
    'ConfirmDialog'
  ];

  function CallForProjectSubscriptionController(
    entity,
    $log,
    AlertService,
    $stateParams,
    SocietySearch,
    PersonSearch,
    formFieldsList,
    TeamSearch,
    CustomUtils,
    $timeout,
    JQWidgetsService,
    $root,
    Principal,
    $http,
    $state,
    $translate,
    PersonMetier,
    CallForProjectService,
    managements,
    JhiLanguageService,
    $filter,
    ConfirmDialog
  ) {
    //cache la barre de navigation
    $root.hideNavBar = !Principal.isAuthenticated();

    var vm = this;

    vm.language = entity.lang;

    vm.isPhoneNumber = CustomUtils.isPhoneNumber;

    vm.isAdmin = function () {
      return Principal.hasAnyAuthority(['ROLE_ADMIN']);
    };

    vm.canEdit = function () {
      if (vm.isAdmin()) {
        return true;
      }
      if (vm.callForProject.managed && CallForProjectService.isOpenForForm(vm.form)) {
        return true;
      }
      return false;
    };

    vm.confirmSend = false;
    vm.callForProjectId = $stateParams.id;
    vm.callForProject = entity;
    vm.managements = managements;

    vm.formConfig = CallForProjectService.getFormConfig(vm.callForProject);

    vm.form = null;
    vm.formName = '';
    vm.groups = [];
    vm.fields = [];
    vm.showValidityError = false;
    vm.isSending = false;

    vm.defaultCountry = 'FR';

    vm.countries = [];

    vm.project = {
      publique: true
    };
    vm.coordonnees = {
      civilite: 'mr'
    };
    vm.submission = {
      callForProjectId: vm.callForProjectId
    };

    angular.forEach(formFieldsList, function (formFields) {
      if (formFields.form.type === 'LI') {
        vm.form = formFields.form;
        vm.fields = formFields.fields;
        vm.groups = formFields.groups;
      }
    });

    angular.forEach(vm.groups, function (group) {
      group.fields = [];
      angular.forEach(vm.fields, function (field) {
        if (field.callForProjectFormGroupId === group.id) {
          if (field.type !== 'empty') {
            group.fields.push(field);
          }
        }
      });
      CustomUtils.sortByKey(group.fields, 'position');
    });

    _.remove(vm.groups, function (group) {
      return group.fields.length === 0;
    });

    CustomUtils.sortByKey(vm.groups, 'position');

    if (vm.form) {
      vm.formName = CustomUtils.removeSpecialChars(vm.form.name);
    }

    var showError = function (error) {
      $log.error(error);
      AlertService.error(error);
    };

    vm.isClosed = function () {
      return CallForProjectService.isClosedForForm(vm.form);
    };

    vm.isOpen = function () {
      return CallForProjectService.isOpenForForm(vm.form);
    };

    vm.preventMaxLength = function () {
      if (
        !CustomUtils.isUndefinedOrNull(vm.callForProject.descMaxLength) &&
        !CustomUtils.isUndefinedOrNull(vm.project.description) &&
        vm.project.description.length >= vm.callForProject.descMaxLength
      ) {
        vm.project.description = vm.project.description.substring(0, vm.callForProject.descMaxLength);
      }
    };

    vm.load = function () {
      vm.initMetierDropDownList();
      vm.initCountryList();
    };

    var personSource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var personDataAdapter = new $.jqx.dataAdapter(personSource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        PersonSearch.query(
          {
            query: vm.personComboBoxSettings.searchString,
            page: 0,
            size: 20
          },
          function (result) {
            angular.forEach(result, function (item) {
              item.name = item.firstName + ' ' + item.lastName;
            });
            callback({
              records: result
            });
          }
        );
      }
    });

    vm.personComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      autoDropDownHeight: true,
      remoteAutoComplete: true,
      placeHolder: $translate.instant('subscription.form.person.placeHolder'),
      //remoteAutoCompleteDelay: 100,
      source: personDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.personComboBoxSettings.searchString = searchString;
        personDataAdapter.dataBind();
      },
      created: function (args) {
        vm.personComboBox = args.instance;
      },
      select: function (event) {
        if (event.args) {
          var item = event.args.item;
          var person = item.originalItem;
          vm.coordonnees.personId = person.id;
          vm.coordonnees.firstName = person.firstName;
          vm.coordonnees.lastName = person.lastName;
          vm.coordonnees.email = person.email;
          vm.coordonnees.phone = person.phone;
          vm.coordonnees.portable = person.portable;
          vm.coordonnees.city = person.city;
          vm.coordonnees.civilite = person.civilite;
        }
      },
      unselect: function () {
        vm.coordonnees.personId = null;
        vm.coordonnees.firstName = null;
        vm.coordonnees.lastName = null;
        vm.coordonnees.email = null;
        vm.coordonnees.phone = null;
        vm.coordonnees.portable = null;
        vm.coordonnees.city = null;
        vm.coordonnees.civilite = null;
      },
      change: function () {
        var selection = vm.personComboBox.getSelectedItem();
        if (selection === null) {
          vm.coordonnees.personId = null;
          vm.coordonnees.firstName = null;
          vm.coordonnees.lastName = null;
          vm.coordonnees.email = null;
          vm.coordonnees.phone = null;
          vm.coordonnees.portable = null;
          vm.coordonnees.city = null;
          vm.coordonnees.civilite = null;
        }
      }
    };

    vm.civiliteSettings = {
      height: 30,
      width: 60,
      autoDropDownHeight: true,
      displayMember: 'label',
      valueMember: 'value',
      created: function (args) {
        vm.civiliteDropdownList = args.instance;
      },
      source: [
        {
          label: $translate.instant('canceropoleGsoApp.Civilite.mr'),
          value: 'mr'
        },
        {
          label: $translate.instant('canceropoleGsoApp.Civilite.mme'),
          value: 'mme'
        }
      ]
    };

    vm.axeDropDownListSettings = {
      height: 30,
      //width: 100,
      autoDropDownHeight: true,
      displayMember: 'name',
      valueMember: 'id',
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder(vm.language),
      created: function (args) {
        vm.axeDropdownList = args.instance;
      },
      source: vm.callForProject.axes
    };

    //vm.selectedSociety = "test";
    var societySource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var societyDataAdapter = new $.jqx.dataAdapter(societySource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        if (!CustomUtils.isEmpty(vm.societyComboBoxSettings.searchString)) {
          var query = 'name:' + vm.societyComboBoxSettings.searchString;
          query += ' or ';
          query += 'shortName:' + vm.societyComboBoxSettings.searchString;
          SocietySearch.query(
            {
              query: query,
              page: 0,
              size: 8
            },
            function (result) {
              callback({
                records: result
              });
            },
            showError
          );
        }
      }
    });

    vm.societyComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      remoteAutoComplete: true,
      autoDropDownHeight: true,
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder(vm.language),
      //remoteAutoCompleteDelay: 100,
      source: societyDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.societyComboBoxSettings.searchString = searchString;
        societyDataAdapter.dataBind();
      },
      created: function (args) {
        vm.societyComboBox = args.instance;
      },
      select: function (event) {
        if (event.args) {
          var item = event.args.item;
          var society = item.originalItem;
          vm.coordonnees.societyId = society.id;
          vm.coordonnees.societyName = society.name;
          vm.coordonnees.city = society.city;
        }
      },
      unselect: function () {
        vm.coordonnees.societyId = null;
        vm.coordonnees.societyName = null;
        vm.coordonnees.city = null;
      },
      change: function () {
        var selection = vm.societyComboBox.getSelectedItem();
        if (selection === null) {
          vm.coordonnees.societyId = null;
          vm.coordonnees.societyName = null;
          vm.coordonnees.city = null;
        }
      }
    };

    var displayMember = 'name';
    if (vm.language === 'en') {
      displayMember = 'nameEn';
    }

    vm.initMetierDropDownList = function () {
      PersonMetier.query(
        {},
        function (result) {
          result.sort(function (r1, r2) {
            var l1 = r1[displayMember].toLowerCase();
            var l2 = r2[displayMember].toLowerCase();
            return l1.localeCompare(l2);
          });
          var dataAdapter = new $.jqx.dataAdapter({
            // jshint ignore:line
            localdata: result,
            datatype: 'json',
            datafields: [
              {
                name: 'id'
              },
              {
                name: 'name'
              },
              {
                name: 'nameEn'
              },
              {
                name: 'personStatuses',
                type: 'array'
              }
            ]
          });
          vm.metierDropDownListSettings.source = dataAdapter;
        },
        showError
      );
    };

    vm.metierDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id',
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder(vm.language)
    };

    vm.metierSelected = false;
    vm.onMetierSelect = function (event) {
      var args = event.args;
      if (args) {
        if (vm.statusDropDownList != null) {
          vm.statusDropDownList.clearSelection();
          vm.status = null;
        }
        var metier = args.item.originalItem;
        vm.coordonnees.personMetierId = metier.id;
        vm.metierSelected = true;

        metier.personStatuses.sort(function (r1, r2) {
          var l1 = r1[displayMember].toLowerCase();
          var l2 = r2[displayMember].toLowerCase();
          return l1.localeCompare(l2);
        });

        var dataAdapter = new $.jqx.dataAdapter({
          localdata: metier.personStatuses,
          datatype: 'json',
          datafields: [
            {
              name: 'id'
            },
            {
              name: 'name'
            },
            {
              name: 'nameEn'
            }
          ]
        });
        vm.statusDropDownListSettings.source = dataAdapter;
      }
    };

    vm.statusDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id',
      autoDropDownHeight: true,
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder(vm.language),
      created: function (args) {
        vm.statusDropDownList = args.instance;
      }
    };

    vm.onStatusSelect = function (event) {
      var args = event.args;
      if (args) {
        var value = args.item.value;
        vm.coordonnees.personStatusId = value;
      }
    };

    vm.initCountryList = function () {
      vm.countries = [];
      CustomUtils.getCountries().then(function (result) {
        angular.forEach(result, function (value, key) {
          if (value.alpha2Code === vm.defaultCountry) {
            vm.countryToSelect = value.name;
          }
          vm.countries.push({
            name: value.name
          });
        });
      });
    };

    vm.checkPhone = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.coordonnees.phone);
      var isPortable = CustomUtils.isPhoneNumber(correctedPhone);
      if (vm.coordonnees.phone !== correctedPhone) {
        $timeout(function () {
          $(event.target).jqxMaskedInput('clear');
          $(event.target).jqxMaskedInput('val', correctedPhone);
        }, 200);
      }
      vm.personForm.phone.$setValidity('minlength', isPortable);
    };

    vm.checkPortable = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.coordonnees.portable);
      if (!CustomUtils.isEmpty(correctedPhone)) {
        var isPortable = CustomUtils.isPhoneNumber(correctedPhone);
        vm.personForm.portable.$setValidity('minlength', isPortable);
        if (vm.coordonnees.portable !== correctedPhone) {
          $timeout(function () {
            $(event.target).jqxMaskedInput('clear');
            $(event.target).jqxMaskedInput('val', correctedPhone);
          }, 200);
        }
      } else {
        vm.personForm.portable.$setValidity('minlength', true);
      }
    };

    vm.trySendSubmission = function (submission) {
      vm.showValidityError = false;

      var send = vm.personForm.$valid && !vm.isSending && vm.confirmSend;
      if (send) {
        if (submission) {
          send = !vm.formHasErrors(vm.projectForm);
        } else {
          send = !vm.projectFormHasErrorsForSave();
        }
      }
      if (send && vm.callForProject.managed && submission) {
        send = vm[vm.formName].$valid;
      }

      if (send) {
        if (submission) {
          var title = $translate.instant('canceropoleGsoApp.submission.confirmSaveTitle');
          var msg = $translate.instant('canceropoleGsoApp.submission.confirmSaveContent');

          ConfirmDialog.openYesNo(title, msg, function (result) {
            if (result === true) {
              vm.sendSubmission(submission);
            }
          });
        } else {
          vm.sendSubmission(submission);
        }
      } else {
        vm.showValidityError = true;
      }
    };

    vm.sendSubmission = function (submission) {
      //correct phone number
      vm.coordonnees.portable = CustomUtils.correctPhone(vm.coordonnees.portable);
      vm.coordonnees.phone = CustomUtils.correctPhone(vm.coordonnees.phone);

      if (CustomUtils.isUndefinedOrNull(vm.coordonnees.civilite)) {
        vm.coordonnees.civilite = 'mr';
      }

      vm.submit = submission;
      vm.isSending = true;

      var submissionFields = [];
      angular.forEach(vm.fields, function (field) {
        if (field.type === 'checkbox') {
          field.value = JSON.stringify(field.value);
        } else if (field.value === true || field.value === false) {
          field.value = field.value.toString();
        } else if (field.type === 'ifYes') {
          if (field.valueRadio === false) {
            field.value = 'false';
          } else {
            field.value = _.escape(field.textValue);
          }
        } else if (field.type === 'date') {
          field.value = $filter('date')(field.value, 'MM/dd/yyyy');
        } else if (field.type === 'ifNo') {
          if (field.valueRadio === true) {
            field.value = 'true';
          } else {
            field.value = _.escape(field.textValue);
          }
        } else if (field.type === 'table') {
          field.value = JSON.stringify(field.value);
        } else if (field.type === 'editor') {
          field.value = field.value;
        } else {
          field.value = _.escape(field.value);
        }
        if (!CustomUtils.isEmpty(field.value)) {
          submissionFields.push({
            callForProjectFormFieldId: field.id,
            response: field.value
          });
        }
      });

      vm.submission.name = vm.project.name;

      if (!vm.callForProject.askDuration && vm.callForProject.defaultDuration) {
        vm.submission.askedDuration = vm.callForProject.defaultDuration;
      }
      if (!vm.callForProject.askBudget && vm.callForProject.defaultBudget) {
        vm.submission.askedBudget = vm.callForProject.defaultBudget;
      }

      vm.checkAndSetMax();

      var toSend = {};
      toSend.coordonnees = vm.coordonnees;
      toSend.submission = vm.submission;
      toSend.responses = submissionFields;
      toSend.project = vm.project;

      $log.info(toSend);

      var url = 'api/submission/responses';
      if (submission) {
        url += '?stepOne=true';
      }

      $http.post(url, toSend).then(
        function (response) {
          vm.isSending = false;
          if (response.status === 200) {
            var result = response.data;
            $translate.use(vm.preferedLanguage);
            $state.go('callForProjectSubmissionConfirmation', {
              id: result.submission.id,
              confirmation: result,
              callForProject: vm.callForProject,
              managements: managements
            });
          }
        },
        function (error) {
          vm.isSending = false;
        }
      );
    };

    vm.projectFormHasErrorsForSave = function () {
      if (angular.isDefined(vm.projectForm)) {
        var errors = vm.projectForm.$error;
        if (errors && errors.required) {
          if (vm.callForProject.canSaveSubmission) {
            var requireds = errors.required;
            var hasError = false;
            angular.forEach(requireds, function (aRequiredField) {
              if (aRequiredField.$name === 'projectName') {
                hasError = true;
              }
            });
            return hasError;
          }
          return true;
        }
      }
      return false;
    };

    vm.formHasErrors = function (form) {
      if (angular.isDefined(form)) {
        if (angular.isString(form)) {
          if (form === vm.formName && vm.formName.length > 0) {
            form = vm[vm.formName];
          } else {
            return false;
          }
        }
        var errors = form.$error;
        if (errors && errors.required) {
          return true;
        }
      }
      return false;
    };

    vm.formErrors = [];

    vm.sameErrors = function (errors) {
      if (vm.formErrors.length === errors.length) {
        for (var i = 0; i < vm.formErrors.length; i++) {
          var oldFormError = vm.formErrors[i];
          var newFormError = errors[i];

          if (oldFormError.id === newFormError.id && oldFormError.fields.length === newFormError.fields.length) {
            for (var j = 0; j < oldFormError.fields.length; j++) {
              var oldFieldError = oldFormError.fields[j];
              var newFieldError = newFormError.fields[j];

              if (oldFieldError.id !== newFieldError.id) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
        return true;
      }
      return false;
    };

    vm.getFormErrors = function () {
      var errors = vm[vm.formName].$error;
      if (errors && (errors.required || errors.maxlength)) {
        var fields = [];
        for (var i = 0; i < vm.fields.length; i++) {
          for (var index in errors.required) {
            var error = errors.required[index];
            var field = vm.fields[i];
            if (error.$name.startsWith(field.name)) {
              fields.push(field);
              break;
            }
          }
          for (var index in errors.maxlength) {
            var error = errors.maxlength[index];
            var field = vm.fields[i];
            if (error.$name.startsWith(field.name)) {
              fields.push(field);
              break;
            }
          }
        }

        var result = [];
        angular.forEach(fields, function (field) {
          var group = null;
          for (var i = 0; i < result.length; i++) {
            var aGroup = result[i];
            if (aGroup.id === field.callForProjectFormGroupId) {
              group = aGroup;
              break;
            }
          }
          if (group === null) {
            group = {
              id: field.callForProjectFormGroupId,
              label: field.callForProjectFormGroupName,
              fields: []
            };
            result.push(group);
          }
          group.fields.push({
            id: field.id,
            label: field.label
          });
        });
        var same = vm.sameErrors(result);
        if (!same) {
          vm.formErrors = result;
        }
      } else {
        vm.formErrors = [];
      }
      return vm.formErrors;
    };

    var teamSource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var teamDataAdapter = new $.jqx.dataAdapter(teamSource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        TeamSearch.query(
          {
            query: vm.teamComboBoxSettings.searchString,
            page: 0,
            size: 20
          },
          function (result) {
            callback({
              records: result
            });
          }
        );
      }
    });

    vm.teamComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      remoteAutoComplete: true,
      //remoteAutoCompleteDelay: 100,
      source: teamDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.teamComboBoxSettings.searchString = searchString;
        teamDataAdapter.dataBind();
      },
      created: function (args) {
        vm.teamComboBox = args.instance;
      },
      select: function (event) {
        if (event.args) {
          var item = event.args.item;
          var team = item.originalItem;
          vm.team = team;
        }
      },
      unselect: function () {
        vm.team = null;
      },
      change: function () {
        var selection = vm.teamComboBox.getSelectedItem();
        if (selection === null) {
          vm.team = null;
        }
      }
    };

    vm.getMaxBudget = function () {
      if (vm.callForProject && vm.callForProject.defaultBudget) {
        return vm.callForProject.defaultBudget;
      }
      return 1000000;
    };

    vm.getMaxDuration = function () {
      if (vm.callForProject && vm.callForProject.defaultDuration) {
        return vm.callForProject.defaultDuration;
      }
      return 10000;
    };

    vm.checkAndSetMax = function () {
      var max = vm.getMaxBudget();
      if (vm.submission.askedBudget > max) {
        vm.submission.askedBudget = max;
      }
      max = vm.getMaxDuration();
      if (vm.submission.askedDuration > max) {
        vm.submission.askedDuration = max;
      }
    };

    vm.load();
  }
})();
