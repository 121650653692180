(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ExportEventRegistrationsDialogController', ExportEventRegistrationsDialogController);

    ExportEventRegistrationsDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'eventRegistrations', 'exportType', '$http', 'DataUtils', 'AlertService', 'eventDays', 'DateUtils', 'ModelConfig', '$filter', 'eventOptions'];

    function ExportEventRegistrationsDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, eventRegistrations, exportType, $http, DataUtils, AlertService, eventDays, DateUtils, ModelConfig, $filter, eventOptions) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.registrations = eventRegistrations;
        ctrl.eventOptions = eventOptions;
        ctrl.exportType = exportType;
        ctrl.columns = [{
                text: "Date",
                datafield: "registrationDate",
                visible: false
            }, {
                text: "Civilité",
                datafield: "civilite",
                visible: false
            }, {
                text: "Prénom",
                datafield: "firstName",
                visible: false
            }, {
                text: "Nom",
                datafield: "lastName",
                visible: false
            }, {
                text: "Societe",
                datafield: "societyName",
                visible: false
            }, {
                text: "Métier",
                datafield: "metier",
                visible: false
            }, {
                text: "Statut",
                datafield: "status",
                visible: false
            }, {
                text: "Ville",
                datafield: "city",
                visible: false
            }, {
                text: "email",
                datafield: "email",
                visible: false
            }, {
                text: "Téléphone",
                datafield: "phone",
                visible: false
            }, {
                text: "Tarif",
                datafield: "totalPrice",
                visible: false
            }, {
                text: "Organisme payeur",
                datafield: "facturationSocietyName",
                visible: false
            }, {
                text: "Ville de l'organisme payeur",
                datafield: "facturationSocietyCity",
                visible: false
            }, {
                text: "Id",
                datafield: "id",
                visible: false
            }, {
                text: "Référence bon de commande",
                datafield: "orderRef",
                visible: false
            }, {
                text: 'Référence facture',
                datafield: 'billRef',
                visible: false
            }, {
                text: 'Confirmé',
                datafield: 'confirmed',
                visible: false
            }, {
                text: 'Présence',
                datafield: 'confirmedPresence',
                visible: false
            }];

        ctrl.eventDays = eventDays;

        var rangeId = 0;
        angular.forEach(ctrl.eventDays, function (eventDay) {
            eventDay.ranges = [];

            eventDay.eventSessions.sort(function (s1, s2) {
                var startDate = DateUtils.convertDateTimeFromServer(s1.startDate);
                var endDate = DateUtils.convertDateTimeFromServer(s2.startDate);
                return DateUtils.compare(startDate, endDate);
            });

            angular.forEach(eventDay.eventSessions, function (eventSession) {
                var startDate = DateUtils.convertDateTimeFromServer(eventSession.startDate);
                var endDate = DateUtils.convertDateTimeFromServer(eventSession.endDate);

                var range = null;
                angular.forEach(eventDay.ranges, function (aRange) {
                    var start = DateUtils.inRange(startDate, aRange.startDate, aRange.endDate);
                    var end = DateUtils.inRange(endDate, aRange.startDate, aRange.endDate);
                    if (start && end) {
                        range = aRange;
                    }
                });

                if (range === null) {
                    //nouveau range
                    range = {
                        id: rangeId,
                        startDate: startDate,
                        endDate: endDate,
                        sessions: []
                    };

                    ctrl.columns.push({
                        text: $filter("date")(startDate, "dd/MM/yyyy") + " - session " + $filter("date")(startDate, "HH:mm") + " au " + $filter("date")(endDate, "HH:mm"),
                        datafield: "range#" + $filter("date")(startDate, "dd/MM/yyyy HH:mm:ss") + "#" + $filter("date")(endDate, "dd/MM/yyyy HH:mm:ss"),
                        visible: false
                    });
                    rangeId++;
                    range.sessions.push(eventSession);
                    eventDay.ranges.push(range);
                } else {
                    range.sessions.push(eventSession);
                }
                if (eventSession.sessionType === ModelConfig.eventSession.sessionType.plenary) {
                    //finalement aucune session selectionnée par défaut
                    //range.selected = eventSession;
                }
            });
        });

        angular.forEach(ctrl.eventOptions, function (eventOption) {
            ctrl.columns.push({
                text: 'Option : ' + eventOption.name,
                datafield: 'option#' + eventOption.id,
                visible: false
            });
        });

        ctrl.ok = function () {
            var selectedColumns = {};
            angular.forEach(ctrl.columns, function (column) {
                if (column.visible === true) {
                    selectedColumns[column.text] = column.datafield;
                }
            });

            var dataIds = [];
            angular.forEach(ctrl.registrations, function (registration) {
                dataIds.push(registration.id);
            });

            ctrl.result = {
                columns: selectedColumns,
                type: exportType,
                data: dataIds
            };

            $http({
                url: "api/event-registrations/export",
                method: "POST",
                data: ctrl.result
            }).then(function (response) {
                if (response.data) {
                    var data = response.data;
                    if (data.errors) {
                        AlertService.error(data.errors);
                    } else {
                        DataUtils.downloadFile(response.data.content, "Participations.xls");
                    }
                }
            });

            $uibModalInstance.close(ctrl.result);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();