(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('sheetTitle', {
                controller: 'SheetTitleController',
                bindings: {
                    type: '@',
                    entity: '=',
                    save: '=',
                    remove: '='
                },
                templateUrl: 'app/sheet/components/title/sheet.title.html'
            });
})();
