(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('RemoveSubmissionDialogController', RemoveSubmissionDialogController);

    RemoveSubmissionDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', '$http', '$log', 'submission', 'expertises'];

    function RemoveSubmissionDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, $http, $log, submission, expertises) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.isSaving = false;
        ctrl.callForProject = callForProject;
        ctrl.submission = submission;
        ctrl.expertises = expertises;
        ctrl.deleteProject = true;

        ctrl.ok = function () {
            if (ctrl.isSaving === false) {
                ctrl.isSaving = true;
                $http.delete("api/call-for-projects/submission/" + ctrl.submission.id + "?project=" + ctrl.deleteProject).then(onSaveSuccess, onSaveError);
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError(response) {
            ctrl.isSaving = false;
            $log.error(response);
        }

    }
})();