(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ExpertiseDetailController', ExpertiseDetailController);

    ExpertiseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Expertise', 'Expert', 'SubmissionResponseEvaluation', 'Submission'];

    function ExpertiseDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Expertise, Expert, SubmissionResponseEvaluation, Submission) {
        var vm = this;

        vm.expertise = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:expertiseUpdate', function(event, result) {
            vm.expertise = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
