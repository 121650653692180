(function() {
  'use strict';

  angular.module('canceropoleGsoApp').controller('AddTableFieldController', AddTableFieldController);

  AddTableFieldController.$inject = ['CustomUtils', 'JQWidgetsService', '$scope', '$log', '$timeout'];

  function AddTableFieldController(CustomUtils, JQWidgetsService, $scope, $log, $timeout) {
    var ctrl = this;

    var defaultValues = [
      {
        col_0: 'Personnel',
        col_1: '',
        col_2: ''
      },
      {
        col_0: 'Equipement',
        col_1: '',
        col_2: ''
      },
      {
        col_0: 'Fonctionnement',
        col_1: '',
        col_2: ''
      },
      {
        col_0: 'Total',
        col_1: '',
        col_2: ''
      }
    ];

    var defaultCols = [
      {
        datafield: 'col_0',
        text: 'Dépenses',
        align: 'center',
        width: 33
      },
      {
        datafield: 'col_1',
        text: 'Coût TTC (€)',
        align: 'center',
        width: 33
      },
      {
        datafield: 'col_2',
        text: 'Nature de la dépense',
        align: 'center',
        width: 33
      }
    ];

    if (_.isNil(ctrl.config)) {
      ctrl.config = {};
      ctrl.config.cols = defaultCols;
      ctrl.config.values = defaultValues;
    }

    ctrl.nbLine = ctrl.config.values.length;
    ctrl.nbCol = ctrl.config.cols.length;

    ctrl.addLine = function() {
      var newLine = {};
      for (var i = 0; i < this.nbCol; i++) {
        newLine['col_' + i] = '';
      }
      this.config.values.push(newLine);
    };
    ctrl.removeLine = function() {
      this.config.values = _.take(this.config.values, this.config.values.length - 1);
    };

    ctrl.nbLineChange = function(event) {
      var newVal = event.args.value;
      if (newVal == 0) {
        return;
      }

      if (newVal != ctrl.config.values.length) {
        if (newVal > ctrl.config.values.length) {
          ctrl.addLine();
        } else {
          ctrl.removeLine();
        }
        ctrl.refreshTable();
      }
    };

    ctrl.nbColChange = function(event) {
      var val = Number(event.args.value);
      if (val === 0) {
        return;
      }

      var newCols = [];
      for (var i = 0; i < val; i++) {
        var label = 'Colonne ' + (i + 1);
        var align = 'center';
        var width = 100 / val;
        var datafield = 'col_' + i;
        if (!_.isNil(ctrl.config.cols[i])) {
          var col = ctrl.config.cols[i];
          label = col.text;
          align = col.align;
          datafield = col.datafield;
        }
        newCols.push({
          text: label,
          align: align,
          width: width,
          datafield: datafield
        });
      }
      if (newCols.length > 0) {
        ctrl.config.cols = newCols;
        ctrl.nbCol = newCols.length;
      }

      _.each(ctrl.config.values, function(value) {
        var key = _.find(Object.keys(value), function(key) {
          var found = false;
          for (var j = 0; j < ctrl.config.cols.length; j++) {
            var col = ctrl.config.cols[j];
            if (col.datafield === key) {
              found = true;
              break;
            }
          }
          return !found;
        });

        if (!_.isNil(key)) {
          delete value[key];
        }
      });

      ctrl.refreshTable();
    };

    var tooltiprenderer = function(element) {
      $(element)
        .parent()
        .jqxTooltip({ position: 'mouse', content: $(element).text() });
    };

    ctrl.computeTotalWidth = function(event) {
      var currentDatafield = event.data;
      var total = 0;
      var max = 100;
      var otherCol = null;
      for (var i = 0; i < ctrl.nbCol; i++) {
        var col = ctrl.config.cols[i];
        total += col.width;
        if (col.datafield !== currentDatafield) {
          otherCol = col;
        }
      }
      if (total < max && otherCol) {
        otherCol.width += max - total;
      } else if (total > max && otherCol) {
        otherCol.width -= total - max;
      }

      ctrl.refreshTable();
    };

    ctrl.refreshTable = function() {
      var datafields = [];
      var columns = [];
      for (var i = 0; i < ctrl.nbCol; i++) {
        var col = ctrl.config.cols[i];
        var text = col.text;
        var dataField = 'col_' + i;
        datafields[datafields.length] = {
          name: dataField
        };
        columns[columns.length] = {
          text: text,
          datafield: dataField,
          rendered: tooltiprenderer,
          width: col.width + '%',
          align: col.align,
          cellsalign: col.align
        };
      }

      var source = {
        //unboundmode: true,
        //totalrecords: ctrl.table.nbLine,
        datafields: datafields,
        localdata: ctrl.config.values,
        updaterow: function(rowid, rowdata, commit) {
          console.log(arguments);
          ctrl.config.values[rowid] = rowdata;
          commit(true);
        }
      };
      var dataAdapter = new $.jqx.dataAdapter(source);

      ctrl.gridSettings = {
        autoheight: true,
        width: '100%',
        source: dataAdapter,
        editable: true,
        columnsresize: false,
        selectionmode: 'multiplecellsadvanced',
        columns: columns
      };
    };
  }
})();
