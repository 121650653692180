(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('MailFieldController', MailFieldController);

    MailFieldController.$inject = ['$log', 'ConfirmDialog', 'DateUtils', '$uibModal', 'CustomUtils', '$state', 'CallForProjectFormField', 'JQWidgetsService', '$translate'];

    function MailFieldController($log, ConfirmDialog, DateUtils, $uibModal, CustomUtils, $state, CallForProjectFormField, JQWidgetsService, $translate) {
        var ctrl = this;

        ctrl.underEdition = false;

        ctrl.templateList = [{
            label: 'Nom du destinataire',
            value: '##nom##'
        }, {
            label: 'Prénom du destinataire',
            value: '##prenom##'
        }, {
            label: "Lien vers l'espace personnel",
            value: '##espace_perso##'
        }];
        if (ctrl.templateType) {
            var specificTemplates = [];
            if (ctrl.templateType === 'mainevent') {
                specificTemplates = [{
                    label: "Identifiant d'inscription",
                    value: '##id_inscription##'
                }, {
                    label: "Date limite d'inscription",
                    value: '##limite_inscription##'
                }, {
                    label: 'Date tarif préférentiel',
                    value: '##limite_tarif_pref##'
                }, {
                    label: 'Date limite soumission communication orale',
                    value: '##limit_oral_comm##'
                }, {
                    label: 'Date limite soumission poster',
                    value: '##limit_poster_comm##'
                }, {
                    label: 'Tarif',
                    value: '##tarif##'
                }, {
                    label: "Titre de l'abstract",
                    value: '##titre_abstract##'
                }, {
                    label: "Référence de l'abstract",
                    value: '##ref_abstract##'
                }, {
                    label: "Thématique de l'abstract",
                    value: '##theme_abstract##'
                }, {
                    label: "Nom de la session de l'abstract",
                    value: '##nom_session##'
                }];
            } else if (ctrl.templateType === 'callforproject') {
                specificTemplates = [{
                    label: "Titre du projet",
                    value: '##titre_projet##'
                }, {
                    label: "Montant sollicité",
                    value: '##montant_sollicite##'
                }, {
                    label: "Montant attribué",
                    value: '##montant_attribue##'
                }, {
                    label: "Durée demandée",
                    value: "##duree_demandee##"
                }, {
                    label: "Durée attribuée",
                    value: "##duree_attribuee##"
                }, {
                    label: 'Date limite de soumission (formulaire 1)',
                    value: '##limite_soumission_formulaire_1##'
                }, {
                    label: 'Date limite de soumission (formulaire 2)',
                    value: '##limite_soumission_formulaire_2##'
                }, {
                    label: 'Nom de la thématique choisie',
                    value: '##thematique_name##'
                }, {
                    label: 'Coordinateur scientifique de la thématique choisie',
                    value: '##thematique_coordinateur##'
                }, {
                    label: "Email coordinateur scientifique",
                    value: '##email_coordinateur##'
                }, {
                    label: "Téléphone coordinateur scientifique",
                    value: '##tel_coordinateur##'
                }, {
                    label: "Chargé de mission de la thématique choisie",
                    value: '##thematique_charge_mission##'
                }, {
                    label: "Email chargé de mission",
                    value: '##email_charge_mission##'
                }, {
                    label: "Tel chargé de mission",
                    value: '##tel_charge_mission##'
                }];
            } else if (ctrl.templateType === 'expert') {
                /*specificTemplates = [{
                 label: "Titre du projet",
                 value: '##titre_projet##'
                 }, {
                 label: "Coordinateur scientifique de l'axe choisi",
                 value: '##axe_coordinateur##'
                 }, {
                 label: "Email coordinateur scientifique",
                 value: '##email_coordinateur##'
                 }, {
                 label: "Téléphone coordinateur scientifique",
                 value: '##tel_coordinateur##'
                 }, {
                 label: "Chargé de mission de l'axe choisi",
                 value: '##axe_charge_mission##'
                 }, {
                 label: "Email chargé de mission",
                 value: '##email_charge_mission##'
                 }, {
                 label: "Tel chargé de mission",
                 value: '##tel_charge_mission##'
                 }];*/
            }
            ctrl.templateList = ctrl.templateList.concat(specificTemplates);
        }

        ctrl.edit = function () {
            ctrl.underEdition = true;
        };

        ctrl.save = function () {
            ctrl.underEdition = false;
            if (ctrl.onSave) {
                ctrl.cleanMailContent();
                ctrl.onSave({
                    id: ctrl.id,
                    title: ctrl.mailTitle,
                    content: ctrl.content
                });
            }
        };

        ctrl.cleanMailContent = function () {
            if (ctrl.content) {
                // #587
                ctrl.content = ctrl.content.replaceAll('text-decoration-line', 'text-decoration');
            }
        };

        ctrl.tryRemove = function () {
            var msg = CustomUtils.safeQuotes(ctrl.mailTitle);
            ConfirmDialog.openConfirmRemove(msg, function () {
                if (ctrl.onRemove) {
                    ctrl.onRemove({
                        id: ctrl.id
                    });
                }
            });
        };

        ctrl.editorSettings = {
            pasteMode: 'text',
            height: 400,
            tools: JQWidgetsService.getDefaultAdminEditorTools(),
            createCommand: JQWidgetsService.getEditorCommand(ctrl.content)
        };

        ctrl.cancel = function () {
            ctrl.underEdition = false;
            if (ctrl.onCancel) {
                ctrl.onCancel({
                    id: ctrl.id
                });
            }
        };
    }
})();
