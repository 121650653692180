(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('unsafe', unsafe);

    unsafe.$inject = ['$sce', 'CustomUtils'];

    function unsafe($sce, CustomUtils) {
        return unsafeFilter;

        function unsafeFilter(text) {
            if (!CustomUtils.isEmpty(text)) {
                if (angular.isString(text)) {
                    return $sce.trustAsHtml(text);
                } else {
                    return text.toString();
                }
            }
            return "";
        }
    }
})();
