(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectTypeDeleteController',CallForProjectTypeDeleteController);

    CallForProjectTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CallForProjectType'];

    function CallForProjectTypeDeleteController($uibModalInstance, entity, CallForProjectType) {
        var vm = this;

        vm.callForProjectType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CallForProjectType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
