(function() {
    "use strict";

    angular.module("canceropoleGsoApp").factory("DateUtils", DateUtils);

    DateUtils.$inject = ["$filter"];

    function DateUtils($filter) {
        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            inRange: inRange,
            compare: compare,
            compareWithoutTime: compareWithoutTime,
            compareWithUndefinedDate: compareWithUndefinedDate,
            toDate: toDate
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                if (_.isString(date) && date.indexOf("+") !== 1) {
                    return moment(date).toDate();
                }
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split("-");
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter("date")(date, "yyyy-MM-dd");
            } else {
                return null;
            }
        }

        function toDate(aDate) {
            if (typeof aDate === "string") {
                return this.convertDateTimeFromServer(aDate);
            }
            return aDate;
        }

        function inRange(d, start, end) {
            d = this.toDate(d);
            start = this.toDate(start);
            end = this.toDate(end);

            // Checks if date in d is between dates in start and end.
            // Returns a boolean or NaN:
            //    true  : if d is between start and end (inclusive)
            //    false : if d is before start or after end
            //    NaN   : if one or more of the dates is illegal.
            // NOTE: The code inside isFinite does an assignment (=).
            return isFinite((d = d.valueOf())) && isFinite((start = start.valueOf())) && isFinite((end = end.valueOf()))
                ? start <= d && d <= end
                : NaN;
        }

        function compare(a, b) {
            a = this.toDate(a);
            b = this.toDate(b);
            // Compare two dates (could be of any type supported by the convert
            // function above) and returns:
            //  -1 : if a < b
            //   0 : if a = b
            //   1 : if a > b
            // NaN : if a or b is an illegal date
            // NOTE: The code inside isFinite does an assignment (=).
            return isFinite((a = a.valueOf())) && isFinite((b = b.valueOf())) ? (a > b) - (a < b) : NaN;
        }

        function compareWithUndefinedDate(a, b) {
            if (a != null && b != null) {
                return this.compare(a, b);
            } else if (a == null && b != null) {
                return -1;
            } else if (a != null && b == null) {
                return 1;
            } else {
                return 0;
            }
        }

        function compareWithoutTime(a, b) {
            a = this.toDate(a);
            b = this.toDate(b);

            a = new Date(a).setHours(0, 0, 0, 0);
            b = new Date(b).setHours(0, 0, 0, 0);
            return this.compare(a, b);
        }
    }
})();
