(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('AntivirusController', AntivirusController);

    AntivirusController.$inject = ['AlertService', '$http', '$translate', 'cron', 'stats', 'url', 'status', '$state'];

    function AntivirusController(AlertService, $http, translate, cron, stats, url, status, $state) {
        var vm = this;
        vm.stats = stats;
        vm.url = url;
        vm.status = status;
        vm.cron = cron;

        vm.reloadDatabase = function () {
            $http.get('api/antivirus/reloadVirusDatabases').then(function () {
                $state.reload();
            });
        };

    }
})();
