(function () {
  'use strict';

  angular.module('canceropoleGsoApp').config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('axe', {
        parent: 'entity',
        url: '/axe',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.axe.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/axe/axes.html',
            controller: 'AxeController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('axe');
              $translatePartialLoader.addPart('global');
              return $translate.refresh();
            }
          ]
        }
      })
      .state('axe-detail', {
        parent: 'entity',
        url: '/axe/{id}',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'canceropoleGsoApp.axe.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/axe/axe-detail.html',
            controller: 'AxeDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('axe');
              return $translate.refresh();
            }
          ],
          entity: [
            '$stateParams',
            'Axe',
            function ($stateParams, Axe) {
              return Axe.get({ id: $stateParams.id }).$promise;
            }
          ]
        }
      })
      .state('axe.new', {
        parent: 'axe',
        url: '/new',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/axe/axe-dialog.html',
                controller: 'AxeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: function () {
                    return {
                      name: null,
                      nameEn: null,
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go('axe', null, { reload: true });
                },
                function () {
                  $state.go('axe');
                }
              );
          }
        ]
      })
      .state('axe.edit', {
        parent: 'axe',
        url: '/{id}/edit',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/axe/axe-dialog.html',
                controller: 'AxeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                  entity: [
                    'Axe',
                    function (Axe) {
                      return Axe.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('axe', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      })
      .state('axe.delete', {
        parent: 'axe',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        onEnter: [
          '$stateParams',
          '$state',
          '$uibModal',
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: 'app/entities/axe/axe-delete-dialog.html',
                controller: 'AxeDeleteController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                  entity: [
                    'Axe',
                    function (Axe) {
                      return Axe.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go('axe', null, { reload: true });
                },
                function () {
                  $state.go('^');
                }
              );
          }
        ]
      });
  }
})();
