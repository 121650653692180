(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SessionRemoveDialogController', SessionRemoveDialogController);

    SessionRemoveDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'session', '$log'];

    function SessionRemoveDialogController($translate, $translatePartialLoader, $uibModalInstance, session, $log) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.session = session;

        ctrl.abstractNb = ctrl.session.abstractNumber;
        ctrl.moderatorNb = ctrl.session.moderators.length;
        ctrl.participationNb = ctrl.session.participations;

        $log.info(ctrl);

        ctrl.canRemove = function () {
            if (ctrl.abstractNb === 0 && ctrl.moderatorNb === 0 && ctrl.participationNb === 0) {
                return true;
            }
            return false;
        };

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.session);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();