(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('avatarSelection', {
                controller: 'AvatarSelectionController',
                bindings: {
                    file: '=',
                    fileContentType: '='
                },
                templateUrl: 'app/components/avatars/avatar-selection.html'
            });
})();
