(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('GeositeDetailController', GeositeDetailController);

    GeositeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Geosite'];

    function GeositeDetailController($scope, $rootScope, $stateParams, entity, Geosite) {
        var vm = this;

        vm.geosite = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:geositeUpdate', function(event, result) {
            vm.geosite = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
