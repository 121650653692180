(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('AbstractPersonDetailController', AbstractPersonDetailController);

    AbstractPersonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AbstractPerson', 'Person', 'AbstractSociety', 'EventAbstract'];

    function AbstractPersonDetailController($scope, $rootScope, $stateParams, entity, AbstractPerson, Person, AbstractSociety, EventAbstract) {
        var vm = this;

        vm.abstractPerson = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:abstractPersonUpdate', function(event, result) {
            vm.abstractPerson = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
