(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LinkAbstractDialogController', LinkAbstractDialogController);

    LinkAbstractDialogController.$inject = ['$uibModalInstance', 'project', '$log', 'CustomUtils', '$timeout', 'EventAbstractSearch'];

    function LinkAbstractDialogController($uibModalInstance, project, $log, CustomUtils, $timeout, EventAbstractSearch) {
        var ctrl = this;
        ctrl.project = project;
        ctrl.abstract = null;

        var abstractSource = {
            datatype: 'json',
            datafields: [{
                    name: 'id'
                }, {
                    name: 'title'
                }],
            id: 'id',
            url: 'api/_search/person',
            data: {
                size: '20'
            }
        };

        var abstractDataAdapter = new $.jqx.dataAdapter(abstractSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                EventAbstractSearch.query({
                    query: ctrl.abstractComboBoxSettings.searchString,
                    page: 0,
                    size: 8
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.abstractComboBoxSettings = {
            displayMember: 'title',
            valueMember: 'id',
            minLength: 1,
            width: '100%',
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            source: abstractDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.abstractComboBoxSettings.searchString = searchString;
                abstractDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.abstractComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var item = event.args.item;
                    if (CustomUtils.isDefined(item)) {
                        ctrl.abstract = item.originalItem;
                    }
                }
            }
        };

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.abstract);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();