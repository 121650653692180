(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('expertise', {
                    parent: 'entity',
                    url: '/expertise',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.expertise.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/expertise/expertise.html',
                            controller: 'ExpertiseController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('expertise');
                                $translatePartialLoader.addPart('cFPFormType');
                                $translatePartialLoader.addPart('expertiseState');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('expertise-detail', {
                    parent: 'entity',
                    url: '/expertise/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.expertise.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/expertise/expertise-detail.html',
                            controller: 'ExpertiseDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('expertise');
                                $translatePartialLoader.addPart('cFPFormType');
                                $translatePartialLoader.addPart('expertiseState');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'Expertise', function ($stateParams, Expertise) {
                                return Expertise.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('expertise.new', {
                    parent: 'expertise',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/expertise/expertise-dialog.html',
                                controller: 'ExpertiseDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            evaluationDate: null,
                                            generalEvaluation: null,
                                            type: null,
                                            generalNotation: null,
                                            generalAdvice: null,
                                            state: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('expertise', null, {reload: true});
                            }, function () {
                                $state.go('expertise');
                            });
                        }]
                })
                .state('expertise.edit', {
                    parent: 'expertise',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/expertise/expertise-dialog.html',
                                controller: 'ExpertiseDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['Expertise', function (Expertise) {
                                            return Expertise.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('expertise', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('expertise.delete', {
                    parent: 'expertise',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/expertise/expertise-delete-dialog.html',
                                controller: 'ExpertiseDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['Expertise', function (Expertise) {
                                            return Expertise.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('expertise', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
