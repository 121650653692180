(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('AddMainEventMailDialogController', AddMainEventMailDialogController);

    AddMainEventMailDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'mail', 'JQWidgetsService', '$log', 'MainEventMail', 'existingMails', 'MainEventService', 'mainEvent', '$timeout'];

    function AddMainEventMailDialogController($translate, $translatePartialLoader, $uibModalInstance, mail, JQWidgetsService, $log, MainEventMail, existingMails, MainEventService, mainEvent, $timeout) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.mail = mail;
        ctrl.mainEvent = mainEvent;

        ctrl.ok = function () {
            ctrl.isSaving = true;
            if (ctrl.mail.id) {
                MainEventMail.update(ctrl.mail, onSaveSuccess, onSaveError);
            } else {
                MainEventMail.save(ctrl.mail, onSaveSuccess, onSaveError);
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            ctrl.isSaving = false;
        }

        function onSaveError() {
            ctrl.isSaving = false;
        }

        $log.info(existingMails);

        ctrl.typeSource = MainEventService.getMailTypes(ctrl.mainEvent);

        for (var key in existingMails) {
            var existingMail = existingMails[key];
            var indexToRemove = null;
            for (var i = 0; i < ctrl.typeSource.length; i++) {
                if (ctrl.typeSource[i].value === existingMail.type) {
                    indexToRemove = i;
                    break;
                }
            }
            if (indexToRemove !== null) {
                ctrl.typeSource.splice(indexToRemove, 1);
            }
        }

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: 'label',
            valueMember: 'value',
            source: ctrl.typeSource,
            change: function (event) {
                if (event.args) {
                    ctrl.editForm.$setDirty();
                    var item = event.args.item;
                    if (item) {
                        ctrl.help = item.originalItem.help;
                    }
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.typeSource.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };
    }
})();
