(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddTeamDialogController', AddTeamDialogController);

    AddTeamDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'team', 'society', 'SocietySearch', 'geosites', 'CustomUtils', 'Team'];

    function AddTeamDialogController($translate, $translatePartialLoader, $uibModalInstance, team, society, SocietySearch, geosites, CustomUtils, Team) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.team = team;
        ctrl.geosites = geosites;
        ctrl.isSaving = false;
        ctrl.goToSheet = false;

        ctrl.society = society;
        if (!CustomUtils.isUndefinedOrNull(ctrl.society)) {
            if (CustomUtils.isUndefinedOrNull(ctrl.team.city)) {
                ctrl.team.city = ctrl.society.city;
            }
            if (CustomUtils.isUndefinedOrNull(ctrl.team.geositeId)) {
                ctrl.team.geositeId = ctrl.society.geositeId;
            }
        }

        ctrl.geositeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            autoDropDownHeight: true,
            source: {
                localdata: ctrl.geosites,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                ctrl.geositeDropDown = event.instance;
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter({
            url: 'api/search',
            datatype: 'json',
            id: 'id',
            data: {
                size: '20'
            },
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }]
        }, {
            loadServerData: function (serverdata, source, callback) {
                var query = "name:" + ctrl.societyComboBoxSettings.searchString;
                query += " or ";
                query += "shortName:" + ctrl.societyComboBoxSettings.searchString;
                SocietySearch.query({
                    query: query,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });


        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
                if (ctrl.society) {
                    ctrl.societyComboBox.val(ctrl.society.name);
                    ctrl.societyComboBoxSettings.search(ctrl.society.name);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var team = item.originalItem;
                    ctrl.society = team;
                    if (CustomUtils.isUndefinedOrNull(ctrl.team.city)) {
                        ctrl.team.city = ctrl.society.city;
                    }
                }
            },
            unselect: function () {
                ctrl.society = null;
            },
            change: function () {
                ctrl.editForm.$dirty = true;
                var selection = ctrl.societyComboBox.getSelectedItem();
                if (selection !== null) {
                    ctrl.society = selection.originalItem;
                    if (CustomUtils.isUndefinedOrNull(ctrl.team.city)) {
                        ctrl.team.city = ctrl.society.city;
                    }
                }
            }
        };

        ctrl.save = function () {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;
                ctrl.team.societyId = ctrl.society.id;

                if (CustomUtils.isUndefinedOrNull(ctrl.team.id)) {
                    Team.save(ctrl.team, ctrl.close);
                } else {
                    Team.update(ctrl.team, ctrl.close);
                }
            }
        };

        ctrl.close = function (result) {
            $uibModalInstance.close({
                goToSheet: ctrl.goToSheet,
                team: result
            });
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();