(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .filter('thumbnail', thumbnail);

    thumbnail.$inject = ['$translate'];

    function thumbnail($translate) {

        return thumbnailFilter;

        function thumbnailFilter(object, fieldName, useHighlight) {
            if (object) {
                var result = object[fieldName];

                if (fieldName === 'name' && _.isEmpty(result)) {
                    var firstName = object['firstName'];
                    if (!_.isEmpty(firstName)) {
                        var lastName = object['lastName'];
                        return firstName + ' ' + lastName;
                    }
                } else if (fieldName === 'metier' || fieldName === 'status') {
                    var value = object[fieldName];
                    if (value === 'not_affected') {
                        return $translate.instant('aggregation.not_affected');
                    }
                }

                if (!_.isNil(result)) {
                    result = result.toString();
                }

                if (_.isEmpty(result)) {
                    result = $translate.instant('data.missing');
                }
                if (angular.isArray(result)) {
                    var str = '';
                    angular.forEach(result, function (item) {
                        if (str.length > 0) {
                            str += ', ';
                        }
                        str += item;
                    });
                    result = str;
                }
                return result;
            }
            return $translate.instant('data.missing');
        }
    }
})();
