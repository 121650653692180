(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('FileAttachmentsController', FileAttachmentsController);

  FileAttachmentsController.$inject = [
    'JQWidgetsService',
    '$uibModal',
    'DataUtils',
    '$translate',
    'CustomUtils',
    '$filter',
    'ConfirmDialog',
    'FileAttachement',
    'LoginService',
    '$http',
    'Principal',
    '$scope'
  ];

  function FileAttachmentsController(
    JQWidgetsService,
    $uibModal,
    DataUtils,
    $translate,
    CustomUtils,
    $filter,
    ConfirmDialog,
    FileAttachement,
    LoginService,
    $http,
    Principal,
    $scope
  ) {
    var ctrl = this;

    ctrl.canEdit = ctrl.canEdit == 'true';
    ctrl.showToExpert = ctrl.showToExpert == 'true';
    ctrl.showForExpert = ctrl.showForExpert == 'true';

    ctrl.fileAttachments = [];
    ctrl.hasAttachment = function () {
      if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
        return true;
      }
      return ctrl.fileAttachments.length > 0;
    };

    ctrl.isAuthenticated = function () {
      return Principal.isAuthenticated();
    };

    ctrl.login = function () {
      LoginService.open(ctrl.type + '_' + ctrl.parentId);
    };

    if (ctrl.showForExpert) {
      this.$onChanges = function (bindings) {
        if (bindings.parentId) {
          ctrl.refresh();
        }
      };
    }

    ctrl.refresh = function () {
      var url = 'api/public/file-attachements/' + ctrl.type + '/' + ctrl.parentId;
      if (ctrl.showForExpert) {
        url += '/expert';
      }
      $http.get(url).then(function (response) {
        ctrl.fileAttachments = response.data;
        ctrl.publicationSource = {
          datatype: 'json',
          localdata: ctrl.fileAttachments,
          datafields: [
            {
              name: 'id',
              type: 'number'
            },
            {
              name: 'name',
              type: 'string'
            },
            {
              name: 'size',
              type: 'number'
            },
            {
              name: 'filePath',
              type: 'string'
            },
            {
              name: 'showToExpert',
              type: 'bool'
            }
          ],
          sortcolumn: 'name',
          sortdirection: 'asc',
          id: 'id'
        };
        ctrl.gridSettings.source = new $.jqx.dataAdapter(ctrl.publicationSource);
        if (!_.isNil(ctrl.publicationGrid)) {
          ctrl.publicationGrid.clearselection();
          ctrl.selectedAttachment = null;
        }
      });
    };

    var sizeRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var str = $filter('bytes')(value);
        var result = html.replace(value, str);
        return result;
      }
      return html;
    };

    ctrl.selectedAttachment = null;
    ctrl.gridSettings = {
      source: [],
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      enabletooltips: true,
      height: 350,
      columnsresize: true,
      altrows: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      created: function (args) {
        ctrl.publicationGrid = args.instance;
      },
      columns: [
        {
          text: $translate.instant('canceropoleGsoApp.fileAttachement.name'),
          datafield: 'name',
          minwidth: 80,
          pinned: true
        },
        {
          text: $translate.instant('canceropoleGsoApp.fileAttachement.showToExpert'),
          datafield: 'showToExpert',
          width: 80,
          hidden: !ctrl.showToExpert,
          columntype: 'checkbox'
        },
        {
          text: $translate.instant('canceropoleGsoApp.fileAttachement.fileSize'),
          datafield: 'size',
          cellsrenderer: sizeRenderer,
          cellsalign: 'right',
          align: 'right',
          width: 80
        }
      ],
      rowselect: function (event) {
        ctrl.selectedAttachment = null;
        if (event.args) {
          var args = event.args;
          ctrl.selectedAttachment = args.row;
        }
      },
      rowdoubleclick: function () {
        ctrl.openAttachment();
      }
    };

    ctrl.addAttachment = function () {
      ctrl.openDialog({
        showToExpert: true
      });
    };

    ctrl.editAttachment = function (add) {
      FileAttachement.get({ id: ctrl.selectedAttachment.id }, function (result) {
        ctrl.openDialog(result);
      });
    };

    ctrl.openDialog = function (attachment) {
      $uibModal
        .open({
          templateUrl: 'app/components/fileattachment/dialog/file-attachement-edit-dialog.html',
          controller: 'FileAttachementEditDialogController',
          backdrop: 'static',
          controllerAs: 'vm',
          resolve: {
            entity: attachment,
            showToExpert: function () {
              return ctrl.showToExpert;
            }
          }
        })
        .result.then(function (attachement) {
          if (CustomUtils.isDefined(attachement.id)) {
            FileAttachement.update(attachement, function (result) {
              ctrl.refresh();
            });
          } else {
            if (ctrl.type === 'submission') {
              attachement.submissionId = ctrl.parentId;
            } else if (ctrl.type === 'registration') {
              attachement.eventRegistrationId = ctrl.parentId;
            }
            FileAttachement.save(attachement, function (result) {
              ctrl.refresh();
            });
          }
        });
    };

    ctrl.tryRemoveAttachment = function () {
      if (CustomUtils.isDefined(ctrl.selectedAttachment)) {
        ConfirmDialog.openConfirmRemove(ctrl.selectedAttachment.name, ctrl.removeAttachment);
      }
    };

    ctrl.removeAttachment = function () {
      FileAttachement.delete({ id: ctrl.selectedAttachment.id }, function () {
        ctrl.refresh();
      });
    };

    ctrl.openAttachment = function () {
      if (CustomUtils.isDefined(ctrl.selectedAttachment)) {
        FileAttachement.get({ id: ctrl.selectedAttachment.id }, function (result) {
          DataUtils.downloadFile(result.content, ctrl.selectedAttachment.name);
        });
      }
    };

    ctrl.open = function (attachment) {
      ctrl.selectedAttachment = attachment;
      ctrl.openAttachment();
    };

    ctrl.refresh();
  }
})();
