(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('participationGrid', {
                controller: 'ParticipationGridController',
                bindings: {
                    grid: '=',
                    editable: '=',
                    mainEventId: '<',
                    sessionIdChange: '=?',
                    autosavestate: '@',
                    id: '@',
                    height: '@?',
                    rowselect: '=?',
                    rowunselect: '=?'
                },
                templateUrl: 'app/components/grid/participation-grid.html'
            });
})();
