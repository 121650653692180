(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('EventPriceDialogController', EventPriceDialogController);

    EventPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventPrice', 'MainEvent', 'PersonStatus'];

    function EventPriceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EventPrice, MainEvent, PersonStatus) {
        var vm = this;

        vm.eventPrice = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.mainevents = MainEvent.query();
        vm.personstatuses = PersonStatus.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.eventPrice.id !== null) {
                EventPrice.update(vm.eventPrice, onSaveSuccess, onSaveError);
            } else {
                EventPrice.save(vm.eventPrice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('canceropoleGsoApp:eventPriceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
