(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('AbstractSocietyDetailController', AbstractSocietyDetailController);

    AbstractSocietyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AbstractSociety', 'Society', 'EventAbstract', 'AbstractPerson'];

    function AbstractSocietyDetailController($scope, $rootScope, $stateParams, entity, AbstractSociety, Society, EventAbstract, AbstractPerson) {
        var vm = this;

        vm.abstractSociety = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:abstractSocietyUpdate', function(event, result) {
            vm.abstractSociety = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
