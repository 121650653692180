(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('call-for-project-type', {
            parent: 'entity',
            url: '/call-for-project-type',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'canceropoleGsoApp.callForProjectType.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-type/call-for-project-types.html',
                    controller: 'CallForProjectTypeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('call-for-project-type-detail', {
            parent: 'entity',
            url: '/call-for-project-type/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'canceropoleGsoApp.callForProjectType.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/call-for-project-type/call-for-project-type-detail.html',
                    controller: 'CallForProjectTypeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('callForProjectType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CallForProjectType', function($stateParams, CallForProjectType) {
                    return CallForProjectType.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('call-for-project-type.new', {
            parent: 'call-for-project-type',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-type/call-for-project-type-dialog.html',
                    controller: 'CallForProjectTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('call-for-project-type', null, { reload: true });
                }, function() {
                    $state.go('call-for-project-type');
                });
            }]
        })
        .state('call-for-project-type.edit', {
            parent: 'call-for-project-type',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-type/call-for-project-type-dialog.html',
                    controller: 'CallForProjectTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CallForProjectType', function(CallForProjectType) {
                            return CallForProjectType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-type', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('call-for-project-type.delete', {
            parent: 'call-for-project-type',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/call-for-project-type/call-for-project-type-delete-dialog.html',
                    controller: 'CallForProjectTypeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CallForProjectType', function(CallForProjectType) {
                            return CallForProjectType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('call-for-project-type', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
