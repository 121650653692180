(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('CallForProjectSubscriptionConfirmController', CallForProjectSubscriptionConfirmController);

    CallForProjectSubscriptionConfirmController.$inject = ['$stateParams', '$log', '$timeout', 'JhiLanguageService'];

    function CallForProjectSubscriptionConfirmController($stateParams, $log, $timeout, JhiLanguageService) {
        var vm = this;

        vm.registration = $stateParams.confirmation;
        vm.callForProject = $stateParams.callForProject;
        vm.managements = $stateParams.managements;

        $timeout(function () {
            JhiLanguageService.changeLanguage(vm.callForProject.lang);
        }, 50);

        $log.info(vm);
    }
})();