(function () {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('event-registration', {
                parent: 'entity',
                url: '/event-registration',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.eventRegistration.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/event-registration/event-registrations.html',
                        controller: 'EventRegistrationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eventRegistration');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('event-registration-detail', {
                parent: 'entity',
                url: '/event-registration/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'canceropoleGsoApp.eventRegistration.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/event-registration/event-registration-detail.html',
                        controller: 'EventRegistrationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eventRegistration');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'EventRegistration', function ($stateParams, EventRegistration) {
                        return EventRegistration.get({ id: $stateParams.id }).$promise;
                    }]
                }
            })
            .state('event-registration.new', {
                parent: 'event-registration',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-registration/event-registration-dialog.html',
                        controller: 'EventRegistrationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    civilite: null,
                                    firstName: null,
                                    lastName: null,
                                    societyName: null,
                                    city: null,
                                    email: null,
                                    callingCode: null,
                                    phone: null,
                                    portable: null,
                                    facturationSocietyName: null,
                                    facturationSocietyAddress: null,
                                    facturationSocietyCp: null,
                                    facturationSocietyCity: null,
                                    facturationSocietyCountry: null,
                                    totalPrice: null,
                                    registrationDate: null,
                                    confirmed: null,
                                    detailPersonFunction: null,
                                    facturationSocietyAddress2: null,
                                    orderRef: null,
                                    billRef: null,
                                    privateInformation: null,
                                    deletedAt: null,
                                    confirmedPresence: null,
                                    address: null,
                                    paid: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('event-registration', null, { reload: true });
                    }, function () {
                        $state.go('event-registration');
                    });
                }]
            })
            .state('event-registration.edit', {
                parent: 'event-registration',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-registration/event-registration-dialog.html',
                        controller: 'EventRegistrationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['EventRegistration', function (EventRegistration) {
                                return EventRegistration.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('event-registration', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('event-registration.delete', {
                parent: 'event-registration',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/event-registration/event-registration-delete-dialog.html',
                        controller: 'EventRegistrationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['EventRegistration', function (EventRegistration) {
                                return EventRegistration.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('event-registration', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
