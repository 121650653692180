(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('Submission', Submission);

    Submission.$inject = ['$resource', 'DateUtils'];

    function Submission($resource, DateUtils) {
        var resourceUrl = 'api/submissions/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.stepOneDate = DateUtils.convertDateTimeFromServer(data.stepOneDate);
                        data.stepTwoDate = DateUtils.convertDateTimeFromServer(data.stepTwoDate);
                        data.modificationDate = DateUtils.convertDateTimeFromServer(data.modificationDate);
                        data.conventionStartDate = DateUtils.convertDateTimeFromServer(data.conventionStartDate);
                        data.conventionEndDate = DateUtils.convertDateTimeFromServer(data.conventionEndDate);
                        data.returnBilanDate = DateUtils.convertDateTimeFromServer(data.returnBilanDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
