(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('FileAttachementController', FileAttachementController);

    FileAttachementController.$inject = ['$scope', '$state', 'DataUtils', 'FileAttachement', 'FileAttachementSearch'];

    function FileAttachementController ($scope, $state, DataUtils, FileAttachement, FileAttachementSearch) {
        var vm = this;
        
        vm.fileAttachements = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.search = search;

        loadAll();

        function loadAll() {
            FileAttachement.query(function(result) {
                vm.fileAttachements = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            FileAttachementSearch.query({query: vm.searchQuery}, function(result) {
                vm.fileAttachements = result;
            });
        }    }
})();
