(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetPersonCommunicationTabController', SheetPersonCommunicationTabController);

  SheetPersonCommunicationTabController.$inject = [
    'Principal',
    '$stateParams',
    '$state',
    'CustomUtils',
    'personEntity',
    '$http',
    'JQWidgetsService',
    '$translate',
    '$filter',
    'AbstractService',
    'DateUtils',
    'DataUtils'
  ];

  function SheetPersonCommunicationTabController(
    Principal,
    $stateParams,
    $state,
    CustomUtils,
    personEntity,
    $http,
    JQWidgetsService,
    $translate,
    $filter,
    AbstractService,
    DateUtils,
    DataUtils
  ) {
    if ($stateParams.tab !== 'communication') {
      //update state param
      $stateParams.tab = 'communication';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;

    vm.Model = {
      id: $stateParams.id,
      entity: personEntity,
      canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN'])
    };

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    var abstractTypeRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderFinalType(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    var abstractAcceptedRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderAccepted(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    var abstractsDataAdapter = new $.jqx.dataAdapter({
      url: 'api/people/' + $stateParams.id + '/abstracts',
      dataType: 'json'
    });

    vm.abstractsSettings = {
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      source: abstractsDataAdapter,
      enabletooltips: true,
      height: 350,
      columnsresize: true,
      altrows: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      created: function (args) {
        vm.abstractsGrid = args.instance;
      },
      columns: [
        {
          text: 'Préfixe',
          datafield: 'eventPrefix',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.id)) {
              var link = '<a href="#/sheet/mainevent/' + object.mainEventId + '?tab=abstracts">';
              link += value;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          width: 100,
          cellsalign: 'center',
          align: 'center',
          pinned: true
        },
        {
          text: 'Id',
          datafield: 'id',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.id)) {
              var link = '<a href="#/sheet/eventabstract/' + object.id + '">';
              link += object.id;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          cellsalign: 'center',
          align: 'center',
          width: 40,
          pinned: true
        },
        {
          text: 'Ref',
          datafield: 'reference',
          columntype: 'textbox',
          cellsalign: 'center',
          align: 'center',
          width: 60
        },
        {
          text: 'Type',
          datafield: 'type',
          cellsrenderer: abstractTypeRenderer,
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 50
        },
        {
          text: 'Décision',
          datafield: 'accepted',
          cellsrenderer: abstractAcceptedRenderer,
          columntype: 'dropdownlist',
          cellsalign: 'center',
          align: 'center',
          filtertype: 'checkedlist',
          width: 50
        },
        {
          text: 'Type final',
          datafield: 'finalType',
          cellsrenderer: abstractTypeRenderer,
          columntype: 'dropdownlist',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          width: 50
        },
        {
          text: 'Thématique',
          datafield: 'axeName',
          minwidth: 100
        },
        {
          text: 'Session',
          datafield: 'session',
          minwidth: 180,
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isEmpty(value)) {
              var clazz = '';
              if (object.finalType === 'O' && object.accepted === true) {
                clazz = 'text-success';
              } else if (object.finalType !== 'O' && object.accepted === false) {
                clazz = 'text-danger';
              } else if (object.finalType === 'I') {
                clazz = 'text-warning';
              }

              var span = '<span class="' + clazz + '">';
              span += value;
              span += '</span>';
              var result = html.replace(value, span);
              return result;
            }
            return html;
          }
        },
        {
          text: 'Nom',
          datafield: 'speakerLastName'
        },
        {
          text: 'Prénom',
          datafield: 'speakerFirstName'
        },
        {
          text: 'Titre',
          datafield: 'title',
          minwidth: 220
        }
      ],
      rowselect: function (event) {
        if (event.args) {
          //var args = event.args;
          //vm.selectedJob = args.row;
        }
      },
      rowunselect: function () {}
    };

    vm.participationSource = {
      url: 'api/people/' + vm.Model.id + '/registrations',
      dataType: 'json',
      dataFields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'date',
          type: 'date'
        },
        {
          name: 'bill',
          type: 'string'
        },
        {
          name: 'billContentType',
          type: 'string'
        },
        {
          name: 'sessions',
          type: 'array'
        },
        {
          name: 'registrationKey',
          type: 'number'
        },
        {
          name: 'presence',
          type: 'bool'
        },
        {
          name: 'moderator',
          type: 'bool'
        }
      ],
      hierarchy: {
        root: 'sessions'
      },
      id: 'id'
    };

    vm.participationDataAdapter = new $.jqx.dataAdapter(vm.participationSource, {
      loadServerData: function (serverdata, source, callback) {
        $http.get(source.url).success(function (results) {
          var records = [];
          angular.forEach(results, function (result) {
            var mainEvent = result.mainEvent;
            var registration = result.eventRegistration;
            var moderators = result.moderators;

            var children = [];
            var atLeastOneTimeModerator = false;
            angular.forEach(registration.sessions, function (session) {
              var child = {
                id: 'session_' + session.id,
                name: session.name,
                date: DateUtils.convertDateTimeFromServer(session.startDate),
                bill: null,
                billContentType: null,
                registrationKey: null,
                moderator: false
              };
              for (var i = 0; i < moderators.length; i++) {
                if (moderators[i].sessionId === session.id) {
                  child.moderator = true;
                  atLeastOneTimeModerator = true;
                  break;
                }
              }
              children.push(child);
            });

            children.sort(function (c1, c2) {
              return DateUtils.compare(c1.date, c2.date);
            });

            var root = {
              id: mainEvent.id,
              name: mainEvent.name,
              date: DateUtils.convertDateTimeFromServer(mainEvent.startDate),
              bill: registration.bill,
              billContentType: registration.billContentType,
              sessions: children,
              registrationKey: result.registrationKey,
              presence: registration.confirmedPresence,
              moderator: atLeastOneTimeModerator
            };

            records.push(root);
          });

          records.sort(function (m1, m2) {
            return DateUtils.compare(m1.date, m2.date);
          });

          callback({ records: records });
        });
      }
    });

    vm.selectedParticipation = null;
    vm.participationsSettings = {
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      source: vm.participationDataAdapter,
      selectionMode: 'singleRow',
      sortable: true,
      height: 350,
      created: function (args) {
        vm.abstractsGrid = args.instance;
      },
      columns: [
        {
          text: 'Nom',
          datafield: 'name',
          cellsRenderer: function (rowKey, dataField, value, data) {
            if (data.sessions) {
              var link = '<a href="#/sheet/mainevent/' + data.id + '?tab=main">';
              link += data.name;
              link += '</a>';

              return link;
            }
            return value;
          },
          minwidth: 180
        },
        {
          text: 'Présence',
          datafield: 'presence',
          width: 80,
          cellsalign: 'center',
          align: 'center',
          cellsRenderer: function (row, column, value, rowData) {
            var checked = '';
            if (value === true) {
              checked = 'checked';
            }
            var checkBox = "<div style='z-index: 999; margin: 5px; margin-left: 30px; margin-top: 8px; margin-bottom: 3px;'>";
            checkBox += '<input type="checkbox" class="checkbox" ' + checked + '></div>';
            return checkBox;
          }
        },
        {
          text: 'Modérateur',
          datafield: 'moderator',
          width: 80,
          cellsalign: 'center',
          align: 'center',
          cellsRenderer: function (row, column, value, rowData) {
            var checked = '';
            if (value === true) {
              checked = 'checked';
            }
            var checkBox = "<div style='z-index: 999; margin: 5px; margin-left: 30px; margin-top: 8px; margin-bottom: 3px;'>";
            checkBox += '<input type="checkbox" class="checkbox" ' + checked + '></div>';
            return checkBox;
          }
        },
        {
          text: 'Date',
          datafield: 'date',
          cellsFormat: 'dd/MM/yyyy HH:mm',
          cellsRenderer: function (rowKey, dataField, value, data) {
            var dateStr = $filter('date')(value, 'dd/MM/yyyy HH:mm');
            if (data.registrationKey) {
              var link = '<a href="#/manifestation/' + data.id + '/space?key=' + data.registrationKey + '">';
              link += dateStr;
              link += '</a>';
              return link;
            }
            return dateStr;
          },
          width: 130
        }
      ],
      rowSelect: function (event) {
        vm.selectedParticipation = null;
        if (event.args) {
          var args = event.args;
          if (args.row.bill) {
            vm.selectedParticipation = args.row;
          }
        }
      },
      rowUnselect: function () {
        vm.selectedParticipation = null;
      }
    };

    vm.showBill = function () {
      if (!CustomUtils.isUndefinedOrNull(vm.selectedParticipation)) {
        DataUtils.openFile(vm.selectedParticipation.billContentType, vm.selectedParticipation.bill);
      }
    };
  }
})();
