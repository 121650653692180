(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .controller('ResponsibleController', ResponsibleController);

    ResponsibleController.$inject = ['$translate', '$translatePartialLoader', 'JQWidgetsService', 'ResponsibleFunction', 'PersonSearch', 'ConfirmDialog'];

    function ResponsibleController($translate, $translatePartialLoader, JQWidgetsService, ResponsibleFunction, PersonSearch, ConfirmDialog) {
        $translatePartialLoader.addPart('responsibleFunction');
        $translatePartialLoader.addPart('responsible');
        $translate.refresh();

        var ctrl = this;

        ctrl.responsibleFunction = null;
        ctrl.responsibleName = '';
        if (!_.isEmpty(ctrl.entity.personFirstName)) {
            ctrl.responsibleName = ctrl.entity.personFirstName;
        }
        if (!_.isEmpty(ctrl.entity.personLastName)) {
            ctrl.responsibleName += ' ' + ctrl.entity.personLastName;
        }

        var responsibleSource = {
            datatype: 'json',
            datafields: [{
                name: 'name'
            }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var responsibleDataAdapter = new $.jqx.dataAdapter(responsibleSource, {
            loadServerData: function (serverdata, source, callback) {
                ResponsibleFunction.query({}, function (result) {
                    ctrl.responsibleFunction = _.find(result, function (item) {
                        return item.id === ctrl.entity.responsibleFunctionId;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });


        ctrl.responsibleFunctionSettings = {
            autoDropDownHeight: true,
            displayMember: 'name',
            valueMember: 'id',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            source: responsibleDataAdapter,
            created: function (args) {
                ctrl.responsibleFunctionDropDown = args.instance;
            },
            change: function (event) {
                if (!_.isNil(event.args)) {
                    var item = event.args.item;
                    ctrl.entity.responsibleFunctionId = item.value;
                }
            }
        };


        var personSource = {
            datatype: 'json',
            datafields: [{
                name: 'name'
            }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + ' ' + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                if (ctrl.responsibleName) {
                    ctrl.personComboBox.val(ctrl.responsibleName);
                    ctrl.personComboBoxSettings.search(ctrl.responsibleName);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                    ctrl.entity.personId = person.id;

                    if (!_.isUndefined(ctrl.entity.email) && !_.isEmpty(person.email)) {
                        ctrl.entity.email = person.email;
                    }
                    if (!_.isUndefined(ctrl.entity.phone) && !_.isEmpty(person.phone)) {
                        ctrl.entity.phone = person.phone;
                    }
                    if (!_.isUndefined(ctrl.entity.callingCode) && !_.isEmpty(person.callingCode)) {
                        ctrl.entity.callingCode = person.callingCode;
                    }
                    if (!_.isUndefined(ctrl.entity.fax) && !_.isEmpty(person.fax)) {
                        ctrl.entity.fax = person.fax;
                    }
                }
            },
            unselect: function () {
                ctrl.entity.personId = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection !== null && selection.originalItem) {
                    ctrl.entity.personId = selection.originalItem.id;
                }
            }
        };

        ctrl.tryRemoveResponsible = function () {
            ConfirmDialog.openConfirmRemove(ctrl.responsibleName, ctrl.removeResponsible);
        };

        ctrl.removeResponsible = function () {
            ctrl.entity.personId = null;
            ctrl.entity.responsibleFunctionId = null;
            ctrl.responsibleName = null;
            ctrl.personComboBox.val('');
            ctrl.responsibleFunctionDropDown.clearSelection();
        };

    }
})();
