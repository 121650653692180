(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SocietyOrganizationDetailController', SocietyOrganizationDetailController);

    SocietyOrganizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SocietyOrganization', 'Society', 'Organization'];

    function SocietyOrganizationDetailController($scope, $rootScope, $stateParams, entity, SocietyOrganization, Society, Organization) {
        var vm = this;

        vm.societyOrganization = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:societyOrganizationUpdate', function(event, result) {
            vm.societyOrganization = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
