(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('cfpForm', {
                controller: 'CFPFormController',
                controllerAs: 'vm',
                bindings: {
                    formField: '<',
                    callForProject: '<',
                    showToolbar: '@?',
                    preview: '@?'
                },
                templateUrl: 'app/components/form/callforproject/cfp-form.html'
            });
})();
