(function() {
  'use strict';

  angular
    .module('canceropoleGsoApp', [
      'ngStorage',
      'tmh.dynamicLocale',
      'pascalprecht.translate',
      'ngResource',
      'ngCookies',
      'ngAria',
      'ngCacheBuster',
      'ngFileUpload',
      'ui.bootstrap',
      'ngAnimate',
      'ui.bootstrap.datetimepicker',
      'ui.router',
      'infinite-scroll',
      'jqwidgets',
      'ngFileSaver',
      'jlareau.bowser',
      'LocalStorageModule',
      'NgSwitchery',
      'angular-sortable-view',
      // jhipster-needle-angularjs-add-module JHipster will add new module here
      'angular-loading-bar'
    ])
    .run(run);

  run.$inject = ['stateHandler', 'translationHandler', 'CustomUtils'];

  function run(stateHandler, translationHandler, CustomUtils) {
    stateHandler.initialize();
    translationHandler.initialize();
    CustomUtils.initialize();
    $.jqx.theme = 'bootstrap';
    $.jqx._jqxChart.prototype.colorSchemes.push(
      {
        name: 'axe',
        colors: ['#007BC2', '#EB690A', '#FCC000', '#D4D600', '#9E009E']
      },
      {
        name: 'geosite',
        colors: ['#FF0000', '#33CC33', '#0000CC', '#66FFFF', '#FF9966', '#808080']
      }
    );
  }
})();
