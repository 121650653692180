(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('call-for-project', {
                    parent: 'entity',
                    url: '/call-for-project',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.callForProject.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/call-for-project/call-for-projects.html',
                            controller: 'CallForProjectController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('callForProject');
                                $translatePartialLoader.addPart('aAPLanguage');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('call-for-project-detail', {
                    parent: 'entity',
                    url: '/call-for-project/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'canceropoleGsoApp.callForProject.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/call-for-project/call-for-project-detail.html',
                            controller: 'CallForProjectDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('callForProject');
                                $translatePartialLoader.addPart('aAPLanguage');
                                return $translate.refresh();
                            }],
                        entity: ['$stateParams', 'CallForProject', function ($stateParams, CallForProject) {
                                return CallForProject.get({id: $stateParams.id}).$promise;
                            }]
                    }
                })
                .state('call-for-project.new', {
                    parent: 'call-for-project',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/call-for-project/call-for-project-dialog.html',
                                controller: 'CallForProjectDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            name: null,
                                            description: null,
                                            year: null,
                                            budget: null,
                                            publique: null,
                                            managed: false,
                                            avatar: null,
                                            avatarContentType: null,
                                            privateSpace: null,
                                            canSaveSubmission: null,
                                            descMaxLength: null,
                                            askBudget: false,
                                            askDuration: false,
                                            budgetHelpText: null,
                                            durationHelpText: null,
                                            defaultBudget: null,
                                            defaultDuration: null,
                                            askAcronym: false,
                                            askAxe: false,
                                            askKeyword: false,
                                            askPartner: false,
                                            askUnitDirector: false,
                                            askTeamName: false,
                                            askTeamDirector: false,
                                            expertiseStartDate: null,
                                            expertiseEndDate: null,
                                            askDescription: false,
                                            formConfig: null,
                                            showCoordinatorToExpert: false,
                                            lang: null,
                                            askAddress: null,
                                            id: null
                                        };
                                    }
                                }
                            }).result.then(function () {
                                $state.go('call-for-project', null, {reload: true});
                            }, function () {
                                $state.go('call-for-project');
                            });
                        }]
                })
                .state('call-for-project.edit', {
                    parent: 'call-for-project',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/call-for-project/call-for-project-dialog.html',
                                controller: 'CallForProjectDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['CallForProject', function (CallForProject) {
                                            return CallForProject.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('call-for-project', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                })
                .state('call-for-project.delete', {
                    parent: 'call-for-project',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/call-for-project/call-for-project-delete-dialog.html',
                                controller: 'CallForProjectDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['CallForProject', function (CallForProject) {
                                            return CallForProject.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('call-for-project', null, {reload: true});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                });
    }

})();
