(function () {
    "use strict";
    angular.module("canceropoleGsoApp").constant("ModelConfig", {
        project: {
            participation: {
                coordinatorType: 'coordinator'
            }
        },
        mainEvent: {
            types: [
                "Bourses",
                "Evènements INCa/Autres Cancéropôles",
                "Formations Autres",
                "Formations",
                "Manifestations",
                "Mobilité",
                "Réunion de travail",
                "Séminaires Autres",
                "Séminaires/Congrès",
                "Séminaires",
                "Séminaires Inter-cancéropôles",
                "Soutien à des séminaires"
            ],
            participation: {
                contactType: 'contact'
            },
            management: {
                types: [{
                        value: 'eventSubscriptionManager',
                        label: 'canceropoleGsoApp.eventManagement.types.eventSubscriptionManager'
                    }, {
                        value: 'free',
                        label: 'canceropoleGsoApp.eventManagement.types.free'
                    }, {
                        value: 'partialFree',
                        label: 'canceropoleGsoApp.eventManagement.types.partialFree'
                    }]
                ,
                getLabel: function (value) {
                    for (var i = 0; i < this.types.length; i++) {
                        var type = this.types[i];
                        if (value === type.value) {
                            return type.label;
                        }
                    }
                    return value;
                },
                getValue: function (label) {
                    for (var i = 0; i < this.types.length; i++) {
                        var type = this.types[i];
                        if (label === type.label) {
                            return type.value;
                        }
                    }
                    return label;
                },
                getIndex: function (value) {
                    for (var i = 0; i < this.types.length; i++) {
                        var type = this.types[i];
                        if (value === type.value) {
                            return i;
                        }
                    }
                    return -1;
                },
                getSubscriptionManagerType: function () {
                    return this.types[0].value;
                },
                getFreeRegistrationType: function () {
                    return this.types[1].value;
                },
                getPartialFreeRegistrationType: function () {
                    return this.types[2].value;
                }
            }
        },
        eventSession: {
            sessionType: {
                plenary: 'plenary',
                normal: 'normal',
                noOral: 'noOral',
                other: 'other'
            }
        }
    });
})();


