(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SearchSocietyDialogController', SearchSocietyDialogController);

    SearchSocietyDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'title', 'SocietySearch'];

    function SearchSocietyDialogController($translate, $translatePartialLoader, $uibModalInstance, title, SocietySearch) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.title = title;

        ctrl.result = null;

        var societySource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: ctrl.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            //multiSelect: true,
            remoteAutoComplete: true,
            autoDropDownHeight: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                var query = "name:" + searchString;
                query += " or ";
                query += "shortName:" + searchString;
                query = query.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = query;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
            }
        };

        ctrl.ok = function () {

            $uibModalInstance.close(ctrl.result);

        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        ctrl.societyEventChangeHandler = function () {

            var selection = ctrl.societyComboBox.getSelectedItem();
            if (selection.originalItem) {
                ctrl.result = selection.originalItem;
            }
        };
    }
})();