(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('fileAttachments', {
                controller: 'FileAttachmentsController',
                bindings: {
                    canEdit: '@',
                    type: '@',
                    parentId: '@',
                    showToExpert: '@',
                    showForExpert: '@'
                },
                templateUrl: 'app/components/fileattachment/file-attachments.html'
            });
})();
