(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('DaySessionEditDialogController', DaySessionEditDialogController);

    DaySessionEditDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'eventDay', '$log'];

    function DaySessionEditDialogController($translate, $translatePartialLoader, $uibModalInstance, eventDay, $log) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.eventDay = eventDay;
        if (ctrl.eventDay == null) {
            ctrl.title = "Ajouter une journée";
            var now = new Date();
            var after = new Date(now.getTime());
            after.setHours(after.getHours() + 1);
            ctrl.eventDay = {
                startDate: now,
                endDate: after
            };

        } else {
            ctrl.title = "Editer une journée";
        }

        ctrl.dateSettings = {
            width: "100%",
            height: 30,
            formatString: "dd/MM/yyyy HH:mm",
            culture: "fr-FR",
            change: function () {
                ctrl.checkDates();
            }
        };

        ctrl.checkDates = function () {
            var startDate = ctrl.eventDay.startDate;
            var endDate = ctrl.eventDay.endDate;

            $log.debug("startDate < endDate ==>", startDate < endDate);
            ctrl.editDaySessionForm.$setValidity('previousDate', startDate < endDate);
        };

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.eventDay);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();