(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AutoAbstractRefDialogController', AutoAbstractRefDialogController);

    AutoAbstractRefDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'abstracts', '$log', '$http', 'AlertService'];

    function AutoAbstractRefDialogController($translate, $translatePartialLoader, $uibModalInstance, abstracts, $log, $http, AlertService) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.abstracts = abstracts;
        ctrl.prefix = "";
        ctrl.startNb = 1;
        ctrl.saving = false;
        ctrl.okText = "Ok";


        $log.info(ctrl.abstracts);

        ctrl.compute = function () {
            var nb = ctrl.startNb;
            angular.forEach(ctrl.abstracts, function (abstract) {
                var ref = ctrl.prefix + nb;
                abstract.reference = ref;
                nb++;
            });
        };

        ctrl.ok = function () {
            ctrl.saving = true;
            ctrl.okText = "Sauvegarde...";
            ctrl.compute();

            var result = {};
            angular.forEach(ctrl.abstracts, function (abstract) {
                result[abstract.id] = abstract.reference;
            });

            $http.post("api/event-abstracts/refs", result)
                    .then(function (response) {
                        if (response.status === 200) {
                            var data = response.data;
                            if (data.length === Object.keys(result).length) {
                                AlertService.success("Les références ont été mises à jour avec succès.");
                            } else {
                                AlertService.warning("Certaines références n'ont pas été mises à jour.");
                            }
                        } else {
                            AlertService.error("Impossible de mettre à jour les références.");
                        }
                        ctrl.saving = false;
                        ctrl.okText = "Ok";
                        $uibModalInstance.close();
                    });
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();