(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ManifestationSubscriptionConfirmController', ManifestationSubscriptionConfirmController);

    ManifestationSubscriptionConfirmController.$inject = ['$stateParams'];

    function ManifestationSubscriptionConfirmController($stateParams) {
        var vm = this;


        vm.registration = $stateParams.confirmation;
    }
})();