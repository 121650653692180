(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SendCallForProjectMailDialogController', SendCallForProjectMailDialogController);

    SendCallForProjectMailDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', '$http', '$log', 'existingsMails', 'JQWidgetsService', '$timeout', 'experts', 'CustomUtils', '$uibModal'];

    function SendCallForProjectMailDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, $http, $log, existingsMails, JQWidgetsService, $timeout, experts, CustomUtils, $uibModal) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;
        ctrl.experts = experts;
        ctrl.existingsMails = existingsMails;
        angular.forEach(ctrl.existingsMails, function (mail) {
            mail.translatedTitle = $translate.instant('canceropoleGsoApp.CallForProjectMailType.' + mail.type);
        });

        ctrl.selectedType = {};

        ctrl.expertsWithMail = [];
        ctrl.expertsWithoutMail = [];
        angular.forEach(ctrl.experts, function (expert) {
            if (CustomUtils.isEmpty(expert.email)) {
                ctrl.expertsWithoutMail.push(expert);
            } else {
                ctrl.expertsWithMail.push(expert);
            }
        });

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "translatedTitle",
            source: ctrl.existingsMails,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.existingsMails.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.ok = function () {
            ctrl.isSaving = true;

            var expertisesIds = [];
            angular.forEach(ctrl.expertsWithMail, function (expert) {
                if (_.isString(expert.expertise)) {
                    expert.expertise = JSON.parse(expert.expertise);
                }
                if (expert.expertise.length > 0) {
                    _.each(expert.expertise, function (anExpertise) {
                        expertisesIds.push(anExpertise.id);
                    })
                }
            });

            var toSend = {
                ids: expertisesIds,
                type: ctrl.selectedType.type
            };
            $http.post("api/call-for-project/expert/sendmail", toSend);
            $uibModalInstance.close();
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();