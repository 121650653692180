(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('PriceTabController', PriceTabController);

    PriceTabController.$inject = [];

    function PriceTabController() {
        var ctrl = this;

        ctrl.isFree = ctrl.showFree && ctrl.mainEvent.freeRegistration;

        ctrl.tab = {
            headers: [],
            content: []
        };

        angular.forEach(ctrl.prices, function (price) {
            var foundHeader = false;
            for (var i = 0; i < ctrl.tab.headers.length; i++) {
                if (ctrl.tab.headers[i] === price.name) {
                    foundHeader = true;
                    break;
                }
            }
            if (!foundHeader) {
                ctrl.tab.headers.push(price.name);
            }
        });

        angular.forEach(ctrl.prices, function (price) {
            var interval = null;
            for (var i = 0; i < ctrl.tab.content.length; i++) {
                var aInterval = ctrl.tab.content[i];
                if (aInterval.startDate === price.startDate && aInterval.endDate === price.endDate) {
                    interval = aInterval;
                    break;
                }
            }
            if (interval === null) {
                interval = {
                    name: price.name,
                    startDate: price.startDate,
                    endDate: price.endDate,
                    prices: []
                };
                ctrl.tab.content.push(interval);
            }
            interval.prices.push(price);
        });


    }
})();