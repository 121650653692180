(function () {
  'use strict';
  angular.module('canceropoleGsoApp').controller('SheetCallForProjectFormTabController', SheetCallForProjectFormTabController);
  SheetCallForProjectFormTabController.$inject = [
    'CallForProject',
    '$stateParams',
    'formFieldsList',
    'SheetHistoryService',
    '$state',
    'callForProject',
    'JQWidgetsService',
    'Principal',
    'CallForProjectService',
    '$log',
    '$translate',
    '$uibModal',
    'CustomUtils',
    '$scope'
  ];
  function SheetCallForProjectFormTabController(
    CallForProject,
    $stateParams,
    formFieldsList,
    SheetHistoryService,
    $state,
    callForProject,
    JQWidgetsService,
    Principal,
    CallForProjectService,
    $log,
    $translate,
    $uibModal,
    CustomUtils,
    $scope
  ) {
    if ($stateParams.tab !== 'form') {
      //update state param
      $stateParams.tab = 'form';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.callForProject = callForProject;
    vm.formFieldsList = formFieldsList;
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
    vm.preview = false;
    if (!vm.canEdit) {
      vm.preview = true;
    }

    vm.formConfig = CallForProjectService.getFormConfig(vm.callForProject);

    vm.existingTypes = [];
    vm.forms = [
      {
        id: '-1',
        name: 'Information porteur de projet',
        type: 'person',
        startDate: null,
        endDate: null
      },
      {
        id: '-2',
        name: 'Projet',
        type: 'project',
        startDate: null,
        endDate: null
      }
    ];
    angular.forEach(vm.formFieldsList, function (formField) {
      var form = formField.form;
      vm.forms.push(form);
      vm.existingTypes.push(form.type);
    });

    var sheetHistory = {
      name: vm.callForProject.name,
      type: 'callforproject',
      id: vm.callForProject.id
    };
    SheetHistoryService.add(sheetHistory);

    vm.edit = function (win) {
      vm.edition[win] = true;
    };

    vm.reload = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.addForm = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/callforproject/dialog/add-form.dialog.html',
          controller: 'AddCallForProjectFormDialogController',
          controllerAs: 'ctrl',
          size: 'sm',
          backdrop: 'static',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('callForProjectForm');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }
            ],
            callForProject: function () {
              return vm.callForProject;
            },
            existingFormTypes: function () {
              return vm.existingTypes;
            },
            templates: [
              '$http',
              function ($http) {
                return $http.get('api/call-for-projects/form/templates').then(function (response) {
                  return response.data;
                });
              }
            ]
          }
        })
        .result.then(function () {
          vm.reload();
        });
    };

    vm.editForm = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/callforproject/dialog/edit-form.dialog.html',
          controller: 'EditCallForProjectFormDialogController',
          controllerAs: 'ctrl',
          size: 'sm',
          backdrop: 'static',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('callForProjectForm');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }
            ],
            callForProject: function () {
              return vm.callForProject;
            },
            formFieldsList: function () {
              return vm.formFieldsList;
            }
          }
        })
        .result.then(function () {
          vm.reload();
        });
    };

    var source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'type', type: 'string' },
        { name: 'startDate', type: 'date' },
        { name: 'endDate', type: 'date' }
      ],
      localdata: vm.forms
    };

    vm.formGridSettings = {
      width: '100%',
      //height: 300,
      source: source,
      autosavestate: true,
      autoloadstate: true,
      columnsresize: true,
      altrows: true,
      //selectionmode: 'multiplerows',
      localization: JQWidgetsService.getLocalization($translate.use()),
      columns: [
        {
          text: 'Nom',
          datafield: 'name',
          minwidth: 230,
          cellsalign: 'center',
          filtertype: 'date',
          align: 'center'
        },
        {
          text: 'Début',
          datafield: 'startDate',
          width: 160,
          cellsalign: 'center',
          cellsformat: 'dd/MM/yyyy',
          align: 'center'
        },
        {
          text: 'Fin',
          datafield: 'endDate',
          width: 160,
          cellsalign: 'center',
          cellsformat: 'dd/MM/yyyy',
          align: 'center'
        }
      ],
      created: function (args) {
        vm.formGrid = args.instance;
        vm.selectedForm = vm.getSelection();
        vm.loadSelectedForm();
      },
      rowselect: function (event) {
        vm.selectedForm = event.args.row;
        vm.loadSelectedForm();
      }
    };

    vm.loadSelectedForm = function () {
      vm.selectedPersonForm = null;
      vm.selectedProjectForm = null;
      vm.selectedFormField = null;
      if (vm.selectedForm) {
        switch (vm.selectedForm.type) {
          case 'person':
            vm.selectedPersonForm = {};
            break;
          case 'project':
            vm.selectedProjectForm = {
              submission: {},
              project: {},
              canSaveProject: function () {},
              saveProject: function () {}
            };
            break;
          default:
            for (var i = 0; i < vm.formFieldsList.length; i++) {
              var formField = vm.formFieldsList[i];
              if (formField.form.id == vm.selectedForm.id) {
                vm.selectedFormField = CallForProjectService.loadFormField(formField);
                break;
              }
            }
        }
      }
      vm.formGrid.savestate();
    };

    vm.getSelection = function () {
      var index = vm.formGrid.getselectedrowindex();
      var selection = null;
      if (index >= 0) {
        selection = vm.forms[index];
      }
      return selection;
    };

    vm.saveCallForProject = function () {
      vm.callForProject.formConfig = JSON.stringify(vm.formConfig);
      CallForProject.update(vm.callForProject);
    };

    $scope.$watch('vm.callForProject.descriptionHelpText', function (oldValue, newValue) {
      if (oldValue !== newValue) {
        if (
          !CustomUtils.isEmpty(vm.callForProject.descriptionHelpText) &&
          !CustomUtils.isUndefinedOrNull(vm.callForProject.descriptionHelpText)
        ) {
          var nbChar = CustomUtils.countCharInHtmlString(vm.callForProject.descriptionHelpText);
          var left = 1000 - nbChar;
          if (left <= 0) {
            var lengthToKeep = vm.callForProject.descriptionHelpText.length - Math.abs(left);
            vm.callForProject.descriptionHelpText = vm.callForProject.descriptionHelpText.substring(0, lengthToKeep);
          }
        }
      }
    });

    vm.editorSettings = {
      width: '100%',
      height: 300,
      tools: JQWidgetsService.getDefaultAdminEditorTools(),
      createCommand: JQWidgetsService.getEditorCommand(vm.callForProject.descriptionHelpText)
    };
  }
})();
