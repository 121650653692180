(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetPersonProjectTabController', SheetPersonProjectTabController);

  SheetPersonProjectTabController.$inject = [
    'Principal',
    '$stateParams',
    '$state',
    'CustomUtils',
    'personEntity',
    '$http',
    'JQWidgetsService',
    '$translate',
    '$filter',
    'AbstractService',
    'DateUtils',
    'DataUtils'
  ];

  function SheetPersonProjectTabController(
    Principal,
    $stateParams,
    $state,
    CustomUtils,
    personEntity,
    $http,
    JQWidgetsService,
    $translate,
    $filter,
    AbstractService,
    DateUtils,
    DataUtils
  ) {
    if ($stateParams.tab !== 'project') {
      //update state param
      $stateParams.tab = 'project';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    var projectsDataAdapter = new $.jqx.dataAdapter({
      url: 'api/person/' + $stateParams.id + '/projects',
      dataType: 'json',
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'participationType',
          type: 'string'
        },
        {
          name: 'participationStartDate',
          type: 'date'
        },
        {
          name: 'participationEndDate',
          type: 'date'
        },
        {
          name: 'acronym',
          type: 'string'
        }
      ]
    });

    vm.projectsSettings = {
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      source: projectsDataAdapter,
      enabletooltips: true,
      height: 350,
      columnsresize: true,
      altrows: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      created: function (args) {
        vm.projectsGrid = args.instance;
      },
      columns: [
        {
          text: 'Nom',
          datafield: 'name',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.id)) {
              var link = '<a href="#/sheet/project/' + object.id + '?tab=main">';
              link += object.name;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          cellsalign: 'center',
          align: 'center',
          minwidth: 200,
          pinned: true
        },
        {
          text: 'Acronym',
          datafield: 'acronym',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 150
        },
        {
          text: 'Type',
          datafield: 'participationType',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          columngroup: 'participation',
          editable: false,
          width: 150
        },
        {
          text: 'Début',
          datafield: 'participationStartDate',
          filtertype: 'date',
          cellsalign: 'center',
          align: 'center',
          cellsformat: 'dd/MM/yyyy',
          columngroup: 'participation',
          editable: false,
          width: 130
        },
        {
          text: 'Fin',
          datafield: 'participationEndDate',
          filtertype: 'date',
          cellsalign: 'center',
          cellsformat: 'dd/MM/yyyy',
          columngroup: 'participation',
          align: 'center',
          editable: false,
          width: 130
        }
      ],
      columngroups: [
        {
          text: 'Participation',
          align: 'center',
          name: 'participation'
        }
      ],
      rowselect: function (event) {
        if (event.args) {
          //var args = event.args;
          //vm.selectedJob = args.row;
        }
      },
      rowunselect: function () {}
    };

    var submissionsDataAdapter = new $.jqx.dataAdapter({
      url: 'api/person/' + $stateParams.id + '/submissions',
      dataType: 'json',
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'callForProjectId',
          type: 'number'
        },
        {
          name: 'callForProjectName',
          type: 'string'
        },
        {
          name: 'callForProjectYear',
          type: 'number'
        },
        {
          name: 'date',
          type: 'date'
        },
        {
          name: 'axeName',
          type: 'string'
        },
        {
          name: 'geositeName',
          type: 'string'
        },
        {
          name: 'askedBudget',
          type: 'number'
        },
        {
          name: 'askedDuration',
          type: 'number'
        },
        {
          name: 'acceptedBudget',
          type: 'number'
        },
        {
          name: 'acceptedDuration',
          type: 'number'
        },
        {
          name: 'key',
          type: 'number'
        },
        {
          name: 'projectId',
          type: 'number'
        }
      ]
    });

    vm.submissionsSettings = {
      width: '100%',
      localization: JQWidgetsService.getLocalization($translate.use()),
      source: submissionsDataAdapter,
      enabletooltips: true,
      height: 350,
      columnsresize: true,
      altrows: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      showaggregates: true,
      showstatusbar: true,
      statusbarheight: 25,
      created: function (args) {
        vm.projectsGrid = args.instance;
      },
      columns: [
        {
          text: 'Date',
          datafield: 'date',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.key)) {
              var link = '<a href="#/callforproject/' + object.callForProjectId + '/space?key=' + object.key + '">';
              link += $filter('date')(object.date, 'dd/MM/yyyy HH:mm');
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          filtertype: 'range',
          cellsalign: 'center',
          align: 'center',
          width: 130,
          pinned: true,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                return ++aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Nom',
          datafield: 'name',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.projectId)) {
              var link = '<a href="#/sheet/project/' + object.projectId + '?tab=main">';
              link += object.name;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          cellsalign: 'center',
          align: 'center',
          minwidth: 200,
          pinned: true
        },
        {
          text: 'Année',
          datafield: 'callForProjectYear',
          filtertype: 'checkedlist',
          columngroup: 'aap',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 80
        },
        {
          text: 'Nom',
          datafield: 'callForProjectName',
          columngroup: 'aap',
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.callForProjectId)) {
              var link = '<a href="#/sheet/callforproject/' + object.callForProjectId + '?tab=main">';
              link += object.callForProjectName;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 180
        },
        {
          text: 'Demandé',
          datafield: 'askedBudget',
          cellsformat: 'c',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          columngroup: 'budget',
          editable: false,
          width: 130,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                if (_.isNil(currentValue)) {
                  currentValue = 0;
                }
                aggregatedValue += currentValue;
                return aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Accepté',
          datafield: 'acceptedBudget',
          cellsformat: 'c',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          columngroup: 'budget',
          editable: false,
          width: 130,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                if (_.isNil(currentValue)) {
                  currentValue = 0;
                }
                aggregatedValue += currentValue;
                return aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Demandée',
          datafield: 'askedDuration',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          columngroup: 'duration',
          editable: false,
          width: 130,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                if (_.isNil(currentValue)) {
                  currentValue = 0;
                }
                aggregatedValue += currentValue;
                return aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Acceptée',
          datafield: 'acceptedDuration',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          columngroup: 'duration',
          editable: false,
          width: 130,
          aggregates: [
            {
              '<b>Total</b>': function (aggregatedValue, currentValue) {
                if (_.isNil(currentValue)) {
                  currentValue = 0;
                }
                aggregatedValue += currentValue;
                return aggregatedValue;
              }
            }
          ]
        },
        {
          text: 'Axe',
          datafield: 'axeName',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 130
        },
        {
          text: 'Site',
          datafield: 'geositeName',
          filtertype: 'checkedlist',
          cellsalign: 'center',
          align: 'center',
          editable: false,
          width: 130
        }
      ],
      columngroups: [
        {
          text: 'Budget (en €)',
          align: 'center',
          name: 'budget'
        },
        {
          text: 'Durée (en mois)',
          align: 'center',
          name: 'duration'
        },
        {
          text: 'Appel à projet',
          align: 'center',
          name: 'aap'
        }
      ],
      rowselect: function (event) {
        if (event.args) {
          //var args = event.args;
          //vm.selectedJob = args.row;
        }
      },
      rowunselect: function () {}
    };
  }
})();
