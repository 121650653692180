(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.person', {
            parent: 'sheet',
            url: 'person/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.person.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/person/sheet.person.html',
                    controller: 'SheetPersonController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('civilite');
                        $translatePartialLoader.addPart('publication');
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('personSubType');
                        $translatePartialLoader.addPart('scientificAttachment');
                        return $translate.refresh();
                    }],
                personEntity: ['$stateParams', 'Person', function ($stateParams, Person) {
                        return Person.get({id: $stateParams.id}).$promise;
                    }]
            }
        }).state('sheet.person.main', {
            parent: 'sheet.person',
            views: {
                'sheet.person.main': {
                    templateUrl: 'app/sheet/person/tab/sheet.person.main.html',
                    controller: 'SheetPersonMainTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.person.followed', {
            parent: 'sheet.person',
            views: {
                'sheet.person.followed': {
                    templateUrl: 'app/sheet/person/tab/sheet.person.followed.html',
                    controller: 'SheetPersonFollowedTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.person.communication', {
            parent: 'sheet.person',
            views: {
                'sheet.person.communication': {
                    templateUrl: 'app/sheet/person/tab/sheet.person.communication.html',
                    controller: 'SheetPersonCommunicationTabController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.person.project', {
            parent: 'sheet.person',
            views: {
                'sheet.person.project': {
                    templateUrl: 'app/sheet/person/tab/sheet.person.project.html',
                    controller: 'SheetPersonProjectTabController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();