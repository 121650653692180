/* globals L, _ */

(function() {
  'use strict';

  angular.module('canceropoleGsoApp').controller('LeafletMapsController', LeafletMapsController);

  LeafletMapsController.$inject = ['MapSearchService', 'GeoService', 'CustomUtils', 'availableTypes', '$log', 'localStorageService'];

  function LeafletMapsController(MapSearchService, GeoService, CustomUtils, availableTypes, $log, localStorageService) {
    var LOCATION_KEY_STORAGE = 'lngLatLocation';

    var vm = this;
    vm.availableTypes = [];
    if (availableTypes && availableTypes.aggregations && availableTypes.aggregations.type) {
      vm.availableTypes = availableTypes.aggregations.type.buckets;
    }

    vm.toggleType = function(type) {
      type.selected = !type.selected;
      var mapStorage = {
        center: vm.center,
        types: vm.getSelectedTypes()
      };

      localStorageService.add(LOCATION_KEY_STORAGE, angular.toJson(mapStorage));

      vm.searchTypes();
    };

    vm.getSelectedTypes = function() {
      var selectedTypes = [];
      if (!CustomUtils.isUndefinedOrNull(vm.availableTypes)) {
        angular.forEach(vm.availableTypes, function(availableType) {
          if (availableType.selected) {
            selectedTypes.push(availableType.key);
          }
        });
      }
      return selectedTypes;
    };

    vm.searchTypes = function() {
      var query = null;
      vm.markerGroup.clearLayers();

      if (!CustomUtils.isUndefinedOrNull(vm.availableTypes)) {
        var typeQuery = '';
        for (var i = 0; i < vm.availableTypes.length; i++) {
          var availableType = vm.availableTypes[i];
          if (availableType.selected) {
            if (typeQuery.length > 0) {
              typeQuery += ' | ';
            }
            typeQuery += availableType.key;
          }
        }
        if (typeQuery.length > 0) {
          query = '_type:(' + typeQuery + ')';
        }
      }
      $log.debug('query is', query);
      if (!CustomUtils.isUndefinedOrNull(query)) {
        MapSearchService.query(
          {
            query: query
          },
          function(result) {
            vm.docCount = result.length;
            for (var i = 0; i < result.length; i++) {
              var aResult = result[i];

              if (aResult.lat) {
                var type = aResult.type;
                var name = aResult.name.replaceAll("'", '');
                var id = aResult.id;

                var description = aResult.description;

                var message = '<a href="#/sheet/' + type + '/' + id + '"><div>';
                //message += "(" + id + ")<br/>";
                message += '<b>' + name + '</b><br/>';
                message += description;
                message += '</div></a>';

                var marker = L.marker([parseFloat(aResult.lat), parseFloat(aResult.lng)], {
                  icon: L.icon(GeoService.iconMarker(aResult.type))
                });
                marker.addTo(vm.markerGroup);
                marker.bindPopup(message);
              }
            }
          }
        );
      }
    };

    vm.center = {
      lat: 45.600865,
      lng: 1.432632,
      zoom: 5
    };

    var lastLocationStr = localStorageService.get(LOCATION_KEY_STORAGE);
    if (lastLocationStr) {
      var lastLocation = angular.fromJson(lastLocationStr);
      vm.center = lastLocation.center;
      if (!CustomUtils.isUndefinedOrNull(vm.availableTypes) && !CustomUtils.isUndefinedOrNull(lastLocation.types)) {
        for (var i = 0; i < lastLocation.types.length; i++) {
          var selectedType = lastLocation.types[i];
          for (var j = 0; j < vm.availableTypes.length; j++) {
            var availableType = vm.availableTypes[j];
            if (selectedType === availableType.key) {
              availableType.selected = true;
              break;
            }
          }
        }
      }
    }

    vm.map = L.map('map', {
      maxZoom: 18
    }).setView([vm.center.lat, vm.center.lng], vm.center.zoom);
    vm.markerGroup = L.markerClusterGroup();
    vm.map.addLayer(vm.markerGroup);
    vm.map.on('moveend zoomend', function(e) {
      var center = _.clone(vm.map.getCenter());
      center.zoom = vm.map.getZoom();

      var mapStorage = {
        center: center,
        types: vm.getSelectedTypes()
      };
      localStorageService.add(LOCATION_KEY_STORAGE, angular.toJson(mapStorage));
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(vm.map);

    vm.searchTypes();
  }
})();
