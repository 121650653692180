(function () {
    'use strict';

    angular.module('canceropoleGsoApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.project', {
            parent: 'sheet',
            url: 'project/:id?tab',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.project.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/project/sheet.project.html',
                    controller: 'SheetProjectController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('project');
                        $translatePartialLoader.addPart('projectParticipation');
                        return $translate.refresh();
                    }],
                project: ['$stateParams', 'Project', function ($stateParams, Project) {
                        return Project.get({id: $stateParams.id}).$promise;
                    }]
            }
        }).state('sheet.project.main', {
            parent: 'sheet.project',
            views: {
                'sheet.project.main': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.main.html',
                    controller: 'SheetProjectMainController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                coordinators: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get("api/project/coordinators/" + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }]
            }
        }).state('sheet.project.submission', {
            parent: 'sheet.project',
            views: {
                'sheet.project.submission': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.submission.html',
                    controller: 'SheetProjectSubmissionController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.project.followed', {
            parent: 'sheet.project',
            views: {
                'sheet.project.followed': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.followed.html',
                    controller: 'SheetProjectFollowedController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.project.intervention', {
            parent: 'sheet.project',
            views: {
                'sheet.project.intervention': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.intervention.html',
                    controller: 'SheetProjectInterventionController',
                    controllerAs: 'vm'
                }
            }
        }).state('sheet.project.publication', {
            parent: 'sheet.project',
            views: {
                'sheet.project.publication': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.publication.html',
                    controller: 'SheetProjectPublicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('publication');
                        return $translate.refresh();
                    }]
            }
        }).state('sheet.project.valorisation', {
            parent: 'sheet.project',
            views: {
                'sheet.project.valorisation': {
                    templateUrl: 'app/sheet/project/tab/sheet.project.valorisation.html',
                    controller: 'SheetProjectValorisationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('valorization');
                        $translatePartialLoader.addPart('valorizationType');
                        return $translate.refresh();
                    }],
                valorizations: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get("api/project/valorization/" + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }]
            }
        });
    }
})();