(function() {
    "use strict";

    angular.module("canceropoleGsoApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("callforproject", {
                parent: "app",
                url: "/aap",
                data: {
                    authorities: ["ROLE_ADMIN"],
                    pageTitle: "canceropoleGsoApp.callForProject.pageTitle"
                },
                params: {
                    event: null
                },
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/callforproject.html",
                        controller: "CallForProjectController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("callForProject");
                            $translatePartialLoader.addPart("cFPFormType");
                            $translatePartialLoader.addPart("callForProjectForm");

                            return $translate.refresh();
                        }
                    ]
                }
            })
            .state("callForProjectSubscription", {
                parent: "app",
                url: "/callForProject/subscription/:id",
                data: {
                    authorities: [],
                    ownLanguage: true
                },
                params: {},
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/subscription/callforproject.subscription.html",
                        controller: "CallForProjectSubscriptionController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        "JhiLanguageService",
                        function($translate, $translatePartialLoader, JhiLanguageService) {
                            $translatePartialLoader.addPart("subscription");
                            $translatePartialLoader.addPart("submission");
                            $translatePartialLoader.addPart("callForProject");
                            $translatePartialLoader.addPart("entities");
                            $translatePartialLoader.addPart("civilite");
                            $translatePartialLoader.addPart("personSocietyInput");
                            $translatePartialLoader.addPart("sheet");
                            return $translate.refresh();
                        }
                    ],
                    entity: [
                        "$stateParams",
                        "CallForProject",
                        "JhiLanguageService",
                        function($stateParams, CallForProject) {
                            return CallForProject.get({ id: $stateParams.id }).$promise;
                        }
                    ],
                    formFieldsList: [
                        "$http",
                        "$stateParams",
                        function($http, $stateParams) {
                            return $http
                                .get("api/call-for-projects/" + $stateParams.id + "/form/all")
                                .then(function(response) {
                                    return response.data;
                                });
                        }
                    ],
                    axes: [
                        "Axe",
                        function(Axe) {
                            return Axe.query();
                        }
                    ],
                    managements: [
                        "$http",
                        "$stateParams",
                        function($http, $stateParams) {
                            return $http
                                .get("api/call-for-projects/managements/" + $stateParams.id)
                                .then(function(response) {
                                    return response.data;
                                });
                        }
                    ],
                    lang: [
                        "JhiLanguageService",
                        "entity",
                        function(JhiLanguageService, entity) {
                            return JhiLanguageService.changeLanguage(entity.lang);
                        }
                    ]
                }
            })
            .state("callForProjectSubmissionConfirmation", {
                parent: "app",
                url: "/callForProject/subscription/{id}/confirmation",
                data: {
                    authorities: [],
                    ownLanguage: true
                },
                params: {
                    confirmation: null,
                    callForProject: null,
                    managements: null
                },
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/subscription/callforproject.subscriptionConfirm.html",
                        controller: "CallForProjectSubscriptionConfirmController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("submission");
                            $translatePartialLoader.addPart("subscription");
                            return $translate.refresh();
                        }
                    ]
                }
            })
            .state("SubmissionSpaceController", {
                parent: "app",
                url: "/callforproject/{id}/space?key",
                data: {
                    authorities: [],
                    ownLanguage: true
                },
                params: {},
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/subscription/space/callforproject.space.html",
                        controller: "CallForProjectSpaceController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("submission");
                            $translatePartialLoader.addPart("subscription");
                            $translatePartialLoader.addPart("callForProject");
                            $translatePartialLoader.addPart("callForProjectForm");
                            $translatePartialLoader.addPart("sheet");
                            $translatePartialLoader.addPart("formFieldType");
                            $translatePartialLoader.addPart("civilite");
                            $translatePartialLoader.addPart("personSocietyInput");
                            $translatePartialLoader.addPart("callForProjectMailType");
                            $translatePartialLoader.addPart("fileAttachement");
                            $translatePartialLoader.addPart("global");

                            return $translate.refresh();
                        }
                    ],
                    allInformations: [
                        "$stateParams",
                        "$http",
                        function($stateParams, $http) {
                            return $http.get("api/submission/private/" + $stateParams.key).then(function(response) {
                                return response.data;
                            });
                        }
                    ],
                    personMetierEntities: [
                        "PersonMetier",
                        function(PersonMetier) {
                            return PersonMetier.query().$promise;
                        }
                    ],
                    lang: [
                        "JhiLanguageService",
                        "allInformations",
                        function(JhiLanguageService, allInformations) {
                            return JhiLanguageService.changeLanguage(allInformations.callForProject.lang);
                        }
                    ]
                }
            })
            .state("SubmissionSpaceController.stepOne", {
                parent: "SubmissionSpaceController",
                views: {
                    "SubmissionSpaceController.stepOne": {
                        templateUrl: "app/callforproject/subscription/space/tab/callforproject.space.stepOne.html",
                        controller: "CallForProjectSpaceStepOneController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("SubmissionSpaceController.stepTwo", {
                parent: "SubmissionSpaceController",
                views: {
                    "SubmissionSpaceController.stepTwo": {
                        templateUrl: "app/callforproject/subscription/space/tab/callforproject.space.stepTwo.html",
                        controller: "CallForProjectSpaceStepTwoController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("SubmissionSpaceController.finalStep", {
                parent: "SubmissionSpaceController",
                views: {
                    "SubmissionSpaceController.finalStep": {
                        templateUrl: "app/callforproject/subscription/space/tab/callforproject.space.finalStep.html",
                        controller: "CallForProjectSpaceFinalStepController",
                        controllerAs: "vm"
                    }
                }
            })
            .state("ExpertSpaceController", {
                parent: "app",
                url: "/callforproject/{id}/expert?key&expertiseId",
                reloadOnSearch: false,
                data: {
                    authorities: [],
                    ownLanguage: true
                },
                params: {},
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/subscription/expert/callforproject.expert.html",
                        controller: "CallForProjectExpertController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("submission");
                            $translatePartialLoader.addPart("subscription");
                            $translatePartialLoader.addPart("callForProject");
                            $translatePartialLoader.addPart("sheet");
                            $translatePartialLoader.addPart("formFieldType");
                            $translatePartialLoader.addPart("civilite");
                            $translatePartialLoader.addPart("personSocietyInput");
                            $translatePartialLoader.addPart("cFPFormType");
                            $translatePartialLoader.addPart("expertise");
                            $translatePartialLoader.addPart("expertiseState");
                            $translatePartialLoader.addPart("fileAttachement");
                            return $translate.refresh();
                        }
                    ],
                    expertInformation: [
                        "$stateParams",
                        "$http",
                        function($stateParams, $http) {
                            return $http.get("api/expert/space/" + $stateParams.key).then(function(response) {
                                return response.data;
                            });
                        }
                    ],
                    callForProject: [
                        "$stateParams",
                        "CallForProject",
                        function($stateParams, CallForProject) {
                            return CallForProject.get({ id: $stateParams.id }).$promise;
                        }
                    ],
                    lang: [
                        "JhiLanguageService",
                        "callForProject",
                        function(JhiLanguageService, callForProject) {
                            return JhiLanguageService.changeLanguage(callForProject.lang);
                        }
                    ]
                }
            })
            .state("CFPStatsController", {
                parent: "app",
                url: "/callforprojects/stats",
                reloadOnSearch: false,
                data: {
                    authorities: ["ROLE_ADMIN"]
                },
                params: {},
                views: {
                    "content@": {
                        templateUrl: "app/callforproject/stats/callforproject.stats.html",
                        controller: "CallForProjectStatsController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("callForProject");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        }
                    ]
                }
            });
    }
})();
