(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('SubmissionResponseDetailController', SubmissionResponseDetailController);

    SubmissionResponseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'SubmissionResponse', 'Submission', 'CallForProjectFormField'];

    function SubmissionResponseDetailController($scope, $rootScope, $stateParams, DataUtils, entity, SubmissionResponse, Submission, CallForProjectFormField) {
        var vm = this;

        vm.submissionResponse = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:submissionResponseUpdate', function(event, result) {
            vm.submissionResponse = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
