(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SelectCallForProjectTemplateDialogController', SelectCallForProjectTemplateDialogController);

    SelectCallForProjectTemplateDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', 'JQWidgetsService', '$log'];

    function SelectCallForProjectTemplateDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, JQWidgetsService, $log) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;

        ctrl.ok = function () {
            $uibModalInstance.close(callForProject);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        ctrl.templateSource = [{
                id: 1,
                name: 'Emergence'
            }, {
                id: 2,
                name: 'Synergie'
            }];

        ctrl.templateSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.templateSource,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.addTemplateForm.$setDirty();
                }
            }
        };
    }
})();