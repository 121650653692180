(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('EventOptionEditDialogController', EventOptionEditDialogController);

    EventOptionEditDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'option', 'mainEvent'];

    function EventOptionEditDialogController($translate, $translatePartialLoader, $uibModalInstance, option, mainEvent) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.eventOption = option;
        ctrl.mainEvent = mainEvent;

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.eventOption);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();