/* global _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SubscriptionSpaceController', SubscriptionSpaceController);

  SubscriptionSpaceController.$inject = [
    '$stateParams',
    'registrationEntity',
    'mainEventEntity',
    '$log',
    'DateUtils',
    'Principal',
    '$http',
    'DataUtils',
    '$uibModal',
    '$state',
    'EventAbstract',
    'sessionEntities',
    'axeEntities',
    '$timeout',
    'EventRegistration',
    '$q',
    'AlertService',
    'ConfirmDialog',
    '$rootScope',
    'CustomUtils',
    'AbstractService',
    'personMetierEntities',
    'MainEventService',
    'eventOptions',
    'prices',
    'JQWidgetsService',
    '$translate',
    'managements',
    'ModelConfig'
  ];

  function SubscriptionSpaceController(
    $stateParams,
    registrationEntity,
    mainEventEntity,
    $log,
    DateUtils,
    Principal,
    $http,
    DataUtils,
    $uibModal,
    $state,
    EventAbstract,
    sessionEntities,
    axeEntities,
    $timeout,
    EventRegistration,
    $q,
    AlertService,
    ConfirmDialog,
    $root,
    CustomUtils,
    AbstractService,
    personMetierEntities,
    MainEventService,
    eventOptions,
    prices,
    JQWidgetsService,
    $translate,
    managements,
    ModelConfig
  ) {
    //cache la barre de navigation
    $root.hideNavBar = !Principal.isAuthenticated();

    var vm = this;
    vm.mainEvent = mainEventEntity;

    var displayMember = 'name';
    if (vm.mainEvent.lang === 'en') {
      displayMember = 'nameEn';
    }

    vm.metiers = personMetierEntities;
    vm.metiers.sort(function (r1, r2) {
      var l1 = r1[displayMember].toLowerCase();
      var l2 = r2[displayMember].toLowerCase();
      return l1.localeCompare(l2);
    });
    vm.prices = prices;
    vm.registration = registrationEntity;
    vm.axes = axeEntities;
    vm.daySessions = sessionEntities;
    vm.sessions = [];
    vm.key = $stateParams.key;
    vm.abstract = null;
    vm.editInscription = false;
    vm.editPrivateInformation = false;
    vm.availableOptions = CustomUtils.sortByKey(eventOptions, 'position');

    vm.freeType = ModelConfig.mainEvent.management.getFreeRegistrationType();
    vm.partialFreeType = ModelConfig.mainEvent.management.getPartialFreeRegistrationType();

    vm.freeRegistrationType = null;
    if (!_.isNil(vm.registration.personId)) {
      angular.forEach(managements, function (management) {
        if (management.personId === vm.registration.personId) {
          if (management.type === vm.freeType) {
            vm.freeRegistrationType = vm.freeType;
          } else if (management.type === vm.partialFreeType) {
            vm.freeRegistrationType = vm.partialFreeType;
          }
        }
      });
    }

    angular.forEach(vm.availableOptions, function (availableOption) {
      availableOption.selected = false;
      for (var i = 0; i < vm.registration.eventOptions.length; i++) {
        var selectedOption = vm.registration.eventOptions[i];
        if (selectedOption.id === availableOption.id) {
          availableOption.selected = true;
        }
      }
    });

    vm.isOpen = function () {
      var limitDate = vm.mainEvent.inscriptionLimitDate;
      limitDate = DateUtils.convertDateTimeFromServer(limitDate);
      //limitDate.setFullYear(1900);
      var now = new Date();
      var compare = DateUtils.compare(now, limitDate);
      if (compare <= 0) {
        return true;
      }
      return false;
    };

    vm.isMarkAsDeleted = function () {
      return !CustomUtils.isUndefinedOrNull(vm.registration.deletedAt);
    };

    //initialisation des ranges et des sessions associées
    var rangeId = 0;
    angular.forEach(vm.daySessions, function (eventDay) {
      eventDay.ranges = [];

      eventDay.eventSessions.sort(function (s1, s2) {
        var startDate = DateUtils.convertDateTimeFromServer(s1.startDate);
        var endDate = DateUtils.convertDateTimeFromServer(s2.startDate);
        return DateUtils.compare(startDate, endDate);
      });

      angular.forEach(eventDay.eventSessions, function (eventSession) {
        vm.sessions.push(eventSession);

        var startDate = DateUtils.convertDateTimeFromServer(eventSession.startDate);
        var endDate = DateUtils.convertDateTimeFromServer(eventSession.endDate);

        var range = null;
        angular.forEach(eventDay.ranges, function (aRange) {
          var start = DateUtils.inRange(startDate, aRange.startDate, aRange.endDate);
          var end = DateUtils.inRange(endDate, aRange.startDate, aRange.endDate);
          if (start && end) {
            range = aRange;
          }
        });

        if (range === null) {
          range = {
            id: rangeId,
            startDate: startDate,
            endDate: endDate,
            sessions: []
          };
          rangeId++;

          range.sessions.push(eventSession);
          eventDay.ranges.push(range);
        } else {
          range.sessions.push(eventSession);
        }
      });

      angular.forEach(eventDay.ranges, function (range) {
        if (!(range.sessions.length === 1 && range.sessions[0].mandatory)) {
          //ajout d'une session fake pour choix de ne pas participer
          range.sessions.unshift({
            startDate: range.sessions[0].startDate,
            endDate: range.sessions[0].endDate,
            id: -1,
            price: null,
            name: $translate.instant('subscription.sessions.notSelected'),
            type: 'fake'
          });
        }
      });
    });

    if (vm.registration.sessions) {
      vm.registration.sessions.sort(function (s1, s2) {
        var d1 = DateUtils.convertDateTimeFromServer(s1.startDate);
        var d2 = DateUtils.convertDateTimeFromServer(s2.startDate);
        return DateUtils.compare(d1, d2);
      });
    }

    vm.isAdmin = function () {
      if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
        return true;
      }
      return false;
    };

    vm.canEditSession = function () {
      if (vm.isAdmin() || (vm.isOpen() && !vm.isMarkAsDeleted())) {
        return true;
      }
      return false;
    };

    vm.canEditOption = function (option) {
      if (option.price && vm.registration.confirmed) {
        return false;
      }

      if (vm.isAdmin()) {
        return true;
      }
      if (option.disabled) {
        return false;
      }
      return vm.canEditSession();
    };

    vm.cancelEdition = function () {
      vm.editInscription = false;
      vm.editPrivateInformation = false;
      vm.reloadRegistration();
    };

    vm.saveInscriptionEdition = function () {
      vm.registration.phone = CustomUtils.correctPhone(vm.registration.phone);
      vm.registration.portable = CustomUtils.correctPhone(vm.registration.portable);
      if (vm.subscriptionForm.$valid === true) {
        vm.editInscription = false;
        if (vm.subscriptionForm.$dirty === true) {
          if (vm.registration.personStatusId !== 18) {
            vm.registration.detailPersonFunction = null;
          }
          EventRegistration.update(
            vm.registration,
            function (result) {
              vm.registration = result;
              AlertService.info('Votre participation à été mise à jour.');
            },
            function (error) {
              $log.error(error);
              AlertService.error("Votre participation n'à pas pu être mise à jour.");
            }
          );
        }
      } else {
        AlertService.warning('Veuillez corriger les erreurs avant de sauvegarder.');
        $log.error('Formulaire invalide');
      }
    };

    vm.savePrivateInformation = function () {
      vm.editPrivateInformation = false;
      EventRegistration.update(
        vm.registration,
        function (result) {
          vm.registration = result;
          AlertService.info('Votre participation à été mise à jour.');
        },
        function (error) {
          $log.error(error);
          AlertService.error("Votre participation n'à pas pu être mise à jour.");
        }
      );
    };

    vm.getMetierName = function () {
      if (vm.registration && vm.registration.personMetierId) {
        for (var i = 0; i < vm.metiers.length; i++) {
          var personMetierEntity = vm.metiers[i];
          if (personMetierEntity.id === vm.registration.personMetierId) {
            return personMetierEntity[displayMember];
          }
        }
      }
      return '';
    };

    vm.getStatusName = function () {
      return vm.status[displayMember];
    };

    vm.metierDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id',
      source: new $.jqx.dataAdapter({
        // jshint ignore:line
        localdata: vm.metiers,
        datatype: 'json',
        id: 'id',
        datafields: [
          {
            name: 'id'
          },
          {
            name: 'name'
          },
          {
            name: 'nameEn'
          },
          {
            name: 'personStatuses',
            type: 'array'
          }
        ]
      })
    };

    vm.onMetierSelect = function (event) {
      var args = event.args;
      if (args) {
        var metier = args.item.originalItem;
        vm.metier = metier;
        vm.registration.personMetierId = metier.id;
        vm.registration.personMetierName = metier.name;

        var status = metier.personStatuses;
        status.sort(function (r1, r2) {
          var l1 = r1[displayMember].toLowerCase();
          var l2 = r2[displayMember].toLowerCase();
          return l1.localeCompare(l2);
        });

        var dataAdapter = new $.jqx.dataAdapter({
          localdata: status,
          datatype: 'json',
          datafields: [
            {
              name: 'id'
            },
            {
              name: 'name'
            },
            {
              name: 'nameEn'
            }
          ]
        });
        vm.statusDropDownListSettings.source = dataAdapter;

        vm.subscriptionForm.$dirty = true;
      }
    };

    vm.statusDropDownList = null;
    vm.statusDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id',
      created: function (args) {
        vm.statusDropDownList = args.instance;
      }
    };

    vm.onStatusSelect = function (event) {
      var args = event.args;
      if (args) {
        var status = args.item.originalItem;
        vm.status = status;
        vm.registration.personStatusId = status.id;
        vm.registration.personStatusName = status.name;

        vm.subscriptionForm.$dirty = true;
        vm.calculateTotalPrice();
      }
    };

    vm.metier = null;
    vm.status = null;
    for (var i = 0; i < personMetierEntities.length; i++) {
      var personMetier = personMetierEntities[i];
      if (personMetier.id === vm.registration.personMetierId) {
        vm.metier = personMetier;
        for (var j = 0; j < vm.metier.personStatuses.length; j++) {
          var personStatus = vm.metier.personStatuses[j];
          if (personStatus.id === vm.registration.personStatusId) {
            vm.status = personStatus;
            break;
          }
        }
        break;
      }
    }

    vm.initCountryList = function () {
      vm.countries = [];
      CustomUtils.getCountries().then(function (result) {
        angular.forEach(result, function (value, key) {
          if (value.alpha2Code === vm.defaultCountry) {
            vm.countryToSelect = value.name;
          }
          vm.countries.push({
            name: value.name
          });
        });
      });
    };

    if (vm.isAdmin()) {
      vm.initCountryList();
    }

    vm.checkPhone = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.registration.phone);
      var isPhone = CustomUtils.isPhoneNumber(correctedPhone);
      if (vm.registration.phone !== correctedPhone) {
        $timeout(function () {
          $(event.target).jqxMaskedInput('clear');
          $(event.target).jqxMaskedInput('val', correctedPhone);
        }, 200);
      }
      vm.subscriptionForm.phone.$setValidity('minlength', isPhone);
    };

    vm.checkPortable = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.registration.portable);
      if (!CustomUtils.isEmpty(correctedPhone)) {
        var isPhoneNumber = CustomUtils.isPhoneNumber(correctedPhone);
        vm.subscriptionForm.portable.$setValidity('minlength', isPhoneNumber);
        if (vm.registration.portable !== correctedPhone) {
          $timeout(function () {
            $(event.target).jqxMaskedInput('clear');
            $(event.target).jqxMaskedInput('val', correctedPhone);
          }, 200);
        }
      } else {
        vm.subscriptionForm.portable.$setValidity('minlength', true);
      }
    };

    vm.sessionDropDownInit = false;

    vm.sessionDropdownSettings = {
      displayMember: 'name',
      valueMember: 'id',
      width: '100%',
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
      autoDropDownHeight: true,
      disabled: !vm.canEditSession(),
      created: function (event) {
        var instance = event.instance;

        vm.sessionDropDownInit = false;
        //initialisation de la valeur par défaut
        var found = false;
        angular.forEach(vm.registration.sessions, function (session) {
          if (!found) {
            angular.forEach(instance.source.records, function (availableSession) {
              if (!found && availableSession.id === session.id) {
                instance.selectItem(session.id);
                found = true;
              }
            });
          }
        });
        if (!found) {
          //selection "ne pas participer"
          instance.selectItem(-1);
        }

        //desactivation de la drowpdownlist pour les utilisateurs non admin
        // et pour les sessions payantes dès que l'inscription est validée
        // et que la date est encore ouverte.
        if (!vm.isAdmin() && vm.isOpen() && vm.registration.confirmed == true) {
          angular.forEach(instance.source.records, function (availableSession) {
            if (!CustomUtils.isUndefinedOrNull(availableSession.price)) {
              instance.setOptions({ disabled: true });
            }
          });
        }

        vm.sessionDropDownInit = true;
      },
      renderer: function (index, label, value) {
        var item = this.items[index];
        var originalItem = item.originalItem;
        if (originalItem.price && originalItem.price > 0) {
          return label + ' <span class="label label-info">+ ' + originalItem.price + ' €</span>';
        }
        return label;
      },
      selectionRenderer: function (element, index, label, value) {
        if (index !== -1) {
          var item = this.source.records[index];
          var result = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">';
          result += label;
          if (item.price && item.price > 0) {
            result += '&nbsp;<span class="label label-info">+ ' + item.price + ' €</span>';
            result += '</div>';
          }
          return result;
        }
      },
      select: function (event) {
        if (vm.sessionDropDownInit === true) {
          vm.subscriptionForm.$dirty = true;
          var originalItem = event.args.item.originalItem;
          if (originalItem.id !== -1) {
            //choix de participer à une session
            vm.registration.sessions.push(originalItem);
          }
          vm.calculateTotalPrice();
          vm.saveInscriptionEdition();
        }
      },
      unselect: function (event) {
        if (event.args.item) {
          var originalItem = event.args.item.originalItem;
          if (originalItem.id !== -1) {
            //choix de ne plus participer à une session
            angular.forEach(vm.registration.sessions, function (session, index) {
              if (session.id === originalItem.id) {
                vm.registration.sessions.splice(index, 1);
              }
            });
          }
        }
      }
    };

    vm.attestationAvailable = function () {
      if (vm.registration.confirmedPresence && DateUtils.compare(vm.mainEvent.endDate, new Date()) < 0) {
        return true;
      }
      return false;
    };

    vm.showAttestation = function () {
      DataUtils.openFileFromUrl('/api/public/main-events/attestation/' + vm.registration.id, 'Attestation.pdf');
    };

    vm.downloadAttestation = function () {
      DataUtils.downloadFileFromUrl('/api/public/main-events/attestation/' + vm.registration.id, 'Attestation.pdf');
    };

    vm.generateBill = function () {
      $log.info('Generation de la facture.');
      $uibModal
        .open({
          templateUrl: 'app/manifestation/subscription/dialog/orderRef.dialog.html',
          controller: 'OrderRefDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            billRef: function () {
              var billRef = vm.registration.billRef;
              if (CustomUtils.isEmpty(billRef)) {
                billRef = vm.mainEvent.prefix + '_' + vm.registration.id;
              }
              return billRef;
            },
            orderRef: function () {
              return vm.registration.orderRef;
            },
            participations: function () {
              return [vm.registration];
            }
          }
        })
        .result.then(
          function (result) {
            var updateRegistration = false;
            if (result.societyName !== null) {
              vm.registration.facturationSocietyName = result.societyName;
              updateRegistration = true;
            }
            if (result.societyAddress !== null) {
              vm.registration.facturationSocietyAddress = result.societyAddress;
              updateRegistration = true;
            }
            if (result.societyAddress !== null) {
              vm.registration.facturationSocietyAddress2 = result.societyAddress2;
              updateRegistration = true;
            }
            if (result.societyCp !== null) {
              vm.registration.facturationSocietyCp = result.societyCp;
              updateRegistration = true;
            }
            if (result.societyCity !== null) {
              vm.registration.facturationSocietyCity = result.societyCity;
              updateRegistration = true;
            }
            if (result.billRef !== vm.registration.billRef) {
              vm.registration.billRef = result.billRef;
              updateRegistration = true;
            }
            if (result.orderRef !== vm.registration.orderRef) {
              vm.registration.orderRef = result.orderRef;
              updateRegistration = true;
            }

            var promise = $q.when(true);
            if (updateRegistration) {
              promise = promise.then(function () {
                return EventRegistration.update(vm.registration, function (result) {
                  vm.registration = result;
                }).$promise;
              });
            }

            var billRef = result.billRef;
            var orderRef = result.orderRef;
            promise = promise.then(function () {
              return $http({
                method: 'GET',
                url: 'api/event-registrations/' + vm.registration.id + '/generate-bill',
                params: {
                  orderRef: orderRef,
                  billRef: billRef
                }
              }).then(function (result) {
                $state.reload();
              });
            });
          },
          function () {
            $log.info('Cancel');
          }
        );
    };

    vm.calculateTotalPrice = function () {
      if (vm.registration.confirmed !== true) {
        var status = {
          name: vm.registration.personStatusName,
          id: vm.registration.personStatusId
        };
        if (vm.freeRegistrationType === vm.freeType) {
          vm.registration.totalPrice = 0;
        } else {
          if (vm.freeRegistrationType === vm.partialFreeType) {
            vm.registration.totalPrice = 0;
          } else {
            vm.registration.totalPrice = MainEventService.getPriceForStatus(vm.prices, vm.mainEvent, status, vm.registration);
          }
          angular.forEach(vm.registration.sessions, function (session) {
            if (session.price) {
              vm.registration.totalPrice += Number(session.price);
            }
          });
          angular.forEach(vm.registration.eventOptions, function (option) {
            if (option.price) {
              vm.registration.totalPrice += Number(option.price);
            }
          });
        }
      }
    };

    vm.abstractDespositIsOpen = function () {
      if (vm.isAdmin()) {
        return true;
      }
      if (vm.isMarkAsDeleted()) {
        return false;
      }
      return AbstractService.abstractDespositIsOpen(vm.mainEvent);
    };

    vm.oralCommunicationDepositIsOpen = function () {
      return AbstractService.oralCommunicationDepositIsOpen(vm.mainEvent);
    };

    vm.posterDepositIsOpen = function () {
      return AbstractService.posterDepositIsOpen(vm.mainEvent);
    };

    vm.createAbstract = function () {
      if (AbstractService.abstractDespositIsOpen(vm.mainEvent)) {
        $state.go('createAbstract', {
          id: vm.mainEvent.id,
          key: vm.key
        });
      }
    };

    vm.canUpdateOrDeleteAbstract = function () {
      if (vm.isAdmin()) {
        return true;
      }
      if (vm.isMarkAsDeleted() || !CustomUtils.isUndefinedOrNull(vm.abstract.finalType)) {
        return false;
      }

      if (vm.abstract.type === 'OP') {
        if (AbstractService.oralCommunicationDepositIsOpen(vm.mainEvent) && AbstractService.posterDepositIsOpen(vm.mainEvent)) {
          return true;
        }
      } else if (vm.abstract.type === 'O') {
        return AbstractService.oralCommunicationDepositIsOpen(vm.mainEvent);
      } else if (vm.abstract.type === 'P') {
        return AbstractService.posterDepositIsOpen(vm.mainEvent);
      }
      return false;
    };

    vm.updateAbstract = function () {
      if (vm.canUpdateOrDeleteAbstract()) {
        $state.go('createAbstract', {
          id: vm.mainEvent.id,
          key: vm.key,
          abstractId: vm.abstract.id
        });
      }
    };

    vm.tryRemoveAbstract = function () {
      if (vm.canUpdateOrDeleteAbstract()) {
        ConfirmDialog.openConfirmRemove(vm.abstract.title, vm.removeAbstract, function () {
          $log.info('cancel');
        });
      }
    };

    vm.removeAbstract = function () {
      EventAbstract.deleteAllInformations(
        { id: vm.abstract.id },
        function () {
          $log.info('ok');
          vm.clearAbstract();
          abstractsDataAdapter.dataBind();
        },
        function (error) {
          $log.error('ko', error);
        }
      );
    };

    var abstractSource = {
      datatype: 'json',
      url: 'api/public/event-abstracts/' + vm.key + '/allByKey',
      datafields: [
        {
          name: 'id',
          type: 'number',
          map: 'eventAbstract>id'
        },
        {
          name: 'title',
          type: 'string',
          map: 'eventAbstract>title'
        },
        {
          name: 'axeName',
          type: 'string'
        },
        {
          name: 'sessionName',
          type: 'string'
        },
        {
          name: 'sessionStartDate',
          type: 'date'
        },
        {
          name: 'speaker',
          type: 'string'
        },
        {
          name: 'speakerId',
          type: 'number'
        },
        {
          name: 'type',
          type: 'string',
          map: 'eventAbstract>type'
        },
        {
          name: 'finalType',
          type: 'string',
          map: 'eventAbstract>finalType'
        },
        {
          name: 'reference',
          type: 'string',
          map: 'eventAbstract>reference'
        },
        {
          name: 'comments',
          type: 'string',
          map: 'eventAbstract>comments'
        },
        {
          name: 'assignCandidate',
          type: 'string',
          map: 'eventAbstract>assignCandidate'
        },
        {
          name: 'registrationKey',
          type: 'string',
          map: 'eventAbstract>registrationKey'
        },
        {
          name: 'speakerCity',
          type: 'string'
        },
        {
          name: 'speakerFunction',
          type: 'string'
        },
        {
          name: 'theme',
          type: 'string',
          map: 'eventAbstract>theme'
        },
        {
          name: 'accepted',
          type: 'bool',
          map: 'eventAbstract>accepted'
        },
        {
          name: 'creationMailDate',
          type: 'date',
          map: 'eventAbstract>creationMailDate'
        },
        {
          name: 'validationMailDate',
          type: 'date',
          map: 'eventAbstract>validationMailDate'
        }
      ],
      sortcolumn: 'title',
      sortdirection: 'asc',
      id: 'id'
    };

    var abstractsDataAdapter = new $.jqx.dataAdapter(abstractSource, {
      beforeLoadComplete: function (results, records) {
        angular.forEach(records, function (record, index) {
          var sessionStartDate = DateUtils.convertDateTimeFromServer(record.sessionStartDate);
          var finalRecord = results[index];
          finalRecord.sessionStartDate = sessionStartDate;
          var aPerson = null;

          angular.forEach(record.abstractPeople, function (person, personIndex) {
            if (person.speaker) {
              aPerson = person;
            }
          });
          if (aPerson) {
            finalRecord.speaker = aPerson.firstName + ' ' + aPerson.lastName;
            finalRecord.speakerId = aPerson.personId;
          }
        });
      }
    });

    var typeRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderFinalType(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    var acceptedRenderer = function (row, column, value, html, cell, object) {
      if (!CustomUtils.isEmpty(value)) {
        var images = AbstractService.renderAccepted(value);
        if (!CustomUtils.isEmpty(images)) {
          var result = html.replace(value, images);
          return result;
        }
      }
      return html;
    };

    var tooltiprenderer = function (element) {
      $(element)
        .parent()
        .jqxTooltip({ position: 'mouse', content: $(element).text() });
    };

    vm.abstractsGridSettings = {
      width: '100%',
      source: abstractsDataAdapter,
      height: 250,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      enabletooltips: true,
      altrows: true,
      columns: [
        {
          text: 'Id',
          dataField: 'id',
          cellsalign: 'center',
          align: 'center',
          filterable: false,
          width: 50
        },
        {
          text: 'Ref',
          dataField: 'reference',
          cellsalign: 'center',
          align: 'center',
          filterable: false,
          width: 50
        },
        {
          text: $translate.instant('subscription.abstract.type'),
          dataField: 'type',
          cellsrenderer: typeRenderer,
          cellsalign: 'center',
          align: 'center',
          filterable: false,
          width: 50
        },
        {
          text: $translate.instant('subscription.abstract.accepted'),
          dataField: 'accepted',
          cellsrenderer: acceptedRenderer,
          rendered: tooltiprenderer,
          cellsalign: 'center',
          align: 'center',
          filterable: false,
          width: 50
        },
        {
          text: $translate.instant('subscription.abstract.finalType'),
          dataField: 'finalType',
          cellsrenderer: typeRenderer,
          cellsalign: 'center',
          align: 'center',
          filterable: false,
          width: 40
        },
        {
          text: $translate.instant('subscription.abstract.speaker'),
          dataField: 'speaker'
        },
        {
          text: $translate.instant('subscription.abstract.title'),
          dataField: 'title'
        },
        {
          text: $translate.instant('subscription.abstract.theme'),
          datafield: 'theme'
        },
        {
          text: $translate.instant('subscription.abstract.axe'),
          dataField: 'axeName'
        },
        {
          text: $translate.instant('subscription.abstract.session'),
          dataField: 'sessionName'
        }
      ],
      created: function (args) {
        vm.abstractGrid = args.instance;
      },
      rowdoubleclick: function () {
        vm.updateAbstract();
      },
      rowselect: function (event) {
        var row = event.args.row;
        $log.info(row);
        vm.abstract = row;
        if (
          vm.abstract.accepted === false &&
          CustomUtils.isUndefinedOrNull(vm.abstract.finalType) &&
          (vm.abstract.type === 'O' || vm.abstract.type === 'OP')
        ) {
          ConfirmDialog.openYesNo(
            'Passage en poster ?',
            'Voulez-vous présenter votre communication sous forme de poster ?',
            function (userChoice) {
              var finalType = 'A';
              var abstractId = vm.abstract.id;
              if (userChoice) {
                finalType = 'P';
              }
              var toChange = {};
              toChange[abstractId] = finalType;

              $http.post('api/event-abstracts/finalTypes?sendMail=true', toChange).then(function (result) {
                var data = result.data;
                if (data.length === 1 && data[0] === abstractId) {
                  AlertService.success('Votre choix à bien été pris en compte.');
                } else {
                  AlertService.error("Impossible de mettre à jour l'abtract " + abstractId + ' avec le type final ' + finalType);
                }
                vm.abstractGrid.updatebounddata();
              });
            }
          );
        }
      }
    };

    vm.sameSociety = function (s1, s2) {
      s1 = s1.name.toLowerCase();
      s2 = s2.name.toLowerCase();
      return s1 === s2;
    };

    vm.clearAbstract = function () {
      vm.abstract = null;
      vm.abstractGrid.clearselection();
    };

    vm.openAbstract = function () {
      DataUtils.downloadFileFromUrl('api/event-abstracts/' + vm.abstract.id + '/pdf', vm.abstract.title + '.pdf');
    };

    vm.sendMail = function (type) {
      $log.info('Sending pre inscription mail to ', vm.registration.email);

      var url = null;
      if (type === 'PRE_INSCRIPTION') {
        url = 'api/event-registrations/' + vm.registration.id + '/send-pre-registration-mail';
      } else if (type === 'INSCRIPTION') {
        url = 'api/event-registrations/' + vm.registration.id + '/send-confirm-registration-mail';
      } else if (type === 'OP_CREATE') {
        url = 'api/event-registrations/' + vm.abstract.id + '/send-abstract-creation-mail';
      } else if (type === 'OP_VALID') {
        url = 'api/event-registrations/' + vm.abstract.id + '/send-abstract-validation-mail';
      }
      if (url !== null) {
        $http({
          method: 'GET',
          url: url
        }).then(function () {
          AlertService.info('Le mail à été envoyé à ' + vm.registration.email);
          vm.reloadRegistration();
          vm.clearAbstract();
          abstractsDataAdapter.dataBind();
        });
      }
    };

    vm.reloadRegistration = function () {
      EventRegistration.getByKey({ id: $stateParams.key }, function (result) {
        vm.registration = result;
      });
    };

    vm.tryUnLinkPerson = function () {
      var title = 'Confirmation de dissociation';
      var msg =
        'Confirmez-vous la dissociation de ' + vm.registration.firstName + ' ' + vm.registration.lastName + ' avec la fiche en base ?';
      ConfirmDialog.openYesNo(title, msg, function (result) {
        if (result === true) {
          vm.unlinkPerson();
        }
      });
    };

    vm.unlinkPerson = function () {
      $http({
        method: 'GET',
        url: 'api/event-registration/' + vm.registration.id + '/unLinkPerson/'
      })
        .success(function () {
          AlertService.success('Dissociation effectuée.');
          $state.reload();
        })
        .error(function () {
          AlertService.error('Problème lors de la dissociation.');
        });
    };

    vm.linkToPerson = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/mainevent/dialog/link-registration-person.dialog.html',
          controller: 'LinkRegistrationPersonDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                $translatePartialLoader.addPart('entities');
                return $translate.refresh();
              }
            ],
            personToSearch: function () {
              return {
                firstName: vm.registration.firstName,
                lastName: vm.registration.lastName
              };
            },
            abstractSpeakers: [
              '$http',
              function ($http) {
                return $http.get('api/public/event-registration/' + vm.registration.id + '/speakers');
              }
            ]
          }
        })
        .result.then(function (result) {
          $http({
            method: 'GET',
            url: 'api/event-registration/' + vm.registration.id + '/linkToPerson/' + result.person.id,
            params: {
              abstractPersonIds: result.abstractPersonIds
            }
          })
            .success(function () {
              AlertService.success('Association effectuée.');
              $state.reload();
            })
            .error(function () {
              AlertService.error("Problème lors de l'association.");
              $state.reload();
            });
        });
    };

    vm.tryUnLinkSociety = function () {
      var title = 'Confirmation de dissociation';
      var msg = 'Confirmez-vous la dissociation de ' + vm.registration.societyName + ' avec la fiche en base ?';
      ConfirmDialog.openYesNo(title, msg, function (result) {
        if (result === true) {
          vm.unlinkSociety();
        }
      });
    };

    vm.unlinkSociety = function () {
      vm.registration.societyId = null;
      EventRegistration.update(
        vm.registration,
        function (result) {
          AlertService.success('Modification effectuée.');
          vm.registration = result;
          $state.reload();
        },
        function () {
          AlertService.error('Problème lors de la dissociation.');
          $state.reload();
        }
      );
    };

    vm.linkToSociety = function () {
      $uibModal
        .open({
          templateUrl: 'app/manifestation/subscription/dialog/link-subscription-society.dialog.html',
          controller: 'LinkSubscriptionSocietyDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                $translatePartialLoader.addPart('entities');
                return $translate.refresh();
              }
            ],
            toSearch: function () {
              return vm.registration.societyName;
            }
          }
        })
        .result.then(function (result) {
          vm.registration.societyId = result.id;
          vm.registration.societyName = result.name;
          vm.registration.city = result.city;

          EventRegistration.update(
            vm.registration,
            function (result) {
              AlertService.success('Association effectuée.');
              vm.registration = result;
              $state.reload();
            },
            function () {
              AlertService.error("Problème lors de l'association.");
              $state.reload();
            }
          );
        });
    };

    vm.optionChange = function () {
      var selectedOptions = [];
      angular.forEach(vm.availableOptions, function (availableOption) {
        if (availableOption.selected === true) {
          selectedOptions.push(availableOption);
        }
      });
      vm.registration.eventOptions = selectedOptions;

      vm.calculateTotalPrice();
      EventRegistration.update(
        vm.registration,
        function (result) {
          vm.registration = result;
          $log.info(result);
          AlertService.info('Votre participation à été mise à jour.');
        },
        function (error) {
          $log.error(error);
          AlertService.error("Votre participation n'à pas pu être mise à jour.");
        }
      );
    };
  }
})();
