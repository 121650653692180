(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetPersonController', SheetPersonController);

  SheetPersonController.$inject = [
    'Principal',
    '$stateParams',
    '$state',
    'Person',
    'SheetHistoryService',
    'personEntity',
    '$translate',
    'ConfirmDialog',
    'AlertService',
    '$log',
    '$uibModal',
    '$http'
  ];

  function SheetPersonController(
    Principal,
    $stateParams,
    $state,
    Person,
    SheetHistoryService,
    personEntity,
    $translate,
    ConfirmDialog,
    AlertService,
    $log,
    $uibModal,
    $http
  ) {
    var vm = this;

    vm.Model = {
      id: $stateParams.id,
      entity: personEntity,
      canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
      edition: {
        title: false
      }
    };

    var sheetHistory = {
      name: vm.Model.entity.firstName + ' ' + vm.Model.entity.lastName,
      type: 'person',
      id: vm.Model.id
    };
    SheetHistoryService.add(sheetHistory);

    vm.load = function () {
      $state.go($state.current, {}, { reload: true });
    };

    vm.cancel = vm.load;

    vm.edit = function (win) {
      vm.Model.edition[win] = true;
    };

    vm.save = function () {
      return Person.update(vm.Model.entity, vm.load, vm.load);
    };

    vm.refreshIndex = function () {
      $http.get('api/person/index/' + vm.Model.id).then(function () {
        var name = vm.Model.entity.firstName + ' ' + vm.Model.entity.lastName;
        AlertService.success("L'indexation de " + name + ' est en cours');
      });
    };

    vm.tryRemovePerson = function () {
      $uibModal.open({
        templateUrl: 'app/components/dialog/dry-remove-entity.dialog.html',
        controller: 'DryRemoveEntityDialogController',
        controllerAs: 'ctrl',
        size: 'md',
        backdrop: 'static',
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('dialog');
              return $translate.refresh();
            }
          ],
          entity: function () {
            return vm.Model.entity;
          },
          deletionMsgKey: function () {
            return 'canceropoleGsoApp.person.delete.question';
          },
          dryDeleteResult: function () {
            return $http.delete('api/person/' + vm.Model.entity.id + '/dry').then(function (response) {
              return response.data;
            });
          },
          dryInfos: function () {
            return [
              {
                key: 'abstractPersonCount',
                msg: 'abstract(s) lié(s) à cette personne..'
              },
              { key: 'callForProjectManagementCount', msg: "aap(s) lié(s) à cette personne en tant qu'organisateur." },
              { key: 'eventManagementCount', msg: "évènement(s) lié(s) à cette personne en tant qu'organisateur." },
              { key: 'expertCount', msg: "aap(s) lié(s) à cette personne en tant qu'expert." },
              { key: 'groupPersonCount', msg: 'groupe(s) lié(s) à cette personne.' },
              { key: 'jobCount', msg: 'parcour(s) lié(s) à cette personne.' },
              { key: 'personAxeCount', msg: 'axe(s) lié(s) à cette personne.' },
              { key: 'personSocietyInputCount', msg: "abstract(s) d'une structure lié(s) à cette personne." },
              { key: 'projectParticipationCount', msg: 'projet(s) lié(s) à cette personne.' },
              { key: 'publicationCount', msg: 'publication(s) liée(s) à cette personne.' },
              { key: 'teamCount', msg: 'équipe(s) liée(s) à cette personne.' },
              { key: 'registrationCount', msg: 'évènement(s) lié(s) à cette personne.' },
              { key: 'userCount', msg: 'utilisateur(s) lié(s) à cette personne.' }
            ];
          },
          onConfirmDeletion: [
            '$q',
            function ($q) {
              return function () {
                var defer = $q.defer();
                Person.delete(
                  { id: vm.Model.entity.id },
                  function () {
                    AlertService.success('Personne supprimée');
                    defer.resolve();
                  },
                  function () {
                    AlertService.error('Problème lors de la suppression de la personne.');
                    defer.reject();
                  }
                );
                return defer.promise;
              };
            }
          ]
        }
      });
    };

    vm.tabs = [
      {
        title: $translate.instant('sheet.person.general.tab'),
        route: 'sheet.person.main'
      },
      {
        title: $translate.instant('sheet.person.followed.tab'),
        route: 'sheet.person.followed'
      },
      {
        title: $translate.instant('sheet.person.communication.tab'),
        route: 'sheet.person.communication'
      },
      {
        title: $translate.instant('sheet.person.project.tab'),
        route: 'sheet.person.project'
      }
    ];

    var activeTab = $stateParams.tab;

    vm.activeTabIndex = 0;
    angular.forEach(vm.tabs, function (tab, index) {
      if (tab.route.endsWith(activeTab)) {
        vm.activeTabIndex = index;
      }
    });

    $state.go(vm.tabs[vm.activeTabIndex].route);
  }
})();
