(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddOrganizationDialogController', AddOrganizationDialogController);

    AddOrganizationDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'availableOrganizations', 'societyId', 'teamId', 'SocietyOrganization', 'TeamOrganization'];

    function AddOrganizationDialogController($translate, $translatePartialLoader, $uibModalInstance, availableOrganizations, societyId, teamId, SocietyOrganization, TeamOrganization) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;

        ctrl.result = {
            id: null,
            organizationId: null,
            code: null,
            position: 1
        };
        if (!_.isNil(societyId)) {
            ctrl.result.societyId = societyId;
        } else if (!_.isNil(teamId)) {
            ctrl.result.teamId = teamId;
        }

        var availableOrganizationDataAdapter = new $.jqx.dataAdapter({
            localdata: availableOrganizations,
            datatype: 'json',
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }]
        });


        ctrl.organizationDropDownListSettings = {
            source: availableOrganizationDataAdapter,
            displayMember: 'name',
            height: 35,
            valueMember: 'id'
        };

        ctrl.ok = function () {
            if (!_.isNil(ctrl.result.societyId)) {
                SocietyOrganization.save(ctrl.result, function (response) {
                    $uibModalInstance.close();
                });
            } else if (!_.isNil(ctrl.result.teamId)) {
                TeamOrganization.save(ctrl.result, function (response) {
                    $uibModalInstance.close();
                });
            }
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();