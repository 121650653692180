(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectMailDetailController', CallForProjectMailDetailController);

    CallForProjectMailDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'CallForProjectMail', 'CallForProject'];

    function CallForProjectMailDetailController($scope, $rootScope, $stateParams, DataUtils, entity, CallForProjectMail, CallForProject) {
        var vm = this;

        vm.callForProjectMail = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectMailUpdate', function(event, result) {
            vm.callForProjectMail = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
