(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('cfpFormProjectController', cfpFormProjectController);

  cfpFormProjectController.$inject = [
    'CustomUtils',
    'Principal',
    'CallForProjectService',
    '$http',
    '$state',
    'AlertService',
    'Submission',
    '$stateParams',
    '$translate',
    'JQWidgetsService',
    '$log',
    '$scope',
    '$timeout'
  ];

  function cfpFormProjectController(
    CustomUtils,
    Principal,
    CallForProjectService,
    $http,
    $state,
    AlertService,
    Submission,
    $stateParams,
    $translate,
    JQWidgetsService,
    $log,
    $scope,
    $timeout
  ) {
    var ctrl = this;
    ctrl.spaceKey = $stateParams.key;
    ctrl.editProject = false;
    ctrl.isSaving = false;

    ctrl.formConfig = CallForProjectService.getFormConfig(ctrl.callForProject);

    if (angular.isUndefined(ctrl.readOnly)) {
      ctrl.readOnly = false;
    }

    ctrl.loadAxe = function () {
      ctrl.axe = null;
      for (var i = 0; i < ctrl.callForProject.axes.length; i++) {
        var axe = ctrl.callForProject.axes[i];
        if (axe.id === ctrl.submission.axeId) {
          ctrl.axe = axe;
          break;
        }
      }
    };

    ctrl.isAdmin = function () {
      return Principal.hasAnyAuthority(['ROLE_ADMIN']);
    };

    ctrl.isOpenForm = function () {
      return CallForProjectService.isOpenForForm(ctrl.form);
    };

    ctrl.reload = function () {
      $state.go($state.current, {}, { reload: true });
    };

    ctrl.preventMaxLength = function () {
      if (
        !CustomUtils.isUndefinedOrNull(ctrl.callForProject.descMaxLength) &&
        !CustomUtils.isUndefinedOrNull(ctrl.project.description) &&
        ctrl.project.description.length >= ctrl.callForProject.descMaxLength
      ) {
        ctrl.project.description = ctrl.project.description.substring(0, ctrl.callForProject.descMaxLength);
      }
    };

    ctrl.axeDropDownListSettings = {
      height: 30,
      //width: 100,
      autoDropDownHeight: true,
      displayMember: 'name',
      valueMember: 'id',
      placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
      created: function (args) {
        ctrl.axeDropdownList = args.instance;
      },
      source: ctrl.callForProject.axes
    };

    ctrl.canSaveProject = function () {
      if (ctrl.isSaving) {
        return false;
      }
      var projectForm = ctrl.projectForm;
      if (ctrl.callForProject.canSaveSubmission) {
        var errors = projectForm.$error;
        var requireds = errors.required;
        var hasError = false;
        angular.forEach(requireds, function (aRequiredField) {
          if (aRequiredField.$name === 'name') {
            hasError = true;
          }
        });
        return !hasError;
      }
      if (projectForm.$valid === true) {
        return true;
      }
      return false;
    };

    ctrl.saveProject = function (doNotCheckValid) {
      if (ctrl.projectForm.$valid === true || doNotCheckValid) {
        if (ctrl.isSaving === false && ctrl.projectForm.$dirty === true) {
          ctrl.isSaving = true;

          ctrl.checkAndSetMax();

          $http.put('api/submission/project/' + ctrl.spaceKey, ctrl.project).then(function (response) {
            if (response.status === 200) {
              ctrl.submission.name = ctrl.project.name;
              Submission.update(ctrl.submission, function () {
                AlertService.success('Informations sauvegardées.');
                ctrl.reload();
              });
            } else {
              ctrl.isSaving = false;
              AlertService.error('Impossible de sauvegarder en base.');
            }
          });
        }
      } else {
        AlertService.warning('Veuillez corriger les erreurs avant de sauvegarder.');
      }
    };

    ctrl.getMaxBudget = function () {
      if (ctrl.callForProject && ctrl.callForProject.defaultBudget) {
        return ctrl.callForProject.defaultBudget;
      }
      return 1000000;
    };

    ctrl.getMaxDuration = function () {
      if (ctrl.callForProject && ctrl.callForProject.defaultDuration) {
        return ctrl.callForProject.defaultDuration;
      }
      return 10000;
    };

    ctrl.checkAndSetMax = function () {
      var max = ctrl.getMaxBudget();
      if (ctrl.submission.askedBudget > max) {
        ctrl.submission.askedBudget = max;
      }
      max = ctrl.getMaxDuration();
      if (ctrl.submission.askedDuration > max) {
        ctrl.submission.askedDuration = max;
      }
    };

    ctrl.$onChanges = function () {
      ctrl.loadAxe();
    };
  }
})();
