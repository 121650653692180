(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .factory('SheetHistoryService', SheetHistoryService);

    SheetHistoryService.$inject = ['localStorageService', 'CustomUtils'];

    function SheetHistoryService(localStorageService, CustomUtils) {

        var that = this;

        this.historySize = 10;

        var service = {
            init: init,
            add: add,
            get: get,
            getLast: getLast,
            save: save,
            remove: remove
        };

        service.init();

        return service;

        function init() {
            var history = localStorageService.get('sheetHistory');
            if (CustomUtils.isUndefinedOrNull(history)) {
                this.sheetHistory = [];
                this.save();
            } else {
                this.sheetHistory = history;
            }
        }

        function add(sheet) {
            var index = null;
            for (var i = 0; i < this.sheetHistory.length; i++) {
                var history = this.sheetHistory[i];

                if (history.type == sheet.type && history.id == sheet.id) {
                    index = i;
                    break;
                }
            }
            if (index !== null) {
                this.sheetHistory.splice(index, 1);
            } else {
                while (this.sheetHistory.length >= that.historySize) {
                    this.sheetHistory.pop();
                }
            }
            this.sheetHistory.unshift(sheet);

            this.save();
        }

        function remove(sheet) {
            var index = null;
            for (var i = 0; i < this.sheetHistory.length; i++) {
                var history = this.sheetHistory[i];

                if (history.type == sheet.type && history.id == sheet.id) {
                    index = i;
                }
            }
            if (index !== null) {
                this.sheetHistory.splice(index, 1);
            }
            this.save();
        }

        function get(index) {
            return this.sheetHistory[index];
        }

        function getLast() {
            return this.sheetHistory[0];
        }
        function save() {
            localStorageService.add("sheetHistory", this.sheetHistory);
        }
    }
})();