(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('CallForProjectTypeDetailController', CallForProjectTypeDetailController);

    CallForProjectTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CallForProjectType'];

    function CallForProjectTypeDetailController($scope, $rootScope, $stateParams, entity, CallForProjectType) {
        var vm = this;

        vm.callForProjectType = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:callForProjectTypeUpdate', function(event, result) {
            vm.callForProjectType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
