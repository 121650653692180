(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'SheetHistoryService'];

    function NavbarController($scope, $state, Auth, Principal, ProfileService, LoginService, SheetHistoryService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.historyList = SheetHistoryService.sheetHistory;

        $scope.$watchCollection(SheetHistoryService.sheetHistory, function () {
            vm.historyList = SheetHistoryService.sheetHistory;
        });

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        Principal.identity().then(function (response) {
            vm.identity = response;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        vm.goToSheet = function (history) {
            var stateName = 'sheet';
            if (history.type) {
                stateName += '.' + history.type;
            }
            $state.go(stateName, history);
        };

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home', {}, {reload: true});
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
