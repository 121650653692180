(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ExpertDeleteController',ExpertDeleteController);

    ExpertDeleteController.$inject = ['$uibModalInstance', 'entity', 'Expert'];

    function ExpertDeleteController($uibModalInstance, entity, Expert) {
        var vm = this;

        vm.expert = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Expert.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
