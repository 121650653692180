(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
        .factory('MainEventService', MainEventService);

    MainEventService.$inject = ['$translate', 'DateUtils', 'AlertService', '$translatePartialLoader'];

    function MainEventService($translate, DateUtils, AlertService, $translatePartialLoader) {
        $translatePartialLoader.addPart('eventPrice');
        $translate.refresh();

        var mainEventMails = [{
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.PRE_INSCRIPTION'),
            value: 'PRE_INSCRIPTION',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.PRE_INSCRIPTION_HELP')
        }, {
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.INSCRIPTION'),
            value: 'INSCRIPTION',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.INSCRIPTION_HELP')
        }];

        var communicationMails = [{
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.OP_CREATE'),
            value: 'OP_CREATE',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.OP_CREATE_HELP')
        }, {
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.OP_REJECT'),
            value: 'OP_REJECT',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.OP_REJECT_HELP')
        }];

        var oralMails = [{
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.O_REJECT'),
            value: 'O_REJECT',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.O_REJECT_HELP')
        }];

        var posterMails = [{
            label: $translate.instant('canceropoleGsoApp.MainEventMailType.P_CHOSEN'),
            value: 'P_CHOSEN',
            help: $translate.instant('canceropoleGsoApp.MainEventMailType.P_CHOSEN_HELP')
        }];

        var service = {
            getPriceForStatus: function (prices, mainEvent, status, registration) {
                if (mainEvent.freeRegistration) {
                    return 0;
                }
                var now = new Date();
                if (registration) {
                    now = registration.registrationDate;
                }

                for (var i = 0; i < prices.length; i++) {
                    var price = prices[i];
                    for (var j = 0; j < price.personStatuses.length; j++) {
                        var personStatus = price.personStatuses[j];
                        if (personStatus.id === status.id) {
                            if (price.startDate) {
                                var start = DateUtils.convertDateTimeFromServer(price.startDate);
                                if (DateUtils.compare(now, start) > 0) {
                                    return price.price;
                                }
                            } else if (price.endDate) {
                                var end = DateUtils.convertDateTimeFromServer(price.endDate);
                                if (DateUtils.compare(now, end) < 0) {
                                    return price.price;
                                }
                            }
                        }
                    }
                }
                $translate('canceropoleGsoApp.eventPrice.errors.nopriceforstatus', { status: status.name }).then(function (msg) {
                    AlertService.error(msg);
                });

                return null;
            },
            getMailTypes: function (mainEvent) {
                var result = angular.copy(mainEventMails);
                if (mainEvent.hasOral || mainEvent.hasPoster) {
                    result = result.concat(angular.copy(communicationMails));
                }
                if (mainEvent.hasPoster) {
                    result = result.concat(angular.copy(posterMails));
                }
                if (mainEvent.hasOral) {
                    result = result.concat(angular.copy(oralMails));
                }
                return result;
            }
        };

        return service;
    }

})();
