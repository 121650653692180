(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LinkSocietyDialogController', LinkSocietyDialogController);

    LinkSocietyDialogController.$inject = ['$uibModalInstance', 'abstractSocieties', '$log', 'SocietySearch', 'AlertService', 'AbstractSociety'];

    function LinkSocietyDialogController($uibModalInstance, abstractSocieties, $log, SocietySearch, AlertService, AbstractSociety) {

        var ctrl = this;

        ctrl.abstractSocieties = abstractSocieties;

        ctrl.society = null;
        ctrl.result = null;

        $log.info(ctrl.abstractSocieties);

        var societySource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: ctrl.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    ctrl.result = result;
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.societyComboBox = args.instance;
                var toSearch = ctrl.abstractSocieties.name;
                ctrl.societyComboBox.val(toSearch);
                ctrl.societyComboBoxSettings.search(toSearch);
            },
            select: function () {
            },
            unselect: function () {
                ctrl.societyId = null;
            },
            change: function () {
                var selection = ctrl.societyComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.societyId = null;
                }
            }
        };

        ctrl.onSelect = function (society) {
            ctrl.society = society;
            $log.info("Selection", ctrl.society);
            $log.info("for", ctrl.abstractSocieties);
        };

        ctrl.ok = function () {

            angular.forEach(ctrl.abstractSocieties.abstractSocieties, function (abstractSociety) {
                abstractSociety.societyId = ctrl.society.id;
                AbstractSociety.update(abstractSociety, function () {
                    AlertService.success("Association effectuée.");
                    $uibModalInstance.close();
                }, function () {
                    AlertService.error("Problème lors de l'association.");
                });
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();