(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .component('expertisePolarChart', {
                controller: 'ExpertisePolarChartController',
                bindings: {
                    aapId: '@',
                    expertises: '<',
                    radius: '@',
                    width: '@',
                    height: '@'
                },
                templateUrl: 'app/components/expertise/expertise-polar-chart.html'
            });
})();
