(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('MapSearchService', MapSearchService);

    MapSearchService.$inject = ['$resource'];

    function MapSearchService($resource) {
        return $resource('api/_map/:query', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                headers: {
                    'content-type': 'application/json'
                }
            }
        });
    }
})();
