(function() {
    'use strict';

    angular
        .module('canceropoleGsoApp')
        .controller('ProjectParticipationDetailController', ProjectParticipationDetailController);

    ProjectParticipationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProjectParticipation', 'Project', 'Person'];

    function ProjectParticipationDetailController($scope, $rootScope, $stateParams, entity, ProjectParticipation, Project, Person) {
        var vm = this;

        vm.projectParticipation = entity;

        var unsubscribe = $rootScope.$on('canceropoleGsoApp:projectParticipationUpdate', function(event, result) {
            vm.projectParticipation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
