(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetSocietyTeamTabController', SheetSocietyTeamTabController);

  SheetSocietyTeamTabController.$inject = [
    'Principal',
    '$stateParams',
    '$state',
    'JQWidgetsService',
    '$translate',
    '$uibModal',
    'CustomUtils',
    'societyEntity',
    'ConfirmDialog',
    '$http',
    'TeamType',
    'AlertService',
    '$log'
  ];

  function SheetSocietyTeamTabController(
    Principal,
    $stateParams,
    $state,
    JQWidgetsService,
    $translate,
    $uibModal,
    CustomUtils,
    society,
    ConfirmDialog,
    $http,
    TeamType,
    AlertService,
    $log
  ) {
    if ($stateParams.tab !== 'team') {
      //update state param
      $stateParams.tab = 'team';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;
    vm.society = society;
    vm.teamTypes = TeamType.query();
    vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

    var teamSource = {
      datatype: 'json',
      url: 'api/society/' + vm.society.id + '/team',
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'shortName',
          type: 'string'
        },
        {
          name: 'city',
          type: 'string'
        },
        {
          name: 'organizationName',
          type: 'string'
        },
        {
          name: 'webSite',
          type: 'string'
        },
        {
          name: 'phone',
          type: 'string'
        },
        {
          name: 'teamTypeId',
          type: 'number'
        },
        {
          name: 'typeTypeName',
          type: 'string'
        },
        {
          name: 'email',
          type: 'string'
        }
      ],
      sortcolumn: 'name',
      sortdirection: 'asc',
      id: 'job>id'
    };

    var teamNameRenderer = function (row, column, value, html, cell, object) {
      var link = '<a href="#/sheet/team/' + object.id + '?tab=main">';
      link += value;
      link += '</a>';

      var result = html.replace(value, link);
      return result;
    };

    var linkRenderer = function (row, column, value, html, cell, object) {
      var link = '<a href="' + value + '" target="_blank">';
      link += value;
      link += '</a>';

      var result = html.replace(value, link);
      return result;
    };

    var mailToRenderer = function (row, column, value, html, cell, object) {
      var link = '<a href="mailto:' + value + '">';
      link += value;
      link += '</a>';

      var result = html.replace(value, link);
      return result;
    };

    var teamsDataAdapter = new $.jqx.dataAdapter(teamSource, {
      beforeLoadComplete: function (records) {
        angular.forEach(records, function (record) {
          if (!CustomUtils.isUndefinedOrNull(record.teamTypeId)) {
            for (var i = 0; i < vm.teamTypes.length; i++) {
              var teamType = vm.teamTypes[i];
              if (teamType.id === record.teamTypeId) {
                record.teamTypeName = teamType.name;
                break;
              }
            }
          }
        });
      }
    });

    vm.teamSelected = null;
    vm.teamsGridSettings = {
      width: '100%',
      source: teamsDataAdapter,
      localization: JQWidgetsService.getLocalization($translate.use()),
      height: 500,
      columnsresize: true,
      sortable: true,
      filterable: true,
      showfilterrow: true,
      altrows: true,
      created: function (args) {
        vm.teamsGrid = args.instance;
      },
      rowselect: function (event) {
        vm.teamSelected = null;
        if (event.args) {
          vm.teamSelected = event.args.row;
        }
      },
      rowunselect: function () {},
      columns: [
        {
          text: 'Nom',
          datafield: 'name',
          minwidth: 200,
          cellsrenderer: teamNameRenderer,
          pinned: true
        },
        {
          text: 'Nom abrégé',
          datafield: 'shortName',
          cellsalign: 'center',
          align: 'center',
          filtertype: 'checkedlist',
          width: 120
        },
        {
          text: 'Type',
          datafield: 'teamTypeName',
          cellsalign: 'center',
          filtertype: 'checkedlist',
          align: 'center',
          width: 150
        },
        {
          text: 'Organisation',
          datafield: 'organizationName',
          cellsalign: 'center',
          align: 'center',
          filtertype: 'checkedlist',
          width: 120
        },
        {
          text: 'Email',
          datafield: 'email',
          cellsalign: 'center',
          align: 'center',
          cellsrenderer: mailToRenderer,
          width: 200
        },
        {
          text: 'Téléphone',
          datafield: 'phone',
          cellsalign: 'center',
          align: 'center',
          width: 120
        },
        {
          text: 'Site web',
          datafield: 'webSite',
          cellsrenderer: linkRenderer,
          minwidth: 200
        }
      ]
    };

    vm.addTeam = function () {
      vm.editTeam();
    };

    vm.editTeam = function (data) {
      $uibModal
        .open({
          templateUrl: 'app/add/team/add.team.dialog.html',
          controller: 'AddTeamDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'md',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('dialog');
                $translatePartialLoader.addPart('team');
                return $translate.refresh();
              }
            ],
            team: function () {
              if (CustomUtils.isUndefinedOrNull(data)) {
                return {
                  id: null,
                  publique: true
                };
              }
            },
            geosites: [
              'Geosite',
              function (Geosite) {
                return Geosite.query().$promise;
              }
            ],
            society: vm.society
          }
        })
        .result.then(function (result) {
          if (result.goToSheet) {
            $state.go(
              'sheet.team',
              {
                id: result.team.id,
                type: 'team',
                name: result.team.name
              },
              {
                reload: true
              }
            );
          } else {
            vm.teamsGrid.updatebounddata();
          }
        });
    };

    vm.tryRemoveTeam = function () {
      ConfirmDialog.openConfirmRemoveLink(vm.teamSelected.name, vm.removeTeam);
    };

    vm.removeTeam = function () {
      vm.teamSelected.societyId = null;
      var url = 'api/society/' + vm.society.id + '/team/' + vm.teamSelected.id;
      $http.delete(url).then(
        function () {
          var msg = 'Sauvegarde effectuée';
          AlertService.success(msg);
          vm.teamSelected = null;
          vm.teamsGrid.clearselection();
          vm.teamsGrid.updatebounddata();
        },
        function (response) {
          var msg = "Impossible de supprimer l'équipe. Cause : " + response.statusText;
          $log.error(msg);
          AlertService.error(msg);
        }
      );
    };
  }
})();
